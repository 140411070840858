import mixpanel from 'mixpanel-browser';
mixpanel.init("972878fce13f7a9fc66731f4f0b478ad");

let env_check = true;

let actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);           //track unique id everywhere
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(name, props);              //alias used for that unique id (guest => member)
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name,props);     // track events   name="Register" props={"phNum": "123", "name": "Abc"}
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);     // user profile info    props= {"type": "Guest"}
    },
    set_once: (props) => {
      if (env_check) mixpanel.people.set_once(props);     // user profile info (only sets if property doesn't already exist)   props= {"type": "Guest"}
    },
    increment: (props) => {
      if (env_check) mixpanel.people.increment(props);     // user profile info    props= {"Count": 1}
    },
  },
};

export let Mixpanel = actions;
