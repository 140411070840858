export const INIT_APP = 'INIT_APP';

export const GET_USER_LOGOUT_REQUESTED = 'GET_USER_LOGOUT_REQUESTED';
export const GET_USER_LOGOUT_SUCCEEDED = 'GET_USER_LOGOUT_SUCCEEDED';
export const GET_USER_LOGOUT_FAILED = 'GET_USER_LOGOUT_FAILED';

export const VERIFY_RESET_CODE_REQUESTED = 'VERIFY_RESET_CODE_REQUESTED';
export const VERIFY_RESET_CODE_SUCCEEDED = 'VERIFY_RESET_CODE_SUCCEEDED';
export const VERIFY_RESET_CODE_FAILED = 'VERIFY_RESET_CODE_FAILED';

export const RESET_PASSWORD_REQUESTED = 'RESET_PASSWORD_REQUESTED';
export const RESET_PASSWORD_SUCCEEDED = 'RESET_PASSWORD_SUCCEEDED';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

export const GET_ME_REQUESTED = 'GET_ME_REQUESTED';
export const GET_ME_SUCCEEDED = 'GET_ME_SUCCEEDED';
export const GET_ME_FAILED = 'GET_ME_FAILED';

export const GET_USER_REFRESH_REQUESTED = 'GET_USER_REFRESH_REQUESTED';
export const GET_USER_REFRESH_SUCCEEDED = 'GET_USER_REFRESH_SUCCEEDED';
export const GET_USER_REFRESH_FAILED = 'GET_USER_REFRESH_FAILED';

export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const USER_LOGGED_OUT_SUCCEEDED = 'USER_LOGGED_OUT_SUCCEEDED';

export const POST_NEW_USER_REQUESTED = 'POST_NEW_USER_REQUESTED';
export const POST_NEW_USER_SUCCEEDED = 'POST_NEW_USER_SUCCEEDED';
export const POST_NEW_USER_FAILED = 'POST_NEW_USER_FAILED';

export const GET_USER_LOGIN_REQUESTED = 'GET_USER_LOGIN_REQUESTED';
export const GET_USER_LOGIN_SUCCEEDED = 'GET_USER_LOGIN_SUCCEEDED';
export const GET_USER_LOGIN_FAILED = 'GET_USER_LOGIN_FAILED';

export const GET_USER_PASSWORD_LINK_REQUESTED =
  'GET_USER_PASSWORD_LINK_REQUESTED';
export const GET_USER_PASSWORD_LINK_SUCCEEDED =
  'GET_USER_PASSWORD_LINK_SUCCEEDED';
export const GET_USER_PASSWORD_LINK_FAILED = 'GET_USER_PASSWORD_LINK_FAILED';

export const RESET_STUDENT_TEST_REPORT = 'RESET_STUDENT_TEST_REPORT';

export const GET_SCHOOL_CODE_SUBMISSION_REQUESTED =
  'GET_SCHOOL_CODE_SUBMISSION_REQUESTED';
export const GET_SCHOOL_CODE_SUBMISSION_SUCCEEDED =
  'GET_SCHOOL_CODE_SUBMISSION_SUCCEEDED';
export const GET_SCHOOL_CODE_SUBMISSION_FAILED =
  'GET_SCHOOL_CODE_SUBMISSION_FAILED';

export const GET_SCHOOL_PIN_SUBMISSION_REQUESTED =
  'GET_SCHOOL_PIN_SUBMISSION_REQUESTED';
export const GET_SCHOOL_PIN_SUBMISSION_SUCCEEDED =
  'GET_SCHOOL_PIN_SUBMISSION_SUCCEEDED';
export const GET_SCHOOL_PIN_SUBMISSION_FAILED =
  'GET_SCHOOL_PIN_SUBMISSION_FAILED';

export const GET_SCHOOL_GRADES_REQUESTED = 'GET_SCHOOL_GRADES_REQUESTED';
export const GET_SCHOOL_GRADES_SUCCEEDED = 'GET_SCHOOL_GRADES_SUCCEEDED';
export const GET_SCHOOL_GRADES_FAILED = 'GET_SCHOOL_GRADES_FAILED';

export const GET_SCHOOL_GRADE_SELECTED = 'GET_SCHOOL_GRADE_SELECTED';

export const GET_CLASS_CREATE_SELECTED = 'GET_CLASS_CREATE_SELECTED';

export const GET_CREATE_CLASS_REQUESTED = 'GET_CREATE_CLASS_REQUESTED';
export const GET_CREATE_CLASS_SUCCEEDED = 'GET_CREATE_CLASS_SUCCEEDED';
export const GET_CREATE_CLASS_FAILED = 'GET_CREATE_CLASS_FAILED';

export const RESET_CREATE_CLASS = 'RESET_CREATE_CLASS';

export const GET_STUDENT_EXCEL_DOWNLOAD_REQUESTED = 'GET_STUDENT_EXCEL_DOWNLOAD_REQUESTED';
export const GET_STUDENT_EXCEL_DOWNLOAD_SUCCEEDED = 'GET_STUDENT_EXCEL_DOWNLOAD_SUCCEEDED';
export const GET_STUDENT_EXCEL_DOWNLOAD_FAILED = 'GET_STUDENT_EXCEL_DOWNLOAD_FAILED';

export const GET_STUDENT_EXCEL_UPLOAD_REQUESTED = 'GET_STUDENT_EXCEL_UPLOAD_REQUESTED';
export const GET_STUDENT_EXCEL_UPLOAD_SUCCEEDED = 'GET_STUDENT_EXCEL_UPLOAD_SUCCEEDED';
export const GET_STUDENT_EXCEL_UPLOAD_FAILED = 'GET_STUDENT_EXCEL_UPLOAD_FAILED';

export const EMPTY_EXCEL_UPLOAD = 'EMPTY_EXCEL_UPLOAD';

export const GET_CREATE_MULTI_CLASS_REQUESTED = 'GET_CREATE_MULTI_CLASS_REQUESTED';
export const GET_CREATE_MULTI_CLASS_SUCCEEDED = 'GET_CREATE_MULTI_CLASS_SUCCEEDED';
export const GET_CREATE_MULTI_CLASS_FAILED = 'GET_CREATE_MULTI_CLASS_FAILED';

export const GET_PIN_SUBMIT_REQUESTED = 'GET_PIN_SUBMIT_REQUESTED';
export const GET_PIN_SUBMIT_SUCCEEDED = 'GET_PIN_SUBMIT_SUCCEEDED';
export const GET_PIN_SUBMIT_FAILED = 'GET_PIN_SUBMIT_FAILED';

export const GET_NEW_CLASS_INITIALIZED = 'GET_NEW_CLASS_INITIALIZED';

export const CLASS_SELECTED = 'CLASS_SELECTED';

export const MODE_SELECTED = 'MODE_SELECTED';

export const GET_SCREEN_ERRORS_MAPPING_REQUESTED =  'GET_SCREEN_ERRORS_MAPPING_REQUESTED';
export const GET_SCREEN_ERRORS_MAPPING_SUCCEEDED =  'GET_SCREEN_ERRORS_MAPPING_SUCCEEDED';
export const GET_SCREEN_ERRORS_MAPPING_FAILED =  'GET_SCREEN_ERRORS_MAPPING_FAILED';

export const SUBMIT_LOGIN_ERROR_REPORT_REQUESTED = 'SUBMIT_LOGIN_ERROR_REPORT_REQUESTED';
export const SUBMIT_LOGIN_ERROR_REPORT_SUCCEEDED = 'SUBMIT_LOGIN_ERROR_REPORT_SUCCEEDED';
export const SUBMIT_LOGIN_ERROR_REPORT_FAILED = 'SUBMIT_LOGIN_ERROR_REPORT_FAILED';

export const RESET_SUBMIT_LOGIN_ERROR_REPORT = 'RESET_SUBMIT_LOGIN_ERROR_REPORT'; 

export const SUBMIT_ERROR_REPORT_REQUESTED = 'SUBMIT_ERROR_REPORT_REQUESTED';
export const SUBMIT_ERROR_REPORT_SUCCEEDED = 'SUBMIT_ERROR_REPORT_SUCCEEDED';
export const SUBMIT_ERROR_REPORT_FAILED = 'SUBMIT_ERROR_REPORT_FAILED';

export const RESET_SUBMIT_ERROR_REPORT = 'RESET_SUBMIT_ERROR_REPORT';

export const CLASS_CATEGORY_SELECTED = 'CLASS_CATEGORY_SELECTED';

export const GET_CHAPTERS_REQUESTED = 'GET_CHAPTERS_REQUESTED';
export const GET_CHAPTERS_SUCCEEDED = 'GET_CHAPTERS_SUCCEEDED';
export const GET_CHAPTERS_FAILED = 'GET_CHAPTERS_FAILED';

export const GET_STUDENTS_REQUESTED = 'GET_STUDENTS_REQUESTED';
export const GET_STUDENTS_SUCCEEDED = 'GET_STUDENTS_SUCCEEDED';
export const GET_STUDENTS_FAILED = 'GET_STUDENTS_FAILED';

export const DELETE_STUDENT_REQUESTED = 'DELETE_STUDENT_REQUESTED';
export const DELETE_STUDENT_SUCCEEDED = 'DELETE_STUDENT_SUCCEEDED';
export const DELETE_STUDENT_FAILED = 'DELETE_STUDENT_FAILED';

export const GET_CHAPTERS_SELECTED = 'GET_CHAPTERS_SELECTED';

export const GET_CONFIG_TYPE_REQUESTED = 'GET_CONFIG_TYPE_REQUESTED';
export const GET_CONFIG_TYPE_SUCCEEDED = 'GET_CONFIG_TYPE_SUCCEEDED';
export const GET_CONFIG_TYPE_FAILED = 'GET_CONFIG_TYPE_FAILED';

export const GET_STUDENT_REPORT_REQUESTED = 'GET_STUDENT_REPORT_REQUESTED';
export const GET_STUDENT_REPORT_SUCCEEDED = 'GET_STUDENT_REPORT_SUCCEEDED';
export const GET_STUDENT_REPORT_FAILED = 'GET_STUDENT_REPORT_FAILED';

export const TOPIC_LEVEL_SELECTED = 'TOPIC_LEVEL_SELECTED';

export const RESET_STUDENT_REPORT = 'RESET_STUDENT_REPORT';

export const GET_QUESTION_REQUESTED = 'GET_QUESTION_REQUESTED';
export const GET_QUESTION_SUCCEEDED = 'GET_QUESTION_SUCCEEDED';
export const GET_QUESTION_FAILED = 'GET_QUESTION_FAILED';

export const SHOW_QUESTION = 'SHOW_QUESTION';

export const CLOSE_QUESTION = 'CLOSE_QUESTION';

export const GET_START_CONFERENCE_REQUESTED = 'GET_START_CONFERENCE_REQUESTED';
export const GET_START_CONFERENCE_SUCCEEDED = 'GET_START_CONFERENCE_SUCCEEDED';
export const GET_START_CONFERENCE_FAILED = 'GET_START_CONFERENCE_FAILED';

export const GET_MEETING_URL_REQUESTED = 'GET_MEETING_URL_REQUESTED';
export const GET_MEETING_URL_SUCCEEDED = 'GET_MEETING_URL_SUCCEEDED';
export const GET_MEETING_URL_FAILED = 'GET_MEETING_URL_FAILED';

export const FETCH_DATE_RANGE_REQUESTED = 'FETCH_DATE_RANGE_REQUESTED';
export const FETCH_DATE_RANGE_SUCCEDED = 'FETCH_DATE_RANGE_SUCCEDED';
export const FETCH_DATE_RANGE_FAILED = 'FETCH_DATE_RANGE_FAILED';

export const SELECT_ATTENDENCE_MONTH = 'SELECT_ATTENDENCE_MONTH';
export const SELECT_ATTENDENCE_MONTH_SUCCEDED =
  'SELECT_ATTENDENCE_MONTH_SUCCEDED';
export const SELECT_ATTENDENCE_MONTH_FAILED = 'SELECT_ATTENDENCE_MONTH_FAILED';

export const SUBTOPIC_SELECTED = 'SUBTOPIC_SELECTED';
export const GO_BACK_TO_TOPIC = 'GO_BACK_TO_TOPIC';

export const CREATE_ANNOUNCEMENT_REQUESTED = 'CREATE_ANNOUNCEMENT_REQUESTED';
export const CREATE_ANNOUNCEMENT_SUCCEEDED = 'CREATE_ANNOUNCEMENT_SUCCEEDED';
export const CREATE_ANNOUNCEMENT_FAILED = 'CREATE_ANNOUNCEMENT_FAILED';

export const UPDATE_ANNOUNCEMENT_REQUESTED = 'UPDATE_ANNOUNCEMENT_REQUESTED';
export const RESET_ANNOUNCEMENT_SUBMITTED = 'RESET_ANNOUNCEMENT_SUBMITTED';

export const DELETE_ANNOUNCEMENT_REQUESTED = 'DELETE_ANNOUNCEMENT_REQUESTED';
export const DELETE_ANNOUNCEMENT_SUCCEEDED = 'DELETE_ANNOUNCEMENT_SUCCEEDED';
export const DELETE_ANNOUNCEMENT_FAILED = 'DELETE_ANNOUNCEMENT_FAILED';

export const GET_STUDENT_SESSION_REQUESTED = 'GET_STUDENT_SESSION_REQUESTED';
export const GET_STUDENT_SESSION_SUCCEEDED = 'GET_STUDENT_SESSION_SUCCEEDED';
export const GET_STUDENT_SESSION_FAILED = 'GET_STUDENT_SESSION_FAILED';

export const GET_STUDENT_STUCK_REQUESTED = 'GET_STUDENT_STUCK_REQUESTED';
export const GET_STUDENT_STUCK_SUCCEDED = 'GET_STUDENT_STUCK_SUCCEDED';
export const GET_STUDENT_STUCK_FAILED = 'GET_STUDENT_STUCK_FAILED';

export const GET_ATTEMTPS_NEW_REQUESTED = 'GET_ATTEMTPS_NEW_REQUESTED';
export const GET_ATTEMTPS_NEW_SUCCEDED = 'GET_ATTEMTPS_NEW_SUCCEDED';
export const GET_ATTEMTPS_NEW_FAILED = 'GET_ATTEMTPS_NEW_FAILED';

export const FETCH_QUESTION_HISTORY_REQUESTED = 'FETCH_QUESTION_HISTORY';
export const FETCH_QUESTION_HISTORY_SUCCEDED =
  'FETCH_QUESTION_HISTORY_SUCCEDED';
export const FETCH_QUESTION_HISTORY_FAILED = 'FETCH_QUESTION_HISTORY_FAILED';

export const QUESTION_TYPE_REQUESTED = 'QUESTION_TYPE_REQUESTED';
export const GO_BACK_HOME_DASHBOARD_REQUESTED =
  'GO_BACK_HOME_DASHBOARD_REQUESTED';

export const QUESTION_TYPE_CONFIG_REQUESTED = 'QUESTION_TYPE_CONFIG_REQUESTED';
export const QUESTION_TYPE_CONFIG_SUCCEDED = 'QUESTION_TYPE_CONFIG_SUCCEDED';
export const QUESTION_TYPE_CONFIG_FAILED = 'QUESTION_TYPE_CONFIG_FAILED';
//bring back the windows
export const GET_TESTS_REQUESTED = 'GET_TESTS_REQUESTED';
export const GET_TESTS_SUCCEEDED = 'GET_TESTS_SUCCEEDED';
export const GET_TESTS_FAILED = 'GET_TESTS_FAILED';

export const GET_TESTS_SELECTED = 'GET_TESTS_SELECTED';
export const RESET_TEST = 'RESET_TEST';

export const GET_EXAMS_QUESTIONS_REQUESTED = 'GET_EXAMS_QUESTIONS_REQUESTED';
export const GET_EXAMS_QUESTIONS_SUCCEEDED = 'GET_EXAMS_QUESTIONS_SUCCEEDED';
export const GET_EXAMS_QUESTIONS_FAILED = 'GET_EXAMS_QUESTIONS_FAILED';

export const CREATE_LIST_FOR_LOADER = 'CREATE_LIST_FOR_LOADER';

export const LAUNCH_EXAM_REQUESTED = 'LAUNCH_EXAM_REQUESTED';
export const LAUNCH_EXAM_SUCCEEDED = 'LAUNCH_EXAM_SUCCEEDED';
export const LAUNCH_EXAM_FAILED = 'LAUNCH_EXAM_FAILED';

export const FETCH_EXAM_REPORT_REQUESTED = 'FETCH_EXAM_REPORT';
export const FETCH_EXAM_REPORT_SUCCEDED = 'FETCH_EXAM_REPORT_SUCCEDED';
export const FETCH_EXAM_REPORT_FAILED = 'FETCH_EXAM_REPORT_FAILED';

export const FETCH_STUDENT_WISE_REPORT_REQUESTED =
  'FETCH_STUDENT_WISE_REPORT_REQUESTED';
export const FETCH_STUDENT_WISE_REPORT_SUCCEDED =
  'FETCH_STUDENT_WISE_REPORT_SUCCEDED';
export const FETCH_STUDENT_WISE_REPORT_FAILED =
  'FETCH_STUDENT_WISE_REPORT_FAILED';

export const FETCH_PRACTICED_REQUESTED = 'FETCH_PRACTICED_REQUESTED';
export const FETCH_PRACTICED_SUCCEDED = 'FETCH_PRACTICED_SUCCEDED';
export const FETCH_PRACTICED_FAILED = 'FETCH_PRACTICED_FAILED';

export const PUBLISH_TEACHER_TOPIC_REQUESTED =
  'PUBLISH_TEACHER_TOPIC_REQUESTED';
export const PUBLISH_TEACHER_TOPIC_FAILED = 'PUBLISH_TEACHER_TOPIC_FAILED';
export const PUBLISH_TEACHER_TOPIC_SUCCEDDED =
  'PUBLISH_TEACHER_TOPIC_SUCCEDDED';

export const SELECT_CHAPTER_REQUESTED = 'SELECT_CHAPTER_REQUESTED';
export const SELECT_CHAPTER_FAILED = 'SELECT_CHAPTER_FAILED';
export const SELECT_CHAPTER_SUCCEDDED = 'SELECT_CHAPTER_SUCCEDDED';

export const SELECT_TEST_REQUESTED = 'SELECT_TEST_REQUESTED';

export const GET_CHANGE_PASSWORD_REQUESTED = 'GET_CHANGE_PASSWORD_REQUESTED';
export const GET_CHANGE_PASSWORD_SUCCEEDED = 'GET_CHANGE_PASSWORD_SUCCEEDED';
export const GET_CHANGE_PASSWORD_FAILED = 'GET_CHANGE_PASSWORD_FAILED';

export const RESET_STUCK_AT = 'RESET_STUCK_AT';

export const GET_SYLLABUS_REQUESTED = 'GET_SYLLABUS_REQUESTED'
export const GET_SYLLABUS_SUCCEEDED = 'GET_SYLLABUS_SUCCEEDED'
export const GET_SYLLABUS_FAILED = 'GET_SYLLABUS_FAILED'

export const SYLLABUS_SELECTED = 'SYLLABUS_SELECTED'

export const CONFIG_DATA_REQUESTED = 'CONFIG_DATA_REQUESTED'
export const CONFIG_DATA_SUCCEEDED = 'CONFIG_DATA_SUCCEEDED'
export const CONFIG_DATA_FAILED = 'CONFIG_DATA_FAILED'

export const POST_REPORT_REQUESTED = 'POST_REPORT_REQUESTED'
export const POST_REPORT_RESET = 'POST_REPORT_RESET'
export const POST_REPORT_SUCCEEDED = 'POST_REPORT_SUCCEEDED'
export const POST_REPORT_FAILED = 'POST_REPORT_FAILED'

export const GET_QUESTION_CONTENT_REQUESTED = 'GET_QUESTION_CONTENT_REQUESTED'
export const GET_QUESTION_CONTENT_SUCCEEDED = 'GET_QUESTION_CONTENT_SUCCEEDED'
export const GET_QUESTION_CONTENT_FAILED = 'GET_QUESTION_CONTENT_FAILED'

export const GET_BROKEN_DOWN_REQUESTED = "GET_BROKEN_DOWN_REQUESTED"
export const GET_BROKEN_DOWN_SUCCEEDED = "GET_BROKEN_DOWN_SUCCEEDED"
export const GET_BROKEN_DOWN_FAILED = "GET_BROKEN_DOWN_FAILED"

export const EXIT_OVERVIEW = "EXIT_OVERVIEW"

export const REGISTER_CLIENT_USER_REQUESTED = "REGISTER_CLIENT_USER_REQUESTED"
export const REGISTER_CLIENT_USER_SUCCEEDED = "REGISTER_CLIENT_USER_SUCCEEDED"
export const REGISTER_CLIENT_USER_FAILED = "REGISTER_CLIENT_USER_FAILED"

export const CLIENT_MAIL_RESEND_REQUESTED = "CLIENT_MAIL_RESEND_REQUESTED"
export const CLIENT_MAIL_RESEND_SUCCEEDED = "CLIENT_MAIL_RESEND_SUCCEEDED"
export const CLIENT_MAIL_RESEND_FAILED = "CLIENT_MAIL_RESEND_FAILED"

export const REGISTER_NEW_STUDENT_REQUESTED = 'REGISTER_NEW_STUDENT_REQUESTED';
export const REGISTER_NEW_STUDENT_SUCCEEDED = 'REGISTER_NEW_STUDENT_SUCCEEDED';
export const REGISTER_NEW_STUDENT_FAILED = 'REGISTER_NEW_STUDENT_FAILED';
export const SET_NEW_STUDENT_REGISTRATION_DATA = 'SET_NEW_STUDENT_REGISTRATION_DATA';
export const RESET_NEW_STUDENT_REGISTRATION = 'RESET_NEW_STUDENT_REGISTRATION';

export const CHECK_STUDENT_EXISTS = "CHECK_STUDENT_EXISTS"
export const CHECK_STUDENT_EXISTS_DATA = "CHECK_STUDENT_EXISTS_DATA"

export const CHECK_TEACHER_EXISTS = "CHECK_TEACHER_EXISTS"
export const CHECK_TEACHER_EXISTS_DATA = "CHECK_TEACHER_EXISTS_DATA"

export const GET_CLASS_TEACHERS_REQUESTED = 'GET_CLASS_TEACHERS_REQUESTED';
export const GET_CLASS_TEACHERS_SUCCEEDED = 'GET_CLASS_TEACHERS_SUCCEEDED';
export const GET_CLASS_TEACHERS_FAILED = 'GET_CLASS_TEACHERS_FAILED';

export const REMOVE_CLASS_TEACHERS_REQUESTED = 'REMOVE_CLASS_TEACHERS_REQUESTED';
export const REMOVE_CLASS_TEACHERS_SUCCEEDED = 'REMOVE_CLASS_TEACHERS_SUCCEEDED';
export const REMOVE_CLASS_TEACHERS_FAILED = 'REMOVE_CLASS_TEACHERS_FAILED';

export const GET_SEARCH_TEACHERS_REQUESTED = 'GET_SEARCH_TEACHERS_REQUESTED';
export const GET_SEARCH_TEACHERS_SUCCEEDED = 'GET_SEARCH_TEACHERS_SUCCEEDED';
export const GET_SEARCH_TEACHERS_FAILED = 'GET_SEARCH_TEACHERS_FAILED';

export const RESET_EXCEL_NEW_STUDENT_REGISTRATION = 'RESET_EXCEL_NEW_STUDENT_REGISTRATION';

export const BULK_REGISTER_STUDENTS_REQUESTED = 'BULK_REGISTER_STUDENTS_REQUESTED';
export const BULK_REGISTER_STUDENTS_SUCCEEDED = 'BULK_REGISTER_STUDENTS_SUCCEEDED';
export const BULK_REGISTER_STUDENTS_FAILED = 'BULK_REGISTER_STUDENTS_FAILED';
export const BULK_SET_STUDENTS_REGISTRATION_DATA = 'BULK_SET_STUDENTS_REGISTRATION_DATA';
export const BULK_RESET_STUDENTS_REGISTRATION = 'BULK_RESET_STUDENTS_REGISTRATION';

export const BULK_REGISTER_TEACHERS_REQUESTED = 'BULK_REGISTER_TEACHERS_REQUESTED';
export const BULK_REGISTER_TEACHERS_SUCCEEDED = 'BULK_REGISTER_TEACHERS_SUCCEEDED';
export const BULK_REGISTER_TEACHERS_FAILED = 'BULK_REGISTER_TEACHERS_FAILED';
export const BULK_SET_TEACHERS_REGISTRATION_DATA = 'BULK_SET_TEACHERS_REGISTRATION_DATA';
export const BULK_RESET_TEACHERS_REGISTRATION = 'BULK_RESET_TEACHERS_REGISTRATION';

export const GET_TOPIC_LAUNCH_SETTINGS_REQUESTED = 'GET_TOPIC_LAUNCH_SETTINGS_REQUESTED';
export const GET_TOPIC_LAUNCH_SETTINGS_SUCCEEDED = 'GET_TOPIC_LAUNCH_SETTINGS_SUCCEEDED';
export const GET_TOPIC_LAUNCH_SETTINGS_FAILED = 'GET_TOPIC_LAUNCH_SETTINGS_FAILED';

export const SET_TOPIC_LAUNCH_SETTINGS_REQUESTED = 'SET_TOPIC_LAUNCH_SETTINGS_REQUESTED';
export const SET_TOPIC_LAUNCH_SETTINGS_SUCCEEDED = 'SET_TOPIC_LAUNCH_SETTINGS_SUCCEEDED';
export const SET_TOPIC_LAUNCH_SETTINGS_FAILED = 'SET_TOPIC_LAUNCH_SETTINGS_FAILED';
export const SET_TOPIC_LAUNCH_SETTINGS_RESET = 'SET_TOPIC_LAUNCH_SETTINGS_RESET';

export const GET_ASSESSMENT_LAUNCH_SETTINGS_REQUESTED = 'GET_ASSESSMENT_LAUNCH_SETTINGS_REQUESTED';
export const GET_ASSESSMENT_LAUNCH_SETTINGS_SUCCEEDED = 'GET_ASSESSMENT_LAUNCH_SETTINGS_SUCCEEDED';
export const GET_ASSESSMENT_LAUNCH_SETTINGS_FAILED = 'GET_ASSESSMENT_LAUNCH_SETTINGS_FAILED';

export const SET_ASSESSMENT_LAUNCH_SETTINGS_REQUESTED = 'SET_ASSESSMENT_LAUNCH_SETTINGS_REQUESTED';
export const SET_ASSESSMENT_LAUNCH_SETTINGS_SUCCEEDED = 'SET_ASSESSMENT_LAUNCH_SETTINGS_SUCCEEDED';
export const SET_ASSESSMENT_LAUNCH_SETTINGS_FAILED = 'SET_ASSESSMENT_LAUNCH_SETTINGS_FAILED';
export const SET_ASSESSMENT_LAUNCH_SETTINGS_RESET = 'SET_ASSESSMENT_LAUNCH_SETTINGS_RESET';

export const GET_CLASS_BOOKMARKS_DATA = "GET_CLASS_BOOKMARKS_DATA"
export const GET_CLASS_BOOKMARKS_DATA_SUCCEEDED = "GET_CLASS_BOOKMARKS_DATA_SUCCEEDED"
export const GET_CLASS_BOOKMARKS_DATA_FAILED = "GET_CLASS_BOOKMARKS_DATA_FAILED"

export const GET_ASSESSMENT_DRAFT_DATA = "GET_ASSESSMENT_DRAFT_DATA"
export const GET_ASSESSMENT_DRAFT_DATA_SUCCEEDED = "GET_ASSESSMENT_DRAFT_DATA_SUCCEEDED"
export const GET_ASSESSMENT_DRAFT_DATA_FAILED = "GET_ASSESSMENT_DRAFT_DATA_FAILED"

export const UPDATE_ASSESSMENT_DRAFT_DATA = "UPDATE_ASSESSMENT_DRAFT_DATA"
export const UPDATE_ASSESSMENT_DRAFT_DATA_SUCCEEDED = "UPDATE_ASSESSMENT_DRAFT_DATA_SUCCEEDED"
export const UPDATE_ASSESSMENT_DRAFT_DATA_FAILED = "UPDATE_ASSESSMENT_DRAFT_DATA_FAILED"

export const SUBMIT_ASSESSMENT_REQUEST = 'SUBMIT_ASSESSMENT_REQUEST';
export const SUBMIT_ASSESSMENT_REQUEST_SUCCEEDED = 'SUBMIT_ASSESSMENT_REQUEST_SUCCEEDED';
export const SUBMIT_ASSESSMENT_REQUEST_FAILED = 'SUBMIT_ASSESSMENT_REQUEST_FAILED';
export const SUBMIT_ASSESSMENT_REQUEST_RESET = 'SUBMIT_ASSESSMENT_REQUEST_RESET';

export const PUBLISH_ASSESSMENT_REQUEST = 'PUBLISH_ASSESSMENT_REQUEST';
export const PUBLISH_ASSESSMENT_REQUEST_SUCCEEDED = 'PUBLISH_ASSESSMENT_REQUEST_SUCCEEDED';
export const PUBLISH_ASSESSMENT_REQUEST_FAILED = 'PUBLISH_ASSESSMENT_REQUEST_FAILED';
export const PUBLISH_ASSESSMENT_REQUEST_RESET = 'PUBLISH_ASSESSMENT_REQUEST_RESET';

export const ADD_QUESTION_TO_BOOKMARK_DATA = "ADD_QUESTION_TO_BOOKMARK_DATA"
export const ADD_QUESTION_TO_BOOKMARK_DATA_SUCCEEDED = "ADD_QUESTION_TO_BOOKMARK_DATA_SUCCEEDED"
export const ADD_QUESTION_TO_BOOKMARK_DATA_FAILED = "ADD_QUESTION_TO_BOOKMARK_DATA_FAILED"

export const RESET_TEST_ATTEMPTS_REQUESTED = 'RESET_TEST_ATTEMPTS_REQUESTED';
export const RESET_TEST_ATTEMPTS_SUCCEEDED = 'RESET_TEST_ATTEMPTS_SUCCEEDED';
export const RESET_TEST_ATTEMPTS_FAILED = 'RESET_TEST_ATTEMPTS_FAILED';

export const REMOVE_QUESTION_FROM_BOOKMARK_DATA = "REMOVE_QUESTION_FROM_BOOKMARK_DATA"
export const REMOVE_QUESTION_FROM_BOOKMARK_DATA_SUCCEEDED = "REMOVE_QUESTION_FROM_BOOKMARK_DATA_SUCCEEDED"
export const REMOVE_QUESTION_FROM_BOOKMARK_DATA_FAILED = "REMOVE_QUESTION_FROM_BOOKMARK_DATA_FAILED"

export const UPDATE_BOOKMARK_DATA = "UPDATE_BOOKMARK_DATA"
export const UPDATE_BOOKMARK_DATA_SUCCEEDED = "UPDATE_BOOKMARK_DATA_SUCCEEDED"
export const UPDATE_BOOKMARK_DATA_FAILED = "UPDATE_BOOKMARK_DATA_FAILED"

export const MOVE_QUESTION = "MOVE_QUESTION"
export const SET_ACTIVE_VIEW_DRAFT = "SET_ACTIVE_VIEW_DRAFT"

export const SELECT_LANGUAGE_PRACTICE_MODE = 'SELECT_LANGUAGE_PRACTICE_MODE'
export const SELECT_LANGUAGE_PRACTICE_MODE_RESET = 'SELECT_LANGUAGE_PRACTICE_MODE_RESET'

export const FETCH_ASSESSMENT_REQUESTS = "FETCH_ASSESSMENT_REQUESTS"
export const FETCH_ASSESSMENT_REQUESTS_SUCCEEDED = "FETCH_ASSESSMENT_REQUESTS_SUCCEEDED"
export const FETCH_ASSESSMENT_REQUESTS_FAILED = "FETCH_ASSESSMENT_REQUESTS_FAILED"


export const GET_LEADERBOARD_REQUESTED = "GET_LEADERBOARD_REQUESTED"
export const GET_LEADERBOARD_SUCCEEDED = "GET_LEADERBOARD_SUCCEEDED"
export const GET_LEADERBOARD_FAILED = "GET_LEADERBOARD_FAILED"



export const GET_LEADERBOARD_DATA_REQUESTED = "GET_LEADERBOARD_DATA_REQUESTED"
export const GET_LEADERBOARD_DATA_SUCCEEDED = "GET_LEADERBOARD_DATA_SUCCEEDED"
export const GET_LEADERBOARD_DATA_FAILED = "GET_LEADERBOARD_DATA_FAILED"


export const GET_MYLEADERBOARD_REQUESTED = "GET_MYLEADERBOARD_REQUESTED"
export const GET_MYLEADERBOARD_SUCCEEDED = "GET_MYLEADERBOARD_SUCCEEDED"
export const GET_MYLEADERBOARD_FAILED = "GET_MYLEADERBOARD_FAILED"

export const GET_LEADERBOARD_STUDENT_REQUESTED = "GET_LEADERBOARD_STUDENT_REQUESTED"
export const GET_LEADERBOARD_STUDENT_SUCCEEDED = "GET_LEADERBOARD_STUDENT_SUCCEEDED"
export const GET_LEADERBOARD_STUDENT_FAILED = "GET_LEADERBOARD_STUDENT_FAILED"

export const GET_TOGGLE_LEADERBOARD_REQUESTED = "GET_TOGGLE_LEADERBOARD_REQUESTED"
export const GET_TOGGLE_LEADERBOARD_SUCCEEDED = "GET_TOGGLE_LEADERBOARD_SUCCEEDED"
export const GET_TOGGLE_LEADERBOARD_FAILED = "GET_TOGGLE_LEADERBOARD_FAILED"

export const ADD_STUDENT_IN_CLASS_REQUESTED="ADD_STUDENT_IN_CLASS_REQUESTED"
export const ADD_STUDENT_IN_CLASS_SUCCEEDED="ADD_STUDENT_IN_CLASS_SUCCEEDED"
export const ADD_STUDENT_IN_CLASS_FAILED="ADD_STUDENT_IN_CLASS_FAILED"

export const STUDENT_REGISTERED_DEVICES = "STUDENT_REGISTERED_DEVICES"
export const STUDENT_REGISTERED_DEVICES_SUCCESS = "STUDENT_REGISTERED_DEVICES_SUCCESS"
export const STUDENT_REGISTERED_DEVICES_FAILED = "STUDENT_REGISTERED_DEVICES_FAILED"

export const FETCH_DATE_FILTERS_REQUESTED = "FETCH_DATE_FILTERS_REQUESTED"
export const FETCH_DATE_FILTERS_SUCCEEDED = "FETCH_DATE_FILTERS_SUCCEEDED"
export const FETCH_DATE_FILTERS_FAILED = "FETCH_DATE_FILTERS_FAILED"

export const GET_ALERT_REQUESTED = "GET_ALERT_REQUESTED"
export const GET_ALERT_SUCCEEDED = "GET_ALERT_SUCCEEDED"
export const GET_ALERT_FAILED = "GET_ALERT_FAILED"

export const GET_NOTIFICATION_SESSION_REQUESTED = "GET_NOTIFICATION_SESSION_REQUESTED"
export const GET_NOTIFICATION_SESSION_SUCCEEDED = "GET_NOTIFICATION_SESSION_SUCCEEDED"
export const GET_NOTIFICATION_SESSION_FAILED = "GET_NOTIFICATION_SESSION_FAILED"

export const GET_QUESTION_ATTEMPTS_REQUESTED = "GET_QUESTION_ATTEMPTS_REQUESTED"
export const GET_QUESTION_ATTEMPTS_SUCCEEDED = "GET_QUESTION_ATTEMPTS_SUCCEEDED"
export const GET_QUESTION_ATTEMPTS_FAILED = "GET_QUESTION_ATTEMPTS_FAILED"

export const GET_CONNECT_GOOGLECLASS_REQUESTED = "GET_CONNECT_GOOGLECLASS_REQUESTED"
export const GET_CONNECT_GOOGLECLASS_SUCCEEDED = "GET_CONNECT_GOOGLECLASS_SUCCEEDED"
export const GET_CONNECT_GOOGLECLASS_FAILED = "GET_CONNECT_GOOGLECLASS_FAILED"

export const GET_SWITCH_GOOGLEACCOUNT_REQUESTED = "GET_SWITCH_GOOGLEACCOUNT_REQUESTED"
export const GET_SWITCH_GOOGLEACCOUNT_SUCCEEDED = "GET_SWITCH_GOOGLEACCOUNT_SUCCEEDED"
export const GET_SWITCH_GOOGLEACCOUNT_FAILED = "GET_SWITCH_GOOGLEACCOUNT_FAILED"

export const GET_TEACHER_GOOGLELOGIN_REQUESTED = "GET_TEACHER_GOOGLELOGIN_REQUESTED"
export const GET_TEACHER_GOOGLELOGIN_SUCCEEDED = "GET_TEACHER_GOOGLELOGIN_SUCCEEDED"
export const GET_TEACHER_GOOGLELOGIN_FAILED = "GET_TEACHER_GOOGLELOGIN_FAILED"

export const GET_TEACHER_GOOGLESIGNUP_REQUESTED = "GET_TEACHER_GOOGLESIGNUP_REQUESTED"
export const GET_TEACHER_GOOGLESIGNUP_SUCCEEDED = "GET_TEACHER_GOOGLESIGNUP_SUCCEEDED"
export const GET_TEACHER_GOOGLESIGNUP_FAILED = "GET_TEACHER_GOOGLESIGNUP_FAILED"

export const GET_GOOGLECLASSES_REQUESTED = "GET_GOOGLECLASSES_REQUESTED"
export const GET_GOOGLECLASSES_SUCCEEDED = "GET_GOOGLECLASSES_SUCCEEDED"
export const GET_GOOGLECLASSES_FAILED = "GET_GOOGLECLASSES_FAILED"

export const GET_GOOGLECLASSESONBOARDING_REQUESTED = "GET_GOOGLECLASSESONBOARDING_REQUESTED"
export const GET_GOOGLECLASSESONBOARDING_SUCCEEDED = "GET_GOOGLECLASSESONBOARDING_SUCCEEDED"
export const GET_GOOGLECLASSESONBOARDING_FAILED = "GET_GOOGLECLASSESONBOARDING_FAILED"

export const GET_GOOGLE_CLASSROOM_STUDENTS_REQUESTED = "GET_GOOGLE_CLASSROOM_STUDENTS_REQUESTED";
export const GET_GOOGLE_CLASSROOM_STUDENTS_SUCCEEDED = "GET_GOOGLE_CLASSROOM_STUDENTS_SUCCEEDED";
export const GET_GOOGLE_CLASSROOM_STUDENTS_FAILED = "GET_GOOGLE_CLASSROOM_STUDENTS_FAILED";

export const SYNC_GOOGLE_CLASSROOM_STUDENTS_REQUESTED = "SYNC_GOOGLE_CLASSROOM_STUDENTS_REQUESTED";
export const SYNC_GOOGLE_CLASSROOM_STUDENTS_SUCCEEDED = "SYNC_GOOGLE_CLASSROOM_STUDENTS_SUCCEEDED";
export const SYNC_GOOGLE_CLASSROOM_STUDENTS_FAILED = "SYNC_GOOGLE_CLASSROOM_STUDENTS_FAILED";

export const GET_LINK_GOOGLECLASS_REQUESTED = "GET_LINK_GOOGLECLASS_REQUESTED"
export const GET_LINK_GOOGLECLASS_SUCCEEDED = "GET_LINK_GOOGLECLASS_SUCCEEDED"
export const GET_LINK_GOOGLECLASS_FAILED = "GET_LINK_GOOGLECLASS_FAILED"

export const GET_UNLINK_GOOGLECLASS_REQUESTED = "GET_UNLINK_GOOGLECLASS_REQUESTED"
export const GET_UNLINK_GOOGLECLASS_SUCCEEDED = "GET_UNLINK_GOOGLECLASS_SUCCEEDED"
export const GET_UNLINK_GOOGLECLASS_FAILED = "GET_UNLINK_GOOGLECLASS_FAILED"

export const GET_LEVEL_DROPDOWN_ITEMS_REQUESTED = "GET_LEVEL_DROPDOWN_ITEMS_REQUESTED"
export const GET_LEVEL_DROPDOWN_ITEMS_SUCCEEDED = "GET_LEVEL_DROPDOWN_ITEMS_SUCCEEDED"
export const GET_LEVEL_DROPDOWN_ITEMS_FAILED = "GET_LEVEL_DROPDOWN_ITEMS_FAILED"

export const GET_LEVEL_SUBMISSION_REQUESTED = 'GET_LEVEL_SUBMISSION_REQUESTED';
export const GET_LEVEL_SUBMISSION_SUCCEEDED = 'GET_LEVEL_SUBMISSION_SUCCEEDED';
export const GET_LEVEL_SUBMISSION_FAILED = 'GET_LEVEL_SUBMISSION_FAILED';

export const GET_MULTI_STUDENT_LEVEL_SUBMISSION_REQUESTED = 'GET_MULTI_STUDENT_LEVEL_SUBMISSION_REQUESTED';
export const GET_MULTI_STUDENT_LEVEL_SUBMISSION_SUCCEEDED = 'GET_MULTI_STUDENT_LEVEL_SUBMISSION_SUCCEEDED';
export const GET_MULTI_STUDENT_LEVEL_SUBMISSION_FAILED = 'GET_MULTI_STUDENT_LEVEL_SUBMISSION_FAILED';

export const GET_MULTI_STUDENT_GROUP_SUBMISSION_REQUESTED = 'GET_MULTI_STUDENT_GROUP_SUBMISSION_REQUESTED';
export const GET_MULTI_STUDENT_GROUP_SUBMISSION_SUCCEEDED = 'GET_MULTI_STUDENT_GROUP_SUBMISSION_SUCCEEDED';
export const GET_MULTI_STUDENT_GROUP_SUBMISSION_FAILED = 'GET_MULTI_STUDENT_GROUP_SUBMISSION_FAILED';

export const UPDATE_SINGLE_STUDENT_NINJA_TEAM = "UPDATE_SINGLE_STUDENT_NINJA_TEAM";

export const GET_ALL_ATTEMPTS_REQUESTED = "GET_ALL_ATTEMPTS_REQUESTED"
export const GET_ALL_ATTEMPTS_SUCCEEDED = "GET_ALL_ATTEMPTS_SUCCEEDED"
export const GET_ALL_ATTEMPTS_FAILED = "GET_ALL_ATTEMPTS_FAILED"

export const REMOVE_TEST_INFO = "REMOVE_TEST_INFO"

export const FETCH_AUTO_GRADING_CTS_REQUESTED = "FETCH_AUTO_GRADING_CTS_REQUESTED"
export const FETCH_AUTO_GRADING_CTS_SUCCEEDED = "FETCH_AUTO_GRADING_CTS_SUCCEEDED"
export const FETCH_AUTO_GRADING_CTS_FAILED = "FETCH_AUTO_GRADING_CTS_FAILED"

export const GET_AUTO_GRADING_QUESTIONS_REQUESTED = "GET_AUTO_GRADING_QUESTIONS_REQUESTED"
export const GET_AUTO_GRADING_QUESTIONS_SUCCEEDED = "GET_AUTO_GRADING_QUESTIONS_SUCCEEDED"
export const GET_AUTO_GRADING_QUESTIONS_FAILED = "GET_AUTO_GRADING_QUESTIONS_FAILED"

export const UPLOAD_AUTO_GRADING_IMAGE_REQUESTED = "UPLOAD_AUTO_GRADING_IMAGE_REQUESTED";
export const UPLOAD_AUTO_GRADING_IMAGE_SUCCEEDED = "UPLOAD_AUTO_GRADING_IMAGE_SUCCEEDED";
export const UPLOAD_AUTO_GRADING_IMAGE_FAILED = "UPLOAD_AUTO_GRADING_IMAGE_FAILED";
export const RESET_UPLOAD_AUTO_GRADING_IMAGE = "RESET_UPLOAD_AUTO_GRADING_IMAGE";

export const FETCH_UNATTEMPTED_AUTO_GRADING_REQUESTED = "FETCH_UNATTEMPTED_AUTO_GRADING_REQUESTED"
export const FETCH_UNATTEMPTED_AUTO_GRADING_SUCCEEDED = "FETCH_UNATTEMPTED_AUTO_GRADING_SUCCEEDED"
export const FETCH_UNATTEMPTED_AUTO_GRADING_FAILED = "FETCH_UNATTEMPTED_AUTO_GRADING_FAILED"

export const EVALUATE_AUTO_GRADING_IMAGE_REQUESTED = "EVALUATE_AUTO_GRADING_IMAGE_REQUESTED";
export const EVALUATE_AUTO_GRADING_IMAGE_SUCCEEDED = "EVALUATE_AUTO_GRADING_IMAGE_SUCCEEDED";
export const EVALUATE_AUTO_GRADING_IMAGE_FAILED = "EVALUATE_AUTO_GRADING_IMAGE_FAILED";
export const RESET_EVALUATE_AUTO_GRADING_IMAGE = "RESET_EVALUATE_AUTO_GRADING_IMAGE";

export const FETCH_ALL_ASSESSMENT_CLASSES_LOADING = 'FETCH_ALL_ASSESSMENT_CLASSES_LOADING';
export const GET_ALL_ASSESSMENT_CLASSES_REQUESTED = 'GET_ALL_ASSESSMENT_CLASSES_REQUESTED';
export const GET_ALL_ASSESSMENT_CLASSES_SUCCEEDED = 'GET_ALL_ASSESSMENT_CLASSES_SUCCEEDED';
export const GET_ALL_ASSESSMENT_CLASSES_FAILED = 'GET_ALL_ASSESSMENT_CLASSES_FAILED';

export const GET_ASSESSMENT_DRAFT_CLASSES_REQUESTED = 'GET_ASSESSMENT_DRAFT_CLASSES_REQUESTED';
export const GET_ASSESSMENT_DRAFT_CLASSES_SUCCEEDED = 'GET_ASSESSMENT_DRAFT_CLASSES_SUCCEEDED';
export const GET_ASSESSMENT_DRAFT_CLASSES_FAILED = 'GET_ASSESSMENT_DRAFT_CLASSES_FAILED';

export const ADD_ASSESSMENT_DRAFT_CLASSES_REQUESTED = 'ADD_ASSESSMENT_DRAFT_CLASSES_REQUESTED';
export const ADD_ASSESSMENT_DRAFT_CLASSES_SUCCEEDED = 'ADD_ASSESSMENT_DRAFT_CLASSES_SUCCEEDED';
export const ADD_ASSESSMENT_DRAFT_CLASSES_FAILED = 'ADD_ASSESSMENT_DRAFT_CLASSES_FAILED';

export const GET_ASSESSMENT_ADDED_CLASSES_REQUESTED = 'GET_ASSESSMENT_ADDED_CLASSES_REQUESTED';
export const GET_ASSESSMENT_ADDED_CLASSES_SUCCEEDED = 'GET_ASSESSMENT_ADDED_CLASSES_SUCCEEDED';
export const GET_ASSESSMENT_ADDED_CLASSES_FAILED = 'GET_ASSESSMENT_ADDED_CLASSES_FAILED';

export const GET_FILTERED_ASSESSMENT_CLASSES_REQUESTED = 'GET_FILTERED_ASSESSMENT_CLASSES_REQUESTED';
export const GET_FILTERED_ASSESSMENT_CLASSES_SUCCEEDED = 'GET_FILTERED_ASSESSMENT_CLASSES_SUCCEEDED';
export const GET_FILTERED_ASSESSMENT_CLASSES_FAILED = 'GET_FILTERED_ASSESSMENT_CLASSES_FAILED';
export const SET_LOADING_FILTERED_CLASSES = "SET_LOADING_FILTERED_CLASSES";

export const SET_SYNC_LEADERBOARD_DATA_LOADER = "SET_SYNC_LEADERBOARD_DATA_LOADER";


export const RESET_STUDENT_PASSWORD_REQUESTED = "RESET_STUDENT_PASSWORD_REQUESTED";
export const RESET_STUDENT_PASSWORD_SUCCEEDED = "RESET_STUDENT_PASSWORD_SUCCEEDED";
export const RESET_STUDENT_PASSWORD_FAILED = "RESET_STUDENT_PASSWORD_FAILED";

export const SET_NEW_STUDENT_PASSWORD_REQUESTED = "SET_NEW_STUDENT_PASSWORD_REQUESTED";
export const SET_NEW_STUDENT_PASSWORD_SUCCEEDED = "SET_NEW_STUDENT_PASSWORD_SUCCEEDED";
export const SET_NEW_STUDENT_PASSWORD_FAILED = "SET_NEW_STUDENT_PASSWORD_FAILED";

export const MODIFY_STUDENT_DETAILS_REQUESTED = "MODIFY_STUDENT_DETAILS_REQUESTED";
export const MODIFY_STUDENT_DETAILS_SUCCEEDED = "MODIFY_STUDENT_DETAILS_SUCCEEDED";
export const MODIFY_STUDENT_DETAILS_FAILED = "MODIFY_STUDENT_DETAILS_FAILED";

export const RESET_MODIFY_STUDENT_DETAILS = "RESET_MODIFY_STUDENT_DETAILS";

export const GET_AI_FEEDBACK_REPORT_REQUESTED = "GET_AI_FEEDBACK_REPORT_REQUESTED";
export const GET_AI_FEEDBACK_REPORT_SUCCEEDED = "GET_AI_FEEDBACK_REPORT_SUCCEEDED";
export const GET_AI_FEEDBACK_REPORT_FAILED = "GET_AI_FEEDBACK_REPORT_FAILED";

export const EDIT_AI_FEEDBACK_CRITERIA_REQUESTED = "SAVE_AI_FEEDBACK_CRITERIA_INFO_REQUESTED";
export const EDIT_AI_FEEDBACK_CRITERIA_SUCCEEDED = "EDIT_AI_FEEDBACK_CRITERIA_SUCCEEDED";
export const EDIT_AI_FEEDBACK_CRITERIA_FAILED = "EDIT_AI_FEEDBACK_CRITERIA_FAILED";
export const SET_EDIT_AI_FEEDBACK_CRITERIA_LOADER = "SET_EDIT_AI_FEEDBACK_CRITERIA_LOADER";
export const EXIT_AI_FEEDBACK_REPORT_VIEW = "EXIT_AI_FEEDBACK_REPORT_VIEW";

export const UPDATE_STUDENT_REPORT_QUESTION_MARKS = "UPDATE_STUDENT_REPORT_QUESTION_MARKS";

export const SET_UNAUTH_LANGUAGE= "SET_UNAUTH_LANGUAGE";

export const SET_CURRENT_OPEN_ENDED_QUESTION_FEEDBACK = "SET_CURRENT_OPEN_ENDED_QUESTION_FEEDBACK";
export const EDIT_OPEN_ENDED_FEEDBACK_REQUESTED = "EDIT_OPEN_ENDED_FEEDBACK_REQUESTED";
export const EDIT_OPEN_ENDED_FEEDBACK_SUCCEEDED = "EDIT_OPEN_ENDED_FEEDBACK_SUCCEEDED";
export const EDIT_OPEN_ENDED_FEEDBACK_FAILED = "EDIT_OPEN_ENDED_FEEDBACK_FAILED";
export const SET_EDIT_OPEN_ENDED_FEEDBACK_LOADER = "SET_EDIT_OPEN_ENDED_FEEDBACK_LOADER";

export const MOCK_TEST_RESET_REQUESTED = "MOCK_TEST_RESET_REQUESTED";
export const MOCK_TEST_RESET_SUCCESS = "MOCK_TEST_RESET_SUCCESS";
export const MOCK_TEST_RESET_FAILURE = "MOCK_TEST_RESET_FAILURE";
export const RESET_MOCK_TEST_RESCHEDULE = "RESET_MOCK_TEST_RESCHEDULE";
export const DOWNLOAD_TOPIC_REPORT_REQUESTED = "DOWNLOAD_TOPIC_REPORT_REQUESTED";
export const DOWNLOAD_TOPIC_REPORT_SUCCESS = "DOWNLOAD_TOPIC_REPORT_SUCCESS";
export const DOWNLOAD_TOPIC_REPORT_FAILURE = "DOWNLOAD_TOPIC_REPORT_FAILURE";

export const DOWNLOAD_MOCK_EXAM_REPORT_REQUESTED = "DOWNLOAD_MOCK_EXAM_REPORT_REQUESTED";
export const DOWNLOAD_MOCK_EXAM_REPORT_SUCCESS = "DOWNLOAD_MOCK_EXAM_REPORT_SUCCESS";
export const DOWNLOAD_MOCK_EXAM_REPORT_FAILURE = "DOWNLOAD_MOCK_EXAM_REPORT_FAILURE";

export const GET_AI_GRADING_FEEDBACK_REPORT_REQUESTED = "GET_AI_GRADING_FEEDBACK_REPORT_REQUESTED";
export const GET_AI_GRADING_FEEDBACK_REPORT_SUCCEEDED = "GET_AI_GRADING_FEEDBACK_REPORT_SUCCEEDED";
export const GET_AI_GRADING_FEEDBACK_REPORT_FAILED = "GET_AI_GRADING_FEEDBACK_REPORT_FAILED";

export const EDIT_AI_GRADING_FEEDBACK_REQUESTED = "EDIT_AI_GRADING_FEEDBACK_REQUESTED";
export const EDIT_AI_GRADING_FEEDBACK_SUCCEEDED = "EDIT_AI_GRADING_FEEDBACK_SUCCEEDED";
export const EDIT_AI_GRADING_FEEDBACK_FAILED = "EDIT_AI_GRADING_FEEDBACK_FAILED";
export const SET_EDIT_AI_GRADING_FEEDBACK_LOADER = "SET_EDIT_AI_GRADING_FEEDBACK_LOADER";
export const EXIT_AI_GRADING_FEEDBACK_REPORT_VIEW = "EXIT_AI_GRADING_FEEDBACK_REPORT_VIEW";

export const exitAIGradingFeedbackReportView=()=>{
  return {
    type: EXIT_AI_GRADING_FEEDBACK_REPORT_VIEW
  }
}

export const editAIGradingFeedback = (feedback_id,marksObtained, feedback) => {
  return {
    type: EDIT_AI_GRADING_FEEDBACK_REQUESTED,
    feedback_id,
    marksObtained,
    feedback
  }
}

export const setEditAIGradingFeedbackLoader = (isSaving) => {
  return {
    type: SET_EDIT_AI_GRADING_FEEDBACK_LOADER,
    isSaving
  }
}

export const getAIGradingFeedbackReport=(config_id,studentId,attemptOrder)=>{
  return {
    type: GET_AI_GRADING_FEEDBACK_REPORT_REQUESTED,
    config_id,
    studentId,
    attemptOrder
  }
}

export const downloadMockExamReport = (classCode,examId) => {
  return {
    type: DOWNLOAD_MOCK_EXAM_REPORT_REQUESTED,
    classCode, 
    examId, 
  }
}

export const downloadTopicReport = (classCode,topicId) => {
  return {
    type: DOWNLOAD_TOPIC_REPORT_REQUESTED,
    classCode, 
    topicId, 
  }
}

export const resetMockTest = (examId, classCode, studentId, start_time, end_time) => {
  return {
    type: MOCK_TEST_RESET_REQUESTED,
    examId, 
    classCode, 
    studentId, 
    start_time, 
    end_time
  }
}

export const resetMockTestReschedule = () => {
  return {
    type: RESET_MOCK_TEST_RESCHEDULE,
  }
}

export const setCurrentOpenEndedQuestionFeedback = (feedback,student_id,index) => {
  return {
    type: SET_CURRENT_OPEN_ENDED_QUESTION_FEEDBACK,
    feedback,
    student_id,
    index
  }
}

export const editOpenEndedFeedback = (assessmentStudentId, marksObtained, feedback, subpartNumber) => {
  return {
    type: EDIT_OPEN_ENDED_FEEDBACK_REQUESTED,
    assessmentStudentId,
    marksObtained,
    feedback,
    subpartNumber
  }
}

export const setEditOpenEndedFeedbackLoader = (isSaving) => {
  return {
    type: SET_EDIT_OPEN_ENDED_FEEDBACK_LOADER,
    isSaving
  }
}

export const setUnauthLanguage = (language)=>{
  return {
    type: SET_UNAUTH_LANGUAGE,
    language,
    }
}

export const updateStudentReportQuestionMarks=(marksObtained,
  student_id,
  index)=>{
  return {
    type: UPDATE_STUDENT_REPORT_QUESTION_MARKS,
    marksObtained,
    student_id,
    index
    }
}

export const exitAIFeedbackReportView=()=>{
  return {
    type: EXIT_AI_FEEDBACK_REPORT_VIEW
  }
}

export const editAIFeedbackCriteria = (criteriaId, marksObtained, feedback) => {
  return {
    type: EDIT_AI_FEEDBACK_CRITERIA_REQUESTED,
    criteriaId,
    marksObtained,
    feedback
  }
}

export const setEditAIFeedbackCriteriaLoader = (isSaving) => {
  return {
    type: SET_EDIT_AI_FEEDBACK_CRITERIA_LOADER,
    isSaving
  }
}

export const getAIFeedbackReport=(config_id,studentId,attemptOrder)=>{
  return {
    type: GET_AI_FEEDBACK_REPORT_REQUESTED,
    config_id,
    studentId,
    attemptOrder
  }
}

export const setLoadingFilteredClasses = () => {
  return {
    type: SET_LOADING_FILTERED_CLASSES
  }
}

export const setNewStudentPassword = (username,password)=>{
  return {
    type: SET_NEW_STUDENT_PASSWORD_REQUESTED,
    username,
    password
  }
}
export const modifyStudentDetails = (payload) => {
  return {
    type: MODIFY_STUDENT_DETAILS_REQUESTED,
    payload
  }
}
export const resetModifyStudentDetails = () => {
  return {
    type: RESET_MODIFY_STUDENT_DETAILS
  }
}

export const resetStudentPassword = (username) => {
  return {
    type: RESET_STUDENT_PASSWORD_REQUESTED,
    username
  }
}


export const fetchFilteredAssessmentClasses=(query)=>{
  return {
    type: GET_FILTERED_ASSESSMENT_CLASSES_REQUESTED,
    query
  }
}

export const fetchAllAssessmentClassesLoading=()=>{
  return {
    type: FETCH_ALL_ASSESSMENT_CLASSES_LOADING
  }
}

export const getAllAssessmentClasses=(offset,limit)=>{
  return {
    type: GET_ALL_ASSESSMENT_CLASSES_REQUESTED,
    offset,
    limit
  }
}

export const getAssessmentDraftClasses = (draftId)=>{
  return {
    type: GET_ASSESSMENT_DRAFT_CLASSES_REQUESTED,
    draftId
  }
}

export const addAssessmentDraftClasses = (draftId,classes,action)=>{
  return {
    type: ADD_ASSESSMENT_DRAFT_CLASSES_REQUESTED,
    draftId,
    classes,
    action
  }
}

export const getAssessmentAddedClasses = (assessmentId)=>{
  return {
    type: GET_ASSESSMENT_ADDED_CLASSES_REQUESTED,
    assessmentId
  }
}

export const addStudentInClassReq=(classCode,data)=>{
  return {
    type:ADD_STUDENT_IN_CLASS_REQUESTED,
    classCode,
    data
  }
}

export const updateSingleStudentNinjaTeam=(studentId, classCode, groupId, groupName) => {
  return {
    type:UPDATE_SINGLE_STUDENT_NINJA_TEAM,
    studentId,
    classCode,
    groupId,
    groupName
  }
}

export const addMultiStudentInClassReq=(classCode,data)=>{
  return {
    type:GET_MULTI_STUDENT_GROUP_SUBMISSION_REQUESTED,
    classCode,
    data
  }
}

export const setSyncLeaderBoardDataLoader = (isSyncing) => {
  return {
    type: SET_SYNC_LEADERBOARD_DATA_LOADER,
    isSyncing
  }
}


export const getLeaderBoardData=(classCode)=>{
  return {
    type:GET_LEADERBOARD_DATA_REQUESTED,
    classCode
  }
}

export const getMyLeaderBoardData=(classCode)=>{
  return {
    type:GET_MYLEADERBOARD_REQUESTED,
    classCode
  }
}

export const getToggleLeaderBoardData=(classCode)=>{
  return {
    type:GET_TOGGLE_LEADERBOARD_REQUESTED,
    classCode
  }
}

export const getLeaderBoardStudentData=(classCode)=>{
  return {
    type:GET_LEADERBOARD_STUDENT_REQUESTED,
    classCode
  }
}

export const EMPTY_NEW_CLASS = "EMPTY_NEW_CLASS"

export const initApp = () => {
  return {
    type: INIT_APP,
  };
};

export const getMe = () => {
  return {
    type: GET_ME_REQUESTED,
  };
};

export const refreshUser = () => {
  return {
    type: GET_USER_REFRESH_REQUESTED,
  };
};

export const userLoggedOut = () => {
  return {
    type: USER_LOGGED_OUT,
  };
};

export const registerUser = (newUser,language) => {
  return {
    type: POST_NEW_USER_REQUESTED,
    newUser,
    language
  };
};

export const loginUser = (user,language) => {
  return {
    type: GET_USER_LOGIN_REQUESTED,
    user,
    language
  };
};

export const forgotPassword = (user,language) => {
  return {
    type: GET_USER_PASSWORD_LINK_REQUESTED,
    user,
    language
  };
};

export const getUserPasswordLinkSucceeded = (payload) => {
  return {
    type: GET_USER_PASSWORD_LINK_SUCCEEDED,
    ...payload,
  };
};

export const getUserLoginSucceeded = (payload) => {
  return {
    type: GET_USER_LOGIN_SUCCEEDED,
    ...payload,
  };
};

export const resetPasswordSucceeded = (payload) => {
  return {
    type: RESET_PASSWORD_SUCCEEDED,
    ...payload,
  };
};

export const verifyResetCode = (queryParams,language) => {
  return {
    type: VERIFY_RESET_CODE_REQUESTED,
    queryParams,
    language
  };
};

export const resetPassword = (userInfo,language) => {
  return {
    type: RESET_PASSWORD_REQUESTED,
    userInfo,
    language
  };
};

export const resetTestReport = () => {
  return {
    type: RESET_STUDENT_TEST_REPORT,
  };
};

export const submitJoinError = (type,payload) => {
  return {
    type,
    ...payload,
  };
};

export const submitCode = (schoolObj) => {
  return {
    type: GET_SCHOOL_CODE_SUBMISSION_REQUESTED,
    schoolObj,
  };
};

export const submitPin = (schoolObj) => {
  return {
    type: GET_SCHOOL_PIN_SUBMISSION_REQUESTED,
    schoolObj,
  };
};

export const fetchGrades = (classCode) => {
  return {
    type: GET_SCHOOL_GRADES_REQUESTED,
    classCode,
  };
};

export const fetchLevelDropdownItems = (classCode) => {
  return {type: GET_LEVEL_DROPDOWN_ITEMS_REQUESTED, classCode}
}

export const submitLevel = (levelObj) => {
  return {type: GET_LEVEL_SUBMISSION_REQUESTED, levelObj}
}

export const submitMultiStudentLevel = (levelObj) => {
  return {type: GET_MULTI_STUDENT_LEVEL_SUBMISSION_REQUESTED, levelObj}
}

export const gradeSelected = (grade) => {
  return {
    type: GET_SCHOOL_GRADE_SELECTED,
    grade,
  };
};

export const classCreated = (className) => {
  return {
    type: GET_CLASS_CREATE_SELECTED,
    className,
  };
};

export const onboardingstudnentExcelUpload = (classCode, file, sendToStudent) => {
  return {
    type: GET_STUDENT_EXCEL_UPLOAD_REQUESTED,
    classCode,
    file,
    sendToStudent,
  };
};
export const emptyExcelUpload = () => {
  return {
    type: EMPTY_EXCEL_UPLOAD,
  };
};

export const onboardingStudentExcelDownload = (classCode) => {
  return {
    type: GET_STUDENT_EXCEL_DOWNLOAD_REQUESTED,
    classCode,
  };
};
export const submitCreateMultiClass = (classesArr) => {
  return {
    type: GET_CREATE_MULTI_CLASS_REQUESTED,
    classesArr,
  };
};
export const submitCreateClass = (selectGClassroom) => {
  return {
    type: GET_CREATE_CLASS_REQUESTED,
    selectGClassroom,  
  };
};

export const resetCreateClass = () => {
  return {
    type: RESET_CREATE_CLASS,
  };
};

export const submitClassPin = (pin) => {
  return {
    type: GET_PIN_SUBMIT_REQUESTED,
    pin,
  };
};

export const deleteNewClass = () => {
  return {
    type: GET_NEW_CLASS_INITIALIZED,
  };
};

export const selectClass = (classObj, gradeObj) => {
  return {
    type: CLASS_SELECTED,
    classObj,
    gradeObj,
  };
};

export const selectMode = (mode) => {
  return {
    type: MODE_SELECTED,
    mode
  };
};
export const fetchScreenErrorsMapping = () => {
  return {
    type: GET_SCREEN_ERRORS_MAPPING_REQUESTED,
  };

}
export const submitLoginErrorReport = (username) => {
  return {
    type: SUBMIT_LOGIN_ERROR_REPORT_REQUESTED,
    username,
  };
}
export const resetSubmitLoginErrorReport = () => {
  return {
    type: RESET_SUBMIT_LOGIN_ERROR_REPORT,
  };
}

export const submitErrorReport = (payload,classId) => {
  return {
    type: SUBMIT_ERROR_REPORT_REQUESTED,
    payload,
    classId
  };
}

export const resetSubmitErrorReport = () => {
  return {
    type: RESET_SUBMIT_ERROR_REPORT,
  };
}

export const classCategorySelected = (category) => {
  return {
    type: CLASS_CATEGORY_SELECTED,
    category,
  };
};

export const fetchChapters = (action) => {
  return {
    type: GET_CHAPTERS_REQUESTED,
    action,
  };
};

export const fetchStudents = () => {
  return {
    type: GET_STUDENTS_REQUESTED,
  };
};

export const removeStudent = (studentId) => {
  return {
    type: DELETE_STUDENT_REQUESTED,
    studentId,
  };
};

export const chapterSelected = (chapter, index) => {
  return {
    type: GET_TOPIC_SELECTED,
    chapter,
    index,
  };
};

export const fetchConfigType = (classCode, topicId) => {
  return {
    type: GET_CONFIG_TYPE_REQUESTED,
    classCode,
    topicId,
  };
};

export const fetchStudentReport = (classCode, topicId) => {
  return {
    type: GET_STUDENT_REPORT_REQUESTED,
    classCode,
    topicId,
  };
};

export const levelSelected = (level) => {
  return {
    type: TOPIC_LEVEL_SELECTED,
    level,
  };
};

export const resetStudentReport = () => {
  return {
    type: RESET_STUDENT_REPORT,
  };
};

export const fetchQuestion = (configId) => {
  return {
    type: GET_QUESTION_REQUESTED,
    configId,
  };
};

export const showQuestion = () => {
  return {
    type: SHOW_QUESTION,
  };
};

export const closeQuestion = () => {
  return {
    type: CLOSE_QUESTION,
  };
};

export const logoutUser = () => {
  return {
    type: GET_USER_LOGOUT_REQUESTED,
  };
};

export const startConference = () => {
  return {
    type: GET_START_CONFERENCE_REQUESTED,
  };
};

export const getMeetingUrl = (code) => {
  return {
    type: GET_MEETING_URL_REQUESTED,
    code,
  };
};

export const fetchDateRange = (classCode) => {
  return {
    type: FETCH_DATE_RANGE_REQUESTED,
    classCode,
  };
};
export const selectAttendenceMonth = (dateDetails, noofDays) => {
  return {
    type: SELECT_ATTENDENCE_MONTH,
    dateDetails,
    noofDays,
  };
};
export const subtopicSelected = (subtopic, index) => {
  return {
    type: SUBTOPIC_SELECTED,
    subtopic,
    index,
  };
};
export const goBackToTopic = () => {
  return {
    type: GO_BACK_TO_TOPIC,
  };
};

export const createAnnouncement = (classCode, inputText, isUpdate) => {
  return {
    type: CREATE_ANNOUNCEMENT_REQUESTED,
    classCode,
    inputText,
    isUpdate
  };
};

export const updateAnnouncement = (classCode) => {
  return {
    type: UPDATE_ANNOUNCEMENT_REQUESTED,
    classCode,
  };
};

export const resetAnnouncementSubmitted = (classCode) => {
  return {
    type: RESET_ANNOUNCEMENT_SUBMITTED,
    classCode,
  };
};

export const deleteAnnouncement = (classCode, orgAnnouncement) => {
	return {
		type: DELETE_ANNOUNCEMENT_REQUESTED,
		classCode,
		orgAnnouncement,
	};
};

export const getStudentSession = (studentId, topicId) => {
  return {
    type: GET_STUDENT_SESSION_REQUESTED,
    studentId,
    topicId,
  };
};

export const getStudentStuck = (studentId, topicId) => {
  return {
    type: GET_STUDENT_STUCK_REQUESTED,
    studentId,
    topicId,
  };
};

export const getAttemptsNew = (studentId, topicId) => {
  return {
    type: GET_ATTEMTPS_NEW_REQUESTED,
    studentId,
    topicId,
  };
};

export const fetchQuestionHistory = (questionId) => {
  return {
    type: FETCH_QUESTION_HISTORY_REQUESTED,
    questionId,
  };
};

export const questionTypeRequested = (questionTypeObj) => {
  return {
    type: QUESTION_TYPE_REQUESTED,
    questionTypeObj,
  };
};

export const goBackHomeDashboard = () => {
  return {
    type: GO_BACK_HOME_DASHBOARD_REQUESTED,
  };
};

export const questionTypeConfig = (selectedClass, configId) => {
  return {
    type: QUESTION_TYPE_CONFIG_REQUESTED,
    selectedClass,
    configId,
  };
};

export const getTests = (classCode) => {
  return {
    type: GET_TESTS_REQUESTED,
    classCode,
  };
};

export const selectTest = (testObj) => {
  return {
    type: GET_TESTS_SELECTED,
    testObj,
  };
};

export const getSelectTest = (classCode) => {
  return {
    type: SELECT_TEST_REQUESTED,
    classCode,
  };
};

export const resetTest = () => {
  return {
    type: RESET_TEST,
  };
};

export const fetchExamQuestion = (questions, userId, questionIndex, attemptOrder) => {
  return {
    type: GET_EXAMS_QUESTIONS_REQUESTED,
    questions,
    userId,
    questionIndex,
    attemptOrder,
  };
};

export const launchTest = (testId, classCode) => {
  return {
    type: LAUNCH_EXAM_REQUESTED,
    testId,
    classCode,
  };
};

export const fetchExamReport = (classCode, examId) => {
  return {
    type: FETCH_EXAM_REPORT_REQUESTED,
    examId,
    classCode,
  };
};

export const resetTestAttempts = (classCode, examId,userId) => {
  return {
    type: RESET_TEST_ATTEMPTS_REQUESTED,
    classCode,
    examId,
    userId
  };
};

export const fetchStudentWiseReport = (examId, userId, attemptOrder) => {
  return {
    type: FETCH_STUDENT_WISE_REPORT_REQUESTED,
    examId,
    userId,
    attemptOrder
  };
};
export const fetchQuestionPracticed = (studentId, topicId) => {
  return {
    type: FETCH_PRACTICED_REQUESTED,
    studentId,
    topicId,
  };
};

export const publishTeacherTopic = (
  classCode,
  publishStatus,
  chapterId,
  topicIndex,
  topicId
) => {
  return {
    type: PUBLISH_TEACHER_TOPIC_REQUESTED,
    classCode,
    publishStatus,
    chapterId,
    topicId,
    topicIndex,
  };
};

export const selectChapter = (confClass, confChapter) => {
  return {
    type: SELECT_CHAPTER_REQUESTED,
    confClass,
    confChapter,
  };
};

export const submitChangePassword = (newPassword, oldPassword) => {
  return {
    type: GET_CHANGE_PASSWORD_REQUESTED,
    newPassword,
    oldPassword,
  }
}

export const submitReport = (data,configId) => {
  return {
    type: POST_REPORT_REQUESTED,
    data,
    configId
  }
}
export const resetReport = () => {
  return {
    type: POST_REPORT_RESET,
  }
}

export const getTopicSyllabus = (chapterId) => {
  return {
    type: GET_SYLLABUS_REQUESTED,
    chapterId
  }
}

export const chooseSyllabus = (chapterId, index) => {
  return {
    type: SYLLABUS_SELECTED,
    chapterId,
    index
  }
}

export const getConfigTypeInfo = (topicId, topicLevel, limit, offset) => {
  return {
    type: CONFIG_DATA_REQUESTED,
    topicId,
    topicLevel,
    limit,
    offset
  }
}

export const resetAt = () => {
  return {
    type: RESET_STUCK_AT,
  }
}

export const getContentInfo = (contentId) => {
  return {
    type: GET_QUESTION_CONTENT_REQUESTED,
    contentId
  }
}

export const showBrokendown = (configId, quesId) => {
  return {
    type: GET_BROKEN_DOWN_REQUESTED,
    configId,
    quesId,
  }
}

export const goBackTopic = () => {
  return {
    type: EXIT_OVERVIEW
  }
}

export const registerClientUser = userObj => {
  return {
    type: REGISTER_CLIENT_USER_REQUESTED,
    userObj
  }
}

export const clientMailResend = () => {
  return {
    type: CLIENT_MAIL_RESEND_REQUESTED
  }
}

export const registerStudent = (newStudent, force) => {
  return {
		type: REGISTER_NEW_STUDENT_REQUESTED,
		newStudent,
		force,
	};
};

export const setNewStudentRegistrationData = (studentData) => {
	return {
		type: SET_NEW_STUDENT_REGISTRATION_DATA,
		studentData,
	};
};

export const resetNewStudentRegistration = () => {
	return {
		type: RESET_NEW_STUDENT_REGISTRATION,
	};
};

export const checkStudentExists = (email, classCode) => {
  return {
    type: CHECK_STUDENT_EXISTS,
    email,
    classCode
  }
}
export const checkTeacherExists = (email, classCode) => {
  return {
    type: CHECK_TEACHER_EXISTS,
    email,
    classCode
  }
}
export const fetchClassTeachers = (classCode) => {
  return {
    type: GET_CLASS_TEACHERS_REQUESTED,
    classCode
  }
}
export const removeClassTeachers = (teacherId,classCode) => {
  return {
    type: REMOVE_CLASS_TEACHERS_REQUESTED,
    teacherId,
    classCode
  }
}
export const getSearchedTeachers = (searchText,classCode) => {
  return {
    type: GET_SEARCH_TEACHERS_REQUESTED,
    searchText,
    classCode
  }
}

export const bulkRegisterStudents = (payload) => {
  return {
		type: BULK_REGISTER_STUDENTS_REQUESTED,
		payload
	};
};

export const bulkRegisterTeachers = (payload) => {
  return {
    type: BULK_REGISTER_TEACHERS_REQUESTED,
    payload
  }
}

export const bulkSetStudentsRegistrationData = (payload) => {
	return {
		type: BULK_SET_STUDENTS_REGISTRATION_DATA,
		payload
	};
};

export const bulkResetNewStudentsRegistration = () => {
	return {
		type: BULK_RESET_STUDENTS_REGISTRATION,
	};
};
export const resetExcelNewStudentRegistration = () => {
  return {
    type: RESET_EXCEL_NEW_STUDENT_REGISTRATION,
  };
};
export const bulkResetNewTeachersRegistration = () => {
	return {
		type: BULK_RESET_TEACHERS_REGISTRATION,
	};
};

export const getTopicLaunchSettings = (classId,topicId) => {
  return {
    type: GET_TOPIC_LAUNCH_SETTINGS_REQUESTED,
    classId,
    topicId
  }
}

export const setTopicLaunchSettings = (data,topicId) => {
  return {
    type: SET_TOPIC_LAUNCH_SETTINGS_REQUESTED,
    data,
    topicId
  }
}

export const setTopicLaunchSettingsReset = () => {
  return {
    type: SET_TOPIC_LAUNCH_SETTINGS_RESET,
  };
};

export const getAssessmentLaunchSettings = (classId,assessmentId) => {
  return {
    type: GET_ASSESSMENT_LAUNCH_SETTINGS_REQUESTED,
    classId,
    assessmentId
  }
}

export const setAssessmentLaunchSettings = (data,assessmentId) => {
  return {
    type: SET_ASSESSMENT_LAUNCH_SETTINGS_REQUESTED,
    data,
    assessmentId
  }
}
export const getClassBookmarksData = (payload) => {
  return {
    type: GET_CLASS_BOOKMARKS_DATA,
    payload
  }
}

export const getAssessmentDraftData = (payload) => {
  return {
    type: GET_ASSESSMENT_DRAFT_DATA,
    payload
  }
}
export const updateAssessmentDraftData = (payload) => {
  return {
    type: UPDATE_ASSESSMENT_DRAFT_DATA,
    payload
  }
}

export const getAssessmentRequests = (classCode) => {
  return {
    type: FETCH_ASSESSMENT_REQUESTS,
    classCode
  }
}

export const submitAssessmentRequest = (payload) => {
  return {
    type: SUBMIT_ASSESSMENT_REQUEST,
    payload
  }
}
export const submitAssessmentRequestReset = (payload) => {
  return {
    type: SUBMIT_ASSESSMENT_REQUEST_RESET,
    payload
  }
}

export const publishAssessmentRequest = (payload) => {
  return {
    type: PUBLISH_ASSESSMENT_REQUEST,
    payload
  }
}

export const publishAssessmentRequestReset = (payload) => {
  return {
    type: PUBLISH_ASSESSMENT_REQUEST_RESET,
  }
}

export const addQuestionToBookmarkData = (payload) => {
  return {
    type: ADD_QUESTION_TO_BOOKMARK_DATA,
    payload
  }
}
export const removeQuestionFromBookmarkData = (payload) => {
  return {
    type: REMOVE_QUESTION_FROM_BOOKMARK_DATA,
    payload
  }
}
export const updateBookmarkData = (payload) => {
  return {
    type: UPDATE_BOOKMARK_DATA,
    payload
  }
}
export const moveQuestion = (payload) => {
  return {
    type: MOVE_QUESTION,
    payload
  }
}
export const setActiveViewDraft = (payload) => {
  return {
    type: SET_ACTIVE_VIEW_DRAFT,
    payload
  }
}

export const setAssessmentLaunchSettingsReset = () => {
  return {
    type: SET_ASSESSMENT_LAUNCH_SETTINGS_RESET,
  };
};

export const selectLanguagePracticeMode = (language) => {
  return {
    type: SELECT_LANGUAGE_PRACTICE_MODE,
    language
  }
}
export const selectLanguagePracticeModeReset = () => {
  return {
    type: SELECT_LANGUAGE_PRACTICE_MODE_RESET,
  }
}

export const emptyNewClass = () => {
  return {
    type: EMPTY_NEW_CLASS
  }
}

export const studentRegisteredDevice = (id) => {
  return {
    type: STUDENT_REGISTERED_DEVICES,
    id
  }
}

export const fetchDateFilters = () => {
  return {
    type: FETCH_DATE_FILTERS_REQUESTED
  }
}

export const getNotificationAlert = (userIds, startDate, endDate) => {
  return {
    type: GET_ALERT_REQUESTED,
    userIds,
    startDate,
    endDate,
  }
}

export const getNotificationSession = (userId, notificationId, startDate, endDate) => {
  return {
    type: GET_NOTIFICATION_SESSION_REQUESTED,
    userId,
    notificationId,
    startDate,
    endDate,
  }
}

export const getQuestionAttempts = (configId, userId) => {
  return {
    type: GET_QUESTION_ATTEMPTS_REQUESTED,
    configId,
    userId
  }
}

export const getConnectGoogleClass = () => {
  return {
    type: GET_CONNECT_GOOGLECLASS_REQUESTED,
  }
}

export const getSwitchGoogleAccount = (code) => {
  return {
    type: GET_SWITCH_GOOGLEACCOUNT_REQUESTED,
    code,
  }
}

export const getTeacherGoogleAccountLogin = () => {
  return {
    type: GET_TEACHER_GOOGLELOGIN_REQUESTED,
  }
}

export const getTeacherGoogleAccountSignup = () => {
  return {
    type: GET_TEACHER_GOOGLESIGNUP_REQUESTED,
  }
}

export const getGoogleClasses = () => {
  return {
    type: GET_GOOGLECLASSES_REQUESTED,
  }
}

export const getGoogleClassesOnboarding = () => {
  return {
    type: GET_GOOGLECLASSESONBOARDING_REQUESTED,
  }
}

export const getGoogleClassroomStudents = (code) => {
  return {
    type: GET_GOOGLE_CLASSROOM_STUDENTS_REQUESTED,
    code
  }
}

export const syncGoogleClassroomStudents = (code,payload) => {
  return {
    type: SYNC_GOOGLE_CLASSROOM_STUDENTS_REQUESTED,
    code,
    payload
  }
}

export const getLinkGoogleClass = (googleName, googleCode) => {
  return {
    type: GET_LINK_GOOGLECLASS_REQUESTED,
    googleName,
    googleCode
  }
}

export const getUnlinkGoogleClass = () => {
  return {
    type: GET_UNLINK_GOOGLECLASS_REQUESTED,
  }
}

export const getAllAttempts = userId => {
  return {
    type: GET_ALL_ATTEMPTS_REQUESTED,
    userId
  }
}

export const removeTestInfo = () => {
  return {
    type: REMOVE_TEST_INFO
  }
}

export const fetchAutoGradingCts = (examId, studentId) => {
  return {
    type: FETCH_AUTO_GRADING_CTS_REQUESTED,
    examId,
    studentId
  }
}

export const fetchAutoGradingQuestions = (configIds,userId) => {
  return {
    type: GET_AUTO_GRADING_QUESTIONS_REQUESTED,
    configIds,
    userId
  }
}

export const uploadAutoGradingImage = (file,index, studentId, classCode, configId, questionId, quesNo, examId, subpartNo) => {
  return {
    type: UPLOAD_AUTO_GRADING_IMAGE_REQUESTED,
    file,
    index,
    studentId,
    classCode,
    configId,
    questionId,
    quesNo,
    examId,
    subpartNo
  }
}

export const resetUploadAutoGradingImage = () => {
  return {
    type: RESET_UPLOAD_AUTO_GRADING_IMAGE
  }
}

export const fetchUnattemptedAutoGrading = (examId, studentId) => {
  return {
    type: FETCH_UNATTEMPTED_AUTO_GRADING_REQUESTED,
    examId,
    studentId
  }
}

export const evaluateAutoGradingImage = (examId, studentId) => {
  return {
    type: EVALUATE_AUTO_GRADING_IMAGE_REQUESTED,
    studentId,
    examId
  }
}
export const resetEvaluateAutoGradingImage = () => {
  return {
    type: RESET_EVALUATE_AUTO_GRADING_IMAGE
  }
}