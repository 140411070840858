import apisauce from "apisauce";
import { once } from "./utils";
import { downloadStudentExcel, uploadStudentExcel } from "./sagas";

const BASE_URL = "https://staging.sparkbackend.cerebry.co/";
const teacherDashboardAPI = "teacher_dashboard";
const apiV1Class = "api/v1/classes";

const create = (baseURL = BASE_URL) => {
  const api = apisauce.create({
    baseURL,
    headers: {
      "Content-Type": "application/json",
    },
    timeout: 10000,
  });

  const studentRegisteredDevice = (userId) => {
    // api.setHeader("Authorization", token);
    // return api.get('api/v2/leaderboard/student_registered_devices');
    return api.get(`api/v2/leaderboard/student/${userId}/student_registered_devices/`);
  };

  const setAccessToken = (token) => {
    return api.setHeader("Authorization", token);
  };

  const removeAccessToken = () => {
    delete api.headers["Authorization"];
  };

  const retryApiSauce = (response) => {
    let methodType = response.config.method.toUpperCase();
    switch (methodType) {
      case 'GET':
        return api.get(response.config.url,response.config.params);
      case 'POST':
        return api.post(response.config.url,response.config.data);
      case 'PUT':
        return api.put(response.config.url,response.config.data);
      case 'DELETE':
        return api.delete(response.config.url,response.config.params);
      default:
        return api.any(response.config);
    }
  }

  const check401 = once((refreshToken) => {
    api.addAsyncResponseTransform(async (response) => {
      let status = response.status || response.data.status;
      let apiUrl = response.url || response.config.url;
      const apiImmuned401 = `api/v2/users/refresh`;
      if(status == 401 && apiUrl.indexOf(apiImmuned401) < 0) {
          setAccessToken(refreshToken);
          const updatedJwtTokenResponse = await refreshUser();
          if(updatedJwtTokenResponse.status != 401 && updatedJwtTokenResponse.data && updatedJwtTokenResponse.data.jwt) {
            let newJwtToken = "jwt "+updatedJwtTokenResponse.data.jwt;
            setAccessToken(newJwtToken);
            saveJWT(JWT_TOKEN,updatedJwtTokenResponse.data.jwt);
            const updatedResponse = await retryApiSauce(response);
            if(updatedResponse && updatedResponse.status != 401 && updatedResponse.data) {
              //this line is not mutating the response, so setting the corresponding values alone.
              //response = updatedResponse;
              response.data = updatedResponse.data;
              response.status = updatedResponse.status;
            }
          } else {
            response.tokenRefreshed = true;
          }
      }
    });
  });

  const getMe = () => {
    return api.get(`api/v2/users/me/`);
  };

  const refreshUser = () => {
    return api.post(`api/v2/users/refresh/`);
  };

  const registerUser = (user,language) => {
    return api.post(`api/v2/users/register/?language=${language}`, user);
  };

  const getClassLeaderboard = code => {
    return api.get(`api/v2/users/class/${code}/leaderboard/`)
  }

  const loginUser = (user,language) => {
    return api.post(`api/v2/users/login/?language=${language}`, user);
  };

  const submitCode = (schoolObj) => {
    return api.post(`api/v2/users/school-addition/`, schoolObj);
  };

  const submitPin = (schoolObj) => {
    return api.post(
      `api/v2/users/school/${schoolObj.school_code}/confirmation/`,
      { school_pin: schoolObj.school_pin }
    );
  };

  const fetchGrades = () => {
    return api.get(`api/v2/users/grades/`);
  };

  const fetchLevelDropdownItems = (classCode) => {
    return api.get(`api/v2/users/class/${classCode}/fetch-level-locks-v2/`);
  }

  const submitLevel = ({studentId, levels, classCode})=> {
    return api.post(
      `api/v2/users/class/${classCode}/set-level-locks/`,
      {
        student_id: studentId,
        levels
      }
    );
  }  

  const submitMultiStudentLevel = ({student_ids, levels, classCode})=> {
    return api.post(
      `api/v2/users/class/${classCode}/set-bulk-level-locks/`,
      {
        student_ids,
        levels
      }
    );
  }
  const uploadStudentExcel = (classCode, file,sendToStudent) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("send_to_student",sendToStudent);
    return api.post(
      `api/v2/users/class/${classCode}/submit-student-onboarding-excel/`,
      formData
    );
  }
  const downloadStudentExcel = (classCode) => {
    return api.get(
      `api/v2/users/class/${classCode}/download-student-onboarding-excel/`
    );
  };
  const createMultiClass = (classesArr) => {
    return api.post(`api/v2/users/multi-class/create/`, classesArr);
  };

  const createClass = (params) => {
    return api.post(`api/v2/users/class/create/`, params);
  };

  const createPin = (pin, classCode) => {
    const pinBody = {
      pin: pin,
    };
    return api.post(
      `api/v2/users/class/${classCode}/question/create/`,
      pinBody
    );
  };

  const fetchChapters = (code) => {
    return api.get(`api/v2/users/teacher-class/${code}/chapters/`);
  };

  const fetchStudents = (code) => {
    return api.get(`api/v2/users/teacher-class/${code}/students/`);
  };

  const removeStudent = (code, id) => {
    return api.delete(`api/v2/users/class/${code}/student/${id}/`);
  };

  const fetchConfigType = (code, topicId) => {
    return api.get(`api/v2/users/${code}/topic/${topicId}/cts/`);
  };

  const fetchStudentReport = (code, topicId) => {
    return api.get(`api/v2/users/${code}/topic/${topicId}/students/`);
  };

  const fetchQuestion = (configId, code) => {
    return api.get(`api/v2/users/${code}/config_type/${configId}/question/`);
  };

  const logoutUser = () => {
    return api.post(`api/v2/users/logout/`);
  };

  const forgotPassword = (user,language) => {
    return api.post(`api/v2/users/forgot/?language=${language}`, user);
  };

  const verifyResetCode = (params,language) => {
    return api.post(`api/v2/users/verify/?language=${language}`, params);
  };

  const resetPassword = (userInfo,language) => {
    return api.post(`api/v2/users/reset/?language=${language}`, userInfo);
  };

  const startConference = (classCode, params) => {
    return api.post(`api/v2/meetings/class/${classCode}/meeting/`, params);
  };

  const getAttendanceDates = (classCode) => {
    return api.get(`api/v2/meetings/class/${classCode}/date-range/`);
  };
  const getMeetingUrl = (code) => {
    const accountType = {
      account_type: "teacher",
    };
    return api.post(`api/v2/meetings/class/${code}/meeting-link/`, accountType);
  };

  const attendListOfMonth = (startMonth, endMonth, selectedClass) => {
    return api.get(
      `api/v2/meetings/class/${selectedClass.code}/date/${startMonth}/${endMonth}/attendance`
    );
  };

  const createAnnouncement = (code, text) => {
    const textObj = {
      text,
    };
    return api.post(
      `api/v2/meetings/class/${code}/announcement/create/`,
      textObj
    );
  };

  const deleteAnnouncement = (code) => {
    return api.delete(`api/v2/meetings/class/${code}/announcement/delete/`);
  };
  const getStudentSession = (topicId, studentId) => {
    return api.get(`api/v2/users/topic/${topicId}/user/${studentId}/sessions/`);
  };

  const getStudentStuck = (topicId, studentId) => {
    return api.get(`api/v2/users/topic/${topicId}/user/${studentId}/current-attempts/`);
  };
  const getAttemptsNew= ( studentId,topicId) => {
   
    return api.get(`api/v2/alerts/teacher-class/TC/user/${studentId}/config_type/${topicId}/attempts/`);
  };

  const fetchQuestionHistory = (questionId) => {
    return api.get(`api/v2/users/history/${questionId}/question/`);
  };

  const fetchTopicSyllabus = (chapterId) => {
    return api.get(`api/v2/users/chapter/${chapterId}/syllabus/`);
  };

  const fetchConfigTypeInfo = ({ topicId, topicLevel, limit, offset }, language) => {
    return api.get(
      `/api/v2/users/topic/${topicId}/config-types/?topic_level=${topicLevel}&limit=${limit}&offset=${offset}`,!!language?{"language":language}:undefined
    );
  };

  const fetchQuestionContentInfo = (contentId) => {
    return api.get(`/api/v2/users/content/${contentId}/fetch/`);
  };


  const leaderboard=(classCode)=>{
    console.log(`/api/v2/leaderboard/teacher-class/${classCode}/leader_board/`)
    return api.get(`/api/v2/leaderboard/teacher-class/${classCode}/leader_board/`)
  }

  const leaderboardData=(classCode)=>{
    return api.get(`/api/v2/leaderboard/teacher-class/${classCode}/leader_board_data/`)
  }

  const myleaderboard=(classCode)=>{
    return api.get(`/api/v2/leaderboard/teacher-class/${classCode}/leader_board/`)
  }
  const addStudentInClass=(classCode,data)=>{
    return api.post(`/api/v2/leaderboard/teacher-class/${classCode}/add_student_in_class_group/`,data)
  }

  const addMultiStudentInClassReq=(classCode,data)=>{
    return api.post(`/api/v2/leaderboard/teacher-class/${classCode}/add_bulk_student_in_single_group/`,data)
  }

  const activateLeaderboard=(classCode)=>{
    return api.post(`/api/v2/leaderboard/teacher-class/${classCode}/activate_deactivate_leaderboard/`)
  }

  const getMyStudentLeaderBoard=(classCode)=>{
    return api.get(`/api/v2/leaderboard/teacher-class/${classCode}/fetch_student_class_groups/`)
  }
  const getstudentLeaderBoard=(classCode)=>{
    return api.get(`/api/v2/leaderboard/teacher-class/${classCode}/fetch_student_class_groups/`)
  }

  const submitConfigTypeReport = (
    configTypeId,
    issueType,
    text,
    question,
    chapter
  ) => {
    const requestObj = {
      issue_type: issueType,
      text,
      question,
      chapter,
    };
    return api.post(
      `api/v2/users/config_type/${configTypeId}/report/`,
      requestObj
    );
  };

  const fetchExamList = (classCode) => {
    return api.get(`/api/v2/users/teacher-class/${classCode}/exams/`);
  };

  const launchTest = (testId, classCode) => {
    const requestBody = {
      class_code: classCode,
    };
    return api.post(`/api/v2/users/assessment/${testId}/launch/`, requestBody);
  };

  const publishTeacherTopic = (classCode, publishStatus, topicId) => {
    const requestBody = {
      class_code: classCode,
      publish: publishStatus,
    };
    return api.post(`api/v2/users/topic/${topicId}/publish/`, requestBody);
  };
  const fetchExamQuestion = (configTypeId, userId, attemptOrder) => {
    if(attemptOrder) return api.get(
      `/api/v2/users/teacher-exam-config-type/${configTypeId}/question/?user_id=${userId}&attempt_order=${attemptOrder}`
    )
    return api.get(
      `/api/v2/users/teacher-exam-config-type/${configTypeId}/question/?user_id=${userId}`
    );
  };

  const fetchStudentResult = (testId, userId) => {
    return api.get(`/api/v2/users/assessment/${testId}/user/${userId}/report/`);
  };
  const fetchExamReport = (classCode, examId) => {
    return api.get(
      `api/v2/users/teacher-class/${classCode}/exam/${examId}/live-report/`
    );
  };

  const resetTestAttempts = (classCode, examId,userId) => {
    const reqBody = {
      exam_id: examId,
      user_id: userId
    };
    return api.post(
      `api/v2/users/teacher-class/${classCode}/exam-reset/` ,reqBody 
    );
  };

  const fetchStudentWiseReport = (examId, userId, attemptOrder) => {
    if(attemptOrder) return api.get(`api/v2/users/assessment/${examId}/user/${userId}/report/?attempt_order=${attemptOrder}`)
    return api.get(`api/v2/users/assessment/${examId}/user/${userId}/report/`);
  };
  const getPracticedQuestion = (topicId, studentId) => {
    return api.get(
      `api/v2/users/topic/${topicId}/user/${studentId}/ct-practiced/`
    );
  };
  const changePassword = (oldPassword, newPassword) => {
    const passwordObj = {
      new_password: newPassword,
      password: oldPassword
    }
    return api.post(`api/v2/users/change/`, passwordObj)
  }

  const report = (data,configId) => {
    return api.post(`/api/v2/users/config_type/${configId}/report/`, data)
  }

  const fetchBrokenDown = (configId, quesId, language) => {
    return api.get(`/api/v2/users/config_type/${configId}/question/${quesId}/fetch-broken-down-questions/`,!!language?{"language":language}:undefined)
  }

  const registerClientUser = userObj => {
    return api.post(`/api/v2/users/register-demo-user/`, userObj)
  }

  const resendClientMail = payload => {
    return api.post(`/api/v2/users/demo-user-resend-email/`, payload)
  }

  const registerNewStudent = (user, force) => {
    if (force) {
      return api.post(`api/v2/users/register-child/?force=true`, user);
    } else {
      return api.post(`api/v2/users/register-child/`, user);
    }
	};

  const fetchCurriculums = (schoolId) => {
    return api.get(`/api/v2/users/school/${schoolId}/demo-user-classes`);
  };

  const checkIfStudentExists = (email,classCode) => {
    return api.get(`/api/v2/users/class/${classCode}/student/${email}/check-exists/`)
    // return api.get(`/api/v2/users/student/${email}/check-exists/`)
  }
  const checkIfTeacherExists = (email,classCode) => {
    return api.get(`/api/v2/users/class/${classCode}/teacher/${email}/check-teacher-exists/`)
    // return api.get(`/api/v2/users/student/${email}/check-exists/`)
  }

  const fetchClassTeachers = (classCode) => {
    return api.get(`/api/v2/users/class/${classCode}/fetch-all-teachers/`)
  }
  const removeClassTeachers = (classCode,teacherId) => {
    return api.post(`/api/v2/users/class/${classCode}/remove-teacher/`,{teacher_id:teacherId})
  }

  const fetchSearchedTeachers =(searchText,classCode) =>{
    return api.get(`/api/v2/users/class/${classCode}/search-teachers/?search=${searchText}`)
  }
  const bulkRegisterStudents = payload => {
    return api.post(`/api/v2/users/bulk-student-register/`, payload)
  }
  const bulkRegisterTeachers = payload => {
    return api.post(`/api/v2/users/teacher-class-addition/`, payload)
  }


  const fetchTopicLaunchSettings = (payload) => {
    return api.get(`/api/v2/users/class/${payload.classId}/topic/${payload.topicId}/launch-setting/`)
  }

  const changeTopicLaunchSettings = (payload) => {
    return api.post(`/api/v2/users/topic/${payload.topicId}/set-launch-setting/`, payload.data)
  }

  const fetchAssessmentLaunchSettings = (payload) => {
    return api.get(`/api/v2/users/class/${payload.classId}/assessment/${payload.assessmentId}/launch-setting/`)
  }

  const changeAssessmentLaunchSettings = (payload) => {
    return api.post(`/api/v2/users/assessment/${payload.assessmentId}/set-launch-setting/`, payload.data)
  }

  const fetchClassBookmarks = (payload) => {
    return api.get(`/api/v2/users/teacher-class/${payload.payload.classCode}/bookmarks/`)
  }

  const fetchAssessmentDraft = (payload) => {
    return api.get(`/api/v2/users/teacher-class/${payload.payload.classCode}/assessment-draft/`)
  }

  const updateAssessmentDraft = (payload) => {
    let temp = JSON.parse(JSON.stringify(payload))
    // console.log("THIS IS API DATA ::::: \n\n",temp)
    let data = {
      "has_opened": temp.payload.has_opened
    }
    if("name" in temp.payload){
      data.name = temp.payload.name
    }
    if("duration" in temp.payload){
      data.duration = temp.payload.duration
    }
    return api.put(`/api/v2/users/assessment-draft/${payload.payload.id}/update/`, data)
  }

  const fetchAssessmentRequests = (classCode) => {
    return api.get(`/api/v2/users/teacher-class/${classCode}/exam-requests/`)
  }

  const submitAssessmentRequest = (payload) => {
    return api.post(`/api/v2/users/teacher-class/${payload.payload.classCode}/submit-assessment-request/`, payload.payload.data)
  }

  const publishAssessment = (payload) => {
    return api.post(`/api/v2/users/assessment-request/${payload.payload.id}/publish/`, payload.payload.data)
  }

  const addRemoveBookmarkQuestion = (payload) => {
    let temp = JSON.parse(JSON.stringify(payload))
    // console.log("THIS IS API DATA ::::: \n\n",temp)
    let data = {
      "ct_id": temp.payload.ct_id,
      "chapter_id": temp.payload.chapter_id,
      // "marks": temp.payload.marks
    }
    if("is_active" in temp.payload){
      data.is_active = temp.payload.is_active
    }
    return api.post(`/api/v2/users/teacher-class/${payload.payload.classCode}/bookmark-question/`, data)
  }

  const updateBookmark = (payload) => {
    let temp = JSON.parse(JSON.stringify(payload))
    // console.log("THIS IS API DATA ::::: \n\n",temp)
    let data = {
      "added_in_assessment_request": temp.payload.added_in_assessment_request,
      "marks": temp.payload.marks
    }
    // if("is_active" in temp.payload){
    //   data.is_active = temp.payload.is_active
    //}
    return api.put(`/api/v2/users/question-bookmark/${payload.payload.bookmark_id}/update/`, data)
  }

  const fetchCtQuestion = (payload) => {
    return api.get(`/api/v2/users/config-type/${payload.ctId}/question/`)
  }

  const fetchAssessmentCtQuestion = (payload) => {
    return api.get(`/api/v2/users/exam-request-config-type/${payload.ctId}/question/`)
  }

  const fetchModalQuestions = (selectedStudentId,payload) =>{
    return api.get(`/api/v2/users/topic/${selectedStudentId}/config-types/?topic_level=${payload.level}&offset=0`)
  }

  const fetchDateFilter = () =>{
    let timeOffset = new Date().getTimezoneOffset()
    return api.get(`/api/v2/alerts/date-filters/?time_offset=${timeOffset}`)
  }

  const getNotificationAlert = (classCode, userIds, startDate, endDate) =>{
    let queryString = ""
    let timeOffset = new Date().getTimezoneOffset()
    if(userIds) queryString += `?user_ids=${userIds}&start_date=${startDate}&end_date=${endDate}&time_offset=${timeOffset}`
    else if(startDate) queryString += `?start_date=${startDate}&end_date=${endDate}&time_offset=${timeOffset}`
    return api.get(`/api/v2/alerts/teacher-class/${classCode}/notifications/${queryString}`)
  }

  const getNotificationSession = (classCode, userId, notificationId, startDate, endDate) =>{
    let timeOffset = new Date().getTimezoneOffset()
    return api.get(`/api/v2/alerts/teacher-class/${classCode}/user/${userId}/review/?notification_id=${notificationId}&start_date=${startDate}&end_date=${endDate}&time_offset=${timeOffset}`)
  }

  const getQuestionAttempts =(configId, userId) => {
    return api.get(`/api/v2/users/config-type/${configId}/user/${userId}/practice/`)
  }

  const getConnectGoogleClass = () => {
    return api.get(`/api/v2/users/connect-google-account/`)
  }

  const getSwitchGoogleAccount = (code) => {
    return api.get(`/api/v2/users/connect-google-account/?class_code=${code}`)
  }

  const getTeacherGoogleAccountLogin = () => {
    return api.get(`/api/v2/users/teacher-google-account-login/`)
  }

  const getTeacherGoogleAccountSignup = () => {
    return api.get(`/api/v2/users/teacher-google-account-signup/`)
  }

  const getGoogleClassesOnboarding = () => {
    return api.get(`/api/v2/users/FetchGoogleClassroomClassesOfDefaultAccount/`)
  }

  const getGoogleClassroomStudents = (classCode) => {
    return api.get(`/api/v2/users/class/${classCode}/fetch_google_classroom_students/`)
  }

  const syncGoogleClassroomStudents = (params) => {
    return api.post(`/api/v2/users/class/${params.code}/sync_google_classroom_students/`,params.payload)
  }

  const getGoogleClasses = classCode => {
    return api.get(`/api/v2/users/class/${classCode}/fetch_google_classroom_classes/`)
  }

  const getLinkGoogleClass = (classCode, payload) => {
    return api.post(`/api/v2/users/class/${classCode}/link_google_account/`,payload)
  }

  const getUnlinkGoogleClass = classCode => {
    return api.post(`/api/v2/users/class/${classCode}/unlink_google_account/`)
  }

  const getAllAttempts = (testId, userId) => {
    return api.get(`/api/v2/users/exam/${testId}/user/${userId}/all-attempts/`)
  }

  const fetchAutoGradingCTS = (examId, studentId) => {
    return api.get(`/api/v2/users/student/${studentId}/exam/${examId}/auto_grading_cts/`)
    
    // send demo response for now
    // return Promise.resolve({
    //   data: [
    //     { question_no: 1, assessment_config_type_id: 3486 },
    //     { question_no: 3, assessment_config_type_id: 3485 },
    //   ],
    //   status: 200,
    // });
  }

  const uploadAutoGradingImage = (file,studentId, classId, configId, questionId, questionNo, examId, subpartNo) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('question_id', questionId);
    formData.append('exam_id', examId);
    formData.append('question_no', questionNo);
    subpartNo && formData.append('subpart_no', subpartNo ? subpartNo : 0);

    return api.post(`api/v2/users/student/${studentId}/teacher-class/${classId}/exam/config_type/${configId}/auto_grading_submit_response/`, formData);
  }

  const fetchUnattemptedAutoGrading = (examId, studentId) => {
    return api.get(
      `/api/v2/users/student/${studentId}/exam/${examId}/auto-grading-unattempted-questions/`
    );
  }

  const evaluateAutoGradingImage = (examId, studentId) => {
    return api.post(
      `/api/v2/users/student/${studentId}/exam/${examId}/evaluate/`
    );
  }


  const getAllAssessmentClasses = (offset,limit,query) => {
    if(query && query.length>=2)
    {
      return api.get(`api/v2/users/fetch-all-school-classes/?search=${query}`);
    }
    else
    return api.get(`api/v2/users/fetch-all-school-classes/?limit=${limit}&offset=${offset}`);
}

  const getAssessmentDraftClasses = (draftId)=>{
    return api.get(`/api/v2/users/assessment-request/${draftId}/classes`);
  }

  const addAssessmentDraftClasses = (draftId, payload)=>{
    return api.post(`/api/v2/users/assessment-request/${draftId}/add-classes/`,payload);
  }

  const getAssessmentAddedClasses = (assessmentId)=>{
    return api.get(`/api/v2/users/assessment/${assessmentId}/classes/`);
  }
  const getScreenErrorsMapping = () => {
    return api.get(`/api/v2/users/teacher-screen-error-mapping/`);
  }
  const submitLoginErrorReport = (payload) => {
    return api.post(
      `/api/v2/users/teacher-submit-login-error-report/`,
      payload
    );
  }
  const submitErrorReport = (payload,classId) => {
    return api.post(
      `/api/v2/users/class/${classId}/teacher-submit-error-report/`,
      payload
    );
  }

  const resetStudentPassword = (username)=>{
    return api.post(`/api/v2/users/forgot/`,{
      username
    });
  }

  const setNewStudentPassword = (username,password)=>{
    return api.post(`/api/v2/users/reset-student-password/`,{
      username,
      password
    });
  }
  const modifyStudentDetails = (payload) => {
    return api.post(`/api/v2/users/edit-user-details/`, payload);
  }


  const getCanvasClassListAPI = () => {
    return api.get(`s2s_partner/fetch-classes/`)
  };

  const getCanvasClassChapterData = (classCode) => {
    return api.get(`s2s_partner/class/${classCode}/fetch-details/`)
  };

  const getAIFeedbackReport = (config_id,studentId,attemptOrder) => {
    return api.get(`api/v2/users/teacher-exam-config-type/${config_id}/student/${studentId}/ai-evaluation-feedback/?attempt_order=${attemptOrder}`)
  }

  const editAIFeedbackCriteria = (criteriaId, marks_obtained, feedback) => {
    return api.post(`/api/v2/users/essay-grading/${criteriaId}/edit-ai-evaluation-feedback/`,{
      marks_obtained, 
      feedback
    });
  }

  const editOpenEndedFeedback = (assessmentStudentId, marks_obtained, feedback, subpartNumber) => {
    return api.post(`/api/v2/users/assessment-student/${assessmentStudentId}/edit-openended-feedback/`,{
      marks: marks_obtained, 
      feedback,
      subpart_number:subpartNumber
    });
  }

  const resetMockTest = (examId, classCode, studentId, start_time, end_time) => {
    return api.post(`/api/v2/users/teacher-class/${classCode}/exam-reset/`,{
      exam_id: examId,
      start_time: start_time || null,
      end_time: end_time || null,
      user_id: studentId
    })
  };

  const downloadMockExamReport = (classCode,examId) => {
    return api.get(`/api/v2/users/teacher-class/${classCode}/exam/${examId}/generate-report/`)
  }
  
  const downloadTopicReport = (classCode,topicId) => {
    return api.get(`/api/v2/users/${classCode}/topic/${topicId}/generate-report/`)
  }

  const getAIGradingFeedbackReport = (config_id,studentId,attemptOrder) => {
    return api.get(`api/v2/users/teacher-exam-config-type/${config_id}/student/${studentId}/ai-grading-feedback/`)
  }

  const editAIGradingFeedback = (feedback_id,marks_obtained, feedback) => {
    return api.post(`/api/v2/users/ai-grading/${feedback_id}/edit-ai-grading-feedback/`,{
      marks_obtained, 
      feedback
    });
  }
    
  return {
    studentRegisteredDevice,
    fetchModalQuestions,
    setAccessToken,
    removeAccessToken,
    check401,
    getMe,
    refreshUser,
    registerUser,
    loginUser,
    submitCode,
    submitPin,
    fetchGrades,
    fetchLevelDropdownItems,
    submitLevel,
    submitMultiStudentLevel,
    createClass,
    createMultiClass,
    downloadStudentExcel,
    uploadStudentExcel,
    createPin,
    fetchChapters,
    fetchStudents,
    removeStudent,
    fetchConfigType,
    fetchStudentReport,
    fetchQuestion,
    logoutUser,
    forgotPassword,
    verifyResetCode,
    resetPassword,
    startConference,
    getMeetingUrl,
    getAttendanceDates,
    attendListOfMonth,
    createAnnouncement,
    deleteAnnouncement,
    getStudentSession,
    getStudentStuck,
    getAttemptsNew,
    fetchQuestionHistory,
    fetchTopicSyllabus,
    fetchConfigTypeInfo,
    submitConfigTypeReport,
    fetchQuestionContentInfo,
    fetchExamList,
    launchTest,
    fetchExamQuestion,
    fetchStudentResult,
    fetchExamReport,
    resetTestAttempts,
    fetchStudentWiseReport,
    getPracticedQuestion,
    publishTeacherTopic,
    changePassword,
    report,
    fetchBrokenDown,
    getClassLeaderboard,
    registerClientUser,
    resendClientMail,
    registerNewStudent,
    fetchCurriculums,
    checkIfStudentExists,
    checkIfTeacherExists,
    fetchClassTeachers,
    removeClassTeachers,
    fetchSearchedTeachers,
    bulkRegisterStudents,
    bulkRegisterTeachers,
    fetchTopicLaunchSettings,
    changeTopicLaunchSettings,
    fetchAssessmentLaunchSettings,
    changeAssessmentLaunchSettings,
    fetchClassBookmarks,
    submitAssessmentRequest,
    addRemoveBookmarkQuestion,
    updateBookmark,
    fetchCtQuestion,
    fetchAssessmentRequests,
    fetchAssessmentCtQuestion,
    fetchAssessmentDraft,
    updateAssessmentDraft,
    publishAssessment,
    leaderboard,
    leaderboardData,
    myleaderboard,
    activateLeaderboard,
    getstudentLeaderBoard,
    getMyStudentLeaderBoard,
    addStudentInClass,
    addMultiStudentInClassReq,
    fetchDateFilter,
    getNotificationAlert,
    getNotificationSession,
    getQuestionAttempts,
    getConnectGoogleClass,
    getSwitchGoogleAccount,
    getTeacherGoogleAccountLogin,
    getTeacherGoogleAccountSignup,
    getGoogleClasses,
    getGoogleClassesOnboarding,
    getGoogleClassroomStudents,
    syncGoogleClassroomStudents,
    getLinkGoogleClass,
    getUnlinkGoogleClass,
    getAllAttempts,
    fetchAutoGradingCTS,
    uploadAutoGradingImage,
    fetchUnattemptedAutoGrading,
    evaluateAutoGradingImage,
    getAllAssessmentClasses,
    getAssessmentDraftClasses,
    addAssessmentDraftClasses,
    getAssessmentAddedClasses,
    getScreenErrorsMapping,
    submitLoginErrorReport,
    submitErrorReport,
    resetStudentPassword,
    setNewStudentPassword,
    modifyStudentDetails,
    getCanvasClassListAPI,
    getCanvasClassChapterData,
    getAIFeedbackReport,
    editAIFeedbackCriteria,
    editOpenEndedFeedback,
    resetMockTest,
    downloadMockExamReport,
    downloadTopicReport,
    getAIGradingFeedbackReport,
    editAIGradingFeedback
  };
};

export default create();
