import { h } from 'preact';
import store from '../store';
import { BrowserRouter as Router, Switch, useHistory } from 'react-router-dom';
import { useState,useEffect } from 'preact/hooks';
import { Provider, useDispatch, useSelector } from 'react-redux';

// Code-splitting is automated for `routes` directory
import Login from "../routes/Login"
import Reset from "../routes/Reset"
import Signup from "../routes/Signup"
import ClientSignup from "../routes/ClientSignup"
import Onboarding from "../routes/Onboarding"
import { fetchScreenErrorsMapping, initApp, refreshUser } from "../action"
import PrivateRoute from "./PrivateRoute"
import Topic from "../routes/Topic"
import Main from "../routes/Main"
import { Route } from "preact-router"
import Revision from "../routes/Revision"
import Chapter from "../routes/Chapter"
import Students from "../routes/Students"
import MockTest from "../routes/MockTest"
import ReviewTest from "../routes/MockTest/ReviewTest"
import StudentTests from "../routes/MockTest/StudentTests"
import ClassAnnouncement from "../routes/ClassAnnouncement"
import VirtualClass from "../routes/VirtualClass"
import StudentOverview from '../routes/StudentOverview'
import ChapterSyllabus from "../routes/ChapterSyllabus"
import ContentSyllabus from "../routes/ContentSyllabus"
import style from "./style.scss"
import TestView from '../routes/TestView';
import ReadOnlyTestView from '../routes/ReadOnlyTestView';
import NoInternet from './modal/no-internet';
import  Leaderboard  from '../routes/LeaderBoard';
import  Notification  from '../routes/Notification';
import CanvasTool from "../routes/CanvasTool"

import { parentMessageListener } from '../utils';

const AppWrapper = () => {
  const dispatch = useDispatch();
  const userSession = useSelector((state) => state.userSession);
  const history = useHistory();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const is_integrated = urlParams.get("is_integrated");
    if(window.is_integrated == "true" || is_integrated=="true") parentMessageListener(dispatch, initApp)
    else dispatch(initApp())
  }, []);
  useEffect(() => {
    if(userSession && userSession.tokenRefreshed) dispatch(refreshUser());

    if(userSession && userSession.isAuthenticated) {
      dispatch(fetchScreenErrorsMapping());
    }
    
  },[userSession])

};


const App = () => {
  const [Online, setOnline] = useState(navigator.onLine)
  const handleCheckOnlineStatus =() =>{
   if(Online){
     const webPing = setInterval(()=>{
       fetch('//google.com',{
         mode: 'no-cors'
       }).then(()=>{
         setOnline(true)
         clearInterval(webPing)
       }).catch(()=>{
         setOnline(false)
       })
     },2000)
   }
 }
 useEffect(()=>{
         window.addEventListener('online',handleCheckOnlineStatus)
         window.addEventListener('offline',handleCheckOnlineStatus)
         return ()=>{
         window.removeEventListener('online',handleCheckOnlineStatus)
         window.removeEventListener('offline',handleCheckOnlineStatus)
         };
   },[])
  return (
    <Provider store={store}>
      <Router>
        {!Online && <NoInternet/>}
        <div id='app' className={style['td--body']}>
          <AppWrapper />
          <Switch>
            <PrivateRoute path='/' exact component={Main} />
            <PrivateRoute
              path='/:selectedClass/chapters/'
              component={Chapter}
              exact
            />
            <PrivateRoute
              path='/:selectedClass/:chapterId/topic'
              component={Topic}
              exact
            />
            <PrivateRoute
              path='/:selectedClass/revision'
              component={Revision}
              exact
            />
            <PrivateRoute
              path='/:selectedClass/:chapterId/:topicId/student-overview'
              component={StudentOverview}
              exact
            />
               {/* <PrivateRoute
              path='/:selectedClass/:chapterId/:topicId/student-overview/:studentId/:questionNumber'
              component={StudentOverview}
              exact
            /> */}
            <PrivateRoute
              path='/:selectedClass/students'
              component={Students} />


            <PrivateRoute
              path='/:selectedClass/leaderboard'
              component={Leaderboard} />

              <PrivateRoute
              path="/:selectedClass/notification"
              component={Notification} />

              {/* //Leaderboard */}
            <PrivateRoute
              path='/:selectedClass/add-test'
              component={TestView} />
            <PrivateRoute
              path='/:selectedClass/view-test/:testId'
              component={ReadOnlyTestView} />
            <PrivateRoute
              path="/:selectedClass/tests"
              component={MockTest} />
            <PrivateRoute
              path="/:selectedClass/:testId/review-test"
              component={ReviewTest} />
            <PrivateRoute
              path="/:selectedClass/:testId/test-students"
              component={StudentTests} />
            <PrivateRoute
              path="/:selectedClass/announcement"
              component={ClassAnnouncement} />
            <PrivateRoute
              path="/:selectedClass/teach"
              component={VirtualClass} />
            <PrivateRoute
              path="/:selectedClass/:topicIndex/:chapterId/:chapterName/cts"
              component={ChapterSyllabus} />
            <Route
              path="/:chapterId/:chapterName/content"
              component={ContentSyllabus} />
            <Route
              path='/clientSignup/:clientName/:sourceTarget?'
              component={ClientSignup} />
            <Route path='/login' component={Login} />
            <Route path='/signup' component={Signup} />
            <Route path='/onboarding' component={Onboarding} />
            <Route path='/reset' component={Reset} />
            <Route path='/canvas-tool' component={CanvasTool} />
          </Switch>
        </div>
      </Router>
    </Provider>
  );
};

export default App;
