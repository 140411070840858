import { Component } from 'preact'
import style from './style.scss'

export default class Input extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            locked: false,
        };
    }

    render(props) {
        const { active, locked } = this.state;
        return (
            <div class={[style["inp-field"], style[(locked ? active : active || props.value) ? "active" : ""],props.isMultiClass?style["multi-class"]:""].join(" ")}>
                <input
                    id={props.name}
                    class={[style["input"],props.isMultiClass?style["multi-class"]:""].join(" ")}
                    type={props.type}
                    value={props.value}
                    placeholder={props.placeholder}
                    onChange={(e) => props.handleChange(e)}
                    onFocus={() => !locked && this.setState({ active: true })}
                    onBlur={() => !locked && this.setState({ active: false })}
                    autocomplete={props.autocomplete?props.autocomplete:props.type == "password" ? "new-password" : "on"} />
            </div>
        )
    }
}
