import { createSelector } from "reselect";

import Immutable from "seamless-immutable";

export const selectApp = (state) => state;

export const getSchools = (state) => {
  return state.userSession.user.teacherSchool;
};

export const getPreferences = (state) => {
  return state.userSession.user.teacherPreferences;
};

export const makeGetLeaderboardVal = (state) => {
  return state.userSession.user.showLeaderboardTeacher
}

export const getUserSession = (state) => {
  return state.userSession
};

export const getSelectedGrade = (state) => {
  const newClass = getNewClass(state);
  return newClass.selectedGrade;
};

export const getNewMultiClasses = (state) => {
  return state.newMultiClasses;
}
export const getNewClass = (state) => {
  return state.newClass;
};

export const getNewMultiClassesCode = (state) => {
  return getNewMultiClasses(state).map((item) => item.Code);
}
export const getNewClassCode = (state) => {
  return getNewClass(state).classCode;
};

export const getSelectedClass = (state) => {
  return state.selectedClass;
};

export const getSchoolGrades = (state) => {
  return state.schoolGrades;
};

export const getStateSelectedGrade = (state) => {
  return state.selectedGrade;
};

export const getSelectedClassCode = (state) => {
  return getSelectedClass(state).code;
};

export const getTopicList = (state) => {
  const selectedClass = getSelectedClass(state);
  if (!!selectedClass) return state.topicView[selectedClass.id];
  return undefined;
};

export const getStudentsList = (state) => {
  const selectedClass = getSelectedClass(state);
  if (!!selectedClass) return state.studentsView[selectedClass.code];
  return undefined;
};

export const getStudentWithoutNinjaCount = (state) => {
  const studentList = getStudentsList(state);
  let count =0;
  if(studentList)
  {
    studentList.forEach(i=>{
      if(i.group.id === null)
      count++;
    });
  } 
  return count;
}

export const getSelectedTopic = (state) => {
  return state.selectedTopic;
};

export const getTopicLevelList = (state) => {
  return state.topicLevelList;
};

export const getTestsListItem = (state) => {
  const selectedClass = getSelectedClass(state);
  if (!!selectedClass) return state.testsView[selectedClass.id];
  return undefined;
};

export const getChapterView = (state) => {
  return state.chapterView;
}

export const getClientUserName = (state) => {
  return state.clientSignupUser.email;
}

export const makeGetStudentRegistrationData = (state) => {
  return state.newStudentRegistrationData;
}

export const makeGetLoaderState = () =>
  createSelector(selectApp, (appState) => appState.examQuestionLoader);

export const makeGetSelectedTopicLanguage = () => createSelector(
  selectApp,
  (appState) => appState.selectedLanguagePracticeMode
)

export const getLeaderBoardStudentDataSelector = (state) => {
  return state.leaderboardStudent;
}

export const getSelectedTestId = () => createSelector(
  selectApp,
  (appState) => appState.selectedTest.id
)