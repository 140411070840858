import { useEffect, useState } from "preact/hooks";
import { useDispatch, useSelector } from "react-redux";
import style from "./style.scss";
import ExamQuestion from '../ExamQuestion'
import { removeTestInfo } from "../../../action"
import { textIdentifier } from "../../../components/Common/textIdentifier";
import { TooltipArrow } from "../../../assets/images";
import AIFeedbackView from "./AIFeedbackView";
import AIGradingFeedbackView from "./AIGradingFeedbackView";
import StudentImageUpload from "./StudentImageUpload";
const Header = props => {
  return (
    <div className={style['test--header']}>
      <div className={style['header--left']}>
        <div className={style['back--logo']} onClick={() => props.goBackTest()}>
          <svg width="16.961" height="12.966" viewBox="0 0 16.961 12.966"><path style="fill:#ffffff;" d="M2.875,19.268l5.231-5.231A1.25,1.25,0,0,1,9.873,15.8L6.785,18.91H18.211a1.25,1.25,0,0,1,0,2.5H6.785L9.873,24.5a1.256,1.256,0,1,1-1.785,1.767L2.875,21.053A1.271,1.271,0,0,1,2.5,20.16,1.2,1.2,0,0,1,2.875,19.268Z" transform="translate(-2.5 -13.675)"/></svg>
        </div>
        <div className={style['test--name']}>{props.selectedTest.name}</div>
      </div>
      {props.selectedStudent && <div className={style["student-name"]}><div className={style["std--name"]}>{props.selectedStudent.firstName+" "+props.selectedStudent.lastName}</div></div>}
      <div className={style["class-name"]}>{props.selectedClass}</div>
    </div>
  )
}

const QuestionReport = props => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleHover = () => {
    setShowTooltip(true);
  }
  const handleLeave = () => {
    setShowTooltip(false);
  }
  const {report, index, examQuestions, selectedStudent} = props;
  const {language} = useSelector((state)=>state.userSession.user)

  return(
    <div className={style["ques-report"]} onMouseEnter={handleHover} onMouseLeave={handleLeave}>
      <div className={style["ques-num"]}>Q{index+1}</div>
      <div className="tooltip-container">
        <div className={[style["tooltip-arrow"], showTooltip ? style["active"] : ""].join(" ")}>
          <TooltipArrow />
        </div>
        <div className={[ style["ques-report-tooltip"],
                          showTooltip ? style["active"] : "",
                          report.validResponse == 0 ? style["wrong"] : report.validResponse == 1 ? style["correct"] : report.validResponse == 2 ? style["partly"] : style["skip"]
                        ].join(" ")}>
            <div className={style["tooltip-text"]}>
              <div className={style["ques-score"]}>
                <div className={report.validResponse == 0 ? style["wrong"] : report.validResponse == 1 ? style["correct"] : report.validResponse == 2 ? style["partly"] : style["skip"]}>
                  {report.marks? Math.round(report.marks * 100) /100:0} {textIdentifier["out-of"][language]} {examQuestions[selectedStudent.id][index].marks? examQuestions[selectedStudent.id][index].marks : 0 } {textIdentifier["mark(s)"][language]}
                </div>
              </div>
            </div>
        </div>
      </div>
      <div className={style["ques-score"]}>{report.marks == null ? <span className={style["skip"]}>{textIdentifier["unattempted"][language]}</span> : report.marks == 0 ? <span className={style["wrong"]}>{textIdentifier["wrong"][language]}</span> : <span className={report.validResponse===2 && style['partly']}> {report.validResponse===2? textIdentifier["partly-correct"][language]:textIdentifier["correct"][language]}</span>}</div>
    </div>
  )

}
const StudentTestReport = (props) => {
  const {
    selectedTest,
    selectedStudent,
    selectStudent,
    selectedClass,
    uploadImageModal,
    handleSyncButton,
  } = props;
  const [showAIFeedback,setShowAIFeedback] = useState(false);
  const [showAIGradingFeedback,setShowAIGradingFeedback] = useState(false);
  const [questionAIFeedback,setQuestionAIFeedback] = useState(undefined);
  const [showImageUpload,setShowImageUpload] = useState(false);

  const {
    studentWiseReport,
    selectedTestStudentId,
    examQuestions,
    examQuestionLoader,
  } = useSelector((state) => state)

  const dispatch = useDispatch()
  const {language} = useSelector((state)=>state.userSession.user)
  
  const handleAIFeedbackCTAClick=(question)=>{
    if(uploadImageModal){
      setShowImageUpload(true);
    }
    else if(question.isAiGrading)
    {
      setShowAIGradingFeedback(true);
    }
    else
    setShowAIFeedback(true);
    setQuestionAIFeedback(question);
  }

  const handleFailedQuestion = (questionIndex) => {
    dispatch(fetchExamQuestion(selectedTest.configTypeIds,selectedStudent.id,questionIndex))
  }

  const goBackTest = () => {
    selectStudent()
    dispatch(removeTestInfo())
    handleSyncButton();
  }
  if(uploadImageModal){
    return (
      <StudentImageUpload
        selectedTest={selectedTest}
        selectedStudent={selectedStudent}
        selectStudent={selectStudent}
        selectedClass={selectedClass}
        goBackTest={goBackTest}
        handleSyncButton={handleSyncButton}
      />
    );
  }
  else if(showAIGradingFeedback)
  {
    return <AIGradingFeedbackView question={questionAIFeedback} handleClose={()=>{setShowAIGradingFeedback(false)}}
      studentId={selectedStudent.id}/>
  }
  else if(showAIFeedback)
    return <AIFeedbackView question={questionAIFeedback} handleClose={()=>{setShowAIFeedback(false)}}
      studentId={selectedStudent.id}
    />
  else
  return (
    <div className={style['test--report']}>
      {!!selectedTest && (
        <div>
          <Header
            selectedTest={selectedTest}
            goBackTest={goBackTest}
            selectedClass={selectedClass}
            selectedStudent={selectedStudent} />

        </div>
      )}
      <div className={style["student-report-container"]}>
        <div class={style["report-section"]}>
          <div className={style["score--student"]}>{textIdentifier["score"][language]} {selectedStudent.score} {textIdentifier["marks-out-of"][language]} {selectedTest.maximumMarks} {textIdentifier["marks"][language]}</div>
          {!!studentWiseReport && !!studentWiseReport[selectedStudent.id] && <div className={style["report-list"]}>
            {studentWiseReport[selectedStudent.id].map((report, index) => {
              // for OR questions display that question report having largest score
              if(Array.isArray(report)){
                if(report.length <= 0){
                  return null;
                }
                let largestMarksReport = report[0]
                for(let i=1;i<report.length;i++){
                  if(largestMarksReport.marks<report[i].marks){
                    largestMarksReport = report[i]
                  }
                }
                report = largestMarksReport;
              }
              return ( report && examQuestions && !!examQuestions[selectedStudent.id] && !!examQuestions[selectedStudent.id][index] &&
                <QuestionReport report={report} index={index} examQuestions={examQuestions} selectedStudent={selectedStudent} />
              )
            })}
          </div>}
        </div>
        {!!examQuestions && !!examQuestions[selectedStudent.id] && Object.values(examQuestions[selectedStudent.id]).map((questionElem, index) => {
          if (Array.isArray(questionElem)) {
            if(questionElem.length <= 0){
              return null;
            }
            // for OR questions display that question report having largest score
            let report;
            if(studentWiseReport && studentWiseReport[selectedStudent.id]){
              let reports = studentWiseReport[selectedStudent.id][index];
              if(reports && reports.length>0){
                report = reports[0];
                for(let i=1;i<reports.length;i++){
                  if(report.marks<reports[i].marks){
                    report = reports[i]
                  }
                }
              }
            }
            return (
              <div key={index} className={style['exam--question--item']}>
                {questionElem.map((questionData, subIndex) => {
                  let userResponse = studentWiseReport && studentWiseReport[selectedStudent.id] && studentWiseReport[selectedStudent.id][index] && studentWiseReport[selectedStudent.id][index][subIndex] ?studentWiseReport[selectedStudent.id][index][subIndex].userResponse:null;
                  
                  return (
                    <div>
                      <ExamQuestion
                        studentWiseReport={report}
                        userResponse={userResponse}
                        question={questionData}
                        index={index}
                        choiceIndex={`${index}_${subIndex}`}
                        hideQuestionNumber={subIndex !== 0}
                        handleAIFeedbackCTAClick={handleAIFeedbackCTAClick}
                        selectedStudent={selectedStudent}
                        // collapsed = {true}
                      />
                      {subIndex !== questionElem.length - 1 && (
                        <div className={style['exam-question-choice']}>Or</div>
                      )}
                    </div>
                  )
                })}
              </div>
            )
          } else {
            let report = studentWiseReport&&studentWiseReport[selectedStudent.id]?studentWiseReport[selectedStudent.id][index]:null;
            let userResponse = report ? report.userResponse:null;
            return (
              <div key={index} className={style['exam--question--item']}>
                <ExamQuestion
                  studentWiseReport={report}
                  userResponse={userResponse}
                  question={questionElem}
                  index={index}
                  handleAIFeedbackCTAClick={handleAIFeedbackCTAClick}
                  selectedStudent={selectedStudent}
                  />
              </div>
            )
          }
        })}
       {examQuestionLoader.length > 0 && examQuestionLoader.map((value, index) => {
          if (!!value && value.status === 0) {
              return (
              <div className={style['loader-container']}>
                  <div className={style['question-heading']}>Question {index + 1}</div>
                  <div className={style['loader']}>Loading...</div>
              </div>
              )
          }
          else if(!!value && value.status === -1) {
            return (
              <div className={style['loader-container']}>
                <div className={style['question-heading']}>Question {index + 1}</div>
                <div onClick ={()=>{this.handleFailedQuestion(index)}} className={style['loader']}>Click here to load this question</div>
              </div>
            )
          }
        })}
      </div>
    </div>
  );
};

export default StudentTestReport;
