import { useEffect, useState } from "preact/hooks";
// import Input from "../../../components/Common/Input";
import { textIdentifier } from "../../../components/Common/textIdentifier";
import ArrowDown from "../../../assets/images/ArrowDown.svg";
import ArrowUp from "../../../assets/images/ArrowUp.svg";
import style from "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../components/Common/Input";
import { resetSubmitErrorReport, submitErrorReport } from "../../../action";

const ErrorReportingModal = ({
  language,
  closeErrorReportingModal,
  classId,
  handleReportSuccess,
  currentSelected,
  setCurrentSelected,
}) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState("");
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const errorReportDropdown = useSelector((state) => state.screenErrorsMapping);
  const errorReportStatus = useSelector((state) => state.errorReport);
  const handleEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleSelect = (item, index) => {
    setCurrentSelected(item);
    setDropdown(false);
    setEmail("");
  };

  const handleDescChange=(e)=>{
    const desc = e.target.value;
    const isBlank =
    desc !== undefined &&
    desc.split(/\s+/).filter((i) => i !== "").length === 0;
    if(isBlank || desc.length<10)
    {
      setIsSubmitEnabled(false);
    }
    else
    {
      setIsSubmitEnabled(true);
    }
    setDescription(e.target.value);
  }

  const handleSubmit = () => {
    if(!loading){
      if(!currentSelected || (currentSelected.needStudentUsername && !email) || (currentSelected.hasDescription && !description)){
        setErr("Please fill all the required fields");
        return;
      }
      const payload = {
        error_id: currentSelected.id,
        issue_name: currentSelected.name,
        args: {
          url: window.location.href,
        },
        description: description,
        student_username: currentSelected.needStudentUsername ? email : null,
      };

      dispatch(submitErrorReport(payload, classId));
      setLoading(true);
      // closeErrorReportingModal();
    }
  };

  useEffect(() => {
    if (errorReportStatus && errorReportStatus.success) {
      handleReportSuccess();
    } else if (errorReportStatus && errorReportStatus.error) {
      // alert("Error in submitting the report");
      setErr(errorReportStatus.error);
      setTimeout(() => {
        setErr("");
      }, 3000);
      dispatch(resetSubmitErrorReport());
    }
    setLoading(false);
  }, [errorReportStatus]);

  return (
    <div className={style["wrapper"]}>
      <div className={style["mainCard"]}>
        <div className={style["container-header"]}>
          <div className={style["header-text"]}>
            {textIdentifier["report-a-bug"][language]}
          </div>
          <div
            className={style["close-button"]}
            onClick={closeErrorReportingModal}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M6 6L18 18M6 18L18 6L6 18Z"
                stroke="#808080"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className={style["container-info"]}>
          <div className={style["container-info-text"]}>Issue Type *</div>
          <div className={[style["issue--select--container"]].join(" ")}>
            <div
              className={`${style["issue--select-title-container"]} ${
                currentSelected !== null &&
                style["issue--select-title-container--selected"]
              }`}
              onClick={() => {
                setDropdown(!dropdown);
              }}
            >
              <div className={style["selected--text"]}>
                {currentSelected
                  ? currentSelected.name
                  : textIdentifier["select-the-issue-type"][language]}
              </div>

              <img
                src={dropdown ? ArrowUp : ArrowDown}
                alt="..."
                className={style["issue--arrow--img"]}
              />
            </div>
            <div
              className={[
                style["level--select--dropdown"],
                !dropdown && style["no-border"],
              ].join(" ")}
            >
              {dropdown &&
                errorReportDropdown.listScreen.map((item, index) => {
                  return (
                    <div
                      className={`${style["level-list-item"]} ${
                        index !== errorReportDropdown.listScreen.length - 1 &&
                        style["level-list-item--border"]
                      }`}
                      onClick={() => {
                        handleSelect(item, index);
                      }}
                    >
                      {item.name}
                    </div>
                  );
                })}
            </div>
          </div>

          {currentSelected && currentSelected.guideUrl && (
            <div className={style["container-info-text--guide"]}>
              <a href={currentSelected.guideUrl} target="_blank">
                {`${textIdentifier["guide-to"][language]} ${currentSelected.name}`}
              </a>
            </div>
          )}
          {currentSelected && currentSelected.noteMessage.length > 0 && (
            <div className={style["container-info-text--note"]}>
              {currentSelected.noteMessage}
            </div>
          )}
          {currentSelected && currentSelected.hasDescription && (
            <>
              <div className={style["container-info-text"]}>
                {currentSelected.guideUrl
                  ? textIdentifier["still-facing-an-issue-decribe-your-issue"][
                      language
                    ]
                  : currentSelected.id === 9
                  ? textIdentifier[
                      "still-want-to-escalate-the-issue-describe-your-issue-along-with-the-login-id-of-the-student"
                    ][language]
                  : textIdentifier["describe-your-issue"][language]}
                *
              </div>
              <textarea
                className={style["description--input"]}
                placeholder="Type here..."
                value={description}
                onChange={handleDescChange}
              ></textarea>
             <div className={style["description--note"]}>{`${textIdentifier["note"][language]}: ${textIdentifier["description-should-be-minimum-10-characters-long"][language]}`}</div>
            </>
          )}
          {currentSelected && currentSelected.needStudentUsername && (
            <>
              <div className={style["container-info-text--username"]}>
                {textIdentifier["student-login-id"][language]}*
              </div>
              <Input
                placeholder="Student Id"
                value={email}
                handleChange={handleEmail}
              />
            </>
          )}
          <div className={style["button"]}>
            <div className={style["error-text"]}>{err}</div>
            <button
              className={(!currentSelected || !isSubmitEnabled) && style["disabled"]}
              onClick={handleSubmit}
            >
              {loading ? "Submitting..." : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorReportingModal;
