import { useState } from "preact/hooks";
import style from "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { SuccessIcon } from "../../../images";
import { resetSubmitErrorReport } from "../../../action";

const SupportSuccessModal = ({ closeSupportSuccessModal,successMessage }) => {
  const dispatch = useDispatch();

  const handleDone = () => {
    closeSupportSuccessModal();
    dispatch(resetSubmitErrorReport());
  }
  return (
    <div className={style["wrapper"]}>
      <div className={style["mainCard"]}>
        <div className={style["success-icon"]}>
          <SuccessIcon />
        </div>
        <div className={style["container-info"]}>
          <div className={style["container-info-text"]}>
            {successMessage}
          </div>
          <div className={style["button"]}>
            <button onClick={handleDone}>Done</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportSuccessModal;
