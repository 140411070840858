import { useEffect, useState, useRef } from "preact/hooks";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import style from "./style.scss";
import {convertToRoman, getTimeFormatted, insertDataInStaticString} from "../../../utils"
import ExamQuestion from '../ExamQuestion'
import StudentTestReport from '../StudentTestReport'
import useDetectOutSideClick from '../../../customHooks/useDetectOutSideClick'
import DropArrow from "../../../assets/images/DropArrow.svg"
import TooltipArrow from "../../../assets/images/TooltipArrow.svg";
import SyncLoader from "../../../assets/images/SyncLoader.svg";
import ContentLoader from '../../../components/Common/ContentLoader'
import { textIdentifier } from "../../../components/Common/textIdentifier";

import {
  getTests,
  selectTest,
  fetchExamReport,
  getSelectTest,
  resetTest,
  launchTest,
  fetchStudentWiseReport,
  fetchExamQuestion,
  getAllAttempts,
  resetTestAttempts,
  downloadMockExamReport,
} from "../../../action"
import { Mixpanel } from "../../../Mixpanel";
import Markup from "../../../components/Common/Markup";
import ResetTestModal from "./ResetTestModal";
import SupportErrorReport from "../../Main/SupportErrorReport";
import MockResetModal from "./MockResetModal";
import { DownloadIcon } from "../../../images";
import ConfirmDownloadReportModal from "../../../components/ConfirmDownloadReportModal";
import { customFeaturesList, FEAT_MOCK_TEST_REPORT_DOWNLOAD } from "../../../customFeats";

const Header = props => {
  return (
    <div className={style['test--header']}>
      <div className={style['header--left']}>
        <div className={style['back--logo']} onClick={() => props.goBackTest()}>
          <svg width="16.961" height="12.966" viewBox="0 0 16.961 12.966"><path style="fill:#4d4d4d;" d="M2.875,19.268l5.231-5.231A1.25,1.25,0,0,1,9.873,15.8L6.785,18.91H18.211a1.25,1.25,0,0,1,0,2.5H6.785L9.873,24.5a1.256,1.256,0,1,1-1.785,1.767L2.875,21.053A1.271,1.271,0,0,1,2.5,20.16,1.2,1.2,0,0,1,2.875,19.268Z" transform="translate(-2.5 -13.675)"/></svg>
        </div>
        <div className={style['test--name']}>{props.selectedTest.name}</div>
      </div>
    </div>
  )
}

const RescheduleInfoTooltop = ({rescheduleInfo,index,children}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(prev => true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(prev => false);
  }

  const handleDateFormat=(date)=>{
    if(!date)
      return ""
    const utcDate = new Date(date);

    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return utcDate.toLocaleDateString('en-GB', options);
  }

  return (
   <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
     {showTooltip && <div className={[index !==0 && style["reschedule-info-tooltip"],index===0 && style["reschedule-info-tooltip--first"]].join(" ")}>
      <div className={style["reset-tooltip-row"]}>Your last update:</div>
      <div className={style["reset-tooltip-row"]}>{`Start date: ${handleDateFormat(rescheduleInfo.startTime)}`}</div>
      <div className={style["reset-tooltip-row"]}>{`End date: ${handleDateFormat(rescheduleInfo.endTime)}`}</div>
      </div>}
    </div>
  );
}

const StudentTests = (props) => {
  const {language} = useSelector((state)=>state.userSession.user)
  const { match } = props
  const { params } = match
  const history = useHistory()
  let {
    selectedClass: paramsSelectedClass,
    testId: paramsSelelectedTest,
  } = params

  const {
    selectedClass,
    selectedTest,
    testsView,
    examReport,
    userSession,
    testAttempts,
    downloadMockExamReportError,
    downloadMockExamReportSuccess,
    downloadMockExamReportLoading
  } = useSelector((state) => state)

  const [studentList, setStudentList] = useState()
  const [selectedStudent, selectStudent] = useState()
  const [selectedAttemptStudent, selectAttemptStudent] = useState()
  const [dropdown, setDropdown] = useState(false)
  const [sortBy, changeSort] = useState("fname")
  const [showAttemptModal, setAttemptModal] = useState(false)
  const [uploadImageModal, setUploadImageModal] = useState(false)
  const [resetTestModal , setResetTestModal] = useState(false)
  const [clickedStudent , setClickedStudent] = useState()
  const featList = (userSession && userSession.user) ? customFeaturesList(userSession.user.teacherPreferences) : []
  const showReportDownloadCTA = featList.includes(FEAT_MOCK_TEST_REPORT_DOWNLOAD)
  const dispatch = useDispatch()
  const ref = useRef()

  const [isResetModalOpen,setIsResetModalOpen] = useState(false);
  const [currentResetStudent, setCurrentResetStudent] = useState(undefined);
  const [isConfirmDownloadModalOpen,setIsConfirmDownloadModalOpen] = useState(false);

  const handleDownloadReport=()=>{
    setIsConfirmDownloadModalOpen(true);
    dispatch(downloadMockExamReport(paramsSelectedClass,selectedTest.id));
  }

  const handleCloseDownloadReportModal=()=>{
    setIsConfirmDownloadModalOpen(false);
  }

  const handleCloseResetModal = () => {
    setIsResetModalOpen(prev=>false);
  }

  const handleResheduledClick = (student)=>{
    setIsResetModalOpen(prev=>true);
    setCurrentResetStudent(student);
  }

  useEffect(() => {
    if (!!selectedTest && selectedTest.id == paramsSelelectedTest) {
      // dispatch(fetchExamReport(paramsSelectedClass, selectedTest.id))
      Mixpanel.track("TeacherProduct_ExamwiseAnalyticsViewed", {
        "ExamID": selectedTest.id,
        "ExamName": selectedTest.name
      });
      Mixpanel.people.set({
        "$email": userSession.user.email,
        "TeacherID": userSession.user.id
      });
    }
    else {
      dispatch(getSelectTest(paramsSelectedClass))
    }
  }, [])

  useEffect(() => {
    if(!!testsView[paramsSelectedClass]){
      Object.values(testsView[paramsSelectedClass]).flat().every(testObj => {
        if(testObj.id == paramsSelelectedTest) {
          dispatch(selectTest(testObj))
          return false
        }
        else return true
      })
    }
  }, [testsView])

  useEffect(() => {
    if(!!selectedTest && !uploadImageModal) dispatch(fetchExamReport(paramsSelectedClass, selectedTest.id))
  }, [selectedTest])

  useEffect(() => {
    if(!!examReport && !!examReport[paramsSelelectedTest]) {
        let sortedStudents =
          sortBy == "fname"
            ? [...examReport[paramsSelelectedTest]].sort((a, b) =>
                (a.firstName + "" + a.lastName).localeCompare(
                  b.firstName + "" + b.lastName
                )
              )
            : sortBy == "lname"
            ? [...examReport[paramsSelelectedTest]].sort((a, b) =>
                (a.lastName + "" + a.firstName).localeCompare(
                  b.lastName + "" + b.firstName
                )
              )
            : sortBy == "low"
            ? [...examReport[paramsSelelectedTest]].sort((a, b) =>
                a.score == "Not Started" ||
                a.score == "Deadline Missed" ||
                a.score == null
                  ? 1
                  : b.score == null ||
                    b.score == "Not Started" ||
                    b.score == "Deadline Missed"
                  ? -1
                  : a.score == "Yet to submit"
                  ? -1
                  : b.score == "Yet to submit"
                  ? 1
                  : a.score > b.score
                  ? 1
                  : b.score > a.score
                  ? -1
                  : 0
              )
            : [...examReport[paramsSelelectedTest]].sort((a, b) =>
                a.score == "Not Started" ||
                a.score == "Deadline Missed" ||
                a.score == null
                  ? 1
                  : b.score == null ||
                    b.score == "Not Started" ||
                    b.score == "Deadline Missed"
                  ? -1
                  : a.score == "Yet to submit"
                  ? 1
                  : b.score == "Yet to submit"
                  ? -1
                  : a.score < b.score
                  ? 1
                  : b.score < a.score
                  ? -1
                  : 0
              );

              //[...studentList].sort((a,b) => a.score == "Not Started" || a.score == "Deadline Missed" || a.score == null? 1 : b.score== null || b.score == "Not Started" || b.score == "Deadline Missed" ? -1 : a.score=="Yet to submit" ? -1 : b.score == "Yet to submit" ? 1 : (a.score > b.score) ? 1 : ((b.score > a.score) ? -1 : 0)) :
            // [...studentList].sort((a,b) => a.score == "Not Started" || a.score == "Deadline Missed" || a.score == null? 1 : b.score== null || b.score == "Not Started" || b.score == "Deadline Missed" ? -1 : a.score=="Yet to submit" ? 1 : b.score == "Yet to submit" ? -1 : (a.score < b.score) ? 1 : ((b.score < a.score) ? -1 : 0))

        setStudentList([...sortedStudents])
    }
  }, [examReport])

  const goBackTest = () => {
    dispatch(resetTest())
    history.push(`/${paramsSelectedClass}/tests`)
  }
  const handleClickOutside = () => {
    setDropdown(false);
  };
  useDetectOutSideClick(ref, handleClickOutside);
  
  const handleModalClick = (value,student) => {
    setResetTestModal(value)
    if(student) setClickedStudent(student);
  }

  const handleRefreshTest = () => {
    setResetTestModal(false)
    dispatch(resetTestAttempts(paramsSelectedClass , selectedTest.id, clickedStudent.id))
    handleSyncButton()
  }
  const timeConvert = (seconds) => {
      seconds = Number(seconds);
      var d = Math.floor(seconds / (3600 * 24));
      var h = Math.floor(seconds % (3600 * 24) / 3600);
      var m = Math.floor(seconds % 3600 / 60);
      var s = Math.floor(seconds % 60);

      var dDisplay = d > 0 ? d + (d == 1 ? textIdentifier["day"][language]+" " : textIdentifier["days"][language]+" ") : "";
      var hDisplay = h > 0 ? h + (h == 1 ? textIdentifier["hr"][language]+" " : textIdentifier["hr"][language]+" ") : "";
      var mDisplay = m > 0 ? m + (m == 1 ? textIdentifier["min"][language]+" " : textIdentifier["min"][language]+" ") : "";
      return !!seconds ? dDisplay + hDisplay + mDisplay : undefined;
  }

  const selectExam = (student, attempt, showImageUpload) => {
    const tempStudent = {...student}
    if(attempt && attempt.attemptOrder && !showImageUpload) {
      dispatch(fetchStudentWiseReport(paramsSelelectedTest, student.id, attempt.attemptOrder))
      dispatch(fetchExamQuestion(selectedTest.configTypeIds, student.id, 0, attempt.attemptOrder))
      tempStudent.score = attempt.marksObtained
    }
    else if (!showImageUpload) {
      dispatch(fetchStudentWiseReport(paramsSelelectedTest, student.id));
      dispatch(fetchExamQuestion(selectedTest.configTypeIds, student.id, 0));
    }
    selectStudent(tempStudent)
    selectAttemptStudent()
    setAttemptModal(false)
  }

  const selectStudentTest = (student, showImageUpload) => {
    setUploadImageModal(showImageUpload)
    if(student.status != "Yet to submit" && (student.status != "Not Started" || student.status != "Deadline Missed")) {
      if(student.attemptOrder) {
        dispatch(getAllAttempts(student.id))
        selectAttemptStudent(student)
        setAttemptModal(true)
      }
      else {
        selectExam(student, null, showImageUpload)
      }
    }
  }
  const handleSyncButton = async() => {
    if(studentList){
      setStudentList()
      dispatch(fetchExamReport(paramsSelectedClass, selectedTest.id))
    }
  }
  const getSubmittedCount = (students) => {
    // console.log("students",students)
    let count = 0;
    students.map(student => {
      if(student.isCompleted) count++;
    })
    return count;
  }

  const getNotStartedCount = (students) => {
    let count = 0;
    students.map(student => {
      if(!student.hasStarted) count++;
    })
    return count;
  
  }
  useEffect(() => {
    if(studentList && studentList.length > 0) {
      let sortedStudents= sortBy == "fname" ?
        [...examReport[paramsSelelectedTest]].sort((a,b) => (a.firstName+""+a.lastName).localeCompare( b.firstName+""+b.lastName)) :
        sortBy == "lname" ?
          [...examReport[paramsSelelectedTest]].sort((a,b) => (a.lastName+""+a.firstName).localeCompare( b.lastName+""+b.firstName)) :
          sortBy == "low" ?
            [...studentList].sort((a,b) => a.score == "Not Started" || a.score == "Deadline Missed" || a.score == null? 1 : b.score== null || b.score == "Not Started" || b.score == "Deadline Missed" ? -1 : a.score=="Yet to submit" ? -1 : b.score == "Yet to submit" ? 1 : (a.score > b.score) ? 1 : ((b.score > a.score) ? -1 : 0)) :
            [...studentList].sort((a,b) => a.score == "Not Started" || a.score == "Deadline Missed" || a.score == null? 1 : b.score== null || b.score == "Not Started" || b.score == "Deadline Missed" ? -1 : a.score=="Yet to submit" ? 1 : b.score == "Yet to submit" ? -1 : (a.score < b.score) ? 1 : ((b.score < a.score) ? -1 : 0))
      setStudentList([...sortedStudents])
      let sortType = sortBy == "high" ? "Score Descending" : sortBy == "low" ? "Score Ascending" : sortBy == "lname" ? "LastName" : "FirstName";
      Mixpanel.track("TeacherProduct_SortPerformed", {
        "SortPerformedFrom": "ExamAnalytics",
        "SortType": sortType
      });
      Mixpanel.people.set({
        "$email": userSession.user.email,
        "TeacherID": userSession.user.id
      });
      // console.log("sortedStudents",sortedStudents)
    }
  },[sortBy])

  useEffect(() => {
    if(selectedStudent) {
      Mixpanel.track("TeacherProduct_StudentExamAnalyticsViewed", {
        "ExamID": selectedTest.id,
        "ExamName": selectedTest.name,
        "StudentID": selectedStudent.id
      });
      Mixpanel.people.set({
        "$email": userSession.user.email,
        "TeacherID": userSession.user.id
      });
    }
  }, [selectedStudent]);

  return (
    <div className={style['test--students']}>
      <SupportErrorReport />
      {
        resetTestModal && clickedStudent && <ResetTestModal handleModalClick={handleModalClick} selectedStudent={clickedStudent} selectedTest={selectedTest} language={language} handleRefreshTest = {handleRefreshTest}/>
      }
      {!!!selectedStudent ?
        <>
          {!!selectedTest && (
            <div>
              <Header
                selectedTest={selectedTest}
                goBackTest={goBackTest}
              />
            </div>
          )}
          {/* <div className={style["test--info"]}>
            <div className={style["class--name"]}>{textIdentifier["class"][language]} : {!!selectedClass && selectedClass.name}</div>
            <div className={style["student--numbers"]}>{!!studentList && studentList.length} {textIdentifier["students-in-the-class"][language]}</div>
          </div> */}
          <div className={style["score-table"]}>
            <div className={style["table-m-header"]}>
              <div className={style["navigator"]}>
                {textIdentifier["student-list"][language]}
              </div>
              <div className={[style["table-sync-button"],!studentList?style["loading"]:""].join(" ")} onClick={handleSyncButton}>
                <div className={style["table-sync-icon"]}>
                  <img src={SyncLoader} alt="" />
                </div>
                {textIdentifier["sync"][language]}
              </div>
            </div>
            {!!studentList && <div className={style["table-m-sub-header-cnt"]}>
              <div className={style["table-m-sub-header-cnt--left"]}>
                {studentList.length} {textIdentifier["students-are-in-this-class"][language]}. {getSubmittedCount(studentList)} {textIdentifier["students-submitted-the-test-and"][language]} {getNotStartedCount(studentList)} {textIdentifier["students-have-not-started-the-test-yet"][language]}
              </div>
             {showReportDownloadCTA && <div className={style["report-download-cta"]} onClick={handleDownloadReport}>
                <DownloadIcon/>
                <div className={style["report-download-cta--text"]}>
                  {textIdentifier["reports"][language]}
                </div>
              </div>}
            </div>}
            {/* {console.log(selectedTest)} */}
            <div className={style["table-header"]}>
              <div class={style["student-name"]}>
                {textIdentifier["student-name"][language]}
                <div className={style["student--sorting"]} ref={ref}>
                 <div className={style["sort--selected"]} onClick={() => {setDropdown(!dropdown)}}>
                   <div>{sortBy == "low" ? (textIdentifier["sort-by-score"][language]+" "+textIdentifier["(ASC)"][language]) : sortBy == "high" ? (textIdentifier["sort-by-score"][language] + " " +textIdentifier["(DESC)"][language]) : sortBy == "fname" ? textIdentifier["sort-by-first-name"][language] : textIdentifier["sort-by-last-name"][language] }</div>
                   <img
                    src={DropArrow}
                    alt="v"
                    className={style['drop--img']}/>
                  </div>
                  <div className={style['select--dropdown']}>
                    {dropdown &&
                      <>
                        {sortBy != "fname" && <div
                          className={style["drop-ele"]}
                          key={"fname"}
                          onClick={() => {
                          handleClickOutside()
                          changeSort("fname")
                          }} >
                          {textIdentifier["sort-by-first-name"][language]}
                        </div>}
                        {sortBy != "lname" && <div
                          className={style["drop-ele"]}
                          key={"lname"}
                          onClick={() => {
                          handleClickOutside()
                          changeSort("lname")
                          }} >
                          {textIdentifier["sort-by-last-name"][language]}
                        </div>}
                        {sortBy != "low" && <div
                          className={style["drop-ele"]}
                          key={"low"}
                          onClick={() => {
                          handleClickOutside()
                          changeSort("low")
                          }} >
                          {textIdentifier["sort-by-score"][language]} {textIdentifier["(ASC)"][language]}
                          {/* {textIdentifier["low-to-high-score"][language]} */}
                        </div>}
                        {sortBy != "high" && <div
                          className={style["drop-ele"]}
                          key={"high"}
                          onClick={() => {
                          handleClickOutside()
                          changeSort("high")
                          }} >
                          {textIdentifier["sort-by-score"][language]} {textIdentifier["(DESC)"][language]}
                          {/* {textIdentifier["high-to-low-score"][language]} */}
                        </div>}
                      </>
                   }
                  </div>
                </div>
              </div>
              <div class={style["student-score"]}>{textIdentifier["score"][language]}</div>
              <div class={style["student-attempted"]}>{textIdentifier["question-attempted"][language]}</div>
              <div class={style["student-solved"]}>{textIdentifier["correctly-solved"][language]}</div>
              <div class={style["student-wrong"]}>{textIdentifier["wrong-attempt"][language]}</div>
              {selectedTest && !selectedTest.disableTimer && <div class={style["student-time"]}>{textIdentifier["time-taken"][language]}</div>}
              {/* { <div class={style["student-time"]}>{textIdentifier["time-taken"][language]}</div>} */}
              { selectedTest && !selectedTest.redoEnabled && 
                <div class={style["student-action"]}>{textIdentifier["action"][language]}
                  <div className={style["action-i-btn"]}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M7.5 7.5L7.52733 7.48667C7.61282 7.44396 7.70875 7.42664 7.80378 7.43677C7.8988 7.4469 7.98893 7.48404 8.0635 7.54381C8.13806 7.60357 8.19394 7.68345 8.22451 7.77399C8.25508 7.86453 8.25907 7.96193 8.236 8.05467L7.764 9.94533C7.74076 10.0381 7.74463 10.1356 7.77513 10.2263C7.80563 10.3169 7.86149 10.3969 7.93609 10.4568C8.01069 10.5166 8.10089 10.5538 8.196 10.564C8.2911 10.5741 8.38712 10.5568 8.47267 10.514L8.5 10.5M14 8C14 8.78793 13.8448 9.56815 13.5433 10.2961C13.2417 11.0241 12.7998 11.6855 12.2426 12.2426C11.6855 12.7998 11.0241 13.2417 10.2961 13.5433C9.56815 13.8448 8.78793 14 8 14C7.21207 14 6.43185 13.8448 5.7039 13.5433C4.97595 13.2417 4.31451 12.7998 3.75736 12.2426C3.20021 11.6855 2.75825 11.0241 2.45672 10.2961C2.15519 9.56815 2 8.78793 2 8C2 6.4087 2.63214 4.88258 3.75736 3.75736C4.88258 2.63214 6.4087 2 8 2C9.5913 2 11.1174 2.63214 12.2426 3.75736C13.3679 4.88258 14 6.4087 14 8ZM8 5.5H8.00533V5.50533H8V5.5Z"
                        stroke="#3185FC"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div className={style["i-tooltip-box"]}>
                      {
                        textIdentifier[
                          "(n)-represent-the-number-of-times-you-have-rescheduled-or-permitted-the-student-to-reattempt-the-test"
                        ][language]
                      }
                    </div>
                  </div>
                </div>
              }
            </div>
            <div className={style["table-container"]}>
              {/* {console.log("studentList",studentList)} */}
              {!!studentList ?
                <>
                  {studentList.map((student,index) => {
                    return (
                      <div
                        className={[
                          style["table-row"],
                          style[
                            student.status == "Not Started" ||
                            student.status == "Deadline Missed" ||
                            student.status == "Yet to submit" ||
                            student.status == "AI Evaluating" && !student.attemptOrder
                              ? "table-disabled"
                              : ""
                          ],
                        ].join(" ")}
                        onClick={() =>
                          student.status == "Not Started" ||
                          student.status == "Deadline Missed" ||
                          student.status == "Yet to submit" ||
                          student.status == "AI Evaluating" && !student.attemptOrder
                            ? ""
                            : selectStudentTest(student)
                        }
                      >
                        <div className={style["username-tooltip"]}>
                          <div className={style["username-head"]}>
                            {textIdentifier["username"][language]}
                          </div>
                          <div className={style["username-main"]}>
                            {student.username}
                          </div>
                          <div className={style["username-head"]}>
                            {textIdentifier["name"][language]}
                          </div>
                          <div className={style["username-main"]}>
                            {student.showLastNameFirst
                              ? (student.lastName ? student.lastName : "") +
                                " " +
                                (student.firstName ? student.firstName : "")
                              : (student.firstName ? student.firstName : "") +
                                " " +
                                (student.lastName ? student.lastName : "")}
                          </div>
                        </div>
                        <div className={style["username-tooltip-arrow"]}>
                          <img src={TooltipArrow} alt="" />
                        </div>
                        <div
                          class={[
                            style["student-name"],
                            style["student-sec-name"],
                          ].join(" ")}
                        >
                          <div className={style["name-cont"]}>
                            {student.showLastNameFirst
                              ? (student.lastName ? student.lastName : "") +
                                " " +
                                (student.firstName ? student.firstName : "")
                              : (student.firstName ? student.firstName : "") +
                                " " +
                                (student.lastName ? student.lastName : "")}
                          </div>
                          {/* <div className={style["username-tooltip"]}>
                            <div className={style["username-head"]}>username</div>
                            <div className={style["username-main"]}>{student.username}</div>
                            <div className={style["tooltip-arrow"]}>
                              <img src={TooltipArrow} alt="" />
                            </div>
                          </div> */}
                          {student.status && (
                            <div
                              className={
                                student.status == "Not Started"
                                  ? style["not-started"]
                                  : student.status == "Completed"
                                  ? style["completed"]
                                  : student.status === "AI Evaluating" ? style["ai-evaluating"] : style["inactive"]
                              }
                            >
                              {student.statusText
                                ? student.statusText
                                : student.status}
                            </div>
                          )}
                        </div>
                        <div class={[style["student-score"]].join(" ")}>
                          {student.score}
                          {student.attemptOrder && (
                            <span>
                              ({textIdentifier["attempt"][language]}{" "}
                              {student.attemptOrder})
                            </span>
                          )}
                        </div>
                        <div class={style["student-attempted"]}>
                          {student.questionsAttempted}
                        </div>
                        <div class={style["student-solved"]}>
                          {student.correct}
                        </div>
                        <div class={style["student-wrong"]}>
                          {student.wrong}
                        </div>
                        {selectedTest && !selectedTest.disableTimer && (
                          <div class={style["student-time"]}>
                            {timeConvert(student.timeTaken)}
                          </div>
                        )}
                        {selectedTest &&
                          !selectedTest.redoEnabled ?
                          (student.status === 'Not Started' && student.reschedule && Object.keys(student.reschedule).length>0) ?
                          (
                            <RescheduleInfoTooltop rescheduleInfo={student.reschedule} index={index}>
                              <div class={style["student-action"]}>
                                  <div
                                    className={[style["action-reset-btn"],style["action-reset-btn--rescheduled"]].join(" ")}
                                    onClick={(e) => {e.stopPropagation(); handleResheduledClick(student)}}
                                  >
                                  {`${textIdentifier["rescheduled"][language]} ${student.reschedule && Object.keys(student.reschedule).length>0 ? `(${student.reschedule.resetCount})` : ""}`} 
                                  </div>
                              </div>
                              </RescheduleInfoTooltop>
                            ) : 
                              (<div class={style["student-action"]}>
                                <div
                                  className={style["action-reset-btn"]}
                                  onClick={(e) => {e.stopPropagation(); handleResheduledClick(student)}}
                                >
                                {`${textIdentifier["reschedule"][language]} ${student.reschedule && Object.keys(student.reschedule).length>0 ? `(${student.reschedule.resetCount})` : ""}`} 
                                </div>
                              </div>
                            ) : null
                          }
                          {
                            student && student.canUploadImage && (
                              <div class={style["student-action"]}>
                                <div
                                  className={style["action-reset-btn"]}
                                  onClick={(e) => {e.stopPropagation(); selectStudentTest(student,true);}}
                                >
                                  {textIdentifier["upload-image"][language]}
                                </div>

                              </div>
                            )
                          }
                      </div>
                    );
                  })}
                </> :
                <ContentLoader />
              }
            </div>
          </div>
        </> :
        <StudentTestReport
          selectedTest={selectedTest}
          selectStudent={selectStudent}
          selectedStudent={selectedStudent}
          uploadImageModal={uploadImageModal}
          handleSyncButton={handleSyncButton}
          selectedClass={paramsSelectedClass} />
      }
      {showAttemptModal &&
        <div className={style["modal-container"]}>
          <div class={style["attempt-container"]}>
            <div class={style["attempt-header"]}>
              <div class={style["exam-name"]}>{selectedTest.name}</div>
              <div class={style["head-left"]}>
                <div class={style["exam-marks"]}>{insertDataInStaticString(textIdentifier['total-n-marks'][language],[selectedTest.maximumMarks])}</div>
                <div class={style["close-md"]} onClick={() => setAttemptModal(false)}><svg width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M5 15L15 5M5 5L15 15" stroke="#9E9E9E" strokeLinecap="round" strokeLinejoin="round"/></svg></div>
              </div>
            </div>
            <div class={style["attempt-list"]}>
              {testAttempts && testAttempts[selectedTest.id] && testAttempts[selectedTest.id][selectedAttemptStudent.id] && testAttempts[selectedTest.id][selectedAttemptStudent.id].map(attempt => {
                return (
                  <div class={[style["attempt-card"],attempt.isAiEvaluating && style["attempt-card--disabled"]].join(" ")} key={attempt.id} onClick={() => selectExam(selectedAttemptStudent, attempt)}>
                    <div class={style["attempt-info"]}>
                      <div class={style["high-font"]}>{insertDataInStaticString(textIdentifier['attempt-n'][language],[attempt.attemptOrder])}</div>
                      <div class={style["low-font"]}>{insertDataInStaticString(textIdentifier['on-time'][language],[getTimeFormatted(attempt.attemptTime)])}</div>
                      {attempt.isAiEvaluating && <div class={style["high-font"]}>{ textIdentifier["ai-evaluation-in-progress"][language]} </div> }
                      {!attempt.isAiEvaluating && <div class={style["high-font"]}><Markup markup={insertDataInStaticString(textIdentifier['n-mark(s)'][language],[attempt.marksObtained])} /></div>}
                      {!attempt.isAiEvaluating && <div class={style["low-font"]}>{textIdentifier['obtained'][language]}</div>}
                    </div>
                    {!attempt.isAiEvaluating &&  <div><svg width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M5.5 3L10.5 8L5.5 13" stroke="#858585" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg></div>}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      }
      {
          isResetModalOpen && <MockResetModal selectedClass={paramsSelectedClass} selectedTest={selectedTest} setStudents={setStudentList} student={currentResetStudent} handleClose={handleCloseResetModal}/>
      }
      {isConfirmDownloadModalOpen && <ConfirmDownloadReportModal user={userSession && userSession.user} isDownloading={downloadMockExamReportLoading} downloadSuccess={downloadMockExamReportSuccess} downloadFail={downloadMockExamReportError} handleClose={handleCloseDownloadReportModal} />}
    </div>
  );
};

export default StudentTests;
