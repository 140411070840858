export const textIdentifier = {
  "Untitled": {
    "arabic": "بدون عنوان",
    "chinese": "無標題",
    "eng": "Untitled",
    "hindi": "शीर्षकहीन",
    "ja": "タイトルなし",
    "uz": "Sarlavhasiz",
    "viet": "Không có tiêu đề"
  },
  "see-answer-upload-overview": {
    "arabic": "انظر الإجابة وتحميل نظرة عامة",
    "chinese": "查看答案上传概述",
    "eng": "See Answer Upload Overview",
    "hindi": "उत्तर अपलोड अवलोकन देखें",
    "ja": "回答のアップロード概要を参照",
    "uz": "Javobni yuklashning umumiy ko'rsatmasini ko'ring",
    "viet": "Xem tổng quan tải lên câu trả lời"
  },
	"upload-answers": {
    "arabic": "تحميل الإجابات",
    "araic": null,
    "chinese": "上傳答案",
    "eng": "Upload Answers",
    "hindi": "उत्तर अपलोड करें",
    "ja": "回答をアップロード",
    "uz": "Javoblarni yuklash",
    "viet": "Tải lên câu trả lời"
  },
	"upload-edit-answers": {
    "arabic": "تحميل / تحرير الإجابات",
    "araic": null,
    "chinese": "上傳/編輯答案",
    "eng": "Upload/Edit Answers",
    "hindi": "उत्तर अपलोड/संपादित करें",
    "ja": "回答をアップロード/編集",
    "uz": "Javoblarni yuklash/tahrirlash",
    "viet": "Tải lên/Chỉnh sửa câu trả lời"
  },
  "answer-upload-status": {
    "arabic": "حالة تحميل الإجابة",
    "araic": null,
    "chinese": "答案上傳狀態",
    "eng": "Answer Upload Status",
    "hindi": "उत्तर अपलोड स्थिति",
    "ja": "回答のアップロード状況",
    "uz": "Javob yuklash holati",
    "viet": "Trạng thái tải lên câu trả lời"
  },
	"upload-image-sub-header-text": {
    "arabic": "انقر على زر 'تحميل الصورة' لتقديم إجابة مكتوبة باليد للتقييم.",
    "araic": null,
    "chinese": "单击“上传图像”按钮以提交手写答案进行评估。",
    "eng": "Click on the 'Upload Image' button to submit handwritten answer for evaluation.",
    "hindi": "मूल्यांकन के लिए हस्तलेखित उत्तर सबमिट करने के लिए 'छवि अपलोड' बटन पर क्लिक करें।",
    "ja": "手書きの回答を提出するには、「画像をアップロード」ボタンをクリックしてください。",
    "uz": "Baholash uchun qo'llanma javobni yuborish uchun 'Rasmni yuklash' tugmasini bosing.",
    "viet": "Nhấp vào nút 'Tải ảnh' để gửi câu trả lời viết tay để đánh giá."   
  },
	"upload-image": {
    "arabic": "تحميل الصورة",
    "araic": null,
    "chinese": "上傳圖片",
    "eng": "Upload Image",
    "hindi": "छवि अपलोड करें",
    "ja": "画像をアップロード",
    "uz": "Rasmni yuklash",
    "viet": "Tải ảnh lên"
  },
	"select-file": {
    "arabic": "اختر ملفًا",
    "araic": null,
    "chinese": "選擇文件",
    "eng": "Select File",
    "hindi": "फ़ाइल चुनें",
    "ja": "ファイルを選択",
    "uz": "Faylni tanlash",
    "viet": "Chọn tập tin"
  },
	"image-uploaded": {
    "arabic": "تم تحميل الصورة",
    "araic": null,
    "chinese": "圖片已上傳",
    "eng": "Image Uploaded",
    "hindi": "छवि अपलोड की गई",
    "ja": "画像がアップロードされました",
    "uz": "Rasm yuklandi",
    "viet": "Hình ảnh đã được tải lên"
  },
	"change-image": {
    "arabic": "تغيير الصورة",
    "araic": null,
    "chinese": "更改圖片",
    "eng": "Change Image",
    "hindi": "छवि बदलें",
    "ja": "画像を変更",
    "uz": "Rasmni o'zgartirish",
    "viet": "Thay đổi hình ảnh"
  },
	"upload-answer-images": {
    "arabic": "تحميل صور الإجابة",
    "araic": null,
    "chinese": "上傳答案圖片",
    "eng": "Upload Answer Images",
    "hindi": "उत्तर छवियाँ अपलोड करें",
    "ja": "回答画像をアップロード",
    "uz": "Javob rasmlarini yuklash",
    "viet": "Tải lên hình ảnh câu trả lời"
  },
  "browse": {
    "arabic": "تصفح",
    "araic": null,
    "chinese": "瀏覽",
    "eng": "Browse",
    "hindi": "ब्राउज़ करें",
    "ja": "閲覧",
    "uz": "Ko'rish",
    "viet": "Duyệt"
  },
	"cancel-upload": {
    "arabic": "إلغاء التحميل",
    "araic": null,
    "chinese": "取消上傳",
    "eng": "Cancel Upload",
    "hindi": "अपलोड रद्द करें",
    "ja": "アップロードをキャンセル",
    "uz": "Yuklashni bekor qilish",
    "viet": "Hủy tải lên"
	},
  "or-drag-a-file-here": {
    "arabic": "أو اسحب ملفًا هنا",
    "araic": null,
    "chinese": "或將文件拖到此處",
    "eng": "Or drag a file here",
    "hindi": "या यहां एक फ़ाइल खींचें",
    "ja": "またはファイルをここにドラッグ",
    "uz": "Yoki faylni bu yerga torting",
    "viet": "Hoặc kéo một tập tin vào đây"
	},
  "your-answer-upload-overview": {
    "arabic": "نظرة عامة على تحميل إجابتك",
    "araic": null,
    "chinese": "您的答案上傳概述",
    "eng": "Your Answer Upload Overview",
    "hindi": "आपके उत्तर अपलोड का अवलोकन",
    "ja": "あなたの回答のアップロード概要",
    "uz": "Sizning javobingizni yuklashning umumiy ko'rsatmasi",
    "viet": "Tổng quan tải lên câu trả lời của bạn"
  },
  "evaluate": {
    "arabic": "تقييم",
    "araic": null,
    "chinese": "評估",
    "eng": "Evaluate",
    "hindi": "मूल्यांकन",
    "ja": "評価",
    "uz": "Baholash",
    "viet": "Đánh giá"
  },
  "not-uploaded": {
    "arabic": "لم يتم التحميل",
    "araic": null,
    "chinese": "未上傳",
    "eng": "Not Uploaded",
    "hindi": "अपलोड नहीं हुआ",
    "ja": "アップロードされていません",
    "uz": "Yuklanmagan",
    "viet": "Không tải lên"
  },
  "uploaded": {
    "arabic": "تم التحميل",
    "araic": null,
    "chinese": "已上傳",
    "eng": "Uploaded",
    "hindi": "अपलोड किया गया",
    "ja": "アップロードされました",
    "uz": "Yuklandi",
    "viet": "Đã tải lên"
  },
  "study-material": {
    "arabic": "مواد الدراسة",
    "chinese": "学习材料",
    "eng": "Study Material",
    "hindi": "अध्ययन सामग्री",
    "ja": "学習教材",
    "uz": "O'quv materiali",
    "viet": "Tài liệu học tập"
  },
  "Search-the-Teacher(s)-by-Email-ID": {
    "arabic": "ابحث عن المعلمين بواسطة معرف البريد الإلكتروني",
    "chinese": "通过电子邮件ID搜索教师",
    "eng": "Search the Teacher(s) by Email ID",
    "hindi": "ईमेल आईडी द्वारा शिक्षकों की खोज करें",
    "ja": "メールIDで先生を検索",
    "uz": "O'qituvchilarni elektron pochta IDsi bo'yicha qidiring",
  },
  "Search-teacher(s)-by-email-ID-to-add-them-to-this-class":{
    "arabic": "ابحث عن المعلمين بواسطة معرف البريد الإلكتروني لإضافتهم إلى هذا الفصل",
    "chinese": "通过电子邮件ID搜索教师以将他们添加到此班级",
    "eng": "Search teacher(s) by email ID to add them to this class",
    "hindi": "उन्हें इस कक्षा में जोड़ने के लिए ईमेल आईडी से शिक्षकों की खोज करें",
    "ja": "このクラスに追加するためにメールIDで先生を検索",
    "uz": "Ularini bu sinfga qo'shish uchun elektron pochta IDsi bo'yicha o'qituvchilarni qidiring"
  },
  "teacher": {
    "arabic": "مدرس",
    "chinese": "老师",
    "eng": "Teacher",
    "hindi": "शिक्षक",
    "ja": "先生",
    "uz": "O'qituvchi",
    "viet": "Giáo viên"
  },
  "teacher-guide":{
    "arabic": "دليل المعلم",
    "chinese": "老师指南",
    "eng": "Teacher Guide",
    "hindi": "शिक्षक मार्गदर्शिका",
    "ja": "先生ガイド",
    "uz": "O'qituvchi qo'llanma",
    "viet": "Hướng dẫn giáo viên"
  },
  "teachers-in": {
    "arabic": "المعلمون في",
    "chinese": "老师在",
    "eng": "Teachers in",
    "hindi": "शिक्षकों में",
    "ja": "先生は",
    "uz": "O'qituvchilar",
    "viet": "Giáo viên ở"
  },
  "add-teacher": {
    "arabic": "إضافة معلم",
    "chinese": "添加老師",
    "eng": "Add Teacher",
    "hindi": "शिक्षक जोड़ें",
    "ja": "先生を追加",
    "uz": "O'qituvchini qo'shish",
    "viet": "Thêm giáo viên"
  },
  "add-teacher(s)-to-this-class":{
    "arabic": "إضافة معلم (أو معلمين) إلى هذا الفصل",
    "chinese": "将老师添加到这个班级",
    "eng": "Add Teacher(s) to this Class",
    "hindi": "इस कक्षा में शिक्षक (या शिक्षकों) जोड़ें",
    "ja": "このクラスに先生（複数）を追加",
    "uz": "Bu sinfga o'qituvchi(lar)ni qo'shish",
    "viet": "Thêm giáo viên (hoặc giáo viên) vào lớp này"
  },
  "add-teachers": {
    "arabic": "إضافة معلمين",
    "chinese": "添加老师",
    "eng": "Add Teachers",
    "hindi": "शिक्षक जोड़ें",
    "ja": "先生を追加",
    "uz": "O'qituvchilarni qo'shing",
    "viet": "Thêm giáo viên"
  },
  "submissions": {
    "arabic": "يُقدِّم",
    "chinese": "提交",
    "eng": "Submissions",
    "hindi": "प्रस्तुत",
    "ja": "提出する",
    "uz": "Taqdimotlar",
    "viet": "Nộp"
  },
  "also-email-credential-to-student": {
    "arabic": "أرسل أيضًا بريدًا إلكترونيًا بالاعتماد إلى الطالب",
    "chinese": "也向学生发送凭证",
    "eng": "Also email credential to student",
    "hindi": "छात्र को भी प्रमाणपत्र ईमेल करें",
    "ja": "学生にも資格情報をメールで送信",
    "uz": "Shuningdek, talabaga elektron pochta manzili",
    "viet": "Gửi email chứng chỉ cho học sinh"
  },
  "download": {
    "arabic": "تحميل",
    "chinese": "下载",
    "eng": "Download",
    "hindi": "डाउनलोड",
    "ja": "ダウンロード",
    "uz": "Yuklab oling",
    "viet": "Tải xuống"
  },
  "initiated-download" :{
    "arabic": "بدأ التنزيل",
    "chinese": "开始下载",
    "eng": "Initiated Download",
    "hindi": "डाउनलोड प्रारंभ किया",
    "ja": "ダウンロードを開始しました",
    "uz": "Yuklash boshlandi",
    "viet": "Khởi tạo tải xuống"
  },
  "choose-file-and-upload": {
    "arabic": "اختر الملف وقم بالتحميل",
    "chinese": "選擇文件並上傳",
    "eng": "Choose File and Upload",
    "hindi": "फ़ाइल चुनें और अपलोड करें",
    "ja": "ファイルを選択してアップロード",
    "uz": "Fayl va yuklash-ni tanlang",
    "viet": "Chọn tệp và tải lên"
  },
  "download-the-spreadsheet-student-onboarding": {
    "arabic": "قم بتنزيل ملف Excel الذي قدمته Cerebry واملأ تفاصيل الطالب.",
    "chinese": "下载Cerebry提供的Excel文件并填写学生详细信息。",
    "eng": "Download the excel file provided by Cerebry and fill up the student details.",
    "hindi": "Cerebry द्वारा प्रदान की गई एक्सेल फ़ाइल डाउनलोड करें और छात्र विवरण भरें।",
    "ja": "Cerebryが提供するExcelファイルをダウンロードして、学生の詳細を入力してください。",
    "uz": "Cerebry tomonidan taqdim etilgan Excel faylini yuklab oling va talaba ma'lumotlarini to'ldiring.",
    "viet": "Tải xuống tệp excel do Cerebry cung cấp và điền thông tin học sinh."
  },
  "download-the-template":{
    "arabic": "تحميل القالب",
    "chinese": "下载模板",
    "eng": "Download the template",
    "hindi": "टेम्पलेट डाउनलोड करें",
    "ja": "テンプレートをダウンロード",
    "uz": "Shablonni yuklab oling",
    "viet": "Tải xuống mẫu"
  },
  "uploading...":{
    "arabic": "تحميل...",
    "chinese": "上传中...",
    "eng": "Uploading...",
    "hindi": "अपलोड हो रहा है...",
    "ja": "アップロード中...",
    "uz": "Yuklanmoqda...",
    "viet": "Đang tải lên..."
  },
  "upload-the-spreadsheet-student-onboarding": {
    "eng": "Enter the students' details as specified in the Excel template and then upload the file.",
    "arabic": "أدخل تفاصيل الطلاب كما هو محدد في قالب Excel ثم قم بتحميل الملف.",
    "chinese": "按照Excel模板中指定的方式输入学生详细信息，然后上传文件。",
    "hindi": "एक्सेल टेम्पलेट में निर्दिष्ट छात्रों की जानकारी दर्ज करें और फिर फ़ाइल अपलोड करें।",
    "ja": "Excelテンプレートで指定された学生の詳細を入力してからファイルをアップロードしてください。",
    "uz": "Excel shablonida ko'rsatilgan talabalar ma'lumotlarini kiriting va so'ng faylni yuklang.",
    "viet": "Nhập thông tin học sinh theo yêu cầu trong mẫu Excel và sau đó tải lên tệp."
  },
  "upload-the-spreadsheet-student-onboarding-submessage": {
    "arabic": "بعد تحميل الملف، ستتلقى بريدًا إلكترونيًا يحتوي على معلومات الطالب وبيانات الاعتماد المُنشأة.",
    "chinese": "上传文件后，您将收到一封电子邮件，其中包含学生信息和生成的凭证。",
    "eng": "After uploading the file, you will receive an email with student information and generated credentials.",
    "hindi": "फ़ाइल अपलोड करने के बाद, आपको एक ईमेल मिलेगा जिसमें छात्र जानकारी और उत्पन्न क्रेडेंशियल होगा।",
    "ja": "ファイルをアップロードすると、学生情報と生成された資格情報が記載されたメールが届きます。",
    "uz": "Faylni yuklagandan so'ng, talaba ma'lumotlari va yaratilgan hisob ma'lumotlari bilan elektron pochta xabarini olasiz.",
    "viet": "Sau khi tải lên tệp, bạn sẽ nhận được một email với thông tin học sinh và thông tin đăng nhập được tạo ra."
  },
  "success-spreadsheet-student-onboarding": {
    "arabic": "تم تحميل الملف بنجاح.",
    "chinese": "文件上传成功。",
    "eng": "File uploaded successfully.",
    "hindi": "फ़ाइल सफलतापूर्वक अपलोड हुई।",
    "ja": "ファイルのアップロードに成功しました。",
    "uz": "Fayl muvaffaqiyatli yuklandi.",
    "viet": "Tệp đã được tải lên thành công."
  },
  "success-spreadsheet-student-onboarding-submessage": {
    "arabic": "تواصل عملية تسجيل الطلاب. ستتلقى بريدًا إلكترونيًا قريبًا إما",
    "chinese": "学生入职正在进行中。 您将很快收到一封电子邮件，其中包含",
    "eng": "Student onboarding is in progress. You will receive an email shortly with either",
    "hindi": "छात्र भर्ती प्रगति पर है। आपको जल्द ही एक ईमेल प्राप्त होगा जिसमें",
    "ja": "学生のオンボーディングが進行中です。 すぐにメールが届きます。",
    "uz": "Talabalarni qabul qilish jarayoni davom etmoqda. Tez orada siz ikkalasi bilan elektron pochta xabarini olasiz",
    "viet": "Quá trình tuyển sinh học sinh đang tiến triển. Bạn sẽ nhận được một email trong thời gian sớm với"
  },
  "student-data-and-credentials": {
    "arabic": "بيانات الطالب والاعتمادات",
    "chinese": "学生数据和凭据",
    "eng": "student data and credentials",
    "hindi": "छात्र डेटा और क्रेडेंशियल",
    "ja": "学生データと資格情報",
    "uz": "talaba ma'lumotlari va hisob ma'lumotlari",
    "viet": "Dữ liệu học sinh và thông tin đăng nhập"
  },
  "error-details-in-file": {
    "arabic": "تفاصيل الخطأ في الملف",
    "chinese": "文件中的错误详细信息",
    "eng": "error details in file",
    "hindi": "फ़ाइल में त्रुटि विवरण",
    "ja": "ファイルのエラー詳細",
    "uz": "fayldagi xato tafsilotlari",
    "viet": "Chi tiết lỗi trong tệp"
  },
  "or": {
    "arabic": "أو",
    "chinese": "要么",
    "eng": "or",
    "hindi": "या",
    "ja": "または",
    "uz": "yoki",
    "viet": "hoặc"
  },
  "add-by-excel-file": {
    "arabic": "إضافة بواسطة ملف Excel",
    "chinese": "通过Excel文件添加",
    "eng": "Add by Excel file",
    "hindi": "एक्सेल फ़ाइल द्वारा जोड़ें",
    "ja": "Excelファイルで追加",
    "uz": "Excel fayli orqali qo'shish",
    "viet": "Thêm bằng tệp excel"
  },
  "continue-with-excel": {
    "arabic": "تابع مع إكسل",
    "chinese": "使用Excel继续",
    "eng": "Continue with Excel",
    "hindi": "एक्सेल के साथ जारी रखें",
    "ja": "Excelで続行",
    "uz": "Excel bilan davom eting",
    "viet": "Tiếp tục với Excel"
  },
  "congratulations": {
    "arabic": "تهانينا",
    "chinese": "恭喜",
    "eng": "Congratulations",
    "hindi": "बधाई हो",
    "ja": "おめでとうございます",
    "uz": "Tabriklaymiz",
    "viet": "Chúc mừng"
  },
  "continue-with-username": {
    "arabic": "تابع مع اسم المستخدم",
    "chinese": "使用用户名继续",
    "eng": "Continue with Username",
    "hindi": "उपयोगकर्ता नाम के साथ जारी रखें",
    "ja": "ユーザー名で続行",
    "uz": "Foydalanuvchi nomi bilan davom eting",
    "viet": "Tiếp tục với Tên người dùng"
  },
  "add-a-class": {
    "arabic": "إضافة فصل",
    "chinese": "添加班级",
    "eng": "Add a class",
    "hindi": "कक्षा जोड़ें",
    "ja": "クラスを追加",
    "uz": "Sinf qo'shing",
    "viet": "Thêm lớp học",
    "ru": "Добавить класс",
    "az": "Sinif əlavə et",
    "jp": "クラスを追加"
  },
  "create-multiple-classes": {
    "arabic": "إنشاء فصول متعددة",
    "chinese": "創建多個班級",
    "eng": "Create Multiple Classes",
    "hindi": "एकाधिक कक्षाएं बनाएं",
    "ja": "複数のクラスを作成",
    "uz": "Bir nechta sinflarni yaratish",
    "viet": "Tạo nhiều lớp học",
    "ru": "Создать несколько классов",
    "az": "Birdən çox sinif yaradın",
    "jp": "複数のクラスを作成"
  },
  "create-class(es)": {
    "arabic": "إنشاء فصل (فصول)",
    "chinese": "创建班级",
    "eng": "Create Class(es)",
    "hindi": "कक्षा (कक्षाएं) बनाएं",
    "ja": "クラスを作成",
    "uz": "Sinf(lar)ni yaratish",
    "viet": "Tạo lớp học",
    "ru": "Создать класс(ы)",
    "az": "Sinif(lər) yaradın",
    "jp": "クラスを作成"
  },
  "upload-logo-here": {
    "arabic": "تحميل الشعار هنا",
    "chinese": "在這裡上傳標誌",
    "eng": "Upload Logo Here",
    "hindi": "यहां लोगो अपलोड करें",
    "ja": "画像をアップロードする",
    "uz": "Logotipni bu yerga yuklang",
    "viet": null
  },
  "minutes-ago": {
    "arabic": "منذ دقائق",
    "chinese": "幾分鐘前",
    "eng": "minutes ago",
    "hindi": "मिनट पहले",
    "ja": "分前",
    "uz": "daqiqa oldin",
    "viet": "phút trước"
  },
  "here-you-allow-student-to-reset-test-and-(n)-represent-number-of-times-you-have-allowed-this-student-to-reset-this-test": {
    "arabic": "هنا تسمح للطالب بإعادة تعيين الاختبار و (n) تمثل عدد المرات التي سمحت بها لهذا الطالب إعادة تعيين هذا الاختبار",
    "chinese": "在這裡，您允許學生重置測試，(n) 代表您允許該學生重置此測試的次數",
    "eng": "Here you allow student to reset test and (n) represent number of times you have allowed this student to reset this test",
    "hindi": "यहां आप छात्र को परीक्षा रीसेट करने की अनुमति देते हैं और (n) इस छात्र को इस परीक्षा को रीसेट करने की अनुमति देने की बार दिखाता है",
    "ja": "ここでは、学生がテストをリセットすることを許可し、(n) はこの学生にこのテストをリセットする回数を許可した回数を表します",
    "uz": "Bu yerda siz talabaga testni qayta oʻrnatishga ruxsat berasiz va (n) bu talabaga testni qayta oʻrnatishga necha marta ruxsat berganingizni bildirasiz",
    "viet": "Ở đây bạn cho phép học viên thiết lập lại bài kiểm tra và (n) đại diện cho số lần bạn đã cho phép học viên này thiết lập lại bài kiểm tra này"
  },
  "these-settings-will-be-applied-to-all-classes-that-are-currently-not-live": {
    "arabic": "سيتم تطبيق هذه الإعدادات على جميع الفصول التي ليست حية حاليًا",
    "chinese": "这些设置将应用于当前未直播的所有班级",
    "eng": "These settings will be applied to all classes that are currently not live",
    "hindi": "इन सेटिंग्स को वर्तमान में लाइव नहीं होने वाली सभी कक्षाओं पर लागू किया जाएगा",
    "ja": "これらの設定は現在ライブではないすべてのクラスに適用されます",
    "uz": "Bu sozlamalar hozirda mavjud boʻlmagan barcha sinflarga qoʻllaniladi",
    "viet": "Các cài đặt này sẽ được áp dụng cho tất cả các lớp không phải là lớp trực tuyến hiện tại"
  },
  "save-to-all-classes": {
    "arabic": "حفظ في جميع الفصول",
    "chinese": "保存到所有班级",
    "eng": "Save to all classes",
    "hindi": "सभी कक्षाओं में सहेजें",
    "ja": "すべてのクラスに保存",
    "uz": "Barcha sinflarga saqlang",
    "viet": ""
  },
  "student-list": {
    "arabic": "قائمة الطلاب",
    "chinese": "學生名單",
    "eng": "Student List",
    "hindi": "छात्र सूची",
    "ja": "生徒一覧",
    "uz": "Talabalar roʻyxati",
    "viet": "Danh sách học sinh"
  },
  "student-login-id": {
    "arabic": "معرف تسجيل الطالب",
    "chinese": "學生登錄ID",
    "eng": "Student Login ID",
    "hindi": "छात्र लॉगिन आईडी",
    "ja": "生徒ログインID",
    "uz": "Talaba kirish IDsi",
    "viet": "ID đăng nhập học sinh"
  },
  "login-id":{
    "arabic": "معرف الدخول",
    "chinese": "登錄ID",
    "eng": "Login ID",
    "hindi": "लॉगिन आईडी",
    "ja": "ログインID",
    "uz": "Kirish IDsi",
    "viet": "ID đăng nhập"
  },
  "sync": {
    "arabic": "مزامنة",
    "chinese": "同步",
    "eng": "Sync",
    "hindi": "सिंक",
    "ja": "最新データを取得",
    "uz": "Sinxronlash",
    "viet": "Đồng bộ"
  },
  "hours-ago": {
    "arabic": "منذ ساعات",
    "chinese": "幾小時前",
    "eng": "hours ago",
    "hindi": "घंटे पहले",
    "ja": "時間前",
    "uz": "soat oldin",
    "viet": "giờ trước"
  },
  "days-ago": {
    "arabic": "منذ أيام",
    "chinese": "幾天前",
    "eng": "days ago",
    "hindi": "दिन पहले",
    "ja": "日前",
    "uz": "kun oldin",
    "viet": "ngày trước"
  },
  "months-ago": {
    "arabic": "منذ أشهر",
    "chinese": "幾個月前",
    "eng": "months ago",
    "hindi": "महीने पहले",
    "ja": "ヶ月前",
    "uz": "bir necha oy avval",
    "viet": "tháng trước"
  },
  "hide-details": {
    "arabic": "إخفاء التفاصيل",
    "chinese": "隱藏細節",
    "eng": "Hide Details",
    "hindi": "विवरण छिपाएं",
    "ja": "詳細を非表示",
    "uz": "Tafsilotlarni yashirish",
    "viet": "Ẩn chi tiết"
  },
  "show-details": {
    "arabic": "إظهار التفاصيل",
    "chinese": "顯示細節",
    "eng": "Show Details",
    "hindi": "विवरण दिखाएं",
    "ja": "詳細を表示",
    "uz": "Tafsilotlarni ko'rsatish",
    "viet": "Hiển thị chi tiết"
  },
  "cerebry-activity": {
    "arabic": "نشاط Cerebry",
    "chinese": "Cerebry 活動",
    "eng": "Cerebry Activity",
    "hindi": "सिरेब्री गतिविधि",
    "ja": "Cerebry アクティビティ",
    "uz": "Miya faoliyati",
    "viet": "Hoạt động Cerebry"
  },
  "display-in-your-time-zone": {
    "arabic": "",
    "chinese": "",
    "eng": "Display in your time zone",
    "hindi": "",
    "ja": "",
    "uz": "Vaqt mintaqangizda ko'rsatish",
    "viet": ""
  },
  "date-time": {
    "arabic": "",
    "chinese": "",
    "eng": "Date/Time",
    "hindi": "",
    "ja": "",
    "uz": "Sana/vaqt",
    "viet": ""
  },
  "location-ip-address": {
    "arabic": "",
    "chinese": "",
    "eng": "Location (IP Address)",
    "hindi": "",
    "ja": "",
    "uz": "Joylashuv (IP-manzil)",
    "viet": ""
  },
  "browser-mobile-pop3-etc": {
    "arabic": "",
    "chinese": "",
    "eng": "Browser, Mobile, POP3, etc.",
    "hindi": "",
    "ja": "",
    "uz": "Brauzer, mobil, POP3 va boshqalar.",
    "viet": ""
  },
  "access-type": {
    "arabic": "نوع الوصول",
    "chinese": "訪問類型",
    "eng": "Access Type",
    "hindi": "पहुंच प्रकार",
    "ja": "アクセスタイプ",
    "uz": "Kirish turi",
    "viet": "Loại truy cập"
  },
  "activity": {
    "arabic": "نشاط",
    "chinese": "活動",
    "eng": "Activity",
    "hindi": "गतिविधि",
    "ja": "アクティビティ",
    "uz": "Faoliyat",
    "viet": "Hoạt động"
  },
  "for": {
    "arabic": "إلى",
    "chinese": "为",
    "eng": "for",
    "hindi": "के लिए",
    "ja": "に",
    "uz": "uchun",
    "viet": "cho"
  },
  "The-question-displayed-may-not-be-the-actual-question-variant-that-appeared-for-the-student": {
    "arabic": "قد لا يكون السؤال المعروض هو السؤال الفعلي الذي ظهر للطالب",
    "chinese": "显示的问题可能不是为学生显示的实际问题变体",
    "eng": "The question displayed may not be the actual question variant that appeared for the student",
    "hindi": "प्रदर्शित प्रश्न छात्र के लिए प्रदर्शित वास्तविक प्रश्न प्रकार नहीं हो सकता है",
    "ja": "表示される質問は、生徒に対して表示された実際の質問のバリエーションではない可能性があります。",
    "uz": "Ko'rsatilgan savol talaba uchun paydo bo'lgan haqiqiy savol varianti bo'lmasligi mumkin",
    "viet": "Câu hỏi được hiển thị có thể không phải là biến thể câu hỏi thực sự xuất hiện cho học sinh"
  },
  "loading-content": {
    "arabic": "تحميل المحتوى",
    "chinese": "加載內容",
    "eng": "Loading Content",
    "hindi": "लोड हो रहा है सामग्री",
    "ja": "コンテンツを読み込み中",
    "uz": "Kontent yuklanmoqda",
    "viet": "Đang tải nội dung"
  },
  "first-page-instructions": {
    "arabic": null,
    "chinese": null,
    "eng": "First page & Instructions",
    "hindi": null,
    "ja": "表紙と説明",
    "uz": "Birinchi sahifa va ko'rsatmalar",
    "viet": "Trang đầu tiên và Hướng dẫn"
  },
  "loading": {
    "arabic": "تحميل",
    "chinese": "加載中",
    "eng": "Loading",
    "hindi": "लोड हो रहा है",
    "ja": "読み込み中",
    "uz": "Yuklanmoqda",
    "viet": "Đang tải"
  },
  "oops-we-are-having-a-technical-difficulty-here": {
    "arabic": "عفوًا , نواجه مشكلة فنية هنا.",
    "chinese": "糟糕,我們這裡遇到了技術難題。",
    "eng": "Oops, we are having a technical difficulty here.",
    "hindi": "उफ़, हमें यहाँ एक तकनीकी समस्या आ रही है।",
    "ja": "技術的な問題が発生しました",
    "uz": "Voy, bizda texnik muammo bor.",
    "viet": "Rất tiếc, chúng tôi đang gặp sự cố kỹ thuật ở đây."
  },
  "please-refresh-or-reload": {
    "arabic": "يرجى التحديث أو إعادة التحميل",
    "chinese": "請刷新或重新加載",
    "eng": "Please refresh or reload",
    "hindi": "कृपया ताज़ा करें या पुनः लोड करें",
    "ja": "再度ページを読み込んでください",
    "uz": "Iltimos, yangilang yoki qayta yuklang",
    "viet": "Bạn hãy làm mới hoặc tải lại trang nhé"
  },
  "refresh": {
    "arabic": "ينعش",
    "chinese": "刷新",
    "eng": "Refresh",
    "hindi": "ताज़ा करना",
    "ja": "ページを再度読み込む",
    "uz": "Yangilash",
    "viet": "Làm mới"
  },
  "close": {
    "arabic": "يغلق",
    "chinese": "關閉",
    "eng": "Close",
    "hindi": "बंद करना",
    "ja": "閉じる",
    "uz": "Yopish",
    "viet": "Đóng"
  },
  "question": {
    "arabic": "سؤال",
    "chinese": "問題",
    "eng": "Question",
    "hindi": "प्रशन",
    "ja": "問題",
    "uz": "Savol",
    "viet": "Câu hỏi"
  },
  "okay,reset": {
    "arabic": "حسناً، إعادة تعيين",
    "chinese": "好的，重置",
    "eng": "Okay, Reset",
    "hindi": "ठीक है, रीसेट",
    "ja": "はい、リセット",
    "uz": "Yaxshi, qayta o'rnating",
    "viet": "Được, Thiết lập lại"
  },
  "hint": {
    "arabic": "تَلمِيح",
    "chinese": "暗示",
    "eng": "Hint",
    "hindi": "संकेत",
    "ja": "ヒント",
    "uz": "Maslahat",
    "viet": "Gợi ý"
  },
  "click-here-to-showcase-all-of-your-bookmarked-questions-You-may-also-create-a-mocktest-from-here": {
    "arabic": "انقر هنا لعرض جميع أسئلتك المرجعية. يمكنك أيضًا إنشاء اختبار وهمي من هنا",
    "chinese": "單擊此處展示所有已添加書籤的問題。您也可以從此處創建模擬測試",
    "eng": "Click here to showcase all of your bookmarked questions. You may also create a mock test from here",
    "hindi": "अपने बुकमार्क किए गए सभी प्रश्नों को दिखाने के लिए यहां क्लिक करें। आप यहां से मॉक टेस्ट भी बना सकते हैं",
    "ja": "クリックするとブックマークした問題がまとめて表示され、テストを作成することができます。",
    "uz": "Belgilangan barcha savollaringizni ko'rsatish uchun shu yerni bosing. Bundan tashqari, bu yerdan soxta test yaratishingiz mumkin",
    "viet": "Nhấp vào đây để hiển thị tất cả các câu hỏi được đánh dấu của bạn. Bạn cũng có thể tạo một bài kiểm tra thử từ đây"
  },
  "exercise": {
    "arabic": "يمارس",
    "chinese": "鍛煉",
    "eng": "Exercise",
    "hindi": "व्यायाम",
    "ja": "演習問題",
    "uz": "Mashq qilish",
    "viet": "Bài tập"
  },
  "selected": {
    "arabic": "محدد",
    "chinese": "已選",
    "eng": "Selected",
    "hindi": "चयनित",
    "ja": "選択中:",
    "uz": "Tanlangan",
    "viet": "Đã chọn"
  },
  "select-a-language-to-display-the-question-in-the-same-language-subsequent-questions-will-also-be-displayed-in-selected-language": {
    "arabic": "حدد لغة لعرض السؤال بنفس اللغة. سيتم أيضًا عرض الأسئلة اللاحقة باللغة المحددة",
    "chinese": "選擇一種語言以用相同的語言顯示問題。後續問題也將以所選語言顯示",
    "eng": "Select a language to display the question in the same language. Subsequent questions will also be displayed in selected language",
    "hindi": "प्रश्न को उसी भाषा में प्रदर्शित करने के लिए भाषा का चयन करें। बाद के प्रश्न भी चयनित भाषा में प्रदर्शित किए जाएंगे",
    "ja": "一度言語を選択すると、出題も同じ言語で行われます。また、以降の問題も選択した言語で表示されます",
    "uz": "Savolni bir xil tilda ko'rsatish uchun tilni tanlang. Keyingi savollar tanlangan tilda ham ko'rsatiladi",
    "viet": "Chọn một ngôn ngữ để hiển thị câu hỏi trong cùng một ngôn ngữ. Các câu hỏi tiếp theo cũng sẽ được hiển thị bằng ngôn ngữ đã chọn"
  },
  "select-language": {
    "arabic": "اختار اللغة",
    "chinese": "選擇語言",
    "eng": "Select Language",
    "hindi": "भाषा का चयन करें",
    "ja": "言語を選択する",
    "uz": "Tilni tanlang",
    "viet": "Chọn ngôn ngữ"
  },
  "select-the-issue-type":{
    "arabic": "حدد نوع المشكلة",
    "chinese": "選擇問題類型",
    "eng": "Select the issue type",
    "hindi": "मुद्दे का प्रकार चुनें",
    "ja": "問題の種類を選択してください",
    "uz": "Muammo turi tanlang",
    "viet": "Chọn loại vấn đề"
  },
  "guide-to":{
    "arabic": "دليل إلى",
    "chinese": "指南",
    "eng": "Guide to",
    "hindi": "के लिए मार्गदर्शिका",
    "ja": "ガイド",
    "uz": "Qo'l",
    "viet": "Hướng dẫn đến"
  },
  "level": {
    "arabic": "مستوى",
    "chinese": "等級",
    "eng": "Level",
    "hindi": "स्तर",
    "ja": "レベル",
    "uz": "Daraja",
    "viet": "Mức độ"
  },
  "show-broken-concept": {
    "arabic": "إظهار المفهوم المكسور",
    "chinese": "顯示破碎的概念",
    "eng": "Show Broken Concept",
    "hindi": "टूटी हुई अवधारणा दिखाएं",
    "ja": "ポイントを表示",
    "uz": "Buzilgan kontseptsiyani ko'rsatish",
    "viet": "Hiển thị khái niệm bị hỏng"
  },
  "report": {
    "arabic": "تقرير",
    "chinese": "報告",
    "eng": "Report",
    "hindi": "शिकायत करना",
    "ja": "報告",
    "uz": "Hisobot",
    "viet": "Báo cáo"
  },
  "reported": {
    "arabic": "ذكرت",
    "chinese": "已报道",
    "eng": "Reported",
    "hindi": "शिकायत करना",
    "ja": "報告しました",
    "uz": "Xabar qilingan",
    "viet": "Đã báo cáo"
  },
  "where-do-you-see-an-issue": {
    "arabic": "أين ترى مشكلة",
    "chinese": "你在哪裡看到問題",
    "eng": "Where do you see an issue",
    "hindi": "आपको कहां दिक्कत दिखती है",
    "ja": "どこに問題がありましたか？",
    "uz": "Muammoni qayerda ko'rasiz",
    "viet": "Bạn thấy vấn đề ở đâu"
  },
  "in-the-question": {
    "arabic": "في السؤال",
    "chinese": "在問題中",
    "eng": "In the question",
    "hindi": "प्रश्न में",
    "ja": "問題",
    "uz": "Savolda",
    "viet": "trong câu hỏi"
  },
  "in-the-solution": {
    "arabic": "في الحل",
    "chinese": "在溶液中",
    "eng": "In the solution",
    "hindi": "घोल में",
    "ja": "解答解説",
    "uz": "Yechimda",
    "viet": "trong giải pháp"
  },
  "solution": {
    "arabic": "حل",
    "chinese": "解決方案",
    "eng": "Solution",
    "hindi": "घोल",
    "ja": "解答解説",
    "uz": "Yechim",
    "viet": "Giải pháp"
  },
  "both": {
    "arabic": "كلاهما",
    "chinese": "兩個都",
    "eng": "Both",
    "hindi": "दोनों",
    "ja": "両方",
    "uz": "Ikkalasi ham",
    "viet": "Cả hai"
  },
  "briefly-explain-the-issue(s)": {
    "arabic": "اشرح بإيجاز المشكلة (المشكلات)",
    "chinese": "簡要說明問題",
    "eng": "Briefly explain the issue(s)",
    "hindi": "संक्षेप में मुद्दे की व्याख्या करें",
    "ja": "問題を教えてください",
    "uz": "Muammo(lar)ni qisqacha tushuntiring",
    "viet": "Giải thích ngắn gọn (các) vấn đề"
  },
  "apply": {
    "arabic": null,
    "chinese": null,
    "eng": "Apply",
    "hindi": null,
    "ja": "確定",
    "uz": "Murojaat qiling",
    "viet": null
  },
  "cancel": {
    "arabic": "يلغي",
    "chinese": "取消",
    "eng": "Cancel",
    "hindi": "रद्द करना",
    "ja": "キャンセル",
    "uz": "Bekor qilish",
    "viet": "Hủy bỏ",
    "ru": "Отмена",
    "az": "Ləğv et",
    "jp": "キャンセル"
  },
  "submit": {
    "arabic": "يُقدِّم",
    "chinese": "提交",
    "eng": "Submit",
    "hindi": "प्रस्तुत",
    "ja": "提出する",
    "uz": "Yuborish",
    "viet": "Nộp"
  },
  "Clear": {
    "arabic": "واضح",
    "chinese": "清除",
    "eng": "Clear",
    "hindi": "मिटायें",
    "ja": "リセット",
    "uz": "Toza",
    "viet": "Thông thoáng"
  },
  "leaderboard": {
    "arabic": "ليدربورد",
    "chinese": "排行榜",
    "eng": "Leaderboard",
    "hindi": "प्रस्तुत",
    "ja": "ランキング表",
    "uz": "Peshqadamlar jadvali",
    "viet": "bảng xếp hạng"
  },
  "notification": {
    "arabic": "إشعار",
    "chinese": "通知",
    "eng": "Notification",
    "hindi": "अधिसूचना",
    "ja": "学習状況",
    "uz": "Bildirishnoma",
    "viet": "thông báo"
  },
  "Review": {
    "arabic": "مراجعةشعار",
    "chinese": "审查 ",
    "eng": "Review",
    "hindi": "समीक्षा",
    "ja": "確認する",
    "uz": "Ko‘rib chiqish",
    "viet": "ôn tập"
  },
  "Resolve": {
    "arabic": "حل",
    "chinese": "解决",
    "eng": "Resolve",
    "hindi": "संकल्प",
    "ja": "解決",
    "uz": "Yechish",
    "viet": "Giải quyết"
  },
  "Actions": {
    "arabic": "أجراءات",
    "chinese": "行动",
    "eng": "Actions",
    "hindi": "कार्रवाईकल्प",
    "ja": "アクション",
    "uz": "Harakatlar",
    "viet": "hành động"
  },
  "action": {
    "arabic": "عمل",
    "chinese": "行動",
    "eng": "Action",
    "hindi": "कार्रवाई",
    "ja": "アクション",
    "uz": "Harakat",
    "viet": "hành động",
    "ru": "Действие",
    "az": "Hərəkət",
    "jp": "アクション"
  },
  "Descriptions": {
    "arabic": "الأوصاف",
    "chinese": "",
    "eng": "Descriptions",
    "hindi": "विवरण",
    "ja": "詳細",
    "uz": "Tavsiflar",
    "viet": "Mô tả"
  },
  "still-facing-an-issue-decribe-your-issue":{
    "eng": "Still facing an issue, describe your issue.",
    "arabic": "ما زلت تواجه مشكلة، اشرح مشكلتك.",
    "chinese": "仍然面临问题，请描述您的问题。",
    "hindi": "अभी भी किसी समस्या का सामना कर रहे हैं, अपनी समस्या का वर्णन करें।",
    "ja": "まだ問題が発生しています。問題を説明してください。",
    "uz": "Hali ham muammoga duch kelibsiz, muammongizni ta'riflang.",
    "viet": "Vẫn đang gặp sự cố, mô tả vấn đề của bạn."
  },
  "describe-your-issue": {
    "arabic": "صف مشكلتك",
    "chinese": "描述您的问题",
    "eng": "Describe your issue.",
    "hindi": "अपनी समस्या का वर्णन करें",
    "ja": "問題を説明してください",
    "uz": "Muammongizni ta'riflang",
    "viet": "Mô tả vấn đề của bạn"
  },
  "still-want-to-escalate-the-issue-describe-your-issue-along-with-the-login-id-of-the-student" : {
    "eng": "Still want to escalate the issue? Describe the issue.",
    "arabic": "هل تريد تصعيد المشكلة؟ اشرح المشكلة.",
    "chinese": "仍然想升级问题？描述问题。",
    "hindi": "अभी भी समस्या को तेजी से बढ़ाना चाहते हैं? समस्या का वर्णन करें।",
    "ja": "問題をエスカレートさせたいですか？問題を説明してください。",
    "uz": "Hali ham muammoga duch kelishni xohlaysizmi? Muammongizni ta'riflang.",
    "viet": "Vẫn muốn nâng cấp vấn đề? Mô tả vấn đề."
  },
  "currently-no-students-have-started-practicing-in-this-class": {
    "arabic": "حاليًا لم يبدأ أي طالب في التدرب في هذا الفصل",
    "chinese": "目前還沒有學生在這個班級開始練習",
    "eng": "Currently no students have started practicing in this class",
    "hindi": "वर्तमान में इस कक्षा में किसी भी छात्र ने अभ्यास शुरू नहीं किया है",
    "ja": "現在、このクラスを受講中の生徒はいません",
    "uz": "Hozirda bu sinfda birorta ham o‘quvchi mashq qilishni boshlamagan",
    "viet": "Hiện tại chưa có học viên nào bắt đầu thực hành trong lớp này"
  },
  "students-have-not-started-the-test-yet": {
    "arabic": "لم يبدأ الطلاب الاختبار بعد",
    "chinese": "學生還沒有開始測試",
    "eng": "students have not started the test yet",
    "hindi": "छात्रों ने अभी तक परीक्षा शुरू नहीं की है",
    "ja": "人が未開始です。",
    "uz": "talabalar hali testni boshlamagan",
    "viet": "Học viên chưa bắt đầu bài kiểm tra"
  },
  "students-submitted-the-test-and": {
    "arabic": "قدم الطلاب الاختبار و",
    "chinese": "學生提交了測試",
    "eng": "students submitted the test and",
    "hindi": "छात्रों ने परीक्षा जमा की और",
    "ja": "人の生徒がテストを提出済みで、",
    "uz": "talabalar test topshirdilar va",
    "viet": "Học viên đã nộp bài kiểm tra và"
  },
  "students-are-in-this-class": {
    "arabic": "الطلاب في هذه الفصل",
    "chinese": "學生在這個班級",
    "eng": "students are in this class",
    "hindi": "छात्र इस कक्षा में हैं",
    "ja": "人の生徒がこのクラスに所属しています。",
    "uz": "talabalar bu sinfda",
    "viet": "Học viên ở trong lớp học này"
  },
  "questions": {
    "arabic": "أسئلة",
    "chinese": "問題",
    "eng": "questions",
    "hindi": "प्रशन",
    "ja": "問題",
    "uz": "savollar",
    "viet": "câu hỏi"
  },
  "chapters": {
    "arabic": "فصول",
    "chinese": "章節",
    "eng": "Chapters",
    "hindi": "अध्याय",
    "ja": "チャプター",
    "uz": "Boblar",
    "viet": "chương"
  },
  "chapter": {
    "arabic": "الفصل",
    "chinese": "章節",
    "eng": "Chapter",
    "hindi": "अध्याय",
    "ja": "チャプター",
    "uz": "Bob",
    "viet": "chương"
  },
  "releasing-soon": {
    "arabic": "الافراج قريبا",
    "chinese": "即將發布",
    "eng": "Releasing Soon",
    "hindi": "जल्द ही रिलीज हो रही है",
    "ja": "公開予定",
    "uz": "Tez orada chiqariladi",
    "viet": "Sắp phát hành"
  },
  "undo-bookmark": {
    "arabic": "تراجع عن الإشارة المرجعية",
    "chinese": "撤消書籤",
    "eng": "Undo Bookmark",
    "hindi": "जल्द ही रिलीज हो रही है",
    "ja": "ブックマークを取り消す",
    "uz": "Xatcho‘pni bekor qilish",
    "viet": "Bỏ đánh dấu trang"
  },
  "set-announcement": {
    "arabic": "تعيين إعلان",
    "chinese": "設置公告",
    "eng": "Set Announcement",
    "hindi": "घोषणा सेट करें",
    "ja": "お知らせを配信する",
    "uz": "E'lonni o'rnatish",
    "viet": "Đặt thông báo"
  },
  "set": {
    "arabic": "تعيين",
    "chinese": "放",
    "eng": "Set",
    "hindi": "सेट",
    "ja": "配信",
    "uz": "Oʻrnatish",
    "viet": "Bộ"
  },
  "set-an-announcement-for-your-class": {
    "arabic": "تعيين إعلان لفصلك",
    "chinese": "為您的班級設置公告",
    "eng": "Set an announcement for your class",
    "hindi": "अपनी कक्षा के लिए एक घोषणा सेट करें",
    "ja": "自分のクラスにお知らせを配信する",
    "uz": "Sinfingiz uchun e'lon o'rnating",
    "viet": "Đặt thông báo cho lớp học của bạn"
  },
  "your-class": {
    "arabic": "فصلك",
    "chinese": "你的班",
    "eng": "Your Class",
    "hindi": null,
    "ja": "自分のクラス",
    "uz": "Sizning sinfingiz",
    "viet": "Lớp của bạn"
  },
  "shown-to-students-when-they-log-into-your-class-in-cerebry": {
    "arabic": "تظهر للطلاب عند تسجيل الدخول إلى صفك في Cerebry",
    "chinese": "當學生在 Cerebry 中登錄您的班級時向他們展示",
    "eng": "Shown to students when they log into your class in Cerebry",
    "hindi": "छात्रों को सेरेब्री में आपकी कक्षा में प्रवेश करने पर दिखाया गया",
    "ja": "生徒があなたのクラスにログインした際、生徒側にも表示する",
    "uz": "Talabalar Cerebry-da sinfingizga kirganlarida ko'rsatiladi",
    "viet": "Hiển thị cho sinh viên khi họ đăng nhập vào lớp học của bạn trong Cerebry"
  },
  "type-your-announcement-here": {
    "arabic": "اكتب إعلانك هنا",
    "chinese": "在此輸入您的公告",
    "eng": "Type your announcement here",
    "hindi": "अपनी घोषणा यहां टाइप करें",
    "ja": "お知らせ内容を入力",
    "uz": "E'loningizni shu yerga yozing",
    "viet": "Nhập thông báo của bạn ở đây"
  },
  "announcement": {
    "arabic": "إعلان",
    "chinese": "公告",
    "eng": "Announcement",
    "hindi": "घोषणा",
    "ja": "お知らせ",
    "uz": "E'lon",
    "viet": "Thông báo"
  },
  "delete-announcement": {
    "arabic": "إعلان حذف",
    "chinese": "刪除公告",
    "eng": "Delete Announcement",
    "hindi": "घोषणा हटाएं",
    "ja": "お知らせを削除する",
    "uz": "E'lonni o'chirish",
    "viet": "Xóa thông báo"
  },
  "teachers": {
    "arabic": "المعلمين",
    "chinese": "老師",
    "eng": "Teachers",
    "hindi": "शिक्षक",
    "ja": "先生",
    "uz": "O'qituvchilar",
    "viet": "Giáo viên"
  },
  "cerebry-is-a-one-stop-solution-for-Math-Teachers": {
    "arabic": "Cerebry - هو حل شامل لمعلمي الرياضيات",
    "chinese": "Cerebry - 是數學教師的一站式解決方案",
    "eng": "Cerebry - Is a one stop solution for Math Teachers",
    "hindi": "सेरेब्री - गणित शिक्षकों के लिए एक स्थान पर समाधान है",
    "ja": "「Manabie」は算数・数学の先生向けのワンストップソリューションです。",
    "uz": "Cerebry - Matematika o'qituvchilari uchun yagona yechim",
    "viet": "Kyons x Cerebry cung cấp giải pháp toàn diện cho các giáo viên dạy Toán"
  },
  "personalized-chapter-wise-exercises-for-every-student-based-on-their-level-adopts-to-your-existing-course-textbooks-or-the-curriculum's-general-syllabus-result-students-enjoy-practicing-math-without-getting-overwhelmed": {
    "arabic": "تمارين حكيمة للفصل مخصصة لكل طالب بناءً على مستواه. يعتمد على الكتب المدرسية الموجودة لديك أو المنهج العام للمناهج الدراسية. نتيجة؟ يستمتع الطلاب بممارسة الرياضيات دون الشعور بالارتباك",
    "chinese": "根據每個學生的水平為每個學生提供個性化的章節明智練習。採用您現有的課程教科書或課程的一般教學大綱。結果？學生喜歡練習數學而不會不知所措",
    "eng": "Personalized chapter wise exercises for every student based on their level. Adopts to your existing course textbooks or the curriculum's general syllabus. Result? Students enjoy practicing Math without getting overwhelmed",
    "hindi": "प्रत्येक छात्र के लिए उनके स्तर के आधार पर वैयक्तिकृत अध्यायवार अभ्यास। आपके मौजूदा पाठ्यक्रम की पाठ्यपुस्तकों या पाठ्यक्रम के सामान्य पाठ्यक्रम को अपनाता है। परिणाम? छात्र बिना अभिभूत हुए गणित का अभ्यास करने का आनंद लेते हैं",
    "ja": "一人ひとりの習熟度に合わせた章毎の演習問題を提供します。また、既存の授業で使っている教材や汎用的なシラバスに準拠するため、生徒は楽しみながら、無理なく数学の学習が可能です。",
    "uz": "Har bir talaba uchun o'z darajasiga qarab shaxsiylashtirilgan boblar bo'yicha mashqlar. Mavjud darsliklaringiz yoki o'quv dasturining umumiy dasturiga mos keladi. Natija? Talabalar matematikadan charchamasdan mashq qilishdan zavqlanishadi",
    "viet": "Các bài tập được xây dựng dưới dạng từng chương theo chương trình sách giáo khoa hoặc chương trình hiện hành. Hơn thế nữa, tất cả đều được cá nhân hoá dựa trên lực học của mỗi học sinh, từ đó tạo hứng thú giúp các bạn học Toán mà không bị áp lực."
  },
  "hello-there-Get-started-with-cerebry": {
    "arabic": "أهلاً بك! ابدأ مع Cerebry",
    "chinese": "你好呀！開始使用 Cerebry",
    "eng": "Hello there! Get started with Cerebry",
    "hindi": "नमस्ते! सेरेब्री से शुरू करें",
    "ja": "こんにちは！さっそく始めてみましょう",
    "uz": "Salom! Cerebry bilan boshlang",
    "viet": "Xin chào! Bắt đầu với Cerebry"
  },
  "start-your-14-day-free-trial-now-hurry-up": {
    "arabic": "ابدأ تجربتك المجانية لمدة 14 يومًا الآن! أسرع - بسرعة",
    "chinese": "立即開始您的 14 天免費試用！趕快",
    "eng": "Start your 14-day free trial now! Hurry up",
    "hindi": "अपना 14-दिन का निःशुल्क परीक्षण अभी प्रारंभ करें! जल्दी करो",
    "ja": "今すぐ使える「14日間の無料体験」はこちら！",
    "uz": "14 kunlik bepul sinov muddatini hoziroq boshlang! Shoshilmoq",
    "viet": "Bắt đầu dùng thử miễn phí 14 ngày của bạn ngay bây giờ! Nhanh lên"
  },
  "with-an-ai-driven-adaptive-maths-practice-platform-cerebry-is-everything-you-need": {
    "arabic": "مع منصة ممارسة الرياضيات التكيفية المدعومة بالذكاء الاصطناعي , فإن Cerebry هو كل ما تحتاجه",
    "chinese": "借助 AI 驅動的自適應數學練習平台,Cerebry 是您需要的一切",
    "eng": "With an AI-Driven Adaptive Maths Practice Platform, Cerebry is everything you need",
    "hindi": "एआई-संचालित एडेप्टिव मैथ्स प्रैक्टिस प्लेटफॉर्म के साथ, सेरेब्री वह सब कुछ है जिसकी आपको जरूरत है",
    "ja": "AIを用いた個別最適型の数学演習ツールであるManabieには、先生が必要なものが全て揃っています。",
    "uz": "AI tomonidan boshqariladigan moslashuvchan matematik amaliyot platformasi bilan Cerebry sizga kerak bo'lgan hamma narsadir",
    "viet": "Cùng với một nền tảng ứng dụng AI để cá nhân hoá việc học Toán, Kyons x Cerebry là tất cả những gì bạn cần"
  },
  "enter-first-name": {
    "arabic": "أدخل الاسم الأول",
    "chinese": "輸入名字",
    "eng": "Enter first name",
    "hindi": "पहला नाम दर्ज करें",
    "ja": "お名前（名）を入力",
    "uz": "Ismingizni kiriting",
    "viet": "Nhập tên"
  },
  "enter-last-name": {
    "arabic": "إدخال اسم آخر",
    "chinese": "輸入姓氏",
    "eng": "Enter last name",
    "hindi": "अंतिम नाम दर्ज करो",
    "ja": "お名前（姓）を入力",
    "uz": "Familiyani kiriting",
    "viet": "Nhập họ"
  },
  "first": {
    "arabic": "أولاً",
    "chinese": "第一的",
    "eng": "first",
    "hindi": "पहला",
    "ja": "最初",
    "uz": "birinchi",
    "viet": "Đầu tiên"
  },
  "first-name": {
    "arabic": "الاسم الأول",
    "chinese": "名",
    "eng": "first name",
    "hindi": "पहला नाम",
    "ja": "名",
    "uz": "ism",
    "viet": "tên",
    "ru": "Имя",
    "az": "Ad",
    "jp": "名前"
  },
  "First-name": {
    "arabic": "الاسم الأول",
    "chinese": "名",
    "eng": "First name",
    "hindi": "पहला नाम",
    "ja": "名",
    "uz": "ism",
    "viet": "tên"
  },
  "Last-name": {
    "arabic": "اسم العائلة",
    "chinese": "姓",
    "eng": "Last name",
    "hindi": "अंतिम नाम",
    "ja": "名",
    "uz": "familiya",
    "viet": "họ"
  },
  "name": {
    "arabic": "اسم",
    "chinese": "姓名",
    "eng": "Name",
    "hindi": "नाम",
    "ja": "お名前",
    "uz": "Ism",
    "viet": "Tên"
  },
  "last-name": {
    "arabic": "اسم العائلة",
    "chinese": "姓",
    "eng": "last name",
    "hindi": "अंतिम नाम",
    "ja": "お名前(姓)",
    "uz": "familiya",
    "viet": "họ",
    "ru": "Фамилия",
    "az": "Soyad",
    "jp": "姓"
  },
  "last": {
    "arabic": "آخر",
    "chinese": "最後的",
    "eng": "last",
    "hindi": "अंतिम",
    "ja": "最後",
    "uz": "oxirgi",
    "viet": "cuối cùng"
  },
  "enter-email-id": {
    "arabic": "أدخل معرف البريد الإلكتروني",
    "chinese": "輸入電子郵件 ID",
    "eng": "Enter email id",
    "hindi": "ईमेल आईडी दर्ज करें",
    "ja": "メールアドレスを入力",
    "uz": "Elektron pochta identifikatorini kiriting",
    "viet": "Nhập địa chỉ email"
  },
  "get-started": {
    "arabic": "البدء",
    "chinese": "開始",
    "eng": "Get Started",
    "hindi": "शुरू हो जाओ",
    "ja": "はじめる",
    "uz": "Boshlash",
    "viet": "Bắt đầu"
  },
  "by-clicking-button-above-you-agree-to-our": {
    "arabic": "بالنقر فوق الزر أعلاه , فإنك توافق على",
    "chinese": "點擊上方按鈕,即表示您同意我們的",
    "eng": "By clicking button above, you agree to our",
    "hindi": "ऊपर दिए गए बटन पर क्लिक करके, आप हमारी सहमति देते हैं",
    "ja": "上のボタンをクリックして同意する",
    "uz": "Yuqoridagi tugmani bosish orqali siz bizning shartlarimizga rozilik bildirasiz",
    "viet": "Bằng cách bấm vào nút bên trên, bạn đã đồng ý với"
  },
  "terms-of-use": {
    "arabic": "شروط الاستخدام",
    "chinese": "使用條款",
    "eng": "terms of use",
    "hindi": "उपयोग की शर्तें",
    "ja": "利用規約",
    "uz": "foydalanish shartlari",
    "viet": "điều khoản sử dụng"
  },
  "and": {
    "arabic": "و",
    "chinese": "和",
    "eng": "and",
    "hindi": "और",
    "ja": "と",
    "uz": "va",
    "viet": "Và"
  },
  "privacy-policy": {
    "arabic": "سياسة الخصوصية",
    "chinese": "隱私政策",
    "eng": "privacy policy",
    "hindi": "गोपनीयता नीति",
    "ja": "プライバシーポリシー",
    "uz": "Maxfiylik siyosati",
    "viet": "Chính sách bảo mật"
  },
  "hey": {
    "arabic": "يا",
    "chinese": "嘿",
    "eng": "Hey",
    "hindi": "अरे",
    "ja": "やぁ",
    "uz": "Hey",
    "viet": "Này bạn ơi"
  },
  "your-a-14-day-free-trial-account-with-cerebry-has-been-created": {
    "arabic": "تم إنشاء حساب تجريبي مجاني لمدة 14 يومًا مع Cerebry",
    "chinese": "您已經創建了一個 14 天的 Cerebry 免費試用帳戶",
    "eng": "your a 14-day free trial account with Cerebry has been created",
    "hindi": "सेरेब्री के साथ आपका 14-दिन का निःशुल्क परीक्षण खाता बना दिया गया है",
    "ja": "14日間無料体験用のアカウントが作成されました",
    "uz": "Cerebry bilan 14 kunlik bepul sinov hisob qaydnomangiz yaratildi",
    "viet": "Tài khoản dùng thử 14 ngày Kyons x Cerebry của bạn đã được tạo"
  },
  "you-will-need-to-verify-your-email-and-complete-the-activation-process": {
    "arabic": "ستحتاج إلى التحقق من بريدك الإلكتروني وإكمال عملية التنشيط",
    "chinese": "您將需要驗證您的電子郵件並完成激活過程",
    "eng": "You will need to verify your email and complete the activation process",
    "hindi": "आपको अपना ईमेल सत्यापित करना होगा और सक्रियण प्रक्रिया पूरी करनी होगी",
    "ja": "本ツールを有効化するため、メールでの認証が必要です。",
    "uz": "Siz elektron pochtangizni tasdiqlashingiz va faollashtirish jarayonini yakunlashingiz kerak",
    "viet": "Bạn cần xác thực email và hoàn tất quá trình kích hoạt tài khoản"
  },
  "open-your-email": {
    "arabic": "افتح بريدك الإلكتروني",
    "chinese": "打開你的電子郵件",
    "eng": "Open your email",
    "hindi": "अपना ईमेल खोलें",
    "ja": "メールを開く",
    "uz": "Elektron pochtangizni oching",
    "viet": "mở email của bạn"
  },
  "an-email-has-been-sent-to": {
    "arabic": "تم إرسال رسالة إلكترونية إلى",
    "chinese": "一封電子郵件已經發送至",
    "eng": "An email has been sent to",
    "hindi": "एक ईमेल इन्हें भेजी गई है",
    "ja": "メールが送信されました",
    "uz": "Elektron pochta manziliga xat yuborildi",
    "viet": "một email vừa được gửi tới"
  },
  "with-a-link-to-activate-your-demo-account-if-you-do-not-find-the-email-please-check-your-spam-folder": {
    "arabic": "مع رابط لتفعيل حسابك التجريبي. إذا لم تعثر على البريد الإلكتروني , فيرجى التحقق من مجلد البريد العشوائي",
    "chinese": "帶有激活模擬賬戶的鏈接。如果您沒有找到該電子郵件,請檢查您的垃圾郵件文件夾",
    "eng": "with a link to activate your demo account. If you do not find the email, please check your spam folder",
    "hindi": "आपके डेमो खाते को सक्रिय करने के लिए एक लिंक के साथ। यदि आपको ईमेल नहीं मिलता है, तो कृपया अपना स्पैम फ़ोल्डर जांचें",
    "ja": "無料体験用のアカウントを有効にするためのURLが記載されています。メールが見つからない場合、迷惑フォルダに届いている可能性があります。",
    "uz": "demo hisobingizni faollashtirish uchun havola bilan. Agar elektron pochtani topmasangiz, spam jildini tekshiring",
    "viet": "cùng với một đường dẫn để kích hoạt tài khoản dùng thử của bạn. Nếu không thể tìm thấy email, hãy kiểm tra thư mục Spam"
  },
  "verify-and-send-again": {
    "arabic": "تحقق وأرسل مرة أخرى",
    "chinese": "驗證並再次發送",
    "eng": "Verify and Send Again",
    "hindi": "सत्यापित करें और फिर से भेजें",
    "ja": "認証してもう一度送る",
    "uz": "Tekshiring va qayta yuboring",
    "viet": "Xác minh và gửi lại"
  },
  "mail-Sent-check-you-account": {
    "arabic": "تم إرسال البريد! تحقق من حسابك",
    "chinese": "郵件已發送！檢查你的帳戶",
    "eng": "Mail Sent! check you account",
    "hindi": "संदेश भेज दिया गया! अपना खाता जांचें",
    "ja": "メールが送信されました！アカウントを確認してください",
    "uz": "Xat yuborildi! hisobingizni tekshiring",
    "viet": "Thư đã gửi! kiểm tra tài khoản của bạn"
  },
  "mail-not-sent-Pleae-try-again": {
    "arabic": "لم يتم إرسال البريد! الرجاء المحاولة مرة أخرى",
    "chinese": "郵件未發送！請再試一次",
    "eng": "Mail not sent! Pleae try again",
    "hindi": "मेल नहीं भेजा गया! कृपया पुन: प्रयास करें",
    "ja": "メールが送信できませんでした！再度お試しください",
    "uz": "Pochta yuborilmadi! Iltimos, qayta urinib ko'ring",
    "viet": "Thư chưa gửi! Vui lòng thử lại"
  },
  "didnt-get-the-email": {
    "arabic": "ألم تحصل على البريد الإلكتروني؟",
    "chinese": "沒有收到電子郵件？",
    "eng": "Didn't get the email?",
    "hindi": "ईमेल नहीं मिला?",
    "ja": "メールが届かない場合はこちら",
    "uz": "E-pochtani olmadingizmi?",
    "viet": "Không nhận được email?"
  },
  "send-it-again": {
    "arabic": "ارسلها مجددا",
    "chinese": "再發一次",
    "eng": "Send it again",
    "hindi": "उसे दोबारा भेजो",
    "ja": "もう一度送信する",
    "uz": "Yana yuboring",
    "viet": "Gửi lại"
  },
  "premium-grade": {
    "arabic": "قسط الصف",
    "chinese": "特級",
    "eng": "Premium grade",
    "hindi": "प्रीमियम ग्रेड",
    "ja": "プレミアムグレード",
    "uz": "Premium daraja",
    "viet": "Cao cấp"
  },
  "back": {
    "arabic": "خلف",
    "chinese": "後退",
    "eng": "Back",
    "hindi": "वापस",
    "ja": "戻る",
    "uz": "Orqaga",
    "viet": "Mặt sau"
  },
  "create-account": {
    "arabic": "إنشاء حساب",
    "chinese": "創建賬戶",
    "eng": "Create Account",
    "hindi": "खाता बनाएं",
    "ja": "アカウントを作成する",
    "uz": "Hisob yaratish",
    "viet": "Tạo tài khoản"
  },
  "create": {
    "arabic": "يخلق",
    "chinese": "創造",
    "eng": "Create",
    "hindi": "बनाएं",
    "ja": "作成する",
    "uz": "Yaratmoq",
    "viet": "Tạo nên"
  },
  "please-enter-first-name": {
    "arabic": "الرجاء إدخال الاسم الأول",
    "chinese": "請輸入名字",
    "eng": "Please enter first name",
    "hindi": "कृपया पहला नाम दर्ज करें",
    "ja": "お名前（名）を入力してください",
    "uz": "Iltimos, ismingizni kiriting",
    "viet": "Vui lòng nhập tên"
  },
  "please-enter-last-name": {
    "arabic": "الرجاء إدخال الاسم الأخير",
    "chinese": "請輸入姓氏",
    "eng": "Please enter last name",
    "hindi": "कृपया अंतिम नाम दर्ज करें",
    "ja": "お名前（姓）を入力してください",
    "uz": "Iltimos, familiyani kiriting",
    "viet": "Vui lòng nhập họ"
  },
  "please-enter-email-id": {
    "arabic": "الرجاء إدخال معرف البريد الإلكتروني",
    "chinese": "請輸入電子郵件 ID",
    "eng": "Please enter email id",
    "hindi": "कृपया ईमेल आईडी दर्ज करें",
    "ja": "メールアドレスを入力してください",
    "uz": "Iltimos, elektron pochta identifikatorini kiriting",
    "viet": "Vui lòng nhập id email"
  },
  "email-already-in-use-with-cerebry": {
    "arabic": "البريد الإلكتروني قيد الاستخدام بالفعل مع Cerebry",
    "chinese": "已在 Cerebry 中使用的電子郵件",
    "eng": "Email already in use with Cerebry",
    "hindi": "ईमेल पहले से ही सेरेब्री के साथ प्रयोग में है",
    "ja": "このメールアドレスは既に使用されています",
    "uz": "Elektron pochta allaqachon Cerebry bilan ishlatilmoqda",
    "viet": "Email đã được sử dụng với Cerebry"
  },
  "please-enter-phone-number": {
    "arabic": "الرجاء إدخال رقم الهاتف",
    "chinese": "請輸入電話號碼",
    "eng": "Please enter phone number",
    "hindi": "कृपया फोन नंबर दर्ज करें",
    "ja": "電話番号を入力してください",
    "uz": "Iltimos, telefon raqamini kiriting",
    "viet": "Vui lòng nhập số điện thoại"
  },
  "phone-number-is-invalid": {
    "arabic": "رقم الهاتف غير صحيح",
    "chinese": "電話號碼無效",
    "eng": "Phone Number is invalid",
    "hindi": "फ़ोन नंबर अमान्य है",
    "ja": "電話番号が無効です",
    "uz": "Telefon raqami yaroqsiz",
    "viet": "Số điện thoại không hợp lệ"
  },
  "please-enter-school-name": {
    "arabic": "الرجاء إدخال اسم المدرسة",
    "chinese": "請輸入學校名稱",
    "eng": "Please enter school name",
    "hindi": "कृपया स्कूल का नाम दर्ज करें",
    "ja": "学校名を入力してください",
    "uz": "Iltimos, maktab nomini kiriting",
    "viet": "Vui lòng nhập tên trường"
  },
  "show-content": {
    "arabic": "عرض المحتوى",
    "chinese": "展示內容",
    "eng": "Show Content",
    "hindi": "सामग्री दिखाओ",
    "ja": "コンテンツを表示する",
    "uz": "Kontentni ko'rsatish",
    "viet": "Hiển thị nội dung"
  },
  "password-does-not-match": {
    "arabic": "كلمة السر غير متطابقة",
    "chinese": "密碼不匹配",
    "eng": "Password does not match",
    "hindi": "पासवर्ड मैच नहीं कर रहा है!",
    "ja": "パスワードが一致しません",
    "uz": "Parol mos emas",
    "viet": "Mật khẩu không hợp lệ"
  },
  "please-fill-all-the-fields": {
    "arabic": "الرجاء تعبئة جميع الحقول",
    "chinese": "請填寫所有字段",
    "eng": "Please fill all the fields!",
    "hindi": "कृपया सभी फ़ील्ड भरें",
    "ja": "すべての必須項目を入力してください",
    "uz": "Iltimos, barcha maydonlarni toʻldiring",
    "viet": "Vui long điên vao tât cả cac mục"
  },
  "old-password": {
    "arabic": "كلمة المرور القديمة",
    "chinese": "舊密碼",
    "eng": "Old Password",
    "hindi": "पुराना पासवर्ड",
    "ja": "古いパスワード",
    "uz": "Eski parol",
    "viet": "Mật khẩu cũ"
  },
  "password": {
    "arabic": "كلمة المرور",
    "chinese": "密碼",
    "eng": "Password",
    "hindi": "पासवर्ड",
    "ja": "パスワード",
    "uz": "Parol",
    "viet": "Mật khẩu"
  },
  "new-password": {
    "arabic": "كلمة المرور الجديدة",
    "chinese": "新密碼",
    "eng": "New Password",
    "hindi": "नया पासवर्ड",
    "ja": "新しいパスワード",
    "uz": "Yangi parol",
    "viet": "mật khẩu mới"
  },
  "re-enter-new-password": {
    "arabic": "أعد إدخال كلمة المرور الجديدة",
    "chinese": "重新輸入新的密碼",
    "eng": "Re-enter New Password",
    "hindi": "नया पासवर्ड पुनः दर्ज करें",
    "ja": "新しいパスワードを再入力してください",
    "uz": "Yangi parolni qayta kiriting",
    "viet": "Nhập lại mật khẩu mới"
  },
  "change-password": {
    "arabic": "تغيير كلمة المرور",
    "chinese": "更改密碼",
    "eng": "Change Password",
    "hindi": "पासवर्ड बदलें",
    "ja": "パスワードを変更",
    "uz": "Parolni o'zgartirish",
    "viet": "Đổi mật khẩu"
  },
  "all-fields-are-required": {
    "arabic": "جميع الحقول مطلوبة",
    "chinese": "各個領域都需要",
    "eng": "All-fields-are-required",
    "hindi": "सभी फ़ील्ड आवश्यक हैं",
    "ja": "すべて必須項目です",
    "uz": "Barcha maydonlar talab qilinadi",
    "viet": "Tất cả các mục cần phải được điền!",
    "ru": "Все поля обязательны",
    "az": "Bütün sahələr tələb olunur",
    "jp": "すべてのフィールドは必須です"
  },
  "type-a-name-for-this-class": {
    "arabic": "اكتب اسمًا لهذه الفئة",
    "chinese": "為此類輸入名稱",
    "eng": "Type a name for this class",
    "hindi": "इस वर्ग के लिए एक नाम लिखें",
    "ja": "クラス名を入力してください",
    "uz": "Ushbu sinf uchun nom kiriting",
    "viet": "Nhập tên cho lớp này",
    "ru": "Введите название для этого класса",
    "az": "Bu sinif üçün ad daxil edin",
    "jp": "このクラスの名前を入力"
  },
  "this-name-is-seen-only-by-you-it-is-to-help-you-label-your-classes-eg-'9th-D'-or-'section-B-10th'": {
    "arabic": "* هذا الاسم لا يراه غيرك. انها لمساعدتك في تسمية فصولك الدراسية. على سبيل المثال- التاسع د أو القسم ب العاشر",
    "chinese": "*這個名字只有你能看到。它是為了幫助你標記你的類。例如- &#39;9th D&#39; 或 &#39;section B 10th&#39;",
    "eng": "*This name is seen only by you. It is to help you label your classes. Eg- '9th D' or 'section B 10th'",
    "hindi": "*यह नाम केवल आपके द्वारा देखा जाता है। यह आपको अपनी कक्षाओं को लेबल करने में मदद करने के लिए है। उदाहरण- '9वीं डी' या 'सेक्शन बी 10वीं'",
    "ja": "クラス名はあなただけに表示されます。クラスの管理にお使いください。（例：　「9th D」、「Section B 10th」など）",
    "uz": "*Ushbu nomni faqat siz ko'rasiz. Bu sizning sinflaringizni belgilashga yordam beradi. Masalan, “9-D” yoki “B-10-qism”",
    "viet": "*Chỉ mình bạn mới có thể nhìn thấy tên này. Chúng được dùng để phân loại các lớp học. Ví dụ: '9th D' hoặc 'Phần B 10th'",
    "uz": "Bu nom faqat sizga ko'rinadi. Bu sizga sinflaringizni belgilashda yordam beradi. Masalan, “9-D” yoki “10-B bo'limi”",
    "ru": "Это имя видно только вам. Оно помогает вам пометить ваши классы. Например, «9-Д» или «секция 10-Б»",
    "az": "Bu ad yalnız sizə görünür. Bu siniflərinizi qeyd etmək üçün sizə kömək edir. Məsələn, “9-D” və ya “bölmə 10-B”",
  },
  "this-name-is-seen-only-by-you-it-is-to-help-you-remember-the-connected-Google-Class": {
    "arabic": null,
    "chinese": null,
    "eng": "*This name is seen only by you. It is to help you remember the connected Google Class",
    "hindi": null,
    "ja": null,
    "uz": "*Ushbu nomni faqat siz ko'rasiz. U bog'langan Google Classni eslab qolishingizga yordam beradi",
    "viet": null
  },
  "create-class": {
    "arabic": "أنشئ فصلًا دراسيًا",
    "chinese": "創建類",
    "eng": "Create Class",
    "hindi": "वर्ग बनाएँ",
    "ja": "クラスを作成する",
    "uz": "Sinf yaratish",
    "viet": "Tạo lớp học",
    "ru": "Создать класс",
    "az": "Sinif yaradın",
    "jp": "クラスを作成"
  },
  "connect-class": {
    "arabic": null,
    "chinese": null,
    "eng": "Connect Class",
    "hindi": null,
    "ja": null,
    "uz": "Sinfni ulash",
    "viet": null
  },
  "disconnect-class": {
    "arabic": null,
    "chinese": null,
    "eng": "Disconnect Google Classroom",
    "hindi": null,
    "ja": null,
    "uz": "Google Classroom-ni uzing",
    "viet": null
  },
  "class-you-are-creating": {
    "arabic": "الفصل الذي تقوم بإنشائه",
    "chinese": "您正在創建的類",
    "eng": "Class you are creating",
    "hindi": "आप जो वर्ग बना रहे हैं",
    "ja": "作成中のクラス",
    "uz": "Siz yaratayotgan sinf",
    "viet": "Lớp bạn đang tạo"
  },
  "grade": {
    "arabic": "درجة",
    "chinese": "年級",
    "eng": "Grade",
    "hindi": "श्रेणी",
    "ja": "学年",
    "uz": "Baho",
    "viet": "Cấp",
    "ru": "Оценка",
    "az": "Dərəcə",
    "jp": "成績"
  },
  "class-name": {
    "arabic": "اسم الفصل",
    "chinese": "班級名稱",
    "eng": "Class Name",
    "hindi": "कक्षा का नाम",
    "ja": "クラス名",
    "uz": "Sinf nomi",
    "viet": "Tên lớp",
    "ru": "Название класса",
    "az": "Sinif adı",
    "jp": "クラス名"
  },
  "press-confirm-to-get-the-code-for-this-class": {
    "arabic": "اضغط على تأكيد للحصول على رمز هذه الفئة",
    "chinese": "按確認以獲取此類的代碼",
    "eng": "Press confirm to get the code for this class",
    "hindi": "इस वर्ग के लिए कोड प्राप्त करने के लिए पुष्टि करें दबाएं",
    "ja": "クラスコードを取得するには確認ボタンを押してください",
    "uz": "Ushbu sinf uchun kodni olish uchun tasdiqlash tugmasini bosing",
    "viet": "Nhấn xác nhận để nhận mã cho lớp học này",
    "ru": "Нажмите подтвердить, чтобы получить код для этого класса",
    "az": "Bu sinif üçün kodu əldə etmək üçün təsdiqləyin",
    "jp": "このクラスのコードを取得するには確認を押してください"
  },
  "confirm": {
    "arabic": "يتأكد",
    "chinese": "確認",
    "eng": "Confirm",
    "hindi": "पुष्टि करें",
    "ja": "保存",
    "uz": "Tasdiqlang",
    "viet": "Xác nhận",
    "ru": "Подтвердить",
    "az": "Təsdiqlə",
    "jp": "確認"
  },
  "confirmation": {
    "arabic": "تأكيد",
    "chinese": "確認",
    "eng": "Confirmation",
    "hindi": "पुष्टि",
    "ja": "確認",
    "uz": "Tasdiqlash",
    "viet": "Xác nhận"
  },
  "go-back": {
    "arabic": "عُد",
    "chinese": "回去",
    "eng": "Go Back",
    "hindi": "वापस जाओ",
    "ja": "戻る",
    "uz": "Ortga qaytish",
    "viet": "Quay lại",
    "ru": "Назад",
    "az": "Geri qayıt",
    "jp": "戻る"
  },
  "class-code": {
    "arabic": "كود الفصل",
    "chinese": "班級代碼",
    "eng": "Class Code",
    "hindi": "कक्षा कोड",
    "ja": "クラスコード",
    "uz": "Sinf kodi",
    "viet": "Mã lớp",
    "ru": "Код класса",
    "az": "Sinif kodu",
    "jp": "クラスコード"
  },
  "share-this-code-with-your-students-to-join-this-class.": {
    "arabic": "شارك هذا الرمز مع طلابك للانضمام إلى هذا الفصل.",
    "chinese": "與您的學生分享此代碼以加入此課程。",
    "eng": "Share this code with your students to join this class.",
    "hindi": "इस कक्षा में शामिल होने के लिए इस कोड को अपने छात्रों के साथ साझा करें।",
    "ja": "このクラスに参加するためのコードを生徒に共有する。",
    "uz": "Ushbu kodni talabalar bilan ulashib, ushbu sinfga qo'shiling.",
    "viet": "Chia sẻ mã này cho học sinh của bạn để tham gia lớp học.",
    "ru": "Поделитесь этим кодом с вашими учениками, чтобы присоединиться к этому классу.",
    "az": "Bu kodu tələbələrinizlə bölüşün və bu sinfə qoşulun.",
    "jp": "このコードを生徒と共有して、このクラスに参加させてください。"
  },
  "share-this-code-with-your-students-to-join-this-class(es).": {
    "arabic": "شارك هذا الرمز مع طلابك للانضمام إلى هذا الفصل.",
    "chinese": "與您的學生分享此代碼以加入此課程。",
    "eng": "Share this code with your students to join this class(es).",
    "hindi": "इस कक्षा में शामिल होने के लिए इस कोड को अपने छात्रों के साथ साझा करें।",
    "ja": "このクラスに参加するためのコードを生徒に共有する。",
    "uz": "Ushbu kurs(lar)ga qo‘shilish uchun ushbu kodni talabalaringizga ulashing.",
    "viet": "Chia sẻ mã này cho học sinh của bạn để tham gia lớp học.",
    "ru": "Поделитесь этим кодом с вашими учениками, чтобы присоединиться к этому(им) классу(ам).",
    "az": "Bu kodu tələbələrinizlə bölüşün və bu sinfə qoşulun.",
    "jp": "このコードを生徒と共有して、このクラスに参加させてください。"
  },
  "join": {
    "arabic": "ينضم",
    "chinese": "加入",
    "eng": "join",
    "hindi": "शामिल होने",
    "ja": "参加する",
    "uz": "qo'shilish",
    "viet": "tham gia"
  },
  "done": {
    "arabic": "منتهي",
    "chinese": "完畢",
    "eng": "Done",
    "hindi": "की गई",
    "ja": "完了",
    "uz": "Bajarildi",
    "viet": "Xong",
    "ru": "Готово",
    "az": "Tamamlandı",
    "jp": "完了"
  },
  "are-you-sure-you-want-to-logout": {
    "arabic": "هل أنت متأكد أنك تريد تسجيل الخروج",
    "chinese": "你確定要退出嗎",
    "eng": "Are you sure you want to logout",
    "hindi": "क्या आप लॉग आउट करना चाहते हैं",
    "ja": "本当にログアウトしますか？",
    "uz": "Haqiqatan ham tizimdan chiqmoqchimisiz",
    "viet": "Bạn có chắc chắn bạn muốn thoát"
  },
  "are-you-sure-you-want-to-reset-this-test": {
    "arabic": "هل أنت متأكد أنك تريد إعادة تعيين هذا الاختبار",
    "chinese": "您确定要重置此测试吗",
    "eng": "Are you sure you want to Reset this test",
    "hindi": "क्या आप वाकई इस परीक्षण को रीसेट करना चाहते हैं",
    "ja": "本当にこのテストをリセットしますか？",
    "uz": "Haqiqatan ham bu testni asl holatiga qaytarmoqchimisiz",
    "viet": "Bạn có chắc chắn bạn muốn đặt lại bài kiểm tra này"
  },
  "logout": {
    "arabic": "تسجيل خروج",
    "chinese": "登出",
    "eng": "Logout",
    "hindi": "लॉग आउट",
    "ja": "ログアウト",
    "uz": "Chiqish",
    "viet": "Đăng xuất"
  },
  "username": {
    "arabic": "اسم المستخدم",
    "chinese": "用戶名",
    "eng": "Username",
    "hindi": "उपयोगकर्ता नाम",
    "ja": "ユーザー名",
    "uz": "Foydalanuvchi nomi",
    "viet": "Tên người dùng"
  },
  "username-should-contain-at-least-3-characters": {
    "arabic": "يجب أن يحتوي اسم المستخدم على 3 أحرف على الأقل",
    "chinese": "用戶名應至少包含 3 個字符",
    "eng": "Username should contain at least 3 characters",
    "hindi": "उपयोगकर्ता नाम में कम से कम 3 अक्षर होने चाहिए",
    "ja": "ユーザー名は3文字以上にする必要があります",
    "uz": "Foydalanuvchi nomi kamida 3 ta belgidan iborat bo'lishi kerak",
    "viet": "Tên người dùng phải chứa ít nhất 3 ký tự"
  },
  "enter-your-email-to-get-a-password-reset-link": {
    "arabic": "أدخل بريدك الإلكتروني للحصول على رابط إعادة تعيين كلمة المرور",
    "chinese": "輸入您的電子郵件以獲取密碼重置鏈接",
    "eng": "Enter your email to get a password reset link",
    "hindi": "पासवर्ड रीसेट लिंक प्राप्त करने के लिए अपना ईमेल दर्ज करें",
    "ja": "メールアドレスを入力して、パスワードリセット用のリンクを取得する",
    "uz": "Parolni tiklash havolasini olish uchun elektron pochtangizni kiriting",
    "viet": "Nhập email của bạn để nhận liên kết đặt lại mật khẩu"
  },
  "sign-in-to-proceed": {
    "arabic": "قم بتسجيل الدخول للمتابعة",
    "chinese": "登錄以繼續",
    "eng": "Sign In to proceed",
    "hindi": "आगे बढ़ने के लिए साइन इन करें",
    "ja": "サインインして続行",
    "uz": "Davom etish uchun tizimga kiring",
    "viet": "Đăng nhập để tiếp tục"
  },
  "email-id": {
    "arabic": "عنوان الايميل",
    "chinese": "電子郵件ID",
    "eng": "Email Id",
    "hindi": "ईमेल आईडी",
    "ja": "メールアドレス",
    "uz": "Elektron pochta identifikatori",
    "viet": "ID email"
  },
  "or-phone-number": {
    "arabic": "أو رقم الهاتف",
    "chinese": "或電話號碼",
    "eng": "or phone number",
    "hindi": "या फोन नंबर",
    "ja": "または電話番号",
    "uz": "yoki telefon raqami",
    "viet": "hoặc số điện thoại"
  },
  "phone-number": {
    "arabic": "رقم التليفون",
    "chinese": "電話號碼",
    "eng": "Phone Number",
    "hindi": "फोन नंबर",
    "ja": "電話番号",
    "uz": "Telefon raqami",
    "viet": "Số điện thoại"
  },
  "enter-password": {
    "arabic": "أدخل كلمة المرور",
    "chinese": "輸入密碼",
    "eng": "Enter Password",
    "hindi": "पास वर्ड दर्ज करें",
    "ja": "パスワードを入力する",
    "uz": "Parolni kiriting",
    "viet": "Nhập mật khẩu"
  },
  "don't-have-an-account": {
    "arabic": "ليس لديك حساب",
    "chinese": "沒有帳號",
    "eng": "Don't have an account",
    "hindi": "खाता नहीं है?",
    "ja": "アカウントを持っていない場合",
    "uz": "Hisobingiz yoʻq",
    "viet": "Không có tài khoản"
  },
  "back-to-login": {
    "arabic": "العودة لتسجيل الدخول",
    "chinese": "回到登入",
    "eng": "Back to login",
    "hindi": "लॉगिन पर वापस",
    "ja": "ログインに戻る",
    "uz": "Kirish sahifasiga qaytish",
    "viet": "quay lại đăng nhập"
  },
  "forgot-password": {
    "arabic": "هل نسيت كلمة السر",
    "chinese": "忘記密碼",
    "eng": "Forgot Password",
    "hindi": "पासवर्ड भूल गए हैं",
    "ja": "パスワードをお忘れですか？",
    "uz": "Parolni unutdingizmi",
    "viet": "Quên mật khẩu"
  },
  "code": {
    "arabic": "شفرة",
    "chinese": "代碼",
    "eng": "Code",
    "hindi": "कोड",
    "ja": "コード",
    "uz": "Kod",
    "viet": "Mã số"
  },
  "click-here-to-showcase-all-of-your-bookmarked-questions-you-may-also-create-a-mock-test-from-here": {
    "arabic": "انقر هنا لعرض جميع أسئلتك المرجعية. يمكنك أيضًا إنشاء اختبار وهمي من هنا",
    "chinese": "單擊此處展示所有已添加書籤的問題。您也可以從此處創建模擬測試",
    "eng": "Click here to showcase all of your bookmarked questions. You may also create a mock test from here",
    "hindi": "अपने बुकमार्क किए गए सभी प्रश्नों को दिखाने के लिए यहां क्लिक करें। आप यहां से मॉक टेस्ट भी बना सकते हैं",
    "ja": "クリックするとブックマークした問題がまとめて表示され、テストを作成することもできます。",
    "uz": "Belgilangan barcha savollaringizni ko'rsatish uchun shu yerni bosing. Bundan tashqari, bu yerdan soxta test yaratishingiz mumkin",
    "viet": "Nhấp vào đây để hiển thị tất cả các câu hỏi được đánh dấu của bạn. Bạn cũng có thể tạo một bài kiểm tra thử từ đây"
  },
  "select-class": {
    "arabic": "فئة مختارة",
    "chinese": "所選班級",
    "eng": "Class Selected",
    "hindi": "कक्षा चयनित",
    "ja": "クラスを選択する",
    "uz": "Sinf tanlangan",
    "viet": "Lớp đã chọn"
  },
  "select-class-google": {
    "arabic": null,
    "chinese": null,
    "eng": "Select Class",
    "hindi": null,
    "ja": null,
    "uz": "Sinfni tanlang",
    "viet": null
  },
  "live-Yet-to-start": {
    "arabic": "مباشر / لم يبدأ بعد",
    "chinese": "生活/尚未開始",
    "eng": "Live / Yet to Start",
    "hindi": "लाइव / अभी तक शुरू करने के लिए",
    "ja": "配信中/未開始",
    "uz": "Jonli / Boshlash uchun hali",
    "viet": "Trực tiếp / Chưa bắt đầu"
  },
  "Draft": {
    "arabic": "مسودة",
    "chinese": "草稿",
    "eng": "Draft",
    "hindi": "प्रारूप",
    "ja": "下書き",
    "uz": "Qoralama",
    "viet": "Bản nháp"
  },
  "live": {
    "arabic": "يعيش",
    "chinese": "居住",
    "eng": "Live",
    "hindi": "लाइव",
    "ja": "配信中",
    "uz": "Jonli",
    "viet": "Sống"
  },
  "pdf-is-being-generated-for-print-please-refresh-the-page-and-retry-in-few-minutes": {
    "arabic": "يتم إنشاء ملف PDF للطباعة. يرجى تحديث الصفحة وإعادة المحاولة في غضون دقائق قليلة",
    "chinese": "正在為打印生成 PDF。請刷新頁面並在幾分鐘後重試",
    "eng": "PDF is being generated for Print. Please refresh the page and retry in few minutes",
    "hindi": "प्रिंट के लिए पीडीएफ तैयार किया जा रहा है। कृपया पृष्ठ को ताज़ा करें और कुछ मिनटों में पुनः प्रयास करें",
    "ja": "印刷用にPDFを生成しています。ページを更新して、数分後に再度お試しください。",
    "uz": "Chop etish uchun PDF yaratilmoqda. Iltimos, sahifani yangilang va bir necha daqiqadan so'ng qayta urinib ko'ring",
    "viet": "PDF đang được tạo để In. Vui lòng làm mới trang và thử lại sau vài phút"
  },
  "no-live-yet-to-start-tests-available-for-this-month": {
    "arabic": "لا تتوفر اختبارات مباشرة / لم تبدأ بعد لهذا الشهر",
    "chinese": "本月沒有實時/尚未開始測試",
    "eng": "No Live / Yet to Start Tests Available for this month",
    "hindi": "इस महीने के लिए कोई लाइव/अभी तक शुरू होने वाले टेस्ट उपलब्ध नहीं हैं",
    "ja": "今月利用可能な配信中/未開始のテストがあります。",
    "uz": "Bu oyda testlarni boshlash uchun jonli/hali mavjud emas",
    "viet": "Chưa tới thời điểm kiểm tra / Chưa thể bắt đầu bài kiểm tra trong tháng này"
  },
  "live-yet-to-start": {
    "arabic": "مباشر / لم يبدأ بعد",
    "chinese": "生活/尚未開始",
    "eng": "Live / Yet to Start",
    "hindi": "लाइव / अभी तक शुरू करने के लिए",
    "ja": "配信中 / 未開始",
    "uz": "Jonli / Boshlash uchun hali",
    "viet": "Đang diễn ra bài kiểm tra / Chưa bắt đầu làm bài"
  },
  "draft": {
    "arabic": "مسودة",
    "chinese": "草稿",
    "eng": "Draft",
    "hindi": "प्रारूप",
    "ja": "下書き",
    "uz": "Qoralama",
    "viet": "Bản nháp"
  },
  "current-mock-test": {
    "arabic": "اختبار وهمي الحالي",
    "chinese": "當前模擬測試",
    "eng": "Current Mock Test",
    "hindi": "वर्तमान मॉक टेस्ट",
    "ja": "現在のテスト",
    "uz": "Joriy sinov sinovi",
    "viet": "Thử nghiệm giả hiện tại"
  },
  "edit-submission": {
    "arabic": "عدل المهمة",
    "chinese": "編輯提交",
    "eng": "Edit Submission",
    "hindi": "सबमिशन संपादित करें",
    "ja": "提出物を編集する",
    "uz": "Taqdimotni tahrirlash",
    "viet": "Chỉnh sửa nội dung gửi"
  },
  "no-draft-exams-available": {
    "arabic": "لا توجد اختبارات اختبارية متاحة",
    "chinese": "沒有可用的草稿考試",
    "eng": "No draft exams available",
    "hindi": "नो-ड्राफ्ट-परीक्षा-उपलब्ध",
    "ja": "下書きのテストはありません",
    "uz": "Hech qanday qoralama imtihonlari mavjud emas",
    "viet": "Không có bài kiểm tra dự thảo nào"
  },
  "yet-to-start": {
    "arabic": "ولكن للبدء",
    "chinese": "尚未開始",
    "eng": "Yet to Start",
    "hindi": "अभी शुरू होना बाकी है",
    "ja": "未開始",
    "uz": "Hali Boshlash uchun",
    "viet": "chưa bắt đầu"
  },
  "completed": {
    "arabic": "مكتمل",
    "chinese": "完全的",
    "eng": "Completed",
    "hindi": "पूरा हुआ",
    "ja": "完了",
    "uz": "Bajarildi",
    "viet": "Hoàn thành"
  },
  "review-questions": {
    "arabic": "راجع الأسئلة",
    "chinese": "檢視問題",
    "eng": "Review Questions",
    "hindi": "समीक्षा प्रश्न",
    "ja": "問題を確認する",
    "uz": "Tekshirish savollari",
    "viet": "câu hỏi ôn tập"
  },
  "see-results": {
    "arabic": "انظر النتائج",
    "chinese": "查看結果",
    "eng": "See Results",
    "hindi": "परिणाम देखें",
    "ja": "結果を確認する",
    "uz": "Natijalarga qarang",
    "viet": "Xem kết quả"
  },
  "view-submission": {
    "arabic": "عرض التقديم",
    "chinese": "查看提交",
    "eng": "View Submission",
    "hindi": "सबमिशन देखें",
    "ja": "提出物を確認する",
    "uz": "Taqdimotni ko'rish",
    "viet": "Xem bài nộp"
  },
  "no-exam-launched-for-this-class": {
    "arabic": "لم يتم بدء اختبار لهذا الفصل",
    "chinese": "這門課沒有考試",
    "eng": "No Exam Launched for this Class",
    "hindi": "इस कक्षा के लिए कोई परीक्षा शुरू नहीं की गई",
    "ja": "このクラスで配信中のテストはありません。",
    "uz": "Bu sinf uchun imtihon boshlanmagan",
    "viet": "Không có kỳ thi nào được khởi chạy cho lớp này"
  },
  "till-then-you-can-explore-other-awesome-things": {
    "arabic": "حتى ذلك الحين يمكنك استكشاف أشياء رائعة أخرى",
    "chinese": "在那之前你可以探索其他很棒的東西",
    "eng": "Till then you can explore other awesome things",
    "hindi": "तब तक आप अन्य भयानक चीजों का पता लगा सकते हैं",
    "ja": "それまでは、他の素晴らしい機能を探究できます",
    "uz": "Ungacha siz boshqa ajoyib narsalarni kashf qilishingiz mumkin",
    "viet": "Cho đến lúc đó bạn có thể khám phá những điều tuyệt vời khác"
  },
  "modify-details": {
    "arabic": "تعديل التفاصيل",
    "chinese": "修改詳細信息",
    "eng": "Modify Details",
    "hindi": "विवरण संशोधित करें",
    "ja": "詳細を変更する",
    "uz": "Ma'lumotlarni o'zgartirish",
    "viet": "Chỉnh sửa chi tiết"
  },
  "modify-student-details": {
    "arabic": "تعديل تفاصيل الطالب",
    "chinese": "修改學生詳細信息",
    "eng": "Modify Student Details",
    "hindi": "छात्र विवरण संशोधित करें",
    "ja": "生徒の詳細を変更する",
    "uz": "O'quvchi ma'lumotlarini o'zgartirish",
    "viet": "Chỉnh sửa chi tiết sinh viên"
  },
  "go-to-practice-mode": {
    "arabic": "انتقل إلى وضع التمرين",
    "chinese": "進入練習模式",
    "eng": "Go to Practice Mode",
    "hindi": "प्रैक्टिस मोड में जाएं",
    "ja": "練習モードへ",
    "uz": "Amaliyot rejimiga o'ting",
    "viet": "Chuyển đến Chế độ luyện tập"
  },
  "practice-mode": {
    "arabic": "وضع الممارسة",
    "chinese": "練習模式",
    "eng": "Practice Mode",
    "hindi": "अभ्यास मोड",
    "ja": "練習モード",
    "uz": "Amaliyot rejimi",
    "viet": "Chế độ luyện tập"
  },
  "test-mode": {
    "arabic": "وضع الاختبار",
    "chinese": "測試模式",
    "eng": "Test Mode",
    "hindi": "परीक्षण मोड",
    "ja": "テストモード",
    "uz": "Sinov rejimi",
    "viet": "Chê độ kiểm tra"
  },
  "instructions-to-select-classes-to-publish-live-test": {
    "arabic": "تعليمات لتحديد الفصول لنشر الاختبار المباشر",
    "chinese": "選擇課程以發布實時測試的說明",
    "eng": "Instructions to select classes to publish live test",
    "hindi": "लाइव टेस्ट प्रकाशित करने के लिए कक्षाओं का चयन करने के निर्देश",
    "ja": "テストを配信するクラスを設定してください",
    "uz": "Jonli testni nashr qilish uchun sinflarni tanlash bo'yicha ko'rsatmalar",
    "viet": "Hướng dẫn chọn lớp công bố bài kiểm tra trực tiếp"
  },
  "instructions-for-test": {
    "arabic": "تعليمات الاختبار",
    "chinese": "測試說明",
    "eng": "Instructions for Test",
    "hindi": "टेस्ट के लिए निर्देश",
    "ja": "テストの指示",
    "uz": "Sinov uchun ko'rsatmalar",
    "viet": "hướng dẫn làm kiểm tra"
  },
  "move-to-live": {
    "arabic": "تحرك للعيش",
    "chinese": "搬家住",
    "eng": "Move to live",
    "hindi": "जीने के लिए आगे बढ़ें",
    "ja": "配信する",
    "uz": "Yashash uchun harakatlaning",
    "viet": "chuyển thành trực tiếp"
  },
  "you-about-to-move-this-draft-version-of-the-test-into-yet-to-start-state-here-are-some-of-default-settings": {
    "arabic": "أنت على وشك نقل نسخة المسودة للاختبار إلى الحالة لم يبدأ بعد. فيما يلي بعض الإعدادات الافتراضية",
    "chinese": "您即將將該測試的草稿版本移至“尚未開始”狀態。以下是一些默認設置",
    "eng": "You are about to move this draft version of the test into 'Yet to start' State. Here are some of default settings",
    "hindi": "आप परीक्षण के इस ड्राफ़्ट संस्करण को 'अभी शुरू होना बाकी है' स्थिति में ले जाने वाले हैं. यहां कुछ डिफ़ॉल्ट सेटिंग्स दी गई हैं",
    "ja": "この下書きを「未開始」に移行します。必要な条件を設定してください",
    "uz": "Siz testning ushbu qoralama versiyasini “Hali boshlanmadi” holatiga ko‘chirmoqchisiz. Bu erda bir nechta standart sozlamalar mavjud",
    "viet": "Bạn sắp chuyển phiên bản nháp của bài kiểm tra này sang Trạng thái &#39;Chưa bắt đầu&#39;. Dưới đây là một số cài đặt mặc định"
  },
  "click-on-the-text-field-to-edit-instructions": {
    "arabic": "انقر فوق حقل النص لتعديل التعليمات",
    "chinese": "單擊文本字段以編輯說明",
    "eng": "Click on the text field to edit instructions",
    "hindi": "निर्देशों को संपादित करने के लिए टेक्स्ट फ़ील्ड पर क्लिक करें",
    "ja": "テキストフィールドをクリックして指示を編集する",
    "uz": "Ko'rsatmalarni tahrirlash uchun matn maydonini bosing",
    "viet": "Nhấp vào trường văn bản để chỉnh sửa hướng dẫn"
  },
  "classlist-to-remove": {
    "arabic": "Classlist للإزالة",
    "chinese": "要刪除的類列表",
    "eng": "Classlist to remove",
    "hindi": "निकालने के लिए वर्गसूची",
    "ja": "配信しないクラス",
    "uz": "O'chirish uchun sinf ro'yxati",
    "viet": "Danh sách lớp cần xóa"
  },
  "classlist-to-publish": {
    "arabic": "قائمة الفصل للنشر",
    "chinese": "要發布的班級列表",
    "eng": "Classlist to publish",
    "hindi": "प्रकाशित करने के लिए कक्षा सूची",
    "ja": "配信するクラス",
    "uz": "Nashr qilinadigan sinflar roʻyxati",
    "viet": "Danh sách lớp để xuất bản"
  },
  "enter-the-text-filed-or-reset-it": {
    "arabic": "أدخل النص المودع أو أعد تعيينه",
    "chinese": "輸入提交的文本或重置它",
    "eng": "Enter the text filed or reset it",
    "hindi": "दायर पाठ दर्ज करें या इसे रीसेट करें",
    "ja": "テキストフィールドを入力またはリセットする",
    "uz": "Taqdim etilgan matnni kiriting yoki uni qayta o'rnating",
    "viet": "Nhập văn bản đã gửi hoặc đặt lại"
  },
  "publish-to-multiple-classes": {
    "arabic": "انشر في فئات متعددة",
    "chinese": "發佈到多個類",
    "eng": "Publish to Multiple Classes",
    "hindi": "एकाधिक कक्षाओं में प्रकाशित करें",
    "ja": "複数のクラスに配信する",
    "uz": "Bir nechta sinflarga nashr qilish",
    "viet": "Xuất bản lên nhiều lớp"
  },
  "click-here": {
    "arabic": "انقر هنا",
    "chinese": "點擊這裡",
    "eng": "Click here",
    "hindi": "यहां क्लिक करें",
    "ja": "ここをクリック",
    "uz": "bu yerni bosing",
    "viet": "Bấm vào đây"
  },
  "to-select-classes": {
    "arabic": "لاختيار الفئات",
    "chinese": "選擇班級",
    "eng": "to select classes",
    "hindi": "कक्षाओं का चयन करने के लिए",
    "ja": "してクラスを選択",
    "uz": "sinflarni tanlash uchun",
    "viet": "để chọn các lớp học"
  },
  "enable-test-with-timer": {
    "arabic": "تمكين الاختبار مع الموقت",
    "chinese": "启用计时器测试",
    "eng": "Enable Test with timer",
    "hindi": "टाइमर के साथ परीक्षण सक्षम करें",
    "ja": "テストに制限時間を設ける",
    "uz": "Taymer bilan testni yoqish",
    "viet": "Bật Kiểm tra bằng bộ đếm thời gian"
  },
  "enable-test-with-re-attempts": {
    "arabic": "تمكين الاختبار مع إعادة المحاولات",
    "chinese": "启用重新尝试测试",
    "eng": "Enable Test with Re-attempts",
    "hindi": "पुनः प्रयास के साथ परीक्षण सक्षम करें",
    "ja": "テストのリトライを有効にする。",
    "uz": "Qayta urinishlar bilan sinovni yoqing",
    "viet": "Bật thử nghiệm với số lần thử lại"
  },
  "set-duration": {
    "arabic": "تحديد المدة",
    "chinese": "设置持续时间",
    "eng": "Set duration",
    "hindi": "अवधि निर्धारित करें",
    "ja": "制限時間を設定する",
    "uz": "Davomiylikni belgilang",
    "viet": "Đặt thời lượng"
  },
  "to-set-duration": {
    "arabic": "لتعيين المدة",
    "chinese": "设置持续时间",
    "eng": "to set duration",
    "hindi": "अवधि निर्धारित करने के लिए",
    "ja": "して設定",
    "uz": "davomiyligini belgilash uchun",
    "viet": "để đặt thời lượng"
  },
  "click-here-to-set-number-of-re-attempts": {
    "arabic": "انقر هنا لتعيين عدد من المحاولات",
    "chinese": "點擊這裡設置重新嘗試次數",
    "eng": "Click here to set number of re-attempts",
    "hindi": "पुनः प्रयासों की संख्या निर्धारित करने के लिए यहां क्लिक करें",
    "ja": "リトライ回数を設定するにはここをクリック",
    "uz": "Qayta urinishlar sonini belgilash uchun shu yerni bosing",
    "viet": "Nhấp vào đây để đặt số lần thử lại"
  },
  "to-set-number-of-re-attempts": {
    "arabic": "لتعيين عدد من المحاولات",
    "chinese": "设置重新尝试的次数",
    "eng": "to set number of re-attempts",
    "hindi": null,
    "ja": "再試行回数を設定するには",
    "uz": "qayta urinishlar sonini belgilash uchun",
    "viet": "để đặt số lần thử lại"
  },
  "re-attempts": {
    "arabic": "إعادة المحاولات",
    "chinese": "重新尝试",
    "eng": "re-attempts",
    "hindi": null,
    "ja": "再試行",
    "uz": "qayta urinishlar",
    "viet": "thử lại"
  },
  "set-number-of-re-attempts": {
    "arabic": "تعيين عدد من إعادة المحاولات",
    "chinese": "重新尝试",
    "eng": "Set number of re-attempts",
    "hindi": null,
    "ja": "再試行回数を設定する",
    "uz": "Qayta urinishlar sonini belgilang",
    "viet": "thiết lập số lần thử lại"
  },
  "attempt-count-must-be-between-1-and-100": {
    "arabic": "يجب أن يكون عدد المحاولات بين 1 و100",
    "chinese": "尝试次数必须介于 1 到 100 之间",
    "eng": "Attempt count must be between 1 and 100",
    "hindi": null,
    "ja": "試行回数は 1 ～ 100 でなければなりません",
    "uz": "Urinishlar soni 1 dan 100 gacha bo'lishi kerak",
    "viet": "Số lần thử phải nằm trong khoảng từ 1 đến 100"
  },
  "ai-powered": {
    "arabic": "مدعوم بالذكاء الاصطناعي",
    "chinese": "人工智能驱动",
    "eng": "AI Powered",
    "hindi": null,
    "ja": "AI を活用した",
    "uz": "AI quvvatlanadi",
    "viet": "Hỗ trợ AI"
  },
  "basic": {
    "arabic": "أساسي",
    "chinese": "基本的",
    "eng": "Basic",
    "hindi": null,
    "ja": "基本",
    "uz": "Asosiy",
    "viet": "Nền tảng"
  },
  "to-edit-instructions-to-be-provided-on-paper": {
    "arabic": "لتحرير الصفحة الأولى والتعليمات",
    "chinese": "编辑首页和说明",
    "eng": "to edit Instructions to be provided on Paper",
    "hindi": "प्रथम पृष्ठ और निर्देशों को संपादित करने के लिए",
    "ja": "して表紙と説明を編集する",
    "uz": "Qog'ozda taqdim etiladigan ko'rsatmalarni tahrirlash",
    "viet": "Để chỉnh sửa Trang đầu & Hướng dẫn"
  },
  "note": {
    "arabic": "ملحوظة",
    "chinese": "筆記",
    "eng": "Note",
    "hindi": "टिप्पणी",
    "ja": "備考",
    "uz": "Eslatma",
    "viet": "Ghi chú",
    "ru": "Заметка",
    "az": "Qeyd",
    "jp": "ノート"
  },
  "contact-support": {
    "arabic": "اتصل بالدعم",
    "chinese": "联系支持",
    "eng": "Contact Support",
    "hindi": "समर्थन से संपर्क करें",
    "ja": "サポートに連絡する",
    "uz": "Qo'llab-quvvatlash bilan bog'lanish",
    "viet": "Liên hệ hỗ trợ"
  },
  "email-id-username" :{
    "arabic": "البريد الإلكتروني / اسم المستخدم",
    "chinese": "电子邮件/用户名",
    "eng": "Email ID / Username",
    "hindi": "ईमेल आईडी/उपयोगकर्ता नाम",
    "ja": "メールアドレス/ユーザー名",
    "uz": "Elektron pochta / Foydalanuvchi nomi",
    "viet": "ID Email / Tên người dùng"
  },
  "this-test-can-be-published-to-only-classes-of-the-same-grade-if-you-wish-to-publish-to-classes-of-different-grades-please-contact-customer-support": {
    "arabic": "يمكن نشر هذا الاختبار على فصول من نفس الدرجة فقط. إذا كنت ترغب في النشر إلى فئات من درجات مختلفة , فيرجى الاتصال بدعم العملاء",
    "chinese": "該測試只能發布給同年級的班級。如果您想發佈到不同年級的班級,請聯繫客戶支持",
    "eng": "This Test can be published to only classes of the same grade. If you wish to publish to classes of different grades, please contact customer support",
    "hindi": "यह परीक्षा केवल उसी ग्रेड की कक्षाओं के लिए प्रकाशित की जा सकती है। यदि आप विभिन्न ग्रेड की कक्षाओं में प्रकाशित करना चाहते हैं, तो कृपया ग्राहक सहायता से संपर्क करें",
    "ja": "このテストは同学年にのみ配信が可能です。異なる学年に配信を希望する場合は、カスタマーサポートまでお問い合わせください",
    "uz": "Ushbu test faqat bir xil sinflar uchun e'lon qilinishi mumkin. Agar siz turli darajadagi sinflarga nashr qilmoqchi bo'lsangiz, mijozlarni qo'llab-quvvatlash xizmatiga murojaat qiling",
    "viet": "Bài kiểm tra này chỉ có thể được công bố cho các lớp cùng khối. Nếu bạn muốn xuất bản lên các lớp có cấp độ khác nhau, vui lòng liên hệ với bộ phận hỗ trợ khách hàng"
  },
  "click-on-default-to-switch-back-to-cerebry's-generic-instructions": {
    "arabic": "انقر فوق افتراضي للعودة إلى التعليمات العامة لـ Cerebry",
    "chinese": "點擊 Default 切換回 Cerebry 的 Generic Instructions",
    "eng": "Click on Default to switch back to Cerebry's Generic Instructions",
    "hindi": "सेरेब्री के सामान्य निर्देशों पर वापस जाने के लिए डिफ़ॉल्ट पर क्लिक करें",
    "ja": "「デフォルト」をクリックし、初期設定の説明に戻す。",
    "uz": "Cerebry-ning umumiy ko'rsatmalariga qaytish uchun Standart-ni bosing",
    "viet": "Nhấp vào Mặc định để quay lại Hướng dẫn chung của Cerebry"
  },
  "default": {
    "arabic": "تقصير",
    "chinese": "默認",
    "eng": "Default",
    "hindi": "चूक जाना",
    "ja": "デフォルトに戻す",
    "uz": "Standart",
    "viet": "Mặc định"
  },
  "save": {
    "arabic": "يحفظ",
    "chinese": "節省",
    "eng": "Save",
    "hindi": "बचाना",
    "ja": "保存",
    "uz": "Saqlash",
    "viet": "Lưu",
    "ru": "Сохранить",
    "az": "Yadda saxla",
    "jp": "保存"
  },
  "correct": {
    "arabic": "صحيح",
    "chinese": "正確的",
    "eng": "Correct",
    "hindi": "सही",
    "ja": "正解",
    "uz": "To'g'ri",
    "viet": "Chính xác"
  },
  "incorrect": {
    "arabic": "غير صحيح",
    "chinese": "不正确",
    "eng": "Incorrect",
    "hindi": "ग़लत",
    "ja": "不正解",
    "uz": "Noto'g'ri",
    "viet": "Không đúng"
  },
  "partly-correct": {
    "arabic": "صحيح جزئيا",
    "chinese": "部分正確",
    "eng": "Partly Correct",
    "hindi": "आंशिक रूप से सही",
    "ja": "部分点",
    "uz": "Qisman to'g'ri",
    "viet": "Đúng một phần"
  },
  "partly-marked": {
    "arabic": "ملحوظ جزئيا",
    "chinese": "部分標記",
    "eng": "Partly marked",
    "hindi": "आंशिक रूप से चिह्नित",
    "ja": "部分点",
    "uz": "Qisman belgilangan",
    "viet": "đánh dấu một phần"
  },
  "wrong": {
    "arabic": "خطأ",
    "chinese": "錯誤的",
    "eng": "Wrong",
    "hindi": "गलत",
    "ja": "不正解",
    "uz": "Noto'g'ri",
    "viet": "Sai"
  },
  "correct-answer": {
    "arabic": "اجابة صحيحة",
    "chinese": "正確答案",
    "eng": "Correct Answer",
    "hindi": "सही उत्तर",
    "ja": "正答",
    "uz": "To'g'ri javob",
    "viet": "Câu trả lời chính xác"
  },
  "general-instructions": {
    "arabic": "تعليمات عامة",
    "chinese": "一般說明",
    "eng": "General Instructions",
    "hindi": "सामान्य निर्देश",
    "ja": "テストの説明",
    "uz": "Umumiy ko'rsatmalar",
    "viet": "Hướng dẫn chung"
  },
  "make-sure-to-click-'submit-answer'-for-every-question-Forgetting-to-do-so-will-result-in-the-question-being-marked-as-unanswered": {
    "arabic": "تأكد من النقر فوق إرسال إجابة لكل سؤال. سيؤدي نسيان القيام بذلك إلى وضع علامة على السؤال بأنه بلا إجابة.",
    "chinese": "確保為每個問題單擊“提交答案”。忘記這樣做會導致問題被標記為未回答。",
    "eng": "Make sure to click 'Submit Answer' for every question. Forgetting to do so will result in the question being marked as unanswered.",
    "hindi": "हर प्रश्न के लिए 'जवाब सबमिट करें' पर क्लिक करना सुनिश्चित करें। ऐसा करने में भूल करने का परिणाम होगा प्रश्न को अनुत्तरित के रूप में चिह्नित किया जा रहा है।",
    "ja": "一問ずつ「回答を送信」をクリックしてください。クリックしないと、未回答として採点されます。",
    "uz": "Har bir savol uchun “Javob yuborish” tugmasini bosganingizga ishonch hosil qiling. Buni unutib qo'ysangiz, savol javobsiz deb belgilanishiga olib keladi.",
    "viet": "Hãy chắc chắn rằng bạn đã bấm “Trả lời“ cho mọi câu hỏi. Nếu không, các câu hỏi sẽ bị đánh dấu là chưa làm"
  },
  "are-you-sure-you-want-to-make-this-exam-live-for-students": {
    "arabic": "هل أنت متأكد من أنك تريد جعل هذا الاختبار مباشرًا للطلاب",
    "chinese": "您確定要為學生直播此考試嗎",
    "eng": "Are you sure you want to make this exam live for students",
    "hindi": "क्या आप वाकई इस परीक्षा को छात्रों के लिए लाइव बनाना चाहते हैं",
    "ja": "このテストを生徒に配信してもよろしいですか？",
    "uz": "Haqiqatan ham bu imtihonni talabalar uchun jonli o'tkazmoqchimisiz",
    "viet": "Bạn có chắc chắn muốn chuyển bài kiểm tra này thành trực tiếp?"
  },
  "click-here-to-load-this-question": {
    "arabic": "انقر هنا لتحميل هذا السؤال",
    "chinese": "單擊此處加載此問題",
    "eng": "Click here to load this question",
    "hindi": "इस प्रश्न को लोड करने के लिए यहां क्लिक करें",
    "ja": "クリックしてこの問題を読み込む",
    "uz": "Bu savolni yuklash uchun shu yerni bosing",
    "viet": "Nhấn vào đây để tải câu hỏi này"
  },
  "click-here-to-load-this": {
    "arabic": "انقر هنا لتحميل هذا",
    "chinese": "點擊此處加載",
    "eng": "Click here to load this",
    "hindi": "इस को लोड करने के लिए",
    "ja": "読み込む",
    "uz": "Buni yuklash uchun shu yerni bosing",
    "viet": "Nhấn vào đây để tải cái này"
  },
  "settings": {
    "arabic": "إعدادات",
    "chinese": "設置",
    "eng": "Settings",
    "hindi": "समायोजन",
    "ja": "設定",
    "uz": "Sozlamalar",
    "viet": "Cài đặt"
  },
  "your-last-update-on": {
    "arabic": "آخر تحديث لك في",
    "chinese": "您的最後更新時間",
    "eng": "Your last update on",
    "hindi": "आपका आखिरी अपडेट चालू है",
    "ja": "最終更新",
    "uz": "Sizning oxirgi yangilanishingiz",
    "viet": "Cập nhật cuối cùng vào"
  },
  "update": {
    "arabic": "تحديث",
    "chinese": "更新",
    "eng": "Update",
    "hindi": "अपडेट",
    "ja": "更新",
    "uz": "Yangilash",
    "viet": "Cập nhật"
  },
  "Updating...":{
    "arabic": "تحديث...",
    "chinese": "更新中...",
    "eng": "Updating...",
    "hindi": "अपडेट हो रहा है...",
    "ja": "更新中...",
    "uz": "Yangilanmoqda...",
    "viet": "Đang cập nhật..."
  },
  "open-Exam-on": {
    "arabic": "فتح الامتحان في",
    "chinese": "開考時間",
    "eng": "Open Exam On",
    "hindi": "ओपन परीक्षा चालू",
    "ja": "テストの配信日を設定",
    "uz": "Imtihonni oching",
    "viet": "Bài kiểm tra Mở"
  },
  "open-student-access-on": {
    "arabic": "فتح وصول الطالب",
    "chinese": "打開學生訪問",
    "eng": "Open Student Access On",
    "hindi": "ओपन स्टूडेंट एक्सेस ऑन",
    "ja": "生徒への配信開始日を設定",
    "uz": "Talaba ruxsatini oching",
    "viet": "Cấp quyền truy cập của sinh viên Bật"
  },
  "no-schedule-set": {
    "arabic": "لم يتم تحديد جدول زمني",
    "chinese": "未設定時間表",
    "eng": "No schedule set",
    "hindi": "कोई शेड्यूल सेट नहीं है",
    "ja": "配信日時が未設定です",
    "uz": "Jadval sozlanmagan",
    "viet": "Chưa có sự kiện nào"
  },
  "open-results-on": {
    "arabic": "فتح النتائج في",
    "chinese": "打開結果",
    "eng": "Open Results On",
    "hindi": "परिणाम खोलें",
    "ja": "結果の配信日を設定",
    "uz": "Natijalarni oching",
    "viet": "Mở kết quả trong"
  },
  "marks-out-of": {
    "arabic": "علامات من",
    "chinese": "標記出",
    "eng": "marks out of",
    "hindi": "से चिन्हित करता है",
    "ja": "点 ※最大",
    "uz": "dan belgilaydi",
    "viet": "đánh dấu"
  },
  "scored": {
    "arabic": "سجل",
    "chinese": "得分",
    "eng": "Scored",
    "hindi": "रन बनाए",
    "ja": "得点",
    "uz": "Gol urdi",
    "viet": "ghi bàn"
  },
  "out-of": {
    "arabic": "بعيدا عن المكان",
    "chinese": "在......之外",
    "eng": "out of",
    "hindi": "से चिन्हित करता है",
    "ja": "/",
    "uz": "tashqarida",
    "viet": "ra khỏi"
  },
  "marks": {
    "arabic": "علامات",
    "chinese": "分數",
    "eng": "marks",
    "hindi": "निशान",
    "ja": "点",
    "uz": "belgilar",
    "viet": "điểm"
  },
  "mark(s)": {
    "arabic": "علامة (ق)",
    "chinese": "分數)",
    "eng": "mark(s)",
    "hindi": "निशान",
    "ja": "点",
    "uz": "belgi(lar)",
    "viet": "Điểm"
  },
  "mark": {
    "arabic": "علامة",
    "chinese": "標記",
    "eng": "mark",
    "hindi": "निशान",
    "ja": "点",
    "uz": "belgi",
    "viet": "đánh dấu"
  },
  "unattempted": {
    "arabic": "غير محاولة",
    "chinese": "未嘗試",
    "eng": "Unattempted",
    "hindi": "अप्रयास",
    "ja": "未解答",
    "uz": "Urinishsiz",
    "viet": "Không thử"
  },
  "skipped": {
    "arabic": "تم تخطيه",
    "chinese": "跳過",
    "eng": "skipped",
    "hindi": "छोड़ा गया",
    "ja": "スキップ",
    "uz": "o'tkazib yubordi",
    "viet": "bỏ qua"
  },
  "students-in-the-class": {
    "arabic": "الطلاب في الفصل",
    "chinese": "班上的學生",
    "eng": "students in the class",
    "hindi": "कक्षा में छात्र",
    "ja": "人の生徒がこのクラスに所属しています",
    "uz": "sinfdagi talabalar",
    "viet": "Học sinh trong lớp"
  },
  "students": {
    "arabic": "طلاب",
    "chinese": "學生",
    "eng": "Students",
    "hindi": "छात्र",
    "ja": "名の生徒",
    "uz": "Talabalar",
    "viet": "Học sinh"
  },
  "student": {
    "arabic": "طالب",
    "chinese": "學生",
    "eng": "Students",
    "hindi": "छात्र",
    "ja": "生徒",
    "uz": "Talabalar",
    "viet": "Học sinh"
  },
  "Student": {
    "arabic": "طالب",
    "chinese": "學生",
    "eng": "Student",
    "hindi": "छात्र",
    "ja": "生徒",
    "uz": "Talaba",
    "viet": "Học sinh"
  },
  "Selected-Student-Details": {
    "arabic": "تفاصيل الطالب المختارة",
    "chinese": "所选学生详细信息",
    "eng": "Selected Student Details",
    "hindi": "चयनित छात्र विवरण",
    "ja": "選択された学生の詳細",
    "uz": "Tanlangan talaba tafsilotlari",
    "viet": "Thông tin chi tiết về sinh viên đã chọn"
  },
  "No-student-found": {
    "arabic": "لم يتم العثور على أي طالب",
    "chinese": "没有找到学生",
    "eng": "No student found",
    "hindi": "कोई छात्र नहीं मिला",
    "ja": "生徒が見つかりませんでした",
    "uz": "Hech qanday talaba topilmadi",
    "viet": "Không tìm thấy học sinh nào"
  },
  "Search": {
    "arabic": "يبحث",
    "chinese": "搜索",
    "eng": "Search",
    "hindi": "खोज",
    "ja": "検索",
    "uz": "Qidirmoq",
    "viet": "Tìm kiếm"
  },
  "sort-by-score": {
    "arabic": "فرز حسب النقاط",
    "chinese": "按分數排序",
    "eng": "Sort by Score",
    "hindi": "स्कोर द्वारा क्रमबद्ध करें",
    "ja": "得点順",
    "uz": "Ballar bo'yicha saralash",
    "viet": "Sắp xếp theo Điểm"
  },
  "(ASC)": {
    "arabic": "(تصاعدي)",
    "chinese": "(升序)",
    "eng": "(ASC)",
    "hindi": "(एससी)",
    "ja": "(昇順)",
    "uz": "(ASC)",
    "viet": "(Tăng dần)"
  },
  "(DESC)": {
    "arabic": "(تنازلي)",
    "chinese": "(降序)",
    "eng": "(DESC)",
    "hindi": "(डेस्क)",
    "ja": "(降順)",
    "uz": "(DESC)",
    "viet": "(Giảm dần)"
  },
  "sort-by-first-name": {
    "arabic": "فرز حسب الاسم الأول",
    "chinese": "按名字排序",
    "eng": "Sort by First name",
    "hindi": "पहले नाम से क्रमबद्ध करें",
    "ja": "名前（名）で並び替え",
    "uz": "Ism bo'yicha saralash",
    "viet": "Sắp xếp theo Tên"
  },
  "sort-by-last-name": {
    "arabic": "فرز حسب الاسم الأخير",
    "chinese": "按姓氏排序",
    "eng": "Sort by Last name",
    "hindi": "अंतिम नाम से क्रमबद्ध करें",
    "ja": "名前（姓）で並び替え",
    "uz": "Familiya bo'yicha saralash",
    "viet": "Sắp xếp theo Họ"
  },
  "low-to-high-score": {
    "arabic": "درجة منخفضة إلى عالية",
    "chinese": "從低到高分",
    "eng": "Low to High Score",
    "hindi": "निम्न से उच्च स्कोर",
    "ja": "得点順（低→高）",
    "uz": "Pastdan yuqori ballgacha",
    "viet": "Điểm từ thấp đến cao"
  },
  "high-to-low-score": {
    "arabic": "درجة عالية إلى منخفضة",
    "chinese": "從高分到低分",
    "eng": "High to Low Score",
    "hindi": "उच्च से निम्न स्कोर",
    "ja": "得点順（高→低）",
    "uz": "Yuqoridan pastgacha ball",
    "viet": "Điểm từ cao đến thấp"
  },
  "score": {
    "arabic": "نتيجة",
    "chinese": "分數",
    "eng": "Score",
    "hindi": "अंक",
    "ja": "得点",
    "uz": "Xol",
    "viet": "Điểm"
  },
  "question-attempted": {
    "arabic": "حاول السؤال",
    "chinese": "問題嘗試",
    "eng": "Question Attempted",
    "hindi": "प्रश्न प्रयास किया गया",
    "ja": "解答数",
    "uz": "Savolga urinish",
    "viet": "Câu hỏi đã trả lời"
  },
  "correctly-solved": {
    "arabic": "تم حلها بشكل صحيح",
    "chinese": "正確解決",
    "eng": "Correctly Solved",
    "hindi": "सही ढंग से हल किया हुआ",
    "ja": "正答数",
    "uz": "To'g'ri hal qilingan",
    "viet": "Trả lời đúng"
  },
  "wrong-attempt": {
    "arabic": "محاولة خاطئة",
    "chinese": "錯誤的嘗試",
    "eng": "Wrong Attempt",
    "hindi": "गलत प्रयास",
    "ja": "誤答数",
    "uz": "Noto'g'ri urinish",
    "viet": "Trả lời sai"
  },
  "time-taken": {
    "arabic": "الوقت المستغرق",
    "chinese": "所用的時間",
    "eng": "Time Taken",
    "hindi": "समय लगेगा",
    "ja": "かかった時間",
    "uz": "Olingan vaqt",
    "viet": "Thời gian làm bài"
  },
  "the-timing-cannot-be-changed-since-one-or-more-students-are-taking-the-test-live-right-now-if-you-wish-to-change-please-wait-for-until-the-test-is-completed-at-the-student-end": {
    "arabic": "لا يمكن تغيير التوقيت نظرًا لأن طالبًا واحدًا أو أكثر يجرون الاختبار مباشرةً الآن. إذا كنت ترغب في التغيير , يرجى الانتظار حتى يكتمل الاختبار عند نهاية الطالب",
    "chinese": "由於一名或多名學生正在現場參加考試,因此時間無法更改。如需更改,請等待學生端測試完成",
    "eng": "The timing cannot be changed since one or more students are taking the test live right now. If you wish to change, please wait for until the test is completed at the student end",
    "hindi": "समय नहीं बदला जा सकता क्योंकि अभी एक या अधिक छात्र लाइव परीक्षा दे रहे हैं। यदि आप बदलना चाहते हैं, तो कृपया छात्र के अंत में परीक्षण पूरा होने तक प्रतीक्षा करें",
    "ja": "現在受験中の生徒がいるため、スケジュールを変更することができません。変更する場合は、生徒がテストを終了するまでお待ちください",
    "uz": "Vaqtni o‘zgartirib bo‘lmaydi, chunki hozir bir yoki bir nechta talaba jonli ravishda test topshirmoqda. Agar siz o'zgartirishni xohlasangiz, talaba oxirida test tugashini kuting",
    "viet": "Không thể thay đổi thời gian làm bài vì hiện tại có một hoặc nhiều học sinh đang làm bài kiểm tra trực tiếp. Nếu bạn muốn thay đổi, vui lòng đợi cho đến khi bài kiểm tra hoàn thành khi học sinh làm bài xong"
  },
  "student-access-would-be-turned-off-due-to-future-date-clear-all-dates-to-open-student-access-now": {
    "arabic": "سيتم إيقاف وصول الطلاب بسبب التاريخ المستقبلي. امسح جميع التواريخ لفتح وصول الطالب الآن",
    "chinese": "由於未來的日期,學生訪問將被關閉。清除所有日期以立即打開學生訪問",
    "eng": "Student access would be turned off due to future date. Clear all dates to Open Student Access now",
    "hindi": "भविष्य की तारीख के कारण छात्र की पहुंच बंद कर दी जाएगी। छात्र प्रवेश खोलने के लिए अभी सभी तिथियां साफ़ करें",
    "ja": "未来の日付が設定されているため、生徒のアクセスができなくなります。今すぐ生徒のアクセスを再開するには、全ての日付を消去してください。",
    "uz": "Kelgusi sana tufayli talabalarga kirish oʻchirib qoʻyiladi. Talaba ruxsatini ochish uchun barcha sanalarni tozalang",
    "viet": "Quyền truy cập của sinh viên sẽ bị tắt do các sự kiện trong tương lai. Xóa tất cả các sự kiện để Mở quyền truy cập của học sinh ngay"
  },
  "do-you-want-to-turn-the-student-access-on-when-open-student-access-date-is-already-set": {
    "arabic": "هل تريد تشغيل وصول الطالب عند تعيين تاريخ فتح وصول الطالب بالفعل",
    "chinese": "如果已設置開放學生訪問日期,是否要打開學生訪問",
    "eng": "Do you want to turn the Student Access on when Open Student Access Date is already set",
    "hindi": "जब ओपन स्टूडेंट एक्सेस डेट पहले से ही सेट हो, तो क्या आप स्टूडेंट एक्सेस को चालू करना चाहते हैं",
    "ja": "生徒への配信と同日に、生徒のアクセス権限も付与しますか？",
    "uz": "Ochiq talaba kirish sanasi allaqachon belgilangan bo‘lsa, Talaba ruxsatini yoqmoqchimisiz",
    "viet": "Bạn có muốn bật Quyền truy cập của học sinh khi Ngày cấp quyền truy cập của học sinh đã được đặt?"
  },
  "few-or-more-students-have-started-the-exam-are-you-sure-you-want-to-save": {
    "arabic": "بدأ الاختبار عدد قليل أو أكثر من الطلاب , فهل تريد بالتأكيد الحفظ",
    "chinese": "少數或更多的學生已經開始考試,你確定要保存嗎",
    "eng": "Few or more students have started the exam, are you sure you want to save",
    "hindi": "कुछ या अधिक छात्रों ने परीक्षा शुरू की है, क्या आप सुनिश्चित हैं कि आप बचत करना चाहते हैं",
    "ja": "生徒が既に受験を開始しています。変更を保存してもよろしいですか？",
    "uz": "Bir necha yoki undan ortiq talaba imtihonni boshladi, saqlamoqchimisiz",
    "viet": "Một hoặc nhiều học sinh đã bắt đầu làm bài kiểm tra, bạn có chắc chắn muốn lưu chứ?"
  },
  "please-select-a-open-student-access-on-date-after-the-open-exam-on-date": {
    "arabic": "يرجى تحديد تاريخ فتح وصول الطالب بعد الاختبار المفتوح في التاريخ",
    "chinese": "請在配信考試日期之後選擇一個開放學生訪問日期",
    "eng": "Please select a Open Student Access On date after the Open Exam on date",
    "hindi": "कृपया ओपन परीक्षा ऑन डेट के बाद ओपन स्टूडेंट एक्सेस ऑन डेट चुनें",
    "ja": "生徒へのアクセス権限付与日は、テスト配信日より後に設定してください。",
    "uz": "Iltimos, Ochiq imtihondan keyin Ochiq Talabalar Kirish sanasini tanlang",
    "viet": "Vui lòng đặt ngày Mở quyền Truy cập cho Học sinh sau ngày Mở quyền Truy cập vào kỳ thi"
  },
  "please-select-a-close-student-access-on-date-after-the-open-exam-on-date": {
    "arabic": "يرجى تحديد تاريخ إغلاق وصول الطالب بعد فتح الامتحان في التاريخ",
    "chinese": "請在開放考試日期之後選擇關閉學生訪問日期",
    "eng": "Please select a Close Student Access On date after the Open Exam on date",
    "hindi": "कृपया ओपन परीक्षा ऑन डेट के बाद क्लोज स्टूडेंट एक्सेस ऑन डेट चुनें",
    "ja": "生徒のアクセス権限停止日は、テスト配信日より後に設定してください。",
    "uz": "Iltimos, Ochiq imtihondan keyin talabalarga kirishni yopish sanasini tanlang",
    "viet": "Vui lòng chọn một Close Student Access On date after the Open Exam on date"
  },
  "open-student-access-now": {
    "arabic": "افتح وصول الطالب الآن",
    "chinese": "立即開放學生訪問",
    "eng": "Open Student Access now",
    "hindi": "विद्यार्थी प्रवेश अभी खोलें",
    "ja": "生徒へのアクセス権限を今すぐ付与する",
    "uz": "Talaba ruxsatini hozir oching",
    "viet": "Mở quyền truy cập của sinh viên ngay bây giờ"
  },
  "toggle-on-to-enable-access-to-test-now": {
    "arabic": "قم بالتبديل إلى تشغيل لتمكين الوصول للاختبار الآن",
    "chinese": "開啟以啟用訪問權限以立即進行測試",
    "eng": "Toggle On to enable access to test now",
    "hindi": "परीक्षण के लिए अभी पहुंच सक्षम करने के लिए टॉगल चालू करें",
    "ja": "オンに切り替えて、今すぐテストへアクセスを可能にする",
    "uz": "Hozir sinovdan oʻtish uchun ruxsatni yoqish uchun yoqing",
    "viet": "Bật để cho phép truy cập vào bài kiểm tra"
  },
  "student-access": {
    "arabic": "وصول الطالب",
    "chinese": "學生訪問",
    "eng": "Student Access",
    "hindi": "छात्र प्रवेश",
    "ja": "生徒のアクセス権限",
    "uz": "Talabalarga kirish",
    "viet": "Quyền truy cập của sinh viên"
  },
  "set-the-start-date-for-when-you-want-to-enable-student-access-for-this-test": {
    "arabic": "قم بتعيين تاريخ البدء عندما تريد تمكين وصول الطالب لهذا الاختبار",
    "chinese": "設置您希望允許學生訪問此測試的開始日期",
    "eng": "Set the Start Date for when you want to enable student access for this test",
    "hindi": "जब आप इस परीक्षा के लिए छात्र की पहुंच को सक्षम करना चाहते हैं, तो उसके लिए प्रारंभ तिथि सेट करें",
    "ja": "このテストへの、生徒のアクセス権限付与日を設定する。",
    "uz": "Talabalarning ushbu testga kirishiga ruxsat berish uchun boshlanish sanasini belgilang",
    "viet": "Đặt Ngày bắt đầu khi bạn muốn cho phép học sinh truy cập vào bài kiểm tra này"
  },
  "set-the-start-date-for-when-you-want-to-enable-student-access-for-this-topic": {
    "arabic": "قم بتعيين تاريخ البدء عندما تريد تمكين وصول الطالب لهذا الموضوع",
    "chinese": "設置您希望允許學生訪問此主題的時間的開始日期",
    "eng": "Set the Start Date for when you want to enable student access for this topic",
    "hindi": "जब आप इस विषय के लिए छात्र पहुंच को सक्षम करना चाहते हैं, तो उसके लिए प्रारंभ तिथि निर्धारित करें",
    "ja": "このトピックへの生徒のアクセス権限付与日を設定する。",
    "uz": "Ushbu mavzu uchun talabalarga ruxsat berishni xohlagan vaqtingiz uchun Boshlanish sanasini belgilang",
    "viet": "Đặt Ngày bắt đầu khi bạn muốn cho phép sinh viên truy cập vào chủ đề này"
  },
  "you-have-chosen-a-start-time-in-the-future-hence-the-student-access-would-be-turned-off": {
    "arabic": "لقد اخترت وقت البدء في المستقبل , ومن ثم سيتم إيقاف وصول الطالب",
    "chinese": "您選擇了未來的開始時間,因此學生訪問權限將被關閉",
    "eng": "You have chosen a start time in the future, hence the Student access would be turned off",
    "hindi": "आपने भविष्य में प्रारंभ समय चुना है, इसलिए छात्र पहुंच बंद कर दी जाएगी",
    "ja": "未来の開始時刻を選択したため、生徒のアクセス権限が停止されます",
    "uz": "Kelajakda boshlanish vaqtini tanladingiz, shuning uchun Talabalar kirishi oʻchirib qoʻyiladi",
    "viet": "Bạn đã chọn thời gian bắt đầu ở tương lai, do đó quyền truy cập của Sinh viên sẽ bị tắt"
  },
  "close-student-access-on": {
    "arabic": "أغلق وصول الطالب قيد التشغيل",
    "chinese": "關閉學生訪問權限",
    "eng": "Close Student Access On",
    "hindi": "विद्यार्थी पहुँच को बंद करें",
    "ja": "生徒のアクセス権限を停止する",
    "uz": "Talabalar ruxsatini yoping",
    "viet": "Bật Đóng quyền truy cập của sinh viên"
  },
  "setting-the-End-date-will-disable-students-access-to-this-test": {
    "arabic": "سيؤدي تعيين تاريخ الانتهاء إلى تعطيل وصول الطلاب إلى هذا الاختبار",
    "chinese": "設置結束日期將禁止學生訪問此測試",
    "eng": "Setting the End Date will disable students access to this test",
    "hindi": "समाप्ति तिथि सेट करने से छात्रों की इस परीक्षा तक पहुंच अक्षम हो जाएगी",
    "ja": "配信終了日を設定すると、このテストへの生徒のアクセス権限が停止されます",
    "uz": "Tugash sanasini belgilash talabalarning ushbu testga kirishini o‘chirib qo‘yadi",
    "viet": "Đặt Ngày kết thúc sẽ vô hiệu hóa quyền truy cập của học sinh vào bài kiểm tra này"
  },
  "setting-the-End-date-will-disable-students-access-to-this-topic": {
    "arabic": "سيؤدي تعيين تاريخ الانتهاء إلى تعطيل وصول الطلاب إلى هذا الموضوع",
    "chinese": "設置結束日期將禁止學生訪問該主題",
    "eng": "Setting the End Date will disable students access to this topic",
    "hindi": "समाप्ति तिथि निर्धारित करने से छात्रों की इस विषय तक पहुंच अक्षम हो जाएगी",
    "ja": "配信終了日を設定すると、このトピックへの生徒のアクセス権限が停止されます。",
    "uz": "Tugash sanasini belgilash talabalarning ushbu mavzuga kirishini o'chirib qo'yadi",
    "viet": "Đặt Ngày kết thúc sẽ vô hiệu hóa quyền truy cập của sinh viên vào chủ đề này"
  },
  "end-time-should-be-later-than-start-time": {
    "arabic": "يجب أن يأتي وقت الانتهاء بعد وقت البدء",
    "chinese": "結束時間應晚於開始時刻",
    "eng": "End time should be later than start time",
    "hindi": "समाप्ति समय प्रारंभ समय के बाद का होना चाहिए",
    "ja": "終了時間は開始時間より遅くしてください",
    "uz": "Tugash vaqti boshlanish vaqtidan kechroq bo'lishi kerak",
    "viet": "Thời gian kết thúc phải muộn hơn thời gian bắt đầu"
  },
  "end-time": {
    "arabic": "وقت النهاية",
    "chinese": "时间结束",
    "eng": "End Time",
    "hindi": "अंत समय",
    "ja": "終了時間",
    "uz": "Tugash vaqti",
    "viet": "Thời gian kết thúc"
  },
  "start-time": {
    "arabic": "وقت البدء",
    "chinese": "開始時間",
    "eng": "Start Time",
    "hindi": "प्रारंभ समय",
    "ja": "開始時間",
    "uz": "Boshlanish vaqti",
    "viet": "Thời gian bắt đầu"
  },
  "total-attempts": {
    "arabic": "مجموع محاولات",
    "chinese": "总尝试次数",
    "eng": "Total Attempts",
    "hindi": "कुल प्रयास",
    "ja": "合計解答回数",
    "uz": "Jami urinishlar",
    "viet": "Tổng số lần thử"
  },
  "please-select-an-end-time-in-the-future": {
    "arabic": "الرجاء تحديد وقت انتهاء في المستقبل",
    "chinese": "請選擇未來的結束時間",
    "eng": "Please select an end time in the future",
    "hindi": "कृपया भविष्य में समाप्ति समय चुनें",
    "ja": "未来の終了時刻を選択してください",
    "uz": "Kelajakda tugash vaqtini tanlang",
    "viet": "Vui lòng chọn thời gian kết thúc ở tương lai"
  },
  "end-time-should-be-later-than-open-exam-time": {
    "arabic": "يجب أن يأتي وقت الانتهاء بعد موعد الامتحان المفتوح",
    "chinese": "結束時間應晚於配信考試時間",
    "eng": "End time should be later than open exam time",
    "hindi": "समाप्ति समय खुली परीक्षा के समय के बाद का होना चाहिए",
    "ja": "終了時間はテスト開始時間より遅くしてください",
    "uz": "Tugash vaqti ochiq imtihon vaqtidan kechroq bo'lishi kerak",
    "viet": "Thời gian kết thúc phải muộn hơn thời gian thi mở"
  },
  "please-select-an-end-time-later-than-open-exam-time": {
    "arabic": "الرجاء تحديد وقت انتهاء متأخر عن وقت الامتحان المفتوح",
    "chinese": "請選擇晚於配信考試時間的結束時間",
    "eng": "Please select an end time later than open exam time",
    "hindi": "कृपया किसी अंतिम समय को खुली परीक्षा के समय के बाद चुनें",
    "ja": "終了時間はテスト開始時間より遅くしてください",
    "uz": "Iltimos, ochiq imtihon vaqtidan kechroq tugash vaqtini tanlang",
    "viet": "Vui lòng chọn thời gian kết thúc muộn hơn thời gian bắt đầu kiểm tra"
  },
  "please-enter-a-valid-end-date-and-time": {
    "arabic": "الرجاء إدخال تاريخ ووقت انتهاء صالحين",
    "chinese": "請輸入有效的結束日期和時間",
    "eng": "Please enter a valid end date and time",
    "hindi": "कृपया एक मान्य समाप्ति तिथि और समय दर्ज करें",
    "ja": "終了日時を正しく入力してください",
    "uz": "Yaroqli tugash sanasi va vaqtini kiriting",
    "viet": "Vui lòng nhập ngày và giờ kết thúc hợp lệ"
  },
  "open-results-after-submission": {
    "arabic": "فتح النتائج بعد الإرسال",
    "chinese": "提交後打開結果",
    "eng": "Open Results after submission",
    "hindi": "सबमिट करने के बाद परिणाम खोलें",
    "ja": "テスト結果を公開する",
    "uz": "Natijalarni topshirgandan keyin oching",
    "viet": "Hiển thị kết quả sau khi nộp bài"
  },
  "toggle-Off-to-delay-the-display-of-Test-results-immediately-on-final-submission": {
    "arabic": "تبديل إيقاف لتأخير عرض نتائج الاختبار فورًا عند الإرسال النهائي",
    "chinese": "關閉以延遲在“最終提交”時立即顯示測試結果",
    "eng": "Toggle Off to delay the display of Test results immediately on 'Final submission'",
    "hindi": "'अंतिम सबमिशन' पर तुरंत परीक्षा परिणामों के प्रदर्शन में देरी करने के लिए टॉगल करें",
    "ja": "オフに切り替えると、最終提出時に生徒にテスト結果がすぐ表示されません。",
    "uz": "“Yakuniy topshirish”da test natijalarini darhol koʻrsatishni kechiktirish uchun “Oʻchirish” tugmasini bosing.",
    "viet": "Tắt để trì hoãn việc hiển thị kết quả bài Kiểm tra trên “Nộp lần cuối”"
  },
  "test-results-available-on-final-submit": {
    "arabic": "نتائج الاختبار متاحة في إرسال نهائي",
    "chinese": "可在“最終提交”上獲得測試結果",
    "eng": "Test Results available on 'Final submit'",
    "hindi": "परीक्षा परिणाम 'फाइनल सबमिट' पर उपलब्ध",
    "ja": "最終提出を行い、テスト結果を表示する",
    "uz": "Test natijalari &quot;Yakuniy topshirish&quot; da mavjud",
    "viet": "Kết quả kiểm tra đã có trên “Nộp lần cuối”"
  },
  "result-data-is-always-set-to-end-date-by-default-please-select-an-appropriate-date-as-per-your-needs-after-the-end-date": {
    "arabic": "يتم دائمًا تعيين بيانات النتيجة على تاريخ الانتهاء افتراضيًا. يرجى تحديد التاريخ المناسب حسب احتياجاتك بعد تاريخ الانتهاء",
    "chinese": "默認情況下,結果數據始終設置為結束日期。請在結束日期之後根據您的需要選擇合適的日期",
    "eng": "Result data is always set to End-date by default. Please select an appropriate date as per your needs after the End-Date",
    "hindi": "परिणाम डेटा हमेशा डिफ़ॉल्ट रूप से समाप्ति तिथि पर सेट होता है। कृपया अंतिम तिथि के बाद अपनी आवश्यकताओं के अनुसार उपयुक्त तिथि का चयन करें",
    "ja": "結果データは「終了日」に初期設定されています。終了日以降、任意の日付を選択してください",
    "uz": "Natija ma'lumotlari sukut bo'yicha har doim Tugash sanasiga o'rnatiladi. Tugash sanasidan keyin ehtiyojlaringizga mos sanani tanlang",
    "viet": "Dữ liệu kết quả luôn được đặt theo ngày Kết thúc theo mặc định. Vui lòng chọn một ngày phù hợp theo nhu cầu của bạn sau ngày Kết thúc."
  },
  "setting-results-date-will-enable-display-of-test-results-to-the-student-after-the-selected-date": {
    "arabic": "سيؤدي تعيين تاريخ النتائج إلى تمكين عرض نتائج الاختبار للطالب بعد التاريخ المحدد",
    "chinese": "設置結果日期將允許在選定日期之後向學生顯示測試結果",
    "eng": "Setting Results date will enable display of test results to the student after the selected date",
    "hindi": "परिणाम तिथि निर्धारित करने से चयनित तिथि के बाद छात्र को परीक्षा परिणाम प्रदर्शित करने में सक्षम हो जाएगा",
    "ja": "結果公開日を設定すると、設定日後は生徒がテスト結果を閲覧することができるようになります。",
    "uz": "Natijalar sanasini belgilash tanlangan sanadan keyin talabaga test natijalarini ko'rsatish imkonini beradi",
    "viet": "Bằng cách đặt Ngày Hiển thị kết quả, học sinh sẽ có thể xem kết quả bài kiểm tra vào sau ngày đã chọn."
  },
  "start-date": {
    "arabic": null,
    "chinese": null,
    "eng": "Start Date",
    "hindi": null,
    "ja": "開始日",
    "uz": "Boshlanish sanasi",
    "viet": null
  },
  "end-date": {
    "arabic": null,
    "chinese": null,
    "eng": "End Date",
    "hindi": null,
    "ja": "終了日",
    "uz": "Tugash sanasi",
    "viet": null
  },
  "welcome-to-cerebry": {
    "arabic": "مرحبًا بكم في Cerebry",
    "chinese": "歡迎來到 Cerebry",
    "eng": "Welcome to Cerebry",
    "hindi": "सेरेब्री में आपका स्वागत है",
    "ja": "ようこそ",
    "uz": "Cerebry-ga xush kelibsiz",
    "viet": "Chào mừng đến với Kyons x Cerebry"
  },
  "two-more-quick-steps-to-get-started": {
    "arabic": "خطوتان أخريان للبدء",
    "chinese": "兩個快速入門步驟",
    "eng": "Two more quick steps to get started",
    "hindi": "आरंभ करने के लिए दो और त्वरित चरण",
    "ja": "2つの簡単なステップですぐに使えます",
    "uz": "Boshlash uchun yana ikkita tezkor qadam",
    "viet": "Chỉ cần hai bước nữa để có thể bắt đầu"
  },
  "please-enter-school-code": {
    "arabic": "الرجاء إدخال رمز المدرسة",
    "chinese": "請輸入學校代碼",
    "eng": "Please enter school code",
    "hindi": "कृपया स्कूल कोड दर्ज करें",
    "ja": "学校コードを入力してください",
    "uz": "Iltimos, maktab kodini kiriting",
    "viet": "Vui lòng nhập mã của trường bạn"
  },
  "please-enter-school-pin": {
    "arabic": "الرجاء إدخال رقم التعريف الشخصي للمدرسة",
    "chinese": "請輸入學校密碼",
    "eng": "Please enter school pin",
    "hindi": "कृपया स्कूल पिन दर्ज करें",
    "ja": "スクールPin（パスワード）を入力してください",
    "uz": "Iltimos, maktab pin kodini kiriting",
    "viet": "Vui lòng nhập mã pin của trường"
  },
  "enter-your-school-code": {
    "arabic": "أدخل رمز مدرستك",
    "chinese": "輸入您的學校代碼",
    "eng": "Enter your school code",
    "hindi": "अपना स्कूल कोड दर्ज करें",
    "ja": "学校コードを入力してください",
    "viet": "Nhập mã của trường bạn",
    "uz": "Maktab kodiingizni kiriting",
    "ru": "Введите код вашей школы",
    "az": "Məktəb kodunuzu daxil edin",
    "jp": "学校コードを入力"
  },
  "enter-school-pin": {
    "arabic": "أدخل رقم التعريف الشخصي للمدرسة",
    "chinese": "進校密碼",
    "eng": "Enter school pin",
    "hindi": "स्कूल पिन दर्ज करें",
    "ja": "スクールPin（パスワード）を入力してください",
    "uz": "Maktab pinini kiriting",
    "viet": "Nhập mã pin của trường",
    "ru": "Введите пин-код школы",
    "az": "Məktəb pin kodunu daxil edin",
    "jp": "学校のピンを入力"
  },
  "testing": {
    "arabic": "اختبارات",
    "chinese": "測試",
    "eng": "TESTING",
    "hindi": "परिक्षण",
    "ja": "テスト",
    "uz": "SINOV",
    "viet": "ĐANG KIỂM TRA"
  },
  "oops-link-is-invalid": {
    "arabic": "أُووبس!! الارتباط غير صالح",
    "chinese": "哎呀！！鏈接無效",
    "eng": "Oops!! link is invalid",
    "hindi": "उफ़ !! लिंक अमान्य है",
    "ja": "リンクが無効です",
    "uz": "Voy!! havola yaroqsiz",
    "viet": "Ôi không!! Liên kết không hợp lệ mất rồi"
  },
  "revision": {
    "arabic": "مراجعة",
    "chinese": "修訂",
    "eng": "Revision",
    "hindi": "संशोधन",
    "ja": "変更",
    "uz": "Qayta ko'rib chiqish",
    "viet": "Ôn tập"
  },
  "invalid-email": {
    "arabic": "بريد إلكتروني خاطئ",
    "chinese": "不合規電郵",
    "eng": "Invalid email!!!",
    "hindi": "अमान्य ईमेल",
    "ja": "無効なメールアドレス",
    "uz": "Yaroqsiz email",
    "viet": "Email không hợp lệ"
  },
  "password-should-contain-atleast-6-characters": {
    "arabic": "يجب أن تحتوي كلمة المرور على 6 أحرف على الأقل",
    "chinese": "密碼應至少包含 6 個字符",
    "eng": "Password should contain atleast 6 characters",
    "hindi": "पासवर्ड में कम से कम 6 अक्षर होने चाहिए",
    "ja": "パスワードは6文字以上にする必要があります",
    "uz": "Parol kamida 6 ta belgidan iborat bo'lishi kerak",
    "viet": "Mật khẩu phải chứa ít nhất 6 ký tự"
  },
  "email-id-or-phone-number": {
    "arabic": "معرف البريد الإلكتروني أو رقم الهاتف",
    "chinese": "電子郵件 ID 或電話號碼",
    "eng": "Email Id or Phone number",
    "hindi": "ईमेल आईडी या फोन नंबर",
    "ja": "メールアドレスまたは電話番号",
    "uz": "Elektron pochta identifikatori yoki telefon raqami",
    "viet": "Id email hoặc số điện thoại"
  },
  "passwords-must-be-at-least-6-characters": {
    "arabic": "يجب أن تتكون كلمات المرور من 6 أحرف على الأقل",
    "chinese": "密碼必須至少為 6 個字符",
    "eng": "Passwords must be at least 6 characters",
    "hindi": "पासवर्ड कम से कम 6 अक्षरों का होना चाहिए",
    "ja": "パスワードは6文字以上にする必要があります",
    "uz": "Parollar kamida 6 ta belgidan iborat bo'lishi kerak",
    "viet": "Mật khẩu phải có ít nhất 6 ký tự"
  },
  "create-an-account": {
    "arabic": "إنشاء حساب",
    "chinese": "創建一個帳戶",
    "eng": "Create an Account",
    "hindi": "खाता बनाएं",
    "ja": "アカウントを作成する",
    "uz": "Hisob ochish",
    "viet": "Tạo tài khoản"
  },
  "already-have-an-account": {
    "arabic": "هل لديك حساب",
    "chinese": "已有帳號",
    "eng": "Already have an account",
    "hindi": "पहले से ही एक खाता है",
    "ja": "すでにアカウントをお持ちですか",
    "uz": "Allaqachon hisobingiz bor",
    "viet": "Đã có tài khoản"
  },
  "overview": {
    "arabic": "ملخص",
    "chinese": "概述",
    "eng": "Overview",
    "hindi": "अवलोकन",
    "ja": "概要",
    "uz": "Umumiy koʻrinish",
    "viet": "Tổng quan"
  },
  "dashboard": {
    "arabic": "لوحة القيادة",
    "chinese": "儀表板",
    "eng": "Dashboard",
    "hindi": "डैशबोर्ड",
    "ja": "進捗状況",
    "uz": "Boshqaruv paneli",
    "viet": "bảng điều khiển"
  },
  "class": {
    "arabic": "فصل",
    "chinese": "班級",
    "eng": "Class",
    "hindi": "कक्षा",
    "ja": "クラス",
    "uz": "Sinf",
    "viet": "Lớp học"
  },
  "students-in-this-class": {
    "arabic": "الطلاب في هذا الفصل",
    "chinese": "本班學生",
    "eng": "students in this class",
    "hindi": "इस कक्षा में छात्र",
    "ja": "人の生徒がこのクラスに所属しています",
    "uz": "bu sinf o'quvchilari",
    "viet": "học sinh trong lớp này"
  },
  "no-students-have-started-this-yet": {
    "arabic": "لم يبدأ أي طلاب هذا حتى الآن",
    "chinese": "還沒有學生開始這個",
    "eng": "No students have started this yet",
    "hindi": "अभी तक किसी छात्र ने इसे शुरू नहीं किया है",
    "ja": "学習を開始した生徒はいません",
    "uz": "Buni hali hech bir talaba boshlamagan",
    "viet": "Chưa có học sinh nào bắt đầu"
  },
  "students-have-not-started": {
    "arabic": "الطلاب لم يبدأوا",
    "chinese": "學生還沒有開始",
    "eng": "students have not started",
    "hindi": "छात्रों ने शुरू नहीं किया है",
    "ja": "人の生徒が、まだこのトピックを開始していません",
    "uz": "talabalar boshlamagan",
    "viet": "học sinh chưa bắt đầu"
  },
  "students-are-at-the": {
    "arabic": "الطلاب في",
    "chinese": "學生們在",
    "eng": "students are at the",
    "hindi": "छात्र हैं",
    "ja": "人の生徒が ",
    "uz": "talabalar",
    "viet": "học sinh đang ở"
  },
  "of-them": {
    "arabic": "منهم",
    "chinese": "其中",
    "eng": "of them",
    "hindi": "उनमें से",
    "ja": "人が",
    "uz": "ulardan",
    "viet": "của họ"
  },
  "have-first-encountered-problems-at": {
    "arabic": "واجهوا مشاكل لأول مرة في",
    "chinese": "第一次遇到問題",
    "eng": "have first encountered problems at",
    "hindi": "में पहली बार समस्याओं का सामना करना पड़ा है",
    "ja": "この問題で手が止まっています: ",
    "uz": "da birinchi marta muammolarga duch kelgan",
    "viet": "gặp vấn đề lần đầu tiên tại"
  },
  "are-stuck-at-these": {
    "arabic": "عالقون في هذه",
    "chinese": "被困在這些",
    "eng": "are stuck at these",
    "hindi": "इन पर अटके हुए हैं",
    "ja": "この問題でつまずいているようです: ",
    "uz": "bularga yopishib qolishadi",
    "viet": "bị mắc kẹt ở đây"
  },
  "paused-voluntarily": {
    "arabic": "توقف طواعية",
    "chinese": "自願暫停",
    "eng": "paused voluntarily",
    "hindi": "स्वेच्छा से रोका गया",
    "ja": "学習を休止中です",
    "uz": "ixtiyoriy ravishda pauza qildi",
    "viet": "tạm dừng một cách tự nguyện"
  },
  "question-type": {
    "arabic": "نوع السؤال",
    "chinese": "問題類型",
    "eng": "Question Type",
    "hindi": "प्रश्न प्रकार",
    "ja": "問題タイプ",
    "uz": "Savol turi",
    "viet": "Loại câu hỏi"
  },
  "student(s)-encountered-problems-here": {
    "arabic": "طالب (ق) واجه مشاكل هنا",
    "chinese": "學生在這裡遇到問題",
    "eng": "student(s) encountered problems here",
    "hindi": "छात्रों को यहां दिक्कत हुई",
    "ja": "生徒がこの問題でつまずいているようです:",
    "uz": "talaba(lar) bu yerda muammolarga duch keldi",
    "viet": "học sinh gặp vấn đề ở đây"
  },
  "student(s)-stuck-here": {
    "arabic": "طالب (طلاب) عالقون هنا",
    "chinese": "學生被困在這裡",
    "eng": "student(s) stuck here",
    "hindi": "छात्र अटक गया यहां",
    "ja": "生徒がここでつまづいているようです",
    "uz": "talaba(lar) shu yerda qolib ketdi",
    "viet": "sinh viên bị mắc kẹt ở đây"
  },
  "sort-by-progress-(ascending)": {
    "arabic": "فرز حسب التقدم (تصاعدي)",
    "chinese": "按進度排序(升序)",
    "eng": "Sort by progress (ascending)",
    "hindi": "प्रगति के आधार पर छाँटें (आरोही) ",
    "ja": "進捗順(昇順)",
    "uz": "Rivojlanish bo'yicha saralash (ko'tarilish)",
    "viet": "Sắp xếp theo tiến độ (tăng dần)"
  },
  "sort-by-progress-(descending)": {
    "arabic": "فرز حسب التقدم (تنازلي)",
    "chinese": "按進度排序(降序)",
    "eng": "Sort by progress (descending)",
    "hindi": "प्रगति के आधार पर छाँटें (अवरोही)",
    "ja": "進捗順(降順)",
    "uz": "Rivojlanish bo'yicha saralash (kamayish bo'yicha)",
    "viet": "Sắp xếp theo tiến độ (giảm dần)"
  },
  "progress-(ascending)": {
    "arabic": "تقدم (تصاعدي)",
    "chinese": "進步(升序)",
    "eng": "progress (ascending)",
    "hindi": "प्रगति के आधार पर छाँटें (आरोही) ",
    "ja": "進捗順(昇順)",
    "uz": "taraqqiyot (ko'tarilish)",
    "viet": "tiến độ (tăng dần)"
  },
  "progress-(descending)": {
    "arabic": "التقدم (تنازلي)",
    "chinese": "進度(降序)",
    "eng": "progress (descending)",
    "hindi": "प्रगति के आधार पर छाँटें (अवरोही)",
    "ja": "進捗順(降順)",
    "uz": "taraqqiyot (pasayish)",
    "viet": "tiến độ (giảm dần)"
  },
  "progress": {
    "arabic": "تقدم",
    "chinese": "進步",
    "eng": "Progress",
    "hindi": "प्रगति",
    "ja": "進捗",
    "uz": "Taraqqiyot",
    "viet": "Tiến độ"
  },
  "need-help": {
    "arabic": "تحتاج مساعدة",
    "chinese": "需要幫忙",
    "eng": "need-help",
    "hindi": "मदद की ज़रूरत है",
    "ja": "ヘルプが必要",
    "uz": "Yordam kerak",
    "viet": "cần giúp đỡ"
  },
  "details": {
    "arabic": "تفاصيل",
    "chinese": "細節",
    "eng": "Details",
    "hindi": "विवरण",
    "ja": "詳細",
    "uz": "Tafsilotlar",
    "viet": "chi tiết"
  },
  "student-stuck": {
    "arabic": "الطالب عالق",
    "chinese": "學生卡住",
    "eng": "Student Stuck",
    "hindi": "छात्र फंसा",
    "ja": "ヘルプが必要",
    "uz": "Talaba qotib qolgan",
    "viet": "sinh viên bị mắc kẹt"
  },
  "students-at": {
    "arabic": "الطلاب في",
    "chinese": "學生在",
    "eng": "Students at",
    "hindi": "पर छात्र",
    "ja": "人の生徒が学習中: ",
    "uz": "Talabalar",
    "viet": "học sinh tại"
  },
  "who-are-they": {
    "arabic": "من هؤلاء",
    "chinese": "他們是誰",
    "eng": "Who are they",
    "hindi": "कौन हैं वे",
    "ja": "生徒一覧",
    "uz": "Ular kim",
    "viet": "họ là ai"
  },
  "question-list": {
    "arabic": "قائمة الأسئلة",
    "chinese": "問題清單",
    "eng": "Question list",
    "hindi": "प्रश्न सूची",
    "ja": "問題リスト",
    "uz": "Savollar ro'yxati",
    "viet": "danh sách câu hỏi"
  },
  "progress-reversal-event": {
    "arabic": "حدث عكس التقدم",
    "chinese": "進度逆轉事件",
    "eng": "Progress Reversal Event",
    "hindi": "प्रगति उत्क्रमण घटना",
    "ja": "進捗反転イベント",
    "uz": "Taraqqiyotni qaytarish hodisasi",
    "viet": "Sự kiện bị đảo ngược tiến độ"
  },
  "a-misuse-of-appeal-function-was-detected-and-the-student-was-reset-back-to-where-the-abuse-happened-a(progress was also reversed)": {
    "arabic": "تم اكتشاف إساءة استخدام وظيفة الاستئناف وتمت إعادة تعيين الطالب إلى حيث حدثت الإساءة (تم عكس التقدم أيضًا)",
    "chinese": "檢測到濫用申訴功能,學生被重置回濫用發生的地方(進度也被逆轉)",
    "eng": "A misuse of appeal function was detected and the student was reset back to where the abuse happened a(Progress was also reversed)",
    "hindi": "अपील समारोह के दुरुपयोग का पता चला था और छात्र को वापस उस स्थान पर रीसेट कर दिया गया था जहां दुरुपयोग हुआ था (प्रगति भी उलट दी गई थी)",
    "ja": "機能の誤使用が検知されました。生徒と進捗は問題が発生した場所に戻されました。",
    "uz": "Apellyatsiya funktsiyasidan noto'g'ri foydalanish aniqlandi va talaba suiiste'mollik sodir bo'lgan joyga qaytarildi a (Taraqqiyot ham teskari bo'ldi)",
    "viet": "Đã phát hiện việc sử dụng chức năng kháng cáo sai mục đích và học sinh đã bị đặt lại về trạng thái phát hiện hành vi (Tiến độ cũng bị đảo ngược)"
  },
  "student-has-not-reached-to-this-level-yet": {
    "arabic": "لم يصل الطالب إلى هذا المستوى بعد",
    "chinese": "學生還沒有達到這個水平",
    "eng": "Student has not reached to this level yet",
    "hindi": "विद्यार्थी अभी तक इस स्तर तक नहीं पहुंचा है",
    "ja": "生徒がこの難易度に達していません。",
    "uz": "Talaba hali bu darajaga yetmagan",
    "viet": "Học sinh chưa đạt đến cấp độ này"
  },
  "session-overview": {
    "arabic": "نظرة عامة على الجلسة",
    "chinese": "會議概覽",
    "eng": "Session Overview",
    "hindi": "सत्र अवलोकन",
    "ja": "セッション概要",
    "uz": "Sessiyaga umumiy nuqtai",
    "viet": "Tổng quan buổi học"
  },
  "question-practiced": {
    "arabic": "ممارسة السؤال",
    "chinese": "習題",
    "eng": "Question Practiced",
    "hindi": "प्रश्न अभ्यास किया",
    "ja": "取り組んだ問題",
    "uz": "Savol mashq qilindi",
    "viet": "câu hỏi luyện tập"
  },
  "currently-solving": {
    "arabic": "حل حاليا",
    "chinese": "目前正在解決",
    "eng": "Currently Solving",
    "hindi": "वर्तमान में समाधान कर रहा है",
    "ja": "今解いている問題",
    "uz": "Hozirda hal qilinmoqda",
    "viet": "Đang giải quyết"
  },
  "attempt": {
    "arabic": "محاولة",
    "chinese": "試圖",
    "eng": "Attempt",
    "hindi": "कोशिश करना",
    "ja": "解答",
    "uz": "Urinish",
    "viet": "thử"
  },
  "breakout-mode": {
    "arabic": "وضع الاختراق",
    "chinese": "突破模式",
    "eng": "Breakout Mode",
    "hindi": "ब्रेकआउट मोड",
    "ja": "ブレイクアウトモード",
    "uz": "Breakout rejimi",
    "viet": "Chế độ đột phá"
  },
  "submitted-answer": {
    "arabic": "الإجابة المرسلة",
    "chinese": "提交的答案",
    "eng": "Submitted Answer",
    "hindi": "प्रस्तुत उत्तर",
    "ja": "提出された解答",
    "uz": "Taqdim etilgan javob",
    "viet": "Đã nộp câu trả lời"
  },
  "submitted": {
    "arabic": "مُقَدَّم",
    "chinese": "已提交",
    "eng": "Submitted",
    "hindi": "प्रस्तुत",
    "ja": "提出済",
    "uz": "Taqdim etilgan",
    "viet": "Đã nộp"
  },
  "add-student": {
    "arabic": "أضف طالب",
    "chinese": "添加學生",
    "eng": "Add Student",
    "hindi": "विद्यार्थी जोड़ें",
    "ja": "生徒を追加する",
    "uz": "Talaba qo'shish",
    "viet": "Thêm học sinh"
  },
  "remove": {
    "arabic": "يزيل",
    "chinese": "消除",
    "eng": "Remove",
    "hindi": "निकालना",
    "ja": "削除",
    "uz": "O'chirish",
    "viet": "Xoá"
  },
  "reset-password": {
    "arabic": "إعادة تعيين كلمة المرور",
    "chinese": "重设密码",
    "eng": "Reset Password",
    "hindi": "पासवर्ड रीसेट",
    "ja": "パスワードを再設定する",
    "uz": "Parolni tiklash",
    "viet": "Đặt lại mật khẩu"
  },
  "reset-test": {
    "arabic": "إعادة تعيين الاختبار",
    "chinese": "重设测试",
    "eng": "Reset Test",
    "hindi": "परीक्षा रीसेट",
    "ja": "テストをリセット",
    "uz": "Sinovni tiklash",
    "viet": "Đặt lại bài kiểm tra"
  },
  "no-student-in-this-class": {
    "arabic": "لا يوجد طالب في هذا الفصل",
    "chinese": "這個班沒有學生",
    "eng": "No student in this class",
    "hindi": "इस कक्षा में कोई छात्र नहीं है",
    "ja": "このクラスにはまだ生徒がいません",
    "uz": "Bu sinfda o‘quvchi yo‘q",
    "viet": "Không có học sinh nào trong lớp"
  },
  "the-student-is-already-registered-on-cerebry-but-not-in-this-class-do-you-want-add-the-student-to-this-class-(password-will-not-be-changed)": {
    "arabic": "الطالب مسجل بالفعل في Cerebry ولكن ليس في هذا الفصل , هل تريد إضافة الطالب إلى هذا الفصل؟ (لن يتم تغيير كلمة المرور)",
    "chinese": "該學生已在 Cerebry 上註冊,但未在該班級中註冊,是否要將該學生添加到該班級中？ (密碼不會更改)",
    "eng": "The Student is already registered on Cerebry but not in this class, Do you want add the student to this class? (Password will not be changed)",
    "hindi": "छात्र पहले से ही सेरेब्री पर पंजीकृत है लेकिन इसमें नहींकक्षा, क्या आप छात्र को इस कक्षा में जोड़ना चाहते हैं? (पासवर्ड होगा बदला नहीं जा सकता)",
    "ja": "生徒がこのクラスに登録されていません。生徒をこのクラスに追加しますか? (パスワードは変更されません)",
    "uz": "Talaba allaqachon Cerebry'da ro'yxatdan o'tgan, lekin bu sinfda emas, talabani shu sinfga qo'shishni xohlaysizmi? (Parol o'zgartirilmaydi)",
    "viet": "Tài khoản học sinh đã được đăng ký trên Cerebry nhưng chưa ở trong lớp học này, Bạn có muốn thêm học sinh vào lớp học này không? (Mật khẩu sẽ không bị thay đổi)"
  },
  "enter-details-of-the-student": {
    "arabic": "أدخل تفاصيل الطالب",
    "chinese": "輸入學生的詳細信息",
    "eng": "Enter details of the Student",
    "hindi": "छात्र का विवरण दर्ज करें",
    "ja": "生徒の詳細を入力してください",
    "uz": "Talaba tafsilotlarini kiriting",
    "viet": "Nhập thông tin chi tiết của học sinh"
  },
  "email": {
    "arabic": "بريد إلكتروني",
    "chinese": "電子郵件",
    "eng": "Email",
    "hindi": "ईमेल",
    "ja": "メールアドレス",
    "uz": "Elektron pochta",
    "viet": "Email",
    "ru": "Электронная почта",
    "az": "Elektron poçt",
    "jp": "メール"
  },
  "enter-student-first-name": {
    "arabic": "أدخل الاسم الأول للطالب",
    "chinese": "輸入學生的名字",
    "eng": "Enter Student First Name",
    "hindi": "छात्र का पहला नाम दर्ज करें",
    "ja": "生徒名（名）を入力してください",
    "uz": "Talaba ismini kiriting",
    "viet": "Nhập tên học sinh"
  },
  "student-name": {
    "arabic": "أسم الطالب",
    "chinese": "學生姓名",
    "eng": "Student Name",
    "hindi": "छात्र का नाम दर्ज करें",
    "ja": "生徒名",
    "uz": "Talaba ismi",
    "viet": "Tên học sinh"
  },
  "enter-student-last-name": {
    "arabic": "أدخل الاسم الأخير للطالب",
    "chinese": "輸入學生姓氏",
    "eng": "Enter Student Last Name",
    "hindi": "छात्र का अंतिम नाम दर्ज करें",
    "ja": "生徒名（姓）を入力してください",
    "uz": "Talaba familiyasini kiriting",
    "viet": "Nhập Họ của Học sinh"
  },
  "enter-student-email-id": {
    "arabic": "أدخل معرف البريد الإلكتروني للطالب",
    "chinese": "輸入學生電子郵件 ID",
    "eng": "Enter Student Email ID",
    "hindi": "छात्र ईमेल आईडी दर्ज करें",
    "ja": "生徒のメールアドレスを入力してください",
    "uz": "Talaba elektron pochta identifikatorini kiriting",
    "viet": "Nhập ID email học sinh"
  },
  "min-6-characters": {
    "arabic": "دقيقة. 6 أحرف",
    "chinese": "分鐘6個字符",
    "eng": "Min. 6 Characters",
    "hindi": "मिन। 6 वर्ण",
    "ja": "最小6文字",
    "uz": "Min. 6 ta belgi",
    "viet": "tối thiểu 6 ký tự"
  },
  "re-enter-your-password": {
    "arabic": "أعد إدخال كلمة المرور",
    "chinese": "重新輸入您的密碼",
    "eng": "Re-enter Your Password",
    "hindi": "दुबारापासवडृ िलखो",
    "ja": "パスワードを再入力してください",
    "uz": "Parolingizni qayta kiriting",
    "viet": "Nhập lại mật khẩu của bạn"
  },
  "continue-with-phone-number": {
    "arabic": "تواصل مع رقم الهاتف",
    "chinese": "繼續電話號碼",
    "eng": "Continue with Phone number",
    "hindi": "फ़ोन नंबर के साथ जारी रखें",
    "ja": "電話番号から追加",
    "uz": "Telefon raqami bilan davom eting",
    "viet": "Tiếp tục với Số điện thoại"
  },
  "continue-with-email-id": {
    "arabic": "تواصل مع معرف البريد الإلكتروني",
    "chinese": "繼續使用電子郵件 ID",
    "eng": "Continue with Email ID",
    "hindi": "ईमेल आईडी के साथ जारी रखें",
    "ja": "メールアドレスから追加",
    "uz": "Email ID bilan davom eting",
    "viet": "Tiếp tục với ID email"
  },
  "please-enter-valid-last-name": {
    "arabic": "الرجاء إدخال اسم عائلة صالح",
    "chinese": "請輸入有效的姓氏",
    "eng": "Please enter valid last name",
    "hindi": "कृपया मान्य पहला नाम दर्ज करें",
    "ja": "有効な名前（姓）を入力してください",
    "uz": "Iltimos, haqiqiy familiyani kiriting",
    "viet": "Vui lòng nhập một họ hợp lệ"
  },
  "please-enter-valid-first-name": {
    "arabic": "الرجاء إدخال الاسم الأول صالح",
    "chinese": "請輸入有效的名字",
    "eng": "Please enter valid first name",
    "hindi": "कृपया मान्य अंतिम नाम दर्ज करें",
    "ja": "有効な名前（名）を入力してください",
    "uz": "Iltimos, haqiqiy ismni kiriting",
    "viet": "Vui lòng nhập một tên hợp lệ"
  },
  "please-enter-a-valid-email-id": {
    "arabic": "الرجاء إدخال معرف البريد الإلكتروني صالح",
    "chinese": "請輸入有效的電子郵件 ID",
    "eng": "Please-enter-a-valid-email-ID",
    "hindi": "कृपया मान्य ईमेल पता दर्ज करें",
    "ja": "有効なメールアドレスを入力してください",
    "uz": "Iltimos, yaroqli elektron pochta identifikatorini kiriting",
    "viet": "Vui lòng nhập một ID email hợp lệ"
  },
  "password-(minimum-6-characters)": {
    "arabic": "كلمة المرور (بحد أدنى 6 أحرف)",
    "chinese": "密碼(最少 6 個字符)",
    "eng": "Password (Minimum 6 characters)",
    "hindi": "पासवर्ड (न्यूनतम 6 वर्ण)",
    "ja": "パスワード（6文字以上）",
    "uz": "Parol (kamida 6 ta belgi)",
    "viet": "Mật khẩu (Tối thiểu 6 ký tự)"
  },
  "please-enter-a-valid-password ": {
    "arabic": "الرجاء إدخال كلمة السر الصحيحة",
    "chinese": "請輸入有效密碼",
    "eng": "Please enter a valid password ",
    "hindi": "कृपया एक वैध पासवर्ड दर्ज करें",
    "ja": "有効なパスワードを入力して下さい",
    "uz": "Yaroqli parolni kiriting ",
    "viet": "Vui lòng nhập mật khẩu hợp lệ"
  },
  "email-has-been-added-several-times-please-review-and-delete-the-duplicates": {
    "arabic": "تمت إضافة البريد الإلكتروني عدة مرات. يرجى مراجعة وحذف التكرارات",
    "chinese": "電子郵件已添加多次。請檢查並刪除重複項",
    "eng": "Email has been added several times. Please review and delete the duplicates",
    "hindi": "ईमेल कई बार जोड़ा गया है। कृपया समीक्षा करें और डुप्लीकेट हटाएं",
    "ja": "このメールアドレスは既に追加されています。重複を確認してください",
    "uz": "Elektron pochta bir necha marta qo'shilgan. Iltimos, dublikatlarni ko'rib chiqing va o'chiring",
    "viet": "Email đã được thêm vào nhiều lần. Vui lòng xem lại và xóa các bản sao"
  },
  "student-exists-shall-be-added-to-the-class-and-the-password-will-not-be-changed": {
    "arabic": "الطالب موجود , يجب إضافته إلى الفصل ولن يتم تغيير كلمة المرور",
    "chinese": "學生存在,應添加到班級,密碼不會更改",
    "eng": "Student exists, shall be added to the class and the password will not be changed",
    "hindi": "छात्र मौजूद है, कक्षा में जोड़ा जाएगा और पासवर्ड नहीं बदला जाएगा",
    "ja": "生徒が登録されています。自動的にクラスに追加され、パスワードは変更されません",
    "uz": "Talaba bor, sinfga qo'shiladi va parol o'zgartirilmaydi",
    "viet": "Học sinh đã tồn tại sẽ được thêm vào lớp học và mật khẩu sẽ không được thay đổi"
  },
  "teacher-exists-shall-be-added-to-the-class-and-the-password-will-not-be-changed": {
    "arabic": "المعلم موجود , يجب إضافته إلى الفصل ولن يتم تغيير كلمة المرور",
    "chinese": "教師存在,應添加到班級,密碼不會更改",
    "eng": "Teacher exists, shall be added to the class and the password will not be changed",
    "hindi": "शिक्षक मौजूद है, कक्षा में जोड़ा जाएगा और पासवर्ड नहीं बदला जाएगा",
    "ja": "先生が登録されています。自動的にクラスに追加され、パスワードは変更されません",
    "uz": "O'qituvchi bor, sinfga qo'shiladi va parol o'zgartirilmaydi",
    "viet": "Giáo viên đã tồn tại sẽ được thêm vào lớp học và mật khẩu sẽ không được thay đổi"
  },
  "please-enter-a-valid-phone-number": {
    "arabic": "يرجى إدخال رقم هاتف صالح",
    "chinese": "請輸入一個有效的電話號碼",
    "eng": "Please enter a valid phone number",
    "hindi": "कृपया एक मान्य फोन नंबर दर्ज करें",
    "ja": "有効な電話番号を入力して下さい",
    "uz": "Iltimos, yaroqli telefon raqamini kiriting",
    "viet": "Vui lòng nhập một số điện thoại hợp lệ"
  },
  "same-phone-number-has-been-added-several-times-please-review-and-delete-the-duplicates": {
    "arabic": "تمت إضافة نفس رقم الهاتف عدة مرات. يرجى مراجعة وحذف التكرارات",
    "chinese": "同一個電話號碼已添加多次。請檢查並刪除重複項",
    "eng": "Same phone number has been added several times. Please review and delete the duplicates",
    "hindi": "एक ही फोन नंबर को कई बार जोड़ा गया है। कृपया समीक्षा करें और डुप्लीकेट हटाएं",
    "ja": "同じ電話番号が何度か追加されています。重複を確認してください",
    "uz": "Xuddi shu telefon raqami bir necha marta qo'shilgan. Iltimos, dublikatlarni ko'rib chiqing va o'chiring",
    "viet": "Số điện thoại này đã được thêm vào nhiều lần. Vui lòng xem lại và xóa các bản sao"
  },
  "student-already-exists-in-this-class-please-delete-this-row": {
    "arabic": "الطالب موجود بالفعل في هذا الفصل. يرجى حذف هذا الصف",
    "chinese": "該班級中已存在學生。請刪除這一行",
    "eng": "Student already exists in this class. Please delete this row",
    "hindi": "विद्यार्थी इस कक्षा में पहले से मौजूद है। कृपया इस पंक्ति को हटा दें",
    "ja": "生徒はこのクラスに既に登録されています。この行を削除してください",
    "uz": "Bu sinfda talaba allaqachon mavjud. Iltimos, ushbu qatorni o'chirib tashlang",
    "viet": "Học sinh này đã tồn tại trong lớp học. Vui lòng xóa hàng này"
  },
  "teacher-already-exists-in-this-class-please-delete-this-row": {
    "arabic": "المعلم موجود بالفعل في هذا الفصل. يرجى حذف هذا الصف",
    "chinese": "該班級中已存在教師。請刪除這一行",
    "eng": "Teacher already exists in this class. Please delete this row",
    "hindi": "शिक्षक इस कक्षा में पहले से मौजूद है। कृपया इस पंक्ति को हटा दें",
    "ja": "先生はこのクラスに既に登録されています。この行を削除してください",
    "uz": "O'qituvchi bu sinfda allaqachon mavjud. Iltimos, ushbu qatorni o'chirib tashlang",
    "viet": "Giáo viên này đã tồn tại trong lớp học. Vui lòng xóa hàng này"
  },
  "assessment-creation": {
    "arabic": "إنشاء التقييم",
    "chinese": "評估創建",
    "eng": "Assessment Creation",
    "hindi": "आकलन निर्माण",
    "ja": "テストの作成",
    "uz": "Baholashni yaratish",
    "viet": "Tạo bài kiểm tra"
  },
  "here-is-a-summary-of-your-selection-of-questions": {
    "arabic": "فيما يلي ملخص لاختيارك من الأسئلة",
    "chinese": "這是您選擇的問題的摘要",
    "eng": "Here is a summary of your selection of questions",
    "hindi": "यहां आपके द्वारा चुने गए प्रश्नों का सारांश दिया गया है",
    "ja": "選択した問題の概要",
    "uz": "Bu yerda siz tanlagan savollarning qisqacha mazmuni",
    "viet": "Dưới đây là tóm tắt các câu hỏi được bạn lựa chọn"
  },
  "by-clicking-on-confirm-cerebry-will-create-the-assessment-for-you": {
    "arabic": "بالنقر فوق تأكيد , سيقوم Cerebry بإنشاء التقييم لك",
    "chinese": "點擊確認,Cerebry 將為您創建評估",
    "eng": "By Clicking on Confirm, Cerebry will create the assessment for you",
    "hindi": "कन्फर्म पर क्लिक करके, सेरेब्री आपके लिए मूल्यांकन तैयार करेगा",
    "ja": "確認をクリックすると、テストが作成されます",
    "uz": "Tasdiqlash tugmasini bosish orqali Cerebry siz uchun baho yaratadi",
    "viet": "Bằng cách bấm vào Xác nhận, Kyons x Cerebry sẽ tạo bài kiểm tra cho bạn"
  },
  "test-paper-submitted": {
    "arabic": "تم إرسال ورقة الاختبار",
    "chinese": "試卷提交",
    "eng": "Test Paper Submitted",
    "hindi": "टेस्ट पेपर सबमिट किया गया",
    "ja": "テストの提出",
    "uz": "Test hujjati topshirildi",
    "viet": "Đã nộp bài kiểm tra"
  },
  "you-will-no-longer-be-able-to-edit-this-submission-but-you-can-view-this-draft-from-the-'test-mode'": {
    "arabic": "لن تتمكن بعد الآن من تحرير هذا الإرسال. ولكن يمكنك عرض هذه المسودة من وضع الاختبار",
    "chinese": "您將無法再編輯此提交。但您可以從“測試模式”查看此草稿",
    "eng": "You will no longer be able to edit this submission. But you can view this draft from the 'Test Mode'",
    "hindi": "अब आप इस सबमिशन को संपादित नहीं कर पाएंगे। लेकिन आप इस मसौदे को 'टेस्ट मोड' से देख सकते हैं",
    "ja": "このテストの編集はできなくなります。ただし、下書きは「テストモード」から閲覧できます",
    "uz": "Siz bu yuborilgan xabarni boshqa tahrirlay olmaysiz. Ammo siz ushbu qoralamani &quot;Sinov rejimi&quot; dan ko'rishingiz mumkin",
    "viet": "Bạn sẽ không thể chỉnh sửa nội dung gửi này nữa. Nhưng bạn có thể xem bản nháp này từ 'Chế độ thử nghiệm'"
  },
  "go-to-test-mode": {
    "arabic": "انتقل إلى وضع الاختبار",
    "chinese": "進入測試模式",
    "eng": "Go to test mode",
    "hindi": "टेस्ट मोड में जाएं",
    "ja": "テストモードへ",
    "uz": "Sinov rejimiga o'ting",
    "viet": "Chuyển sang chế độ kiểm tra"
  },
  "please-enter-a-valid-name": {
    "arabic": "رجاء ادخل اسما صحيحا",
    "chinese": "請輸入一個有效的名字",
    "eng": "Please enter a valid name",
    "hindi": "कृपया कोई मान्य नाम दर्ज करें",
    "ja": "有効な名前を入力してください",
    "uz": "Yaroqli nom kiriting",
    "viet": "Xin vui lòng nhập tên hợp lệ"
  },
  "please-enter-a-valid-testduration": {
    "arabic": "الرجاء إدخال اختبار صالحالمدة",
    "chinese": "請輸入有效的測試持續時間",
    "eng": "Please enter a valid testDuration",
    "hindi": "कृपया एक मान्य परीक्षण अवधि दर्ज करें",
    "ja": "有効な受験期間を入力してください",
    "uz": "Iltimos, yaroqli testDuration kiriting",
    "viet": "Vui lòng nhập thời lượng kiểm tra hợp lệ"
  },
  "add-more-chapters": {
    "arabic": "أضف المزيد من الفصول",
    "chinese": "添加更多章節",
    "eng": "Add More Chapters",
    "hindi": "अधिक अध्याय जोड़ें",
    "ja": "チャプターを追加",
    "uz": "Qo'shimcha bo'limlar qo'shing",
    "viet": "Thêm chương khác"
  },
  "create-mock-test": {
    "arabic": "قم بإنشاء اختبار وهمي",
    "chinese": "創建模擬測試",
    "eng": "Create Mock Test",
    "hindi": "मॉक टेस्ट बनाएं",
    "ja": "テストを作成",
    "uz": "Soxta test yaratish",
    "viet": "Tạo Bài kiểm tra thử"
  },
  "edit-mock-test": {
    "arabic": "تحرير اختبار وهمي",
    "chinese": "編輯模擬測試",
    "eng": "Edit Mock Test",
    "hindi": "मॉक टेस्ट संपादित करें",
    "ja": "テストを編集",
    "uz": "Soxta testni tahrirlash",
    "viet": "Chỉnh sửa Bài kiểm tra thử"
  },
  "min": {
    "arabic": "دقيقة",
    "chinese": "分鐘",
    "eng": "min",
    "hindi": "मिनट",
    "ja": "分",
    "uz": "min",
    "viet": "tối thiểu"
  },
  "mins": {
    "arabic": "دقيقة",
    "chinese": "分鐘",
    "eng": "min(s)",
    "hindi": "मिनट",
    "ja": "(分)",
    "uz": "mening(larim)",
    "viet": "phút"
  },
  "one-or-more-Chapter-questions-are-hidden-you-can-reorder-questions-only-if-all-chapters-are-made-visible-through-filters": {
    "arabic": "تم إخفاء سؤال أو أكثر من أسئلة الفصل. لا يمكنك إعادة ترتيب الأسئلة إلا إذا كانت جميع الفصول مرئية من خلال عوامل التصفية",
    "chinese": "隱藏了一個或多個章節問題。只有當所有章節都通過過濾器可見時,您才能重新排序問題",
    "eng": "One or more Chapter questions are hidden. You can reorder questions only if all chapters are made visible through filters",
    "hindi": "एक या अधिक अध्याय प्रश्न छिपे हुए हैं। आप प्रश्नों का क्रम तभी बदल सकते हैं जब सभी अध्याय फिल्टर के माध्यम से दिखाई दें",
    "ja": "1つ以上のチャプターの問題が非表示になっています。すべてのチャプターがフィルターによって表示されるようになっている場合にのみ、問題を並べ替えることができます",
    "uz": "Bir yoki bir nechta bo'lim savollari yashirin. Agar barcha bo'limlar filtrlar orqali ko'rinadigan bo'lsa, savollarni qayta tartiblashingiz mumkin",
    "viet": "Một hoặc vài câu hỏi đã bị ẩn. Bạn chỉ có thể sắp xếp lại các câu hỏi nếu tất cả các chương được hiển thị thông qua các bộ lọc"
  },
  "toggle-Off-to-remove-from-mock-test": {
    "arabic": "قم بتبديل الإيقاف للإزالة من الاختبار الوهمي",
    "chinese": "切換關閉以從模擬測試中刪除",
    "eng": "Toggle Off to remove from Mock Test",
    "hindi": "मॉक टेस्ट से निकालने के लिए टॉगल ऑफ करें",
    "ja": "テストから削除",
    "uz": "Soxta sinovdan oʻchirish uchun “Oʻchirish” tugmasini bosing",
    "viet": "Tắt để xoá khỏi Bài kiểm tra thử"
  },
  "toggle-on-to-add-to-mock-test": {
    "arabic": "قم بالتبديل إلى تشغيل للإضافة إلى اختبار Mock",
    "chinese": "開啟以添加到模擬測試",
    "eng": "Toggle On to add to Mock Test",
    "hindi": "मॉक टेस्ट में जोड़ने के लिए टॉगल ऑन करें",
    "ja": "テストに追加",
    "uz": "Soxta testga qo'shish uchun yoqing",
    "viet": "Bật để thêm vào Bài kiểm tra thử"
  },
  "bookmark": {
    "arabic": "المرجعية",
    "chinese": "書籤",
    "eng": "Bookmark",
    "hindi": "बुकमार्क",
    "ja": "ブックマーク",
    "uz": "Xatcho‘p",
    "viet": "đánh dấu"
  },
  "bk-ques-can-be": {
    "arabic": "يمكن إضافة الأسئلة التي تم وضع إشارة مرجعية عليها في الاختبار الوهمي الخاص بك من شاشة عرض الأسئلة التي تم وضع إشارة مرجعية عليها",
    "chinese": "可以從“已添加書籤的問題視圖”屏幕將已添加書籤的問題添加到您的模擬測試中",
    "eng": "Bookmarked questions can be added into your mock test from the 'Bookmarked Questions View' Screen",
    "hindi": "बुकमार्क किए गए प्रश्नों को 'बुकमार्क किए गए प्रश्न व्यू' स्क्रीन से आपके मॉक टेस्ट में जोड़ा जा सकता है",
    "ja": "ブックマークされた問題は、[ブックマークされた問題の表示] 画面からテストに追加できます。",
    "uz": "Xatcho‘p qo‘yilgan savollarni “Xatcho‘plangan savollarni ko‘rish” ekranidan soxta testingizga qo‘shish mumkin",
    "viet": "Các câu hỏi được đánh dấu có thể được thêm vào Bài kiểm tra thử của bạn bằng cách vào trang 'Xem các Câu hỏi được đánh dấu'"
  },
  "Hint": {
    "arabic": "تَلمِيح",
    "chinese": "暗示",
    "eng": "Hint",
    "hindi": "संकेत देना",
    "ja": "ヒント",
    "uz": "Maslahat",
    "viet": "Gợi ý"
  },
  "toggle-on-to-enable-access-to-topic-now": {
    "arabic": "قم بالتبديل إلى تشغيل لتمكين الوصول إلى الموضوع الآن",
    "chinese": "開啟以立即啟用對主題的訪問",
    "eng": "Toggle On to enable access to topic now",
    "hindi": "विषय पर अभी पहुंच सक्षम करने के लिए चालू करें",
    "ja": "オンに切り替えると、生徒がトピックを閲覧できるようになります。",
    "uz": "Mavzuga hozir kirishni yoqish uchun yoqing",
    "viet": "Bật để cấp quyền truy cập vào chủ đề ngay bây giờ"
  },
  "end-date-is-required": {
    "arabic": "تاريخ الانتهاء مطلوب",
    "chinese": "結束日期是必需的",
    "eng": "End date is required",
    "hindi": "समाप्ति तिथि आवश्यक है",
    "ja": "終了日を設定する",
    "uz": "Tugash sanasi talab qilinadi",
    "viet": "Bắt buộc phải có ngày kết thúc"
  },
  "create-virtual-class": {
    "arabic": "إنشاء فئة افتراضية",
    "chinese": "創建虛擬課堂",
    "eng": "Create Virtual Class",
    "hindi": "वर्चुअल क्लास बनाएं",
    "ja": "バーチャルクラスの作成",
    "uz": "Virtual sinf yarating",
    "viet": "Tạo lớp học ảo"
  },
  "see-attendance": {
    "arabic": "انظر الحضور",
    "chinese": "查看考勤",
    "eng": "See Attendance",
    "hindi": "उपस्थिति देखें",
    "ja": "出席を確認する",
    "uz": "Davomatga qarang",
    "viet": "xem điểm danh"
  },
  "click-the-button-below-to-launch-a-virtual-class-right-now": {
    "arabic": "انقر فوق الزر أدناه لبدء فصل دراسي افتراضي الآن",
    "chinese": "單擊下面的按鈕立即啟動虛擬課程",
    "eng": "Click the button below to launch a virtual class right now",
    "hindi": "अभी वर्चुअल क्लास शुरू करने के लिए नीचे दिए गए बटन पर क्लिक करें",
    "ja": "下のボタンをクリックして、今すぐバーチャルクラスを起動します",
    "uz": "Hozirda virtual sinfni ishga tushirish uchun quyidagi tugmani bosing",
    "viet": "Nhấp vào nút bên dưới để bắt đầu một lớp học ảo ngay bây giờ"
  },
  "virtual-class": {
    "arabic": "فئة افتراضية",
    "chinese": "虛擬課堂",
    "eng": "Virtual Class",
    "hindi": "वर्चुअल क्लास",
    "ja": "バーチャルクラス",
    "uz": "Virtual sinf",
    "viet": "Lớp học ảo"
  },
  "class-selected": {
    "arabic": "فئة مختارة",
    "chinese": "所選班級",
    "eng": "Class Selected",
    "hindi": "वर्ग चयनित",
    "ja": "選択中のクラス",
    "uz": "Sinf tanlangan",
    "viet": "Lớp đã chọn"
  },
  "hi": {
    "arabic": "أهلاً",
    "chinese": "你好",
    "eng": "Hi",
    "hindi": "नमस्ते",
    "ja": "ようこそ",
    "uz": "Salom",
    "viet": "CHÀO"
  },
  "create-new-class": {
    "arabic": "أنشئ صفًا جديدًا",
    "chinese": "創建新類",
    "eng": "Create new class",
    "hindi": "नई कक्षा बनाएँ",
    "ja": "新しいクラスを作成",
    "uz": "Yangi sinf yarating",
    "viet": "Tạo lớp mới"
  },
  "most-correct-solves": {
    "arabic": "معظم الحلول الصحيحة",
    "chinese": "最正確的解決方案",
    "eng": "Most Correct Solves",
    "hindi": "सबसे सही समाधान",
    "ja": "最も正しい解答",
    "uz": "Eng to'g'ri echimlar",
    "viet": "Lời giải chính xác nhất"
  },
  "class-list": {
    "arabic": "قائمة الطبقة",
    "chinese": "班級名單",
    "eng": "Class List",
    "hindi": "वर्ग सूची",
    "ja": "クラス一覧",
    "uz": "Sinf ro'yxati",
    "viet": "Danh sách lớp"
  },
  "teach-now": {
    "arabic": "علم الآن",
    "chinese": "現在教",
    "eng": "Teach Now",
    "hindi": "अब सिखाओ",
    "ja": "今すぐ教える",
    "uz": "Hozir o'rgating",
    "viet": "dạy ngay"
  },
  "select-grade": {
    "arabic": "حدد الدرجة",
    "chinese": "選擇年級",
    "eng": "Select Grade",
    "hindi": "ग्रेड का चयन करें",
    "ja": "学年を選択",
    "uz": "Bahoni tanlang",
    "viet": "Chọn lớp"
  },
  "few-or-more-students-have-started-the-practice-are-you-sure-you-want-to-save": {
    "arabic": "بدأ عدد قليل أو أكثر من الطلاب هذه الممارسة , هل أنت متأكد من أنك تريد الحفظ",
    "chinese": "幾個或更多的學生開始練習,你確定要保存嗎",
    "eng": "Few or more students have started the practice, are you sure you want to save",
    "hindi": "कुछ या अधिक छात्रों ने अभ्यास शुरू कर दिया है, क्या आप सुनिश्चित हैं कि आप बचत करना चाहते हैं",
    "ja": "複数の生徒が学習を開始しました。保存しますか?",
    "uz": "Amaliyotni bir necha yoki undan koʻp talaba boshladi, siz saqlamoqchimisiz",
    "viet": "Ít hoặc nhiều học viên đã bắt đầu luyện tập, bạn có chắc chắn muốn lưu"
  },
  "on": {
    "arabic": "على",
    "chinese": "在",
    "eng": "On",
    "hindi": "पर",
    "ja": "オン",
    "uz": "Yoniq",
    "viet": "TRÊN"
  },
  "off": {
    "arabic": "عن",
    "chinese": "離開",
    "eng": "Off",
    "hindi": "बंद",
    "ja": "オフ",
    "uz": "Oʻchirilgan",
    "viet": "Tắt"
  },
  "saved": {
    "arabic": "أنقذ",
    "chinese": "已保存",
    "eng": "Saved",
    "hindi": "बचाया",
    "ja": "保存済",
    "uz": "Saqlangan",
    "viet": "Đã lưu"
  },
  "sorted-by": {
    "arabic": "مرتبة حسب",
    "chinese": "排序",
    "eng": "Sorted by",
    "hindi": "आधार पर छाँटे",
    "ja": "並べ替え",
    "uz": "Saralangan",
    "viet": "Sắp xếp theo"
  },
  "sort-by": {
    "arabic": "مرتبة حسب",
    "chinese": "排序",
    "eng": "Sort by",
    "hindi": "आधार पर छाँटे",
    "ja": "並べ替え",
    "uz": "Saralash turi",
    "viet": "Sor bởi"
  },
  "initial-progress": {
    "arabic": "التقدم الأولي",
    "chinese": "初步進展",
    "eng": "Initial Progress",
    "hindi": "प्रारंभिक प्रगति",
    "ja": "初期の進捗状況",
    "uz": "Dastlabki taraqqiyot",
    "viet": "Tiến độ ban đầu"
  },
  "progress-achieved": {
    "arabic": "التقدم المحرز",
    "chinese": "取得的進展",
    "eng": "Progress Achieved",
    "hindi": "प्रगति हासिल की",
    "ja": "進捗状況",
    "uz": "Erishilgan taraqqiyot",
    "viet": "Tiến độ đạt được"
  },
  "topics-practiced": {
    "arabic": "المواضيع التي تم التدرب عليها",
    "chinese": "實踐主題",
    "eng": "Topics Practiced",
    "hindi": "विषयों का अभ्यास किया",
    "ja": "学習済のトピック",
    "uz": "Amaliy mavzular",
    "viet": "chủ đề thực hành"
  },
  "levels": {
    "arabic": "المستويات",
    "chinese": "级别",
    "eng": "Levels",
    "hindi": "स्तरों",
    "ja": "レベル",
    "uz": "Darajalar",
    "viet": "cấp độ"
  },
  "set-levels": {
    "arabic": "تعيين المستويات",
    "chinese": "设置级别",
    "eng": "Set Levels",
    "hindi": "स्तर निर्धारित करें",
    "ja": "レベルを設定する",
    "uz": "Darajani o'rnatish",
    "viet": "Đặt cấp độ"
  },
  "assign-ninjas": {
    "arabic": "تعيين النينجا",
    "chinese": "分配忍者",
    "eng": "Assign Ninjas",
    "hindi": "निन्जा को असाइन करें",
    "ja": "忍者を割り当てる",
    "uz": "Ninjalarni tayinlang",
    "viet": "Chỉ định Ninja"
  },
  "teams": {
    "arabic": "فرق",
    "chinese": "团队",
    "eng": "Teams",
    "hindi": "टीमें",
    "ja": "チーム",
    "uz": "Jamoalar",
    "viet": "Đội"
  },
  "search-students": {
    "arabic": "البحث عن الطلاب",
    "chinese": "搜索學生",
    "eng": "Search Students",
    "hindi": "छात्र खोजें",
    "ja": "生徒を検索する",
    "uz": "Talabalarni qidirish",
    "viet": "Tìm kiếm sinh viên"
  },
  "confirm-password": {
    "arabic": "تأكيد كلمة المرور",
    "chinese": "確認密碼",
    "eng": "Confirm Password",
    "hindi": "पासवर्ड की पुष्टि कीजिये",
    "ja": "パスワードを認証する",
    "uz": "Parolni tasdiqlang",
    "viet": "Xác nhận mật khẩu"
  },
  "enter-test-name": {
    "arabic": "أدخل اسم الاختبار",
    "chinese": "輸入測試名稱",
    "eng": "Enter test name",
    "hindi": "परीक्षण नाम दर्ज करें",
    "ja": "テスト名を入力",
    "uz": "Test nomini kiriting",
    "viet": "Nhập tên kiểm tra"
  },
  "successfully-added-to-the-class": {
    "arabic": "تمت إضافته إلى الفصل بنجاح",
    "chinese": "成功加入班級",
    "eng": "successfully added to the class",
    "hindi": "कक्षा में सफलतापूर्वक जोड़ा गया",
    "ja": "クラスに正常に追加されました",
    "uz": "sinfga muvaffaqiyatli qo'shildi",
    "viet": "đã thêm thành công vào lớp"
  },
  "and-their-credentials-have-been-emailed-to-them.": {
    "arabic": "وتم إرسال بيانات اعتمادهم إليهم عبر البريد الإلكتروني.",
    "chinese": "他們的憑據已通過電子郵件發送給他們。",
    "eng": "and their credentials have been emailed to them.",
    "hindi": "और उनकी पहचान उन्हें ईमेल किया गया है।",
    "ja": "彼らの資格情報はメールで送信されました。",
    "uz": "va ularning hisob ma'lumotlari elektron pochta orqali yuborilgan.",
    "viet": "và thông tin đăng nhập của họ đã được gửi qua email cho họ."
  },
  "of-whom": {
    "arabic": "ممن",
    "chinese": "其中的",
    "eng": "of whom",
    "hindi": "जिनमें से",
    "ja": "誰の",
    "uz": "kimdan",
    "viet": "của ai"
  },
  "already-on-the-platform": {
    "arabic": "بالفعل على المنصة",
    "chinese": "已經在平台上",
    "eng": "already on the platform",
    "hindi": "पहले से ही मंच पर",
    "ja": "すでにプラットフォームに",
    "uz": "allaqachon platformada",
    "viet": "đã có trên nền tảng"
  },
  "was": {
    "arabic": "كان",
    "chinese": "曾是",
    "eng": "was",
    "hindi": "था",
    "ja": "だった",
    "uz": "edi",
    "viet": "đã từng là"
  },
  "were": {
    "arabic": "كان",
    "chinese": "是",
    "eng": "were",
    "hindi": "थे",
    "ja": "だった",
    "uz": "edi",
    "viet": "đã từng"
  },
  "o": {
    "arabic": "ا",
    "chinese": "歐",
    "eng": "o",
    "hindi": "थे",
    "ja": "〇",
    "uz": "O",
    "viet": "Ô"
  },
  "password-and-confirm-password-do-not-match": {
    "arabic": "كلمة المرور وتأكيد كلمة المرور غير متطابقين",
    "chinese": "密碼和確認密碼不匹配",
    "eng": "Password and Confirm Password do not match",
    "hindi": "पासवर्ड और कन्फर्म पासवर्ड मेल नहीं खाते",
    "ja": "パスワードと確認用パスワードが一致しません",
    "uz": "Parol va parolni tasdiqlash mos kelmaydi",
    "viet": "Mật khẩu và Xác nhận mật khẩu không khớp"
  },
  "add-using-phone-number": {
    "arabic": "أضف باستخدام رقم الهاتف",
    "chinese": "使用電話號碼添加",
    "eng": "Add using phone number",
    "hindi": "फ़ोन नंबर का उपयोग करके जोड़ें",
    "ja": "電話番号を追加",
    "uz": "Telefon raqamidan foydalanib qo'shing",
    "viet": "Thêm bằng số điện thoại"
  },
  "add-using-email-id": {
    "arabic": "أضف باستخدام معرف البريد الإلكتروني",
    "chinese": "使用電子郵件 ID 添加",
    "eng": "Add using Email ID",
    "hindi": "ईमेल आईडी का उपयोग कर जोड़ें",
    "ja": "メールアドレスを追加",
    "uz": "Email ID yordamida qo'shing",
    "viet": "Thêm bằng ID Email"
  },
  "add-using-username": {
    "arabic": "أضف باستخدام اسم المستخدم",
    "chinese": "使用用戶名添加",
    "eng": "Add using Username",
    "hindi": "उपयोगकर्ता नाम का उपयोग करके जोड़ें",
    "ja": "ユーザー名を追加",
    "uz": "Foydalanuvchi nomidan foydalanib qo'shing",
    "viet": "Thêm bằng tên người dùng"
  },
  "text": {
    "arabic": "نص",
    "chinese": "文本",
    "eng": "text",
    "hindi": "मूलपाठ",
    "ja": "本文",
    "uz": "matn",
    "viet": "chữ"
  },
  "day": {
    "arabic": "يوم",
    "chinese": "天",
    "eng": "day",
    "hindi": "दिन",
    "ja": "日",
    "uz": "kun",
    "viet": "ngày"
  },
  "days": {
    "arabic": "أيام",
    "chinese": "天",
    "eng": "days",
    "hindi": "दिन",
    "ja": "日",
    "uz": "kunlar",
    "viet": "ngày"
  },
  "hr": {
    "arabic": "ساعة",
    "chinese": "小時",
    "eng": "hr",
    "hindi": "घंटा",
    "ja": "時間",
    "uz": "soat",
    "viet": "giờ"
  },
  "not-started": {
    "arabic": "لم يبدأ",
    "chinese": "沒有開始",
    "eng": "Not Started",
    "hindi": "शुरू नहीं",
    "ja": "未開始",
    "uz": "Boshlanmagan",
    "viet": "Chưa bắt đầu"
  },
  "deadline-missed": {
    "arabic": "فات الموعد النهائي",
    "chinese": "錯過最後期限",
    "eng": "Deadline Missed",
    "hindi": "समय सीमा छूटी",
    "ja": "期限切れ",
    "uz": "Muddati o'tkazib yuborilgan",
    "viet": "Hạn chót bị bỏ lỡ"
  },
  "enable-partial-marking": {
    "arabic": "تمكين العلامة الجزئية",
    "chinese": "啟用部分標記",
    "eng": "Enable Partial Marking",
    "hindi": "आंशिक अंकन सक्षम करें",
    "ja": "部分点を有効にする",
    "uz": "Qisman belgilashni yoqing",
    "viet": "Kích hoạt đánh dấu một phần"
  },
  "enable-same-questions-for-all": {
    "arabic": "تمكين نفس الأسئلة للجميع",
    "chinese": "為所有人啟用相同的問題",
    "eng": "Enable same questions for all",
    "hindi": "सभी के लिए समान प्रश्न सक्षम करें",
    "ja": "すべての生徒に同じ問題を配信する",
    "uz": "Hamma uchun bir xil savollarni yoqing",
    "viet": "Bật cùng một câu hỏi cho tất cả"
  },
  "enable-corrective-practise": {
    "arabic": "تمكين الممارسة التصحيحية",
    "chinese": "啟用糾正措施",
    "eng": "Enable Corrective Practise",
    "hindi": "सुधारात्मक अभ्यास सक्षम करें",
    "ja": "テスト完了後に復習問題を自動配信する",
    "uz": "Tuzatish amaliyotini yoqing",
    "viet": "Kích hoạt thực hành khắc phục"
  },
  "jan": {
    "arabic": "يناير",
    "chinese": "簡",
    "eng": "Jan",
    "hindi": "जनवरी",
    "ja": "1月",
    "uz": "Yanvar",
    "viet": "Tháng một"
  },
  "feb": {
    "arabic": "فبراير",
    "chinese": "二月",
    "eng": "Feb",
    "hindi": "फ़रवरी",
    "ja": "2月",
    "uz": "Fevral",
    "viet": "Tháng Hai"
  },
  "mar": {
    "arabic": "مار",
    "chinese": "三月",
    "eng": "Mar",
    "hindi": "मार्च",
    "ja": "3月",
    "uz": "mart",
    "viet": "tháng ba"
  },
  "apr": {
    "arabic": "أبريل",
    "chinese": "4月",
    "eng": "Apr",
    "hindi": "अप्रैल",
    "ja": "4月",
    "uz": "aprel",
    "viet": "tháng tư"
  },
  "may": {
    "arabic": "يمكن",
    "chinese": "可能",
    "eng": "May",
    "hindi": "मई",
    "ja": "5月",
    "uz": "may",
    "viet": "Có thể"
  },
  "june": {
    "arabic": "يونيه",
    "chinese": "六月",
    "eng": "June",
    "hindi": "जून",
    "ja": "6月",
    "uz": "iyun",
    "viet": "Tháng sáu"
  },
  "july": {
    "arabic": "يوليو",
    "chinese": "七月",
    "eng": "July",
    "hindi": "जुलाई",
    "ja": "7月",
    "uz": "iyul",
    "viet": "Tháng bảy"
  },
  "aug": {
    "arabic": "أغسطس",
    "chinese": "八月",
    "eng": "Aug",
    "hindi": "अगस्त",
    "ja": "8月",
    "uz": "avgust",
    "viet": "tháng 8"
  },
  "sept": {
    "arabic": "سبتمبر",
    "chinese": "九月",
    "eng": "Sept",
    "hindi": "सितंबर",
    "ja": "9月",
    "uz": "sentabr",
    "viet": "tháng 9"
  },
  "oct": {
    "arabic": "أكتوبر",
    "chinese": "十月",
    "eng": "Oct",
    "hindi": "अक्टूबर",
    "ja": "10月",
    "uz": "Oktyabr",
    "viet": "tháng mười"
  },
  "nov": {
    "arabic": "نوفمبر",
    "chinese": "十一月",
    "eng": "Nov",
    "hindi": "नवंबर",
    "ja": "11月",
    "uz": "Noyabr",
    "viet": "tháng 11"
  },
  "dec": {
    "arabic": "ديسمبر",
    "chinese": "十二月",
    "eng": "Dec",
    "hindi": "दिसम्बर",
    "ja": "12月",
    "uz": "dekabr",
    "viet": "Tháng mười hai"
  },
  "mon": {
    "arabic": "الاثنين",
    "chinese": "我的",
    "eng": "Mon",
    "hindi": "सोमवार",
    "ja": "月",
    "uz": "mening",
    "viet": "Của tôi"
  },
  "tue": {
    "arabic": "الثلاثاء",
    "chinese": "星期二",
    "eng": "Tue",
    "hindi": "मंगल",
    "ja": "火",
    "uz": "Seshanba",
    "viet": "thứ ba"
  },
  "wed": {
    "arabic": "تزوج",
    "chinese": "星期三",
    "eng": "Wed",
    "hindi": "बुध",
    "ja": "水",
    "uz": "Chorshanba",
    "viet": "Thứ Tư"
  },
  "thu": {
    "arabic": "خميس",
    "chinese": "收集",
    "eng": "Thu",
    "hindi": "गुरु",
    "ja": "木",
    "uz": "Yig'ish",
    "viet": "Thu"
  },
  "fri": {
    "arabic": "الجمعة",
    "chinese": "星期五",
    "eng": "Fri",
    "hindi": "शुक्र",
    "ja": "金",
    "uz": "Juma",
    "viet": "thứ sáu"
  },
  "concept": {
    "arabic": "مفهوم",
    "chinese": "概念",
    "eng": "Concept",
    "hindi": "अवधारणा",
    "ja": "コンセプト",
    "uz": "Kontseptsiya",
    "viet": "Ý tưởng"
  },
  "one": {
    "arabic": "واحد",
    "chinese": "概念",
    "eng": "Concept",
    "hindi": "एक",
    "ja": "コンセプト",
    "uz": "Kontseptsiya",
    "viet": "Ý tưởng"
  },
  "design-test": {
    "arabic": "اختبار التصميم",
    "chinese": "设计测试",
    "eng": "Design Test",
    "hindi": "डिजाइन टेस्ट",
    "ja": "テストを作成",
    "uz": "Dizayn sinovi",
    "viet": "Kiểm tra thiết kế"
  },
  "Notification": {
    "arabic": null,
    "chinese": null,
    "eng": "Notification",
    "hindi": null,
    "ja": "学習状況",
    "uz": "Bildirishnoma",
    "viet": null
  },
  "Date & Time": {
    "arabic": "التاريخ والوقت",
    "chinese": "约会时间",
    "eng": "Date & Time",
    "hindi": "दिनांक समय",
    "ja": "日時",
    "uz": "Sana va vaqt",
    "viet": "Ngày giờ"
  },
  "Reminder": {
    "arabic": "تذكير ",
    "chinese": "提醒",
    "eng": "Reminder",
    "hindi": "अनुस्मारक",
    "ja": "種別",
    "uz": "Eslatma",
    "viet": "Lời nhắc nhở"
  },
  "Status": {
    "arabic": "حالة ",
    "chinese": "地位",
    "eng": "Status",
    "hindi": "स्थिति",
    "ja": "ステータス",
    "uz": "Holat",
    "viet": "Trạng thái"
  },
  "descriptions": {
    "arabic": "وصف ",
    "chinese": "描述",
    "eng": "Description",
    "hindi": "विवरण",
    "ja": "詳細",
    "uz": "Tavsif",
    "viet": "Sự miêu tả"
  },
  "alert": {
    "arabic": null,
    "chinese": null,
    "eng": "Alert",
    "hindi": null,
    "ja": "アラート",
    "uz": "Ogohlantirish",
    "viet": null
  },
  "milestones": {
    "arabic": null,
    "chinese": null,
    "eng": "Milestones",
    "hindi": null,
    "ja": "マイルストン",
    "uz": "Marosimlar",
    "viet": null
  },
  "updates": {
    "arabic": null,
    "chinese": null,
    "eng": "Updates",
    "hindi": null,
    "ja": "アップデート",
    "uz": "Yangilanishlar",
    "viet": null
  },
  "currently-on": {
    "arabic": "حاليا على",
    "chinese": "目前开启",
    "eng": "Currently on",
    "hindi": "फ़िलहाल चालू है",
    "ja": "現在学習中",
    "uz": "Hozirda",
    "viet": "Hiện tại trên"
  },
  "Topic": {
    "arabic": "عنوان",
    "chinese": "话题 ",
    "eng": "Topic",
    "hindi": "विषय",
    "ja": "トピック",
    "uz": "Mavzu",
    "viet": "Đề tài"
  },
  "mastered": {
    "arabic": "يتقن",
    "chinese": "掌握了 ",
    "eng": "Mastered",
    "hindi": "महारत हासिल",
    "ja": "発展",
    "uz": "O'zlashtirilgan",
    "viet": "làm chủ được"
  },
  "set-Levels-(multiple-students)": {
    "arabic": null,
    "chinese": null,
    "eng": "Set Levels (Multiple Students)",
    "hindi": null,
    "ja": "レベルを設定する (複数の生徒)",
    "uz": "Darajalarni belgilash (bir nechta talabalar)",
    "viet": null
  },
  "explored": {
    "arabic": "استكشاف",
    "chinese": "探索 ",
    "eng": "Explored",
    "hindi": "पता लगाया",
    "ja": "標準",
    "uz": "Tadqiq qilingan",
    "viet": "Đã khám phá"
  },
  "Learnt": {
    "arabic": "تعلمت",
    "chinese": "学到了 ",
    "eng": "Learnt",
    "hindi": "सीखा",
    "ja": "基本",
    "uz": "O'rgangan",
    "viet": "Đã học"
  },
  "learnt": {
    "arabic": "تعلمت",
    "chinese": "学到了 ",
    "eng": "Learnt",
    "hindi": "सीखा",
    "ja": "基本",
    "uz": "O'rgangan",
    "viet": "Đã học"
  },
  "pending": {
    "arabic": "قيد الانتظار",
    "chinese": "待办的 ",
    "eng": "Pending",
    "hindi": "लंबित",
    "ja": "未完了",
    "uz": "Kutilmoqda",
    "viet": "Chưa giải quyết"
  },
  "Yet-to-master": {
    "arabic": "بعد لإتقان ",
    "chinese": "尚未掌握 ",
    "eng": "Yet-to-Learnt",
    "hindi": "अभी तक मास्टर करने के लिए",
    "ja": "未習熟",
    "uz": "Hali o'rganilmagan",
    "viet": "Chưa thành thạo"
  },
  "collapse-all-students-alerts": {
    "arabic": "انهيار جميع تنبيهات الطلاب ",
    "chinese": "折叠所有学生警报 ",
    "eng": "Collapse all students alerts",
    "hindi": "पतन-सभी-छात्र-अलर्ट",
    "ja": "すべて閉じる",
    "uz": "Barcha talabalar ogohlantirishlarini yopish",
    "viet": "thu gọn-tất cả sinh viên-cảnh báo"
  },
  "collapse-response-&-solution": {
    "arabic": "طي الاستجابة والحل ",
    "chinese": "折叠响应和解决方案 ",
    "eng": "Collapse Response & Solution",
    "hindi": "पतन-प्रतिक्रिया-और-समाधान",
    "ja": "答案と解説を閉じる",
    "uz": "Yopish javobi va yechim",
    "viet": "thu gọn-phản hồi & giải pháp"
  },
  "expand-all-students-alerts": {
    "arabic": " توسيع جميع تنبيهات الطلاب",
    "chinese": "展开所有学生警报 ",
    "eng": "Expand all students alerts",
    "hindi": "विस्तार-सभी-छात्र-अलर्ट",
    "ja": "すべて開く",
    "uz": "Barcha talabalar ogohlantirishlarini kengaytirish",
    "viet": "mở rộng-tất cả-sinh viên-cảnh báo"
  },
  "expand-response-&-solution": {
    "arabic": "توسيع الاستجابة والحل ",
    "chinese": "展开响应和解决方案 ",
    "eng": "Expand Response & Solution",
    "hindi": "विस्तार-प्रतिक्रिया-और-समाधान",
    "ja": "答案と解説を開く",
    "uz": "Javob va yechim ni kengaytiring",
    "viet": "mở rộng-phản hồi & giải pháp"
  },
  "unresolved": {
    "arabic": null,
    "chinese": null,
    "eng": "Unresolved",
    "hindi": null,
    "ja": "未解決",
    "uz": "Yechilmagan",
    "viet": null
  },
  "progress-in-skills": {
    "arabic": " توسيع جميع تنبيهات الطلاب",
    "chinese": "技能进步 ",
    "eng": "Progress-in-skills",
    "hindi": "कौशल में प्रगति",
    "ja": "学習の進捗",
    "uz": "Ko'nikmalarning rivojlanishi",
    "viet": "kỹ năng tiến bộ"
  },
  "this-day": {
    "arabic": null,
    "chinese": null,
    "eng": "This day",
    "hindi": null,
    "ja": "今日",
    "uz": "Shu kun",
    "viet": null
  },
  "this-week": {
    "arabic": null,
    "chinese": null,
    "eng": "This week",
    "hindi": null,
    "ja": "今週",
    "uz": "Ushbu hafta",
    "viet": null
  },
  "this-month": {
    "arabic": null,
    "chinese": null,
    "eng": "This month",
    "hindi": null,
    "ja": "今月",
    "uz": "Shu oy",
    "viet": null
  },
  "custom": {
    "arabic": null,
    "chinese": null,
    "eng": "Custom",
    "hindi": null,
    "ja": "カスタム",
    "uz": "Maxsus",
    "viet": null
  },
  "total-learning-time-mins": {
    "arabic": "إجمالي وقت التعلم بالدقائق",
    "chinese": "总学习时间分钟",
    "eng": "Total learning time mins",
    "hindi": "कुल-सीखने-समय-मिनट",
    "ja": "合計学習時間(分)",
    "uz": "Jami o'rganish vaqti min",
    "viet": "Tổng thời gian học tập-phút"
  },
  "total-learning-time": {
    "arabic": "إجمالي وقت التعلم بالدقائق",
    "chinese": "总学习时间分钟",
    "eng": "Total Learning Time",
    "hindi": "कुल-सीखने-समय-मिनट",
    "ja": "合計学習時間",
    "uz": "Umumiy o'rganish vaqti",
    "viet": "Tổng thời gian học tập-phút"
  },
  "total-time-on-cerebry": {
    "arabic": "إجمالي الوقت في المخ",
    "chinese": "大脑总时间",
    "eng": "Total time on Cerebry",
    "hindi": "सेरेब्री पर कुल समय",
    "ja": "これまでの累計学習時間",
    "uz": "Cerebry-dagi umumiy vaqt",
    "viet": "Tổng thời gian trên não"
  },
  "total": {
    "arabic": "المجموع",
    "chinese": "全部的",
    "eng": "Total",
    "hindi": "कुल",
    "ja": "合計",
    "uz": "Jami",
    "viet": "tổng cộng"
  },
  "learning": {
    "arabic": "تعلُّم",
    "chinese": "學習",
    "eng": "Learning",
    "hindi": "सीखना",
    "ja": "学ぶ",
    "uz": "O'rganish",
    "viet": "học hỏi"
  },
  "time": {
    "arabic": " وقت",
    "chinese": "時間",
    "eng": "Time",
    "hindi": "समय",
    "ja": "時間",
    "uz": "Vaqt",
    "viet": "thời gian"
  },
  "q.no": {
    "arabic": "  سؤال: لا ",
    "chinese": "问号",
    "eng": "Q.no",
    "hindi": "प्र.सं",
    "ja": "問題番号",
    "uz": "Savol yo'q",
    "viet": "Q.không"
  },
  "difficulty": {
    "arabic": " صعوبة",
    "chinese": "困难",
    "eng": "Difficulty",
    "hindi": "कठिनाई",
    "ja": "難易度",
    "uz": "Qiyinchilik",
    "viet": "Khó khăn"
  },
  "skill-associated": {
    "arabic": "المهارة المرتبطة",
    "chinese": "技能相关",
    "eng": "Skill Associated",
    "hindi": "कौशल संबद्ध",
    "ja": "関連する学習目標",
    "uz": "Skill Associated",
    "viet": "Kỹ năng liên quan"
  },
  "number-of-attempts": {
    "arabic": "عدد المحاولات",
    "chinese": "尝试次数",
    "eng": "Number of Attempts",
    "hindi": "प्रयासों की संख्या",
    "ja": "解答回数",
    "uz": "Urinishlar soni",
    "viet": " Số lần thử "
  },
  "time-per-attempt": {
    "arabic": "الوقت لكل محاولة",
    "chinese": "每次尝试的时间",
    "eng": "Time Per Attempt",
    "hindi": "प्रति प्रयास समय",
    "ja": "解答時間",
    "uz": "Har bir urinish vaqti",
    "viet": " Thời gian mỗi lần thử"
  },
  "show-other-questions": {
    "arabic": "عرض الأسئلة الأخرى",
    "chinese": "显示其他问题",
    "eng": "Show others questions",
    "hindi": "अन्य प्रश्न दिखाएँ",
    "ja": "他の問題を表示",
    "uz": "Boshqalarga savollarni ko'rsating",
    "viet": " Hiển thị các câu hỏi khác"
  },
  "hide-other-questions": {
    "arabic": "عرض الأسئلة الأخرى",
    "chinese": "显示其他问题",
    "eng": "Hide others questions",
    "hindi": "अन्य प्रश्न दिखाएँ",
    "ja": "他の問題を隠す",
    "uz": "Boshqa savollarni yashirish",
    "viet": " Hiển thị các câu hỏi khác"
  },
  "alert-notification-description": {
    "arabic": " وصف إشعار التنبيه",
    "chinese": "警报通知说明",
    "eng": "Alert Notification Description?",
    "hindi": "चेतावनी अधिसूचना विवरण",
    "ja": "詳細",
    "uz": "Ogohlantirish bildirishnomasi tavsifi?",
    "viet": " Mô tả thông báo cảnh báo"
  },
  "alert-milestone-description": {
    "arabic": " وصف إشعار التنبيه",
    "chinese": "警报通知说明",
    "eng": "Alert/Milestone Description?",
    "hindi": "चेतावनी अधिसूचना विवरण",
    "ja": "詳細",
    "uz": "Ogohlantirish/baza tavsifi?",
    "viet": " Mô tả thông báo cảnh báo"
  },
  "connect-to-google-class": {
    "arabic": "الاتصال بجوجل كلاس",
    "chinese": "连接到 Google 课堂",
    "eng": "Connect to Google Classroom",
    "hindi": "Google क्लास से कनेक्ट करें",
    "ja": "Googleクラスに接続する",
    "uz": "Google Classroom xizmatiga ulaning",
    "viet": "Kết nối với Lớp học Google"
  },
  "connect-to-google-classroom": {
    "arabic": null,
    "chinese": null,
    "eng": "Connect to Google Classroom",
    "hindi": null,
    "ja": null,
    "uz": "Google Classroom xizmatiga ulaning",
    "viet": null
  },
  "select-google-classroom-classes": {
    "arabic": null,
    "chinese": null,
    "eng": "Select Google Classroom classes",
    "hindi": null,
    "ja": null,
    "uz": "Google Classroom kurslarini tanlang",
    "viet": null
  },
  "disconnect-google-classroom": {
    "arabic": null,
    "chinese": null,
    "eng": "Disconnect Google Classroom",
    "hindi": null,
    "ja": null,
    "uz": "Google Classroom-ni uzing",
    "viet": null
  },
  "are-you-sure-you-want-to-disconnect?": {
    "arabic": null,
    "chinese": null,
    "eng": "Are you sure you want to disconnect?",
    "hindi": null,
    "ja": null,
    "uz": "Haqiqatan ham aloqani uzmoqchimisiz?",
    "viet": null
  },
  "yes-go-ahead": {
    "arabic": null,
    "chinese": null,
    "eng": "Yes Go ahead",
    "hindi": null,
    "ja": null,
    "uz": "Ha, davom eting",
    "viet": null
  },
  "published-to-google-classroom,-if-connected": {
    "arabic": null,
    "chinese": null,
    "eng": "Published to Google Classroom, if connected",
    "hindi": null,
    "ja": null,
    "uz": "Agar ulangan boʻlsa, Google Classroom’da chop etiladi",
    "viet": null
  },
  "no-student-report-found": {
    "arabic": "لم يتم العثور على تقرير الطالب",
    "chinese": "未找到学生报告",
    "eng": "No student report found",
    "hindi": "किसी छात्र की रिपोर्ट नहीं मिली",
    "ja": "データが見つかりません",
    "uz": "Hech qanday talaba hisoboti topilmadi",
    "viet": " Không tìm thấy báo cáo của sinh viên"
  },
  "report-a-bug": {
    "arabic": "الإبلاغ عن خطأ",
    "chinese": "报告错误",
    "eng": "Report a Bug",
    "hindi": "बग की रिपोर्ट करें",
    "ja": "バグを報告する",
    "uz": "Xatolikni xabar qilish",
    "viet": "Báo cáo lỗi"
  },
  "please-check-your-student-filter": {
    "arabic": "يرجى التحقق من مرشح الطالب الخاص بك",
    "chinese": "请检查您的学生过滤器",
    "eng": "Please Check Your Student Filter",
    "hindi": "कृपया अपना छात्र फ़िल्टर जांचें",
    "ja": "フィルターを確認し、再度お試しください",
    "uz": "Iltimos, talaba filtringizni tekshiring",
    "viet": " Vui lòng kiểm tra bộ lọc sinh viên của bạn"
  },
  "view Attempt": {
    "arabic": " عرض المحاولة",
    "chinese": "查看尝试",
    "eng": "View Attempt",
    "hindi": "प्रयास देखें",
    "ja": "学習履歴",
    "uz": "Ko'rish urinishi",
    "viet": " Xem lần thử"
  },
  "give Feedback": {
    "arabic": " إعطاء ردود الفعل",
    "chinese": "给予反馈",
    "eng": "Give Feedback",
    "hindi": "प्रतिक्रिया दें",
    "ja": "フィードバックする",
    "uz": "Fikr bildirish",
    "viet": "Cung cấp thông tin phản hồi"
  },
  "more": {
    "arabic": "أكثر",
    "chinese": "更多的",
    "eng": "More",
    "hindi": "अधिक",
    "ja": "さらに表示",
    "uz": "Ko'proq",
    "viet": "Hơn"
  },
  "less": {
    "arabic": "أكثر",
    "chinese": "更多的",
    "eng": "Less",
    "hindi": "अधिक",
    "ja": "少なく表示",
    "uz": "Ozroq",
    "viet": "Hơn"
  },
  "Number-of-Attempts": {
    "arabic": "عدد المحاولات",
    "chinese": "尝试次数",
    "eng": "Number of Attempts",
    "hindi": "प्रयासों की संख्या",
    "ja": "解答回数",
    "uz": "Urinishlar soni",
    "viet": "Số lần thử"
  },
  "question-attempts": {
    "arabic": "محاولات السؤال",
    "chinese": "提问尝试",
    "eng": "Question Attempts",
    "hindi": "प्रश्न प्रयास",
    "ja": "解答",
    "uz": "Savolga urinishlar",
    "viet": "Câu hỏi cố gắng"
  },
  "Indicates-that-the-question-was-attempted-correctly": {
    "arabic": "يشير إلى أنه تمت محاولة السؤال بشكل صحيح.",
    "chinese": "表明该问题已正确尝试。",
    "eng": "Indicates that the question was attempted correctly.",
    "hindi": "यह दर्शाता है कि प्रश्न का प्रयास सही ढंग से किया गया था।",
    "ja": "質問が正しく試みられたことを示します。",
    "uz": "Savol to'g'ri berilganligini bildiradi.",
    "viet": "Cho biết rằng câu hỏi đã được thử chính xác."
  },
  "Indicates-that-the-question-was-initially-passed-by-the-student": {
    "arabic": "يشير إلى أن السؤال قد تم اجتيازه في البداية من قبل الطالب",
    "chinese": "表示该问题最初由学生通过",
    "eng": "Indicates that the question was initially passed by the student",
    "hindi": "इंगित करता है कि प्रश्न प्रारंभ में छात्र द्वारा उत्तीर्ण किया गया था",
    "ja": "質問が最初に学生によって渡されたことを示します",
    "uz": "Savol dastlab talaba tomonidan berilganligini bildiradi",
    "viet": "Cho biết rằng câu hỏi ban đầu đã được học sinh thông qua"
  },
  "Indicates-that-the-question-was-attempted-wrong-The-student-may-have-eventually-gotten-this-question-correct-and-moved-forward": {
    "arabic": "يشير إلى أن السؤال تمت محاولته بطريقة خاطئة. ربما يكون الطالب قد حصل في النهاية على هذا السؤال بشكل صحيح وانتقل إلى الأمام",
    "chinese": "表明该问题尝试错误。学生可能最终答对了这个问题并继续前进",
    "eng": "Indicates that the question was attempted wrong. The student may have eventually gotten this question correct and moved forward",
    "hindi": "यह दर्शाता है कि प्रश्न का प्रयास गलत किया गया था। हो सकता है कि छात्र को अंततः यह प्रश्न सही मिल गया हो और वह आगे बढ़ गया हो",
    "ja": "質問の試みが間違っていたことを示します。生徒は最終的にこの問題を正解し、先に進むことができたかもしれません",
    "uz": "Savol noto'g'ri berilganligini bildiradi. Oxir oqibat, talaba bu savolni to'g'ri qabul qilib, oldinga siljigan bo'lishi mumkin",
    "viet": "Cho biết rằng câu hỏi đã được thử sai. Học sinh cuối cùng có thể đã trả lời đúng câu hỏi này và tiếp tục"
  },
  "Indicates-that-the-question-was-attempted-wrong-in-all-attempts": {
    "arabic": "يشير إلى أن السؤال تمت محاولته بشكل خاطئ في جميع المحاولات",
    "chinese": "表示该问题在所有尝试中均出现错误",
    "eng": "Indicates that the question was attempted wrong in all attempts",
    "hindi": "यह इंगित करता है कि सभी प्रयासों में प्रश्न गलत हल किया गया था",
    "ja": "質問がすべての試行で間違っていたことを示します",
    "uz": "Savol barcha urinishlarda noto‘g‘ri berilganligini bildiradi",
    "viet": "Cho biết rằng câu hỏi đã được thử sai trong tất cả các lần thử"
  },
  "Indicates-the-number-of-variant-questions-attempted-by-the-student": {
    "arabic": "يشير إلى عدد الأسئلة المتنوعة التي حاول الطالب الإجابة عليها",
    "chinese": "表示‘n’，学生尝试的不同问题的数量",
    "eng": "Indicates ‘n’, the number of variant questions attempted by the student",
    "hindi": "‘n’ को दर्शाता है, जो छात्र द्वारा हल किए गए विभिन्न प्रश्नों की संख्या है",
    "ja": "「n」は生徒が試みた変形質問の数を示します",
    "uz": "&quot;n&quot; ni bildiradi, talaba tomonidan qo'yilgan variantli savollar soni",
    "viet": "Cho biết ‘n’, số lượng câu hỏi biến thể mà học sinh đã thử"
  },
  "Indicates-that-the-Question-7-was-attempted-incorrectly-in-all-of-the-12-attempts": {
    "arabic": "يشير إلى أنه تمت محاولة طرح السؤال 7 بشكل غير صحيح في جميع المحاولات الـ 12.",
    "chinese": "表明问题 7 在 12 次尝试中全部尝试错误。",
    "eng": "Indicates that the Question 7 was attempted incorrectly in all of the 12 attempts.",
    "hindi": "इंगित करता है कि प्रश्न 7 को सभी 12 प्रयासों में गलत तरीके से हल किया गया था।",
    "ja": "質問 7 は 12 回の試行すべてで不正解であったことを示します。",
    "uz": "12 ta urinishning barchasida 7-savol noto'g'ri qilinganligini ko'rsatadi.",
    "viet": "Cho biết rằng Câu hỏi 7 đã được thực hiện sai trong tất cả 12 lần thử."
  },
  "Indicates-that-the-Question-10-was-attempted-correctly-in-all-of-the-2-attempts": {
    "arabic": "يشير إلى أنه تمت محاولة طرح السؤال 10 بشكل صحيح في كل المحاولتين.",
    "chinese": "表示问题 10 在 2 次尝试中全部正确。",
    "eng": "Indicates that the Question 10 was attempted correctly in all of the 2 attempts.",
    "hindi": "यह दर्शाता है कि प्रश्न 10 को सभी 2 प्रयासों में सही ढंग से हल किया गया था।",
    "ja": "質問 10 は 2 回すべて正解したことを示します。",
    "uz": "10-savol 2 urinishning barchasida to'g'ri bajarilganligini ko'rsatadi.",
    "viet": "Cho biết rằng Câu hỏi 10 đã được thực hiện đúng trong cả 2 lần thực hiện."
  },
  "legend": {
    "arabic": "أسطورة",
    "chinese": "传奇",
    "eng": "Legend",
    "hindi": "दंतकथा",
    "ja": "伝説",
    "uz": "Afsona",
    "viet": "Huyền thoại"
  },
  "examples": {
    "arabic": "أمثلة",
    "chinese": "例子",
    "eng": "Examples",
    "hindi": "उदाहरण",
    "ja": "例",
    "uz": "Misollar",
    "viet": "Ví dụ"
  },
  "milestone": {
    "arabic": "منعطف",
    "chinese": "里程碑",
    "eng": "Milestone",
    "hindi": "माइलस्टोन ",
    "ja": "マイルストン",
    "uz": "Muhim bosqich",
    "viet": "Cột mốc quan trọng"
  },
  "exploring": {
    "arabic": "الاستكشاف",
    "chinese": "探索",
    "eng": "Exploring",
    "hindi": "तलाश",
    "ja": "探検する",
    "uz": "Tadqiq qilish",
    "viet": "Khám phá"
  },
  "Exam-Name": {
    "arabic": "اسم الامتحان",
    "chinese": "考试名称",
    "eng": "Exam Name",
    "hindi": "परीक्षा का नाम",
    "ja": "テスト名",
    "uz": "Imtihon nomi",
    "viet": "Tên kỳ thi"
  },
  "Duration": {
    "arabic": "مدة",
    "chinese": "期间",
    "eng": "Duration",
    "hindi": "अवधि",
    "ja": "制限時間",
    "uz": "Davomiyligi",
    "viet": "Khoảng thời gian"
  },
  "Upload-Logo": {
    "arabic": "تحميل الشعار",
    "chinese": "上传标志",
    "eng": "Upload Logo",
    "hindi": "लोगो अपलोड करें",
    "ja": "画像／ロゴのアップロード",
    "uz": "Logotip yuklash",
    "viet": "Tải lên biểu trưng"
  },
  "Select-Font": {
    "arabic": "حدد الخط",
    "chinese": "选择字体",
    "eng": "Select Font",
    "hindi": "फ़ॉन्ट चुनें",
    "ja": "フォント",
    "uz": "Shrift-ni tanlang",
    "viet": "Chọn phông chữ"
  },
  "Custom-Heading-1": {
    "arabic": "عنوان مخصص 1",
    "chinese": "自定义标题 1",
    "eng": "Custom Heading 1",
    "hindi": "कस्टम शीर्षक 1",
    "ja": "見出し１",
    "uz": "Maxsus sarlavha 1",
    "viet": "Tiêu đề tùy chỉnh 1"
  },
  "Custom-Heading-2": {
    "arabic": "عنوان مخصص 2",
    "chinese": "自定义标题 2",
    "eng": "Custom Heading 2",
    "hindi": "कस्टम शीर्षक 2",
    "ja": "見出し2",
    "uz": "Maxsus sarlavha 2",
    "viet": "Tiêu đề tùy chỉnh 2"
  },
  "Custom-Heading-3": {
    "arabic": "عنوان مخصص 3",
    "chinese": "自定义标题 3",
    "eng": "Custom Heading 3",
    "hindi": "कस्टम शीर्षक 3",
    "ja": "見出し3",
    "uz": "Maxsus sarlavha 3",
    "viet": "Tiêu đề tùy chỉnh 3"
  },
  "General-Instructions": {
    "arabic": "تعليمات عامة",
    "chinese": "一般说明",
    "eng": "General Instructions",
    "hindi": "सामान्य निर्देश",
    "ja": "テストの指示",
    "uz": "Umumiy ko'rsatmalar",
    "viet": "Hướng dẫn chung"
  },
  "total-n-marks": {
    "arabic": null,
    "chinese": null,
    "eng": "Total {replace_rgx_1} Mark(s)",
    "hindi": null,
    "ja": "合計 {replace_rgx_1} 点",
    "uz": "Jami {replace_rgx_1} belgi(lar)",
    "viet": "Tổng số {replace_rgx_1} điểm"
  },
  "attempt-n": {
    "arabic": "{replace_rgx_1}",
    "chinese": "{replace_rgx_1}",
    "eng": "Attempt {replace_rgx_1}",
    "hindi": "{replace_rgx_1}",
    "ja": "{replace_rgx_1}回目",
    "uz": "Urinish: {replace_rgx_1}",
    "viet": "{replace_rgx_1}"
  },
  "n-mark(s)": {
    "arabic": null,
    "chinese": null,
    "eng": "<strong>{replace_rgx_1}</strong> Mark(s)",
    "hindi": null,
    "ja": "<strong>{replace_rgx_1}</strong> 点",
    "uz": "<strong>{replace_rgx_1}</strong> Belgi(lar)",
    "viet": "<strong>{replace_rgx_1}</strong> Điểm"
  },
  "on-time": {
    "arabic": null,
    "chinese": null,
    "eng": "On {replace_rgx_1}",
    "hindi": null,
    "ja": "{replace_rgx_1}",
    "uz": "{replace_rgx_1} da",
    "viet": "TRÊN {replace_rgx_1}"
  },
  "obtained": {
    "arabic": null,
    "chinese": null,
    "eng": "Obtained",
    "hindi": null,
    "ja": "取得しました",
    "uz": "Olingan",
    "viet": "Thu được"
  },
  "publish-to-classes": {
    "arabic": null,
    "chinese": null,
    "eng": "Publish to classes",
    "hindi": null,
    "ja": "テストを複数のクラスに配信",
    "uz": "Sinflarga nashr qilish",
    "viet": null
  },
  "classes": {
    "arabic": null,
    "chinese": null,
    "eng": "Classes",
    "hindi": null,
    "ja": "クラス",
    "uz": "Sinflar",
    "viet": null
  },
  "recipient-classes": {
    "arabic": null,
    "chinese": null,
    "eng": "Recipient Classes",
    "hindi": null,
    "ja": "配信先のクラス",
    "uz": "Qabul qiluvchilar sinflari",
    "viet": null
  },
  "click-on": {
    "arabic": null,
    "chinese": null,
    "eng": "Click on",
    "hindi": null,
    "ja": null,
    "uz": "ni bosing",
    "viet": null
  },
  "arrow-to-return-to-previous-dialog-to-confirm-the-publish-to-the-saved-Recipient-Classes": {
    "arabic": null,
    "chinese": null,
    "eng": "arrow to return to previous dialog to confirm the publish to the saved Recipient Classes",
    "hindi": null,
    "ja": null,
    "uz": "Saqlangan Qabul qiluvchilar sinflarida nashr qilishni tasdiqlash uchun oldingi dialog oynasiga qaytish uchun strelka",
    "viet": null
  },
  "ascending": {
    "arabic": null,
    "chinese": null,
    "eng": "Ascending",
    "hindi": null,
    "ja": "昇順",
    "uz": "Ko'tarilish",
    "viet": null
  },
  "descending": {
    "arabic": null,
    "chinese": null,
    "eng": "Descending",
    "hindi": null,
    "ja": "降順",
    "uz": "Pastga tushmoqda",
    "viet": null
  },
  "search-classes": {
    "arabic": null,
    "chinese": null,
    "eng": "Search Classes",
    "hindi": null,
    "ja": "クラスを検索",
    "uz": "Sinflarni qidirish",
    "viet": null
  },
  "published-classes": {
    "arabic": null,
    "chinese": null,
    "eng": "Published Classes",
    "hindi": null,
    "ja": "配信済みクラス",
    "uz": "Nashr qilingan darslar",
    "viet": null
  },
  "ai-evaluation-in-progress": {
    "arabic": null,
    "chinese": null,
    "eng": "AI Evaluation in progress...",
    "hindi": null,
    "ja": null,
    "uz": "AI baholash davom etmoqda...",
    "viet": null
  },
  "answer": {
    "arabic": null,
    "chinese": null,
    "eng": "Answer",
    "hindi": null,
    "ja": null,
    "uz": "Javob",
    "viet": null
  },
  "ai-feedback": {
    "arabic": null,
    "chinese": null,
    "eng": "AI Feedback",
    "hindi": null,
    "ja": null,
    "uz": "AI haqida fikr-mulohaza",
    "viet": null
  },
 "feedback":{
	  "arabic": "تعليق",
	  "chinese": "反馈",
	  "eng": "Feedback",
	  "hindi": "प्रतिक्रिया",
	  "ja": "フィードバック",
	  "uz": "Fikr-mulohaza",
	  "viet": "nhận xét"
	},
  "ai-and-teacher-evaluation": {
    "arabic": null,
    "chinese": null,
    "eng": "AI and Teacher Evaluation",
    "hindi": null,
    "ja": null,
    "uz": "AI va o'qituvchilarni baholash",
    "viet": null
  },
  "ai-essay-grader-rubric": {
    "arabic": null,
    "chinese": null,
    "eng": "AI Essay Grader - Rubric",
    "hindi": null,
    "ja": null,
    "uz": "AI insho Greyder - Rubrika",
    "viet": null
  },
  "ai-grader-rubric":{
    "arabic": null,
    "chinese": null,
    "eng": "AI Grader - Rubric",
    "hindi": null,
    "ja": null,
    "uz": "AI Greyder - Rubrika",
    "viet": null
  },
  "sign-in-with-google": {
    "arabic": "الدخول مع جوجل",
    "chinese": "使用 Google 登录",
    "eng": "Sign in with Google",
    "hindi": "Google से साइन इन करें",
    "ja": "Googleでサインイン",
    "uz": "Google bilan kiring",
    "viet": "Đăng nhập bằng Google"
  },
  "sign-up-with-google": {
    "arabic": "قم بالتسجيل مع جوجل",
    "chinese": "使用 Google 注册",
    "eng": "Sign up with Google",
    "hindi": "Google के साथ साइन अप करें",
    "ja": "Google に登録する",
    "uz": "Google bilan ro'yxatdan o'ting",
    "viet": "Đăng ký với Google"
  },
  "dont-have-an-account-?": {
    "arabic": "ليس لديك حساب؟",
    "chinese": "没有帐户？",
    "eng": "Don't have an account?",
    "hindi": "कोई खाता नहीं है?",
    "ja": "アカウントをお持ちでない場合は、",
    "uz": "Hisobingiz yo'qmi?",
    "viet": "Bạn chưa có tài khoản?"
  },
  "already-have-an-account-?": {
    "arabic": "هل لديك حساب؟",
    "chinese": "已经有帐户？",
    "eng": "Already have an account?",
    "hindi": "क्या आपके पास पहले से एक खाता मौजूद है?",
    "ja": "すでにアカウントをお持ちですか？",
    "uz": "Hisobingiz bormi?",
    "viet": "Bạn co săn san để tạo một tai khoản?"
  },
  "create-new-account": {
    "arabic": "انشاء حساب جديد",
    "chinese": "建立新帐户",
    "eng": "Create New Account",
    "hindi": "नया खाता बनाएँ",
    "ja": "新しいアカウントを作成する",
    "uz": "Yangi hisob yaratish",
    "viet": "Tạo tài khoản mới"
  },
  "sign-in": {
    "arabic": "تسجيل الدخول",
    "chinese": "登入",
    "eng": "Sign In",
    "hindi": "दाखिल करना",
    "ja": "サインイン",
    "uz": "Tizimga kirish",
    "viet": "Đăng nhập"
  },
  "Last-Name": {
    "arabic": "اسم العائلة",
    "chinese": "姓",
    "eng": "Last Name",
    "hindi": "अंतिम नाम",
    "ja": "苗字",
    "uz": "Familiya",
    "viet": "Họ"
  },
  "First-Name": {
    "arabic": "الاسم الأول",
    "chinese": "名",
    "eng": "First Name",
    "hindi": "पहला नाम",
    "ja": "ファーストネーム",
    "uz": "Ism",
    "viet": "Tên đầu tiên"
  },
  "create-a-teacher-account-to-start": {
    "arabic": "قم بإنشاء حساب المعلم للبدء",
    "chinese": "创建教师帐户以开始",
    "eng": "Create a Teacher account to start",
    "hindi": "आरंभ करने के लिए एक शिक्षक खाता बनाएँ",
    "ja": "始めるには教師アカウントを作成してください",
    "uz": "Boshlash uchun o'qituvchi hisobini yarating",
    "viet": "Tạo tài khoản Giáo viên để bắt đầu"
  },
  "confirm-and-proceed": {
    "arabic": "تأكيد والمضي قدما",
    "chinese": "确认并继续",
    "eng": "Confirm and proceed",
    "hindi": "पुष्टि करें और आगे बढ़ें",
    "ja": "確認して次に進みます",
    "uz": "Tasdiqlang va davom eting",
    "viet": "Xác nhận và tiến hành"
  },
  "securely-validate-your-information-,-ensuring-the-accuracy-of-your-details.": {
    "arabic": "التحقق من صحة معلوماتك بشكل آمن، مما يضمن دقة التفاصيل الخاصة بك.",
    "chinese": "安全验证您的信息，确保您的详细信息的准确性。",
    "eng": "Securely Validate Your Information, ensuring the accuracy of your details.",
    "hindi": "अपने विवरण की सटीकता सुनिश्चित करते हुए, अपनी जानकारी को सुरक्षित रूप से मान्य करें।",
    "ja": "お客様の情報を安全に検証し、詳細の正確性を保証します。",
    "uz": "Ma'lumotlaringizning to'g'riligini ta'minlab, ma'lumotlaringizni ishonchli tarzda tasdiqlang.",
    "viet": "Xác thực thông tin của bạn một cách an toàn, đảm bảo tính chính xác của thông tin chi tiết của bạn."
  },
  "welcome-to-cerebry-!-press-confirm-to-verify-your-details": {
    "arabic": "مرحبا بكم في سيريبري! اضغط على تأكيد للتحقق من التفاصيل الخاصة بك",
    "chinese": "欢迎来到塞雷布里！按确认以验证您的详细信息",
    "eng": "Welcome to Cerebry! Press confirm to verify your details",
    "hindi": "सेरेब्री में आपका स्वागत है! अपना विवरण सत्यापित करने के लिए पुष्टि दबाएँ",
    "ja": "セレブへようこそ！確認を押して詳細を確認してください",
    "uz": "Cerebry-ga xush kelibsiz! Tafsilotlaringizni tasdiqlash uchun tasdiqlash tugmasini bosing",
    "viet": "Chào mừng đến với Cerebry! Nhấn xác nhận để xác minh thông tin của bạn"
  },
  "switch-account": {
    "arabic": "تبديل الحساب",
    "chinese": "切换账户",
    "eng": "Switch account",
    "hindi": "खाता स्थानांतरित करें",
    "ja": "アカウントを切り替える",
    "uz": "Hisobni almashtirish",
    "viet": "Chuyển tài khoản"
  },
  "login-via-email": {
    "arabic": "تسجيل الدخول عبر البريد الإلكتروني",
    "chinese": "通过电子邮件登录",
    "eng": "Login Via Email",
    "hindi": "ईमेल के माध्यम से लॉगिन करें",
    "ja": "電子メールによるログイン",
    "uz": "Elektron pochta orqali kiring",
    "viet": "Đăng nhập qua email"
  },
  "please-choose-another-google-account-and-try-again-!": {
    "arabic": "الرجاء اختيار حساب Google آخر والمحاولة مرة أخرى!",
    "chinese": "请选择另一个 Google 帐户并重试！",
    "eng": "Please choose another Google account and try again!",
    "hindi": "कृपया कोई अन्य Google खाता चुनें और पुनः प्रयास करें!",
    "ja": "別の Google アカウントを選択して、もう一度お試しください。",
    "uz": "Boshqa Google hisobini tanlang va qaytadan urining!",
    "viet": "Vui lòng chọn một tài khoản Google khác và thử lại!"
  },
  "your-google-account-is-not-associated-with-cerebry-as-a-teacher.": {
    "arabic": "حساب Google الخاص بك غير مرتبط بـ Cerebry كمدرس.",
    "chinese": "您的 Google 帐户未与 Cerebry as a Teacher 关联。",
    "eng": "Your Google account is not associated with Cerebry as a Teacher.",
    "hindi": "आपका Google खाता एक शिक्षक के रूप में सेरेब्री से संबद्ध नहीं है।",
    "ja": "あなたの Google アカウントは Cerebry as a Teacher に関連付けられていません。",
    "uz": "Google hisobingiz Cerebry as a Teacher bilan bog‘lanmagan.",
    "viet": "Tài khoản Google của bạn không được liên kết với Cerebry với tư cách là Giáo viên."
  },
  "link-sent": {
    "chinese":"链接已发送",
    "ja":"リンクが送信されました",
    "viet":"Đã gửi liên kết",
    "eng": "Link sent",
    "hindi": "लिंक भेजा गया",
    "arabic": "تم إرسال الرابط",
	  "uz":"Havola yuborildi"
  },
  "success":{
    "chinese":"成功",
    "ja":"成功",
    "viet":"Thành công",
    "eng": "Success",
    "hindi": "सफलता",
    "arabic": "نجاح",
    "uz":"Muvaffaqiyat"
  },
  "description-should-be-minimum-10-characters-long":{
    "chinese":"描述长度至少为 10 个字符",
    "ja":"説明は 10 文字以上にする必要があります",
    "viet":"Mô tả phải dài tối thiểu 10 ký tự",
    "eng": "Description should be minimum 10 characters long",
    "hindi": "विवरण कम से कम 10 अक्षर लंबा होना चाहिए",
    "arabic": "يجب أن لا يقل طول الوصف عن 10 أحرف",
    "uz":"Tavsif kamida 10 ta belgidan iborat bo'lishi kerak"
  },
  "are-you-sure-you-want-to-save-this-?":{
    "chinese":"您确定要保存此内容吗？",
    "ja":"これを保存してもよろしいですか?",
    "viet":"Bạn có chắc chắn muốn lưu cái này không?",
    "eng": "Are you sure you want to save this?",
    "hindi": "क्या आप वाकई इसे सहेजना चाहते हैं?",
    "arabic": "هل أنت متأكد أنك تريد حفظ هذا؟",
    "uz":"Haqiqatan ham buni saqlamoqchimisiz?"
  },
  "these-modifications-will-also-reflect-on-the-student-dashboard":{
    "chinese":"这些修改也将反映在学生仪表板上。",
    "ja":"これらの変更は生徒のダッシュボードにも反映されます。",
    "viet":"Những sửa đổi này cũng sẽ phản ánh trên bảng điều khiển của sinh viên.",
    "eng": "These modifications will also reflect on the student dashboard.",
    "hindi": "ये संशोधन छात्र डैशबोर्ड पर भी दिखाई देंगे।",
    "arabic": "سوف تنعكس هذه التعديلات أيضًا على لوحة تحكم الطالب.",
    "uz":"Ushbu o'zgartirishlar talabalarning boshqaruv panelida ham aks etadi."
  },
  "edit":{
    "chinese":"编辑",
    "ja":"編集",
    "viet":"Biên tập",
    "eng": "Edit",
    "hindi": "संपादन करना",
    "arabic": "يحرر",
    "uz":"Tahrirlash",
    "ru": "Редактировать",
    "az": "Redaktə et",
    "jp": "編集"
  },
  "fields-cannot-be-empty":{
    "chinese":"字段不能为空",
    "ja":"フィールドを空にすることはできません",
    "viet":"Các trường không thể trống",
    "eng": "Fields cannot be empty",
    "hindi": "फ़ील्ड खाली नहीं हो सकते",
    "arabic": "لا يمكن أن تكون الحقول فارغة",
    "uz":"Maydonlar boʻsh boʻlishi mumkin emas"
  },
  "score-cannot-be-greater-than-total-marks":{
    "chinese":"分数不能大于总分",
    "ja":"スコアは合計点を超えることはできません",
    "viet":"Điểm không được lớn hơn tổng điểm",
    "eng": "Score cannot be greater than total marks",
    "hindi": "स्कोर कुल अंकों से अधिक नहीं हो सकता",
    "arabic": "لا يمكن أن تكون النتيجة أكبر من مجموع العلامات",
    "uz":"Ballar umumiy balldan katta bo‘lishi mumkin emas"
  },
  "scored":{
    "chinese":"得分",
    "ja":"得点",
    "viet":"ghi bàn",
    "eng": "Scored",
    "hindi": "रन बनाए",
    "arabic": "سجل",
    "uz":"Gol urdi"
  },
  "score-cannot-be-negative": {
    "chinese":"分数不能为负数",
    "ja":"スコアをマイナスにすることはできません",
    "viet":"Điểm không được âm",
    "eng": "Score cannot be negative",
    "hindi": "स्कोर नकारात्मक नहीं हो सकता",
    "arabic": "لا يمكن أن تكون النتيجة سلبية",
    "uz":"Ballar salbiy bo'lishi mumkin emas"
  },
  "please-enter-username-id": {
    "eng": "Please enter your username or ID",
    "az": "Zəhmət olmasa istifadəçi adınızı və ya ID-ni daxil edin",
    "uz": "Iltimos, foydalanuvchi nomingizni yoki ID raqamingizni kiriting",
    "ru": "Пожалуйста, введите ваше имя пользователя или ID"
  },
  "please-enter-a-valid-username-id": {
    "eng": "Please enter a valid username or ID",
    "az": "Zəhmət olmasa etibarlı istifadəçi adı və ya ID daxil edin",
    "uz": "Iltimos, haqiqiy foydalanuvchi nomi yoki ID raqamini kiriting",
    "ru": "Пожалуйста, введите действительное имя пользователя или ID"
  },
  "reschedule":{
    "arabic": "إعادة جدولة",
    "eng": "Reschedule",
    "hindi": "पुनर्निर्धारित",
    "ja": "スケジュールを変更する",
    "viet": "Lên lịch lại",
    "chinese": "重新安排"
  },
  "rescheduled":{
    "arabic": "إعادة جدولة",
    "eng": "Rescheduled",
    "hindi": "फिर से निर्धारित",
    "ja": "再スケジュール",
    "viet": "Đã lên lịch lại",
    "chinese": "改期"
  },
  "reset-now":{
    "arabic": "إعادة الضبط الآن",
    "eng": "Reset Now",
    "hindi": "अभी रीसेट करें",
    "ja": "今すぐリセット",
    "viet": "Đặt lại ngay",
    "chinese": "立即重置"
  },
  "start-date": {
    "arabic": "تاريخ البدء",
    "eng": "Start Date",
    "hindi": "आरंभ करने की तिथि",
    "ja": "開始日",
    "viet": "Ngày bắt đầu",
    "chinese": "开始日期"
  },
  "end-date": {
    "arabic": "تاريخ الانتهاء",
    "eng": "End Date",
    "hindi": "अंतिम तिथि",
    "ja": "終了日",
    "viet": "Ngày kết thúc",
    "chinese": "结束日期"
  },
  "clicking-on-reschedule-will-reset-the-progress-of-this-student": {
    "arabic": `سيؤدي النقر على "إعادة الجدولة" إلى إعادة ضبط تقدم هذا الطالب`,
    "eng": `Clicking on "Reschedule" will reset the progress of this student`,
    "hindi": `"पुनर्निर्धारित" पर क्लिक करने से इस छात्र की प्रगति रीसेट हो जाएगी`,
    "ja": `「再スケジュール」をクリックすると、この生徒の進捗状況がリセットされます`,
    "viet": `Nhấp vào "Lên lịch" sẽ thiết lập lại tiến độ của học sinh này`,
    "chinese": `单击“重新安排”将重置该学生的进度`
  },
  "reset-reschedule-test":{
    "arabic": "إعادة تعيين/إعادة جدولة الاختبار",
    "eng": "Reset/Reschedule Test",
    "hindi": "परीक्षण को रीसेट/पुनर्निर्धारित करें",
    "ja": "テストのリセット/再スケジュール",
    "viet": "Đặt lại/Lên lịch kiểm tra lại",
    "chinese": "重置/重新安排测试"
  },
  "username-has-been-added-several-times-please-review-and-delete-the-duplicates": {
    "eng": "Username has been added several times, please review and delete the duplicates.",
    "ru": "Имя пользователя добавлено несколько раз, пожалуйста, проверьте и удалите дубликаты.",
    "uz": "Foydalanuvchi nomi bir necha marta qo'shilgan, iltimos, tekshirib, dublikatlarni o'chiring.",
    "ja": "ユーザー名が複数回追加されましたので、確認して重複を削除してください。",
    "az": "İstifadəçi adı bir neçə dəfə əlavə olunub, zəhmət olmasa, təkrarlara baxın və onları silin."
  },
  "the-topic-will-also-be-published-to-google-classroom,-if-connected": {
    "arabic": "سيتم أيضًا نشر الموضوع على Google Classroom، إذا كان متصلاً",
    "eng": "The topic will also be published to Google Classroom, if connected",
    "hindi": "कनेक्ट होने पर विषय Google क्लासरूम पर भी प्रकाशित किया जाएगा",
    "ja": "接続されている場合、トピックは Google Classroom にも公開されます",
    "viet": "Chủ đề cũng sẽ được xuất bản lên Google Classroom nếu được kết nối",
    "chinese": "该主题还将发布到 Google Classroom（如果已连接）"
  },
  "the-topic-is-published-to-google-classroom": {
    "arabic": "تم نشر الموضوع في Google Classroom",
    "eng": "The topic is published to Google classroom",
    "hindi": "विषय Google कक्षा में प्रकाशित किया गया है",
    "ja": "トピックは Google クラスルームに公開されます",
    "viet": "Chủ đề được xuất bản lên lớp học Google",
    "chinese": "该主题已发布到 Google 课堂"
  },
  "google-classroom-link": {
    "arabic": "رابط جوجل كلاس روم",
    "eng": "Google Classroom link",
    "hindi": "गूगल क्लासरूम लिंक",
    "ja": "Googleクラスルームへのリンク",
    "viet": "Liên kết lớp học Google",
    "chinese": "谷歌课堂链接"
  },
  "google-classroom-link-copied": {
    "arabic": "تم نسخ رابط Google Classroom",
    "eng": "Google Classroom link copied",
    "hindi": "Google क्लासरूम लिंक कॉपी किया गया",
    "ja": "Google Classroom のリンクがコピーされました",
    "viet": "Đã sao chép liên kết Google Lớp học",
    "chinese": "已复制 Google 课堂链接"
  },
  "(n)-represent-the-number-of-times-you-have-rescheduled-or-permitted-the-student-to-reattempt-the-test": {
    "arabic": "(ن) تمثل عدد المرات التي قمت فيها بإعادة جدولة الاختبار أو السماح له بإعادة محاولة الاختبار.",
    "eng": "(n) represent the number of times you have rescheduled or permitted the student to reattempt the test.",
    "hindi": "(एन) यह दर्शाता है कि आपने कितनी बार परीक्षा को पुनर्निर्धारित किया है या छात्र को दोबारा परीक्षा देने की अनुमति दी है।",
    "ja": "(n) スケジュールを変更した回数、または学生にテストの再試行を許可した回数を表します。",
    "viet": "(n) thể hiện số lần bạn đã lên lịch lại hoặc cho phép học sinh thi lại bài kiểm tra.",
    "chinese": "(n) 代表您重新安排或允许学生重新尝试考试的次数。"
  },
  "reports": {
    "arabic": "التقارير",
    "eng": "Reports",
    "hindi": "रिपोर्ट",
    "ja": "レポート",
    "viet": "báo cáo",
    "chinese": "報告"
  },
  "okay,-sure": {
    "arabic": "حسنًا، بالتأكيد",
    "eng": "Okay, Sure",
    "hindi": "ठीक है पक्का",
    "ja": "わかりました、もちろん",
    "viet": "Được rồi, chắc chắn rồi",
    "chinese": "好的，当然"
  },
  "the-report-cannot-be-downloaded-as-theres-no-registered-email-associated-with-your-account": {
    "arabic": "لا يمكن تنزيل التقرير لأنه لا يوجد بريد إلكتروني مسجل مرتبط بحسابك",
    "eng": "The report cannot be downloaded as there's no registered email associated with your account",
    "hindi": "रिपोर्ट डाउनलोड नहीं की जा सकती क्योंकि आपके खाते से कोई पंजीकृत ईमेल संबद्ध नहीं है",
    "ja": "アカウントに関連付けられた登録メールアドレスがないため、レポートをダウンロードできません",
    "viet": "Không thể tải xuống báo cáo vì không có email đăng ký nào được liên kết với tài khoản của bạn",
    "chinese": "由于没有与您的帐户关联的注册电子邮件，因此无法下载报告"
  },
  "the-reports-are-being-generated-now-and-will-be-sent-to": {
    "arabic": "يتم الآن إنشاء التقارير وسيتم إرسالها إلى",
    "eng": "The reports are being generated now and will be sent to",
    "hindi": "अभी रिपोर्ट तैयार की जा रही है और भेज दी जाएगी",
    "ja": "レポートは現在生成されており、次の宛先に送信されます。",
    "viet": "Các báo cáo hiện đang được tạo và sẽ được gửi tới",
    "chinese": "报告正在生成中，并将发送至"
  },
  "soon": {
    "arabic": "قريباً",
    "eng": "soon",
    "hindi": "जल्द ही",
    "ja": "すぐ",
    "viet": "sớm",
    "chinese": "很快"
  },
  "Two": {
    "eng": "Two",
    "uz": "Ikki",
    "ru": "Два",
    "az": "İki",
    "jp": "二"
  },
  "Three": {
    "eng": "Three",
    "uz": "Uch",
    "ru": "Три",
    "az": "Üç",
    "jp": "三"
  },
  "more-quick-steps-to-get-started": {
    "eng": "more quick steps to get started",
    "uz": "Boshlash uchun yana tez qadamlar",
    "ru": "Больше быстрых шагов для начала",
    "az": "Başlamaq üçün daha sürətli addımlar",
    "jp": "始めるためのさらに簡単な手順"
  },
  "Create-an-account": {
    "eng": "Create an account",
    "uz": "Hisob yaratish",
    "ru": "Создать аккаунт",
    "az": "Hesab yarat",
    "jp": "アカウントを作成"
  },
  "join-a-school": {
    "eng": "Join a school",
    "uz": "Maktabga qo'shilish",
    "ru": "Присоединиться к школе",
    "az": "Məktəbə qoşul",
    "jp": "学校に参加"
  },
  "setup-class": {
    "eng": "Setup Class",
    "uz": "Sinfni sozlash",
    "ru": "Настройка класса",
    "az": "Sinfi qur",
    "jp": "クラスを設定"
  },
  "sync-students": {
    "eng": "Sync Students",
    "uz": "O'quvchilarni sinxronlash",
    "ru": "Синхронизация учеников",
    "az": "Tələbələri sinxronlaşdır",
    "jp": "学生を同期"
  },
  "join": {
    "eng": "Join",
    "uz": "Qo'shil",
    "ru": "Присоединиться",
    "az": "Qoşul",
    "jp": "参加"
  },
  "if-you-wish-to-create-more-such-classes,-you-can-do-so-by-clicking-on-“create-new-class”-button-on-your-profile-within-the-teacher-dashboard": {
    "eng": "If you wish to create more such classes, you can do so by clicking on “Create new class” button on your profile within the teacher dashboard",
    "uz": "Agar siz ko'proq shunday sinflarni yaratmoqchi bo'lsangiz, o'qituvchi boshqaruv panelidagi profilingizda “Yangi sinf yaratish” tugmasini bosish orqali buni amalga oshirishingiz mumkin",
    "ru": "Если вы хотите создать больше таких классов, вы можете сделать это, нажав кнопку «Создать новый класс» в вашем профиле на панели управления учителя",
    "az": "Belə siniflərdən daha çox yaratmaq istəyirsinizsə, müəllim idarə panelinizdəki profilinizdə “Yeni sinif yarat” düyməsini basaraq bunu edə bilərsiniz",
    "jp": "さらに同様のクラスを作成したい場合は、教師ダッシュボードのプロファイル内の「新しいクラスを作成」ボタンをクリックして実行できます"
  },
  "sort": {
    "eng": "Sort",
    "uz": "Saralash",
    "ru": "Сортировка",
    "az": "Sıralamaq",
    "jp": "並べ替え"
  },
  "skip-sync": {
    "eng": "Skip Sync",
    "uz": "Sinxronizatsiyani o'tkazib yuborish",
    "ru": "Пропустить синхронизацию",
    "az": "Sinxronizasiyanı keçin",
    "jp": "同期をスキップ"
  },
  "mark-setup-as-complete": {
    "eng": "Mark Setup as Complete",
    "uz": "Sozlashni tugallangan deb belgilang",
    "ru": "Отметить настройку как завершенную",
    "az": "Quraşdırmanı tamamlama kimi işarələyin",
    "jp": "セットアップを完了としてマークする"
  },
  "class-you-have-created": {
    "eng": "Class you have created",
    "uz": "Siz yaratgan sinf",
    "ru": "Созданный вами класс",
    "az": "Yaratdığınız sinif",
    "jp": "あなたが作成したクラス"
  },
 "you-cannot-proceed-with-account-sync-process-since-a-few-students-are-flagged-for-incomplete-information": {
    "eng": "You cannot proceed with account sync process since a few students are flagged for incomplete information",
    "uz": "Ba'zi talabalar haqida ma'lumot to'liq emasligi sababli hisobni sinxronizatsiya qilishni davom ettira olmaysiz",
    "ru": "Вы не можете продолжить синхронизацию учетной записи, так как у нескольких студентов неполная информация",
    "az": "Bir neçə tələbənin məlumatı tamamlanmadığı üçün hesab sinxronizasiyasını davam etdirə bilməzsiniz",
    "jp": "一部の学生の情報が不完全なため、アカウント同期プロセスを進めることができません"
  },
  "yes": {
    "eng": "Yes",
    "uz": "Ha",
    "ru": "Да",
    "az": "Bəli",
    "jp": "はい"
  },
  "complete-setup": {
    "eng": "Complete Setup",
    "uz": "Sozlashni yakunlang",
    "ru": "Завершить настройку",
    "az": "Quraşdırmanı tamamla",
    "jp": "セットアップを完了する"
  },
  "are-you-sure-you-want-to-proceed-with-the-account-sync-process": {
    "eng": "Are you sure you want to proceed with the account sync process",
    "uz": "Hisobni sinxronizatsiya qilish jarayonini davom ettirmoqchimisiz?",
    "ru": "Вы уверены, что хотите продолжить процесс синхронизации учетной записи?",
    "az": "Hesab sinxronizasiya prosesini davam etdirmək istədiyinizə əminsiniz?",
    "jp": "アカウント同期プロセスを進めてもよろしいですか？"
  },
  "once-student-information-has-been-synced,-you-will-no-longer-be-able-to-edit-their-information": {
    "eng": "Once student information has been synced, you will no longer be able to edit their information",
    "uz": "Talabalar ma'lumoti sinxronizatsiyalanganidan keyin ularning ma'lumotlarini o'zgartira olmaysiz",
    "ru": "После синхронизации информации о студентах вы больше не сможете её редактировать",
    "az": "Tələbə məlumatları sinxronizasiya edildikdən sonra məlumatlarını redaktə edə bilməyəcəksiniz",
    "jp": "学生情報が同期されると、その情報を編集できなくなります"
  },
  "google-classroom-classes": {
    "eng": "Google Classroom classes",
    "uz": "Google Sinf darslari",
    "ru": "Классы Google Classroom",
    "az": "Google Sinifləri",
    "jp": "Google クラスルーム クラス"
  },
  "enter-class-name": {
    "eng": "Enter Class name",
    "uz": "Sinf nomini kiriting",
    "ru": "Введите название класса",
    "az": "Sinif adını daxil edin",
    "jp": "クラス名を入力"
  },
  "answer-uploaded": {
    "arabic": "تم تحميل الإجابة",
    "eng": "Answer Uploaded",
    "hindi": "उत्तर अपलोड किया गया",
    "ja": "回答がアップロードされました",
    "viet": "Đã tải lên câu trả lời",
    "chinese": "答案已上传"
  },
  "something-went-wrong":{
    "arabic": "حدث خطأ ما",
    "eng": "Something went wrong!",
    "hindi": "कुछ गलत हो गया",
    "ja": "何かがうまくいかなかった",
    "viet": "Đã xảy ra lỗi",
    "chinese": "出了点问题"
  },
  "class-code-or-name":{
    "arabic": "رمز أو اسم الفصل",
    "eng": "Class code or name",
    "hindi": "कक्षा कोड या नाम",
    "ja": "クラス コードまたは名前",
    "uz": "Sinf kodi yoki nomi",
    "viet": "Mã hoặc tên lớp học"
  },
}
