import { useEffect, useState } from "preact/hooks";
import style from "./style.scss";
import {parseQuestionResponse} from "../../../utils"
import { textIdentifier } from "../../../components/Common/textIdentifier";
import { useSelector } from "react-redux";
import { markedRight, markedWrong } from "../../../assets/images";

const UpArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
      <path d="M1 6.5L6 1.5L11 6.5" stroke="#30538A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}
export const DangerousMarkup = (props) => {
  const [markup, changeMarkup] = useState(props.markup)
  const [qid, changeQid ] = useState(props.qid)
  useEffect(() => {
    changeMarkup(props.markup)
  },[qid])

  if(qid !== props.qid) changeQid(props.qid)

  return (
    <div
      key={qid}
      dangerouslySetInnerHTML={{
        __html: markup,
      }}
    />
  )
}

const AnswerTypeEssay = ({essay,handleEssayAnswer,essayWordCount,essayParaCount,disableEssayAnswer}) => {
  return (<div className={style["essay-answer-cnt"]}>
    <div className={style["word-count-header"]}>
      <div className={style["word-counter"]}>
        {`Paragraphs:${essayParaCount}`}
      </div>
      <div className={style["word-counter"]}>
        {`Words:${essayWordCount}`}
      </div>
    </div>
    <textarea disabled value={essay} className={style["essay-inp-textarea"]}/>
  </div>)
}

const ExamQuestion = (props) => {
  const [essayWordCount,setEssayWordCount] = useState(0);
  const [essayParaCount, setEssayParaCount] = useState(0);
  const [essayAnswer, setEssayAnswer] = useState("");

  const { question, index, choiceIndex, studentWiseReport, hideQuestionNumber,userResponse,handleAIFeedbackCTAClick,selectedStudent } = props
  const answerId = `exam_question_${choiceIndex ? choiceIndex : index}`

  const [collapsedState, setCollapsedState] = useState((studentWiseReport && studentWiseReport.validResponse != 1)?false:true);
  // console.log(studentWiseReport, "studentWiseReport")

  const handleEssayAnswer=(e)=>{
    const essayText = e.target.value;
    const words = essayText.length>0 ? essayText.trim().split(/\s+/):[];
    const paragraphs = essayText.length>0 ? essayText.trim().split(/\n+/):[];
    setEssayAnswer(prev=>essayText);
    setEssayParaCount(prev=>paragraphs.length);
    setEssayWordCount(prev=>words.length);
  }

  useEffect(() => {
    if(userResponse)
      {
        if(question.isEssayType)
          {
            const essayText = userResponse[0][0];
            const words = essayText.length>0 ? essayText.trim().split(/\s+/):[];
            const paragraphs = essayText.length>0 ? essayText.trim().split(/\n+/):[];
            setEssayAnswer(prev=>essayText);
            setEssayParaCount(prev=>paragraphs.length);
            setEssayWordCount(prev=>words.length);
          }
          else
          {
            let arg = typeof userResponse ==="string" ? parseQuestionResponse(userResponse):userResponse;
            if (Boolean(arg)) {
              parse_answer.insertAnswers(arg, answerId)
            }
            parse_answer.disableAnswers(answerId)
            if (
              question &&
              question.solution &&
              question.question &&
              question.id &&
              studentWiseReport.markingList &&
              Array.isArray( studentWiseReport.markingList) &&
              studentWiseReport.markingList.length > 0
            ) {
              const subpartAnsInterval = setInterval(() => {
                if (
                  document.getElementById(answerId) &&
                  document.getElementById(answerId).getElementsByClassName("sub-part")
                    .length > 0
                ) {
                  const ansSubparts = Object.values(
                    document
                      .getElementById(answerId)
                      .getElementsByClassName("sub-part")
                  );
                  ansSubparts.forEach((partAnsEle, partAnsIndex) => {
                    let partAnsPoint = partAnsEle.querySelector("b");
                    partAnsPoint.style.color =
                    studentWiseReport.markingList[partAnsIndex] > 0 ? "#3CD22D" : "#FF6666";
                    let partAnsMarked = document.createElement("div");
                    partAnsMarked.style.marginTop = "2px";
                    partAnsMarked.style.marginLeft = "-13px";
                    partAnsMarked.style.marginRight = "13px";
                    partAnsMarked.className = "sub-part-marked";
                    partAnsMarked.innerHTML =
                    studentWiseReport.markingList[partAnsIndex] > 0
                        ? markedRight
                        : markedWrong;
                    if (
                      partAnsPoint.nextSibling &&
                      partAnsPoint.nextSibling.tagName == "DIV" &&
                      partAnsPoint.nextSibling.className == "sub-part-marked"
                    )
                      partAnsPoint.nextSibling.remove();
                    partAnsPoint.insertAdjacentElement("afterend", partAnsMarked);
                  });
                  clearInterval(subpartAnsInterval);
                }
              }, 25);
            }
          }  
      }
  }, [question,userResponse])
  const {language} = useSelector((state)=>state.userSession.user)
  const isAIFeedbackCTAEnabled = question && selectedStudent && studentWiseReport && selectedStudent.hasStarted && selectedStudent.isCompleted && studentWiseReport.validResponse!==null  && (question.isEssayType || question.isOpenEnded || question.isAiGrading);
  return (
    Boolean(question) && (
      <div class={style['exam--question--section']} >
        {!hideQuestionNumber && (
          <div class={style['question--heading']}>
            <div class={style['question--heading--left']}>
              <div class={style["ques--num"]}>{textIdentifier["question"][language]} {index + 1}</div>
              {
                studentWiseReport ?(( studentWiseReport.validResponse == 1 ? (
                  <div class={style['correct--answer']}>
                    {textIdentifier["correct"][language]} 
                  </div>
                ) : studentWiseReport.validResponse == 2 ?
                    <div class={style['partial--answer']}>{textIdentifier["partly-correct"][language]} </div>
                :studentWiseReport.validResponse == 0 ?(
                  <div class={style['incorrect--answer']}>{textIdentifier["wrong"][language]} </div>
              ): ( <div class={style['unattempted--answer']}>{textIdentifier["unattempted"][language]} </div> )
              )): null}
              <div class={style['ques--score']}>{studentWiseReport &&
                  // studentWiseReport && studentWiseReport.validResponse == 2 &&
                `${studentWiseReport.marks?Math.round(studentWiseReport.marks *100) /100:'0'} ${textIdentifier["out-of"][language]} `}
              {question.marks} {textIdentifier["mark(s)"][language]}
              </div>
              {isAIFeedbackCTAEnabled && <div className={style["ai-feedback-cta"]} onClick={()=>handleAIFeedbackCTAClick({...question,attemptOrder:studentWiseReport.attemptOrder,marks: studentWiseReport.marks?Math.round(studentWiseReport.marks *100) /100:0,maximumMarks: question.marks,questionIndex: index,  userResponse: userResponse, marking_list: studentWiseReport.markingList})}>
                AI Feedback
              </div>}
            </div>
            {studentWiseReport && <div class={style['question--heading--right']} onClick={()=> setCollapsedState(!collapsedState)}>
              <div  class={style['question--heading--right--text']} >
                {collapsedState ? textIdentifier["expand-response-&-solution"][language] : textIdentifier["collapse-response-&-solution"][language]}
              </div>
              <div class={[style["question--heading--right--arrow"], collapsedState?style["down"]:""].join(" ")}>
                <UpArrow />
              </div>
            </div>}
          </div>
        )}
        <div class={style['exam--question']}>
          <DangerousMarkup
            qid={'exam--question'+question.qid}
            markup={question.question}
          />
        </div>
        {<div class={[style["exam--answer--container"],(studentWiseReport && collapsedState)?style["hidden"]:""].join(" ")}>
          <div class={style['horizontal--line']}></div>
          <div class={style['exam--answer']} id={answerId}>
            {question.isEssayType ?
              <AnswerTypeEssay 
              essay={essayAnswer}
              essayParaCount={essayParaCount}
              essayWordCount={essayWordCount}
              handleEssayAnswer={handleEssayAnswer}
            />: <DangerousMarkup
              qid={question.qid}
              markup={question.answerType}
            />}
          </div>
          {studentWiseReport && <>
            <div class={style['horizontal--line']}></div>
            <div class={style["exam--answer--heading"]}>{textIdentifier["correct-answer"][language]}</div>
            <div class={style['exam--answer']} id={answerId}>
              <DangerousMarkup
                qid={question.qid}
                markup={question.solution}
              />
            </div>
          </>}
        </div>}

      </div>
    )
  )
}

export default ExamQuestion
