import { NoInternetNinja } from '../../assets/images';
import style from './style.scss'

function Modal ()  {
    const refreshPage = ()=>{
        window.location.reload();
     }
    return (
      <div>
        <div className={style['wrapper']}>
            <div className= {style['mainCard']}>
            <div className={style['container-img']}>
          <div className={style['img']} >
            <NoInternetNinja/>
          </div> 
          </div>
          <div className={style['container-info']}>
          <div className={style['message']}>
            <p>Oops, we are having a technical difficulty here. <br /> Please refresh or reload.</p>
          </div>
          <div className={style['button']}>
            <button onClick={refreshPage}>Refresh</button>
          </div>
          </div>
          </div>
        </div>
      </div>
    )
  }


export default Modal