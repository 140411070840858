import { useEffect, useState } from "preact/hooks";
import { useDispatch, useSelector } from "react-redux";
import style from "./style.scss";
import ExamQuestion from "./ExamQuestion";
import { evaluateAutoGradingImage, fetchAutoGradingCts, fetchUnattemptedAutoGrading, removeTestInfo, resetEvaluateAutoGradingImage } from "../../../../action";
import { textIdentifier } from "../../../../components/Common/textIdentifier";
import { TooltipArrow } from "../../../../assets/images";
import UploadImageModal from "./UploadImageModal";
const Header = (props) => {
  const dispatch = useDispatch();

  const handleUploadOverView = () => {
    dispatch(fetchUnattemptedAutoGrading(props.selectedTest.id, props.selectedStudent.id));
    props.setShowOverViewModal(true);
  }

  return (
    <div className={style["test--header"]}>
      <div className={style["header--left"]}>
        <div className={style["back--logo"]} onClick={() => props.goBackTest()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.06661 6.43278C6.18365 6.54997 6.24939 6.70882 6.24939 6.87445C6.24939 7.04008 6.18365 7.19893 6.06661 7.31612L4.00827 9.37445L17.4999 9.37445C17.6657 9.37445 17.8247 9.4403 17.9419 9.55751C18.0591 9.67472 18.1249 9.83369 18.1249 9.99945C18.1249 10.1652 18.0591 10.3242 17.9419 10.4414C17.8247 10.5586 17.6657 10.6244 17.4999 10.6244L4.00827 10.6244L6.06661 12.6828C6.12801 12.74 6.17726 12.809 6.21142 12.8857C6.24558 12.9623 6.26395 13.0451 6.26543 13.129C6.26691 13.2129 6.25147 13.2963 6.22004 13.3741C6.18861 13.4519 6.14182 13.5226 6.08247 13.582C6.02312 13.6413 5.95243 13.6881 5.8746 13.7196C5.79678 13.751 5.71342 13.7664 5.6295 13.7649C5.54558 13.7635 5.46282 13.7451 5.38616 13.7109C5.30949 13.6768 5.24049 13.6275 5.18327 13.5661L2.05827 10.4411C1.94123 10.3239 1.87549 10.1651 1.87549 9.99945C1.87549 9.83382 1.94123 9.67497 2.05827 9.55778L5.18327 6.43278C5.30046 6.31574 5.45931 6.25 5.62494 6.25C5.79056 6.25 5.94942 6.31574 6.06661 6.43278Z"
              fill="#6B6B6B"
            />
          </svg>
        </div>
        <div className={style["test--name"]}>{props.selectedTest.name}</div>
      </div>

      <div
        className={style["upload-overview"]}
        onClick={handleUploadOverView}
      >
        {textIdentifier["see-answer-upload-overview"][props.language]}
      </div>
    </div>
  );
};

const QuestionReport = (props) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleHover = () => {
    setShowTooltip(true);
  };
  const handleLeave = () => {
    setShowTooltip(false);
  };
  const { report, index, examQuestions, selectedStudent } = props;
  const { language } = useSelector((state) => state.userSession.user);

  return (
    <div
      className={style["ques-report"]}
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
    >
      <div className={style["ques-num"]}>Q{index + 1}</div>
      <div className="tooltip-container">
        <div
          className={[
            style["tooltip-arrow"],
            showTooltip ? style["active"] : "",
          ].join(" ")}
        >
          <TooltipArrow />
        </div>
        <div
          className={[
            style["ques-report-tooltip"],
            showTooltip ? style["active"] : "",
            report.validResponse == 0
              ? style["wrong"]
              : report.validResponse == 1
              ? style["correct"]
              : report.validResponse == 2
              ? style["partly"]
              : style["skip"],
          ].join(" ")}
        >
          <div className={style["tooltip-text"]}>
            <div className={style["ques-score"]}>
              <div
                className={
                  report.validResponse == 0
                    ? style["wrong"]
                    : report.validResponse == 1
                    ? style["correct"]
                    : report.validResponse == 2
                    ? style["partly"]
                    : style["skip"]
                }
              >
                {report.marks ? Math.round(report.marks * 100) / 100 : 0}{" "}
                {textIdentifier["out-of"][language]}{" "}
                {examQuestions[selectedStudent.id][index].marks
                  ? examQuestions[selectedStudent.id][index].marks
                  : 0}{" "}
                {textIdentifier["mark(s)"][language]}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style["ques-score"]}>
        {report.marks == null ? (
          <span className={style["skip"]}>
            {textIdentifier["unattempted"][language]}
          </span>
        ) : report.marks == 0 ? (
          <span className={style["wrong"]}>
            {textIdentifier["wrong"][language]}
          </span>
        ) : (
          <span className={report.validResponse === 2 && style["partly"]}>
            {" "}
            {report.validResponse === 2
              ? textIdentifier["partly-correct"][language]
              : textIdentifier["correct"][language]}
          </span>
        )}
      </div>
    </div>
  );
};
const StudentImageUpload = (props) => {
  const { selectedTest, selectedStudent, selectStudent, selectedClass } = props;
  const [imageUploadModal, setImageUploadModal] = useState(null);
  const [selectedSubpartNo, setSelectedSubpartNo] = useState(null);
  const [showOverViewModal, setShowOverViewModal] = useState(false);
  const [selectedQuesIndex, setSelectedQuesIndex] = useState(null);
  const [imageUploadEvaluating, setImageUploadEvaluating] = useState(false);
  const [imageUploadEvaluatingError, setImageUploadEvaluatingError] = useState(false);
  const [imageUploadEvaluatingSuccess, setImageUploadEvaluatingSuccess] = useState(false);
  //set of unattempted questions
  const [unattemptedQuestions, setUnattemptedQuestions] = useState(new Set());
  const {
    studentWiseReport,
    selectedTestStudentId,
    autoGradingQuestions,
    autoGradingQuestionLoader,
    userSession,
    autoGradingImageEvaluating,
    autoGradingImageEvaluationSuccess,
    autoGradingImageEvaluationError,
    unattemptedAutoGrading,
    autoGradingImageData,
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.userSession.user);
  const { user } = userSession;

  const handleAutoGradingEvaluation = () => {
    dispatch(evaluateAutoGradingImage(selectedTest.id, selectedStudent.id));
  };

  const handleUploadImageModal = (question,quesInd, subpartNo) => {
    setImageUploadModal(question);
    setSelectedSubpartNo(subpartNo);
    setSelectedQuesIndex(quesInd);
  };
  // const handleFailedQuestion = (questionIndex) => {
  //   dispatch(
  //     fetchExamQuestion(
  //       selectedTest.configTypeIds,
  //       selectedStudent.id,
  //       questionIndex
  //     )
  //   );
  // };

  useEffect(() => {
    if (autoGradingImageEvaluating) {
      setImageUploadEvaluating(true);
    } else {
      setImageUploadEvaluating(false);
      if(autoGradingImageEvaluationSuccess){
        setImageUploadEvaluatingSuccess(true);
        dispatch(resetEvaluateAutoGradingImage());
        props.goBackTest();
      }
      if(autoGradingImageEvaluationError){
        setImageUploadEvaluatingError(textIdentifier["something-went-wrong"][language]);
      }
    }
  }, [autoGradingImageEvaluationSuccess, autoGradingImageEvaluationError, autoGradingImageEvaluating]);

useEffect(() => {
    if(unattemptedAutoGrading){
      setUnattemptedQuestions(new Set(unattemptedAutoGrading));
    }
  }, [unattemptedAutoGrading]);

  useEffect(() => {
    dispatch(fetchAutoGradingCts(selectedTest.id, selectedStudent.id));
  }, []);

  const goBackTest = () => {
    selectStudent();
    dispatch(removeTestInfo());
  };
  return (
    <div className={style["test--report"]}>
      {!!selectedTest && (
        <div>
          <Header
            selectedTest={selectedTest}
            goBackTest={goBackTest}
            selectedClass={selectedClass}
            selectedStudent={selectedStudent}
            language={language}
            setShowOverViewModal={setShowOverViewModal}
          />
        </div>
      )}
      {showOverViewModal && (
        <div className={style["upload-overview-modal-container"]}>
          <div className={style["upload-overview-modal"]}>
            <div className={style["upload-overview-modal--header"]}>
              {textIdentifier["your-answer-upload-overview"][language]}
            </div>
            <div className={style["upload-overview-modal--content"]}>
              {Object.values(autoGradingQuestions[selectedStudent.id]).map(
                (question, index) => {
                  return (
                    <div class={style["status-row"]} key={index}>
                      <div class={style["question-num"]}>
                        Q {question.quesNo}
                      </div>
                      {/* {props.examView?.unSubmitted[question.quesNo] == 1 ? (
                        <div class={style["submitted"]}>
                          {
                            textIdentifier["answer-uploaded"][
                              language
                            ]
                          }
                        </div>
                      ) : (
                        <div class={style["un-submitted"]}>
                          {textIdentifier["not-uploaded"][language]}
                        </div>
                      )} */}
                      {unattemptedQuestions.has(question.quesNo) ? (
                        <div class={style["un-submitted"]}>
                          {textIdentifier["not-uploaded"][language]}
                        </div>
                      ) : (
                        <div class={style["submitted"]}>
                          {textIdentifier["answer-uploaded"][language]}
                        </div>
                      )}
                    </div>
                  );
                }
              )}
            </div>
            <div className={style["upload-overview-modal--buttons"]}>
              <div
                className={style["close-button"]}
                onClick={() => setShowOverViewModal(false)}
              >
                {textIdentifier["close"][language]}
              </div>
              <div
                className={style["evaluate-button"]}
                onClick={handleAutoGradingEvaluation}
              >
                {textIdentifier["evaluate"][language]}
              </div>
            </div>
          </div>
        </div>
      )}
      {imageUploadModal != null && (
        <UploadImageModal
          user={user}
          selectedQuestion={imageUploadModal}
          handleUploadImageModal={handleUploadImageModal}
          selectedSubpartNo={selectedSubpartNo}
          uploadAutoGradingImage={props.uploadAutoGradingImage}
          imageUploadData={props.imageUploadData}
          selectedClass={props.selectedClass}
          studentId={selectedStudent.id}
          examId={selectedTest.id}
          index={selectedQuesIndex}
          
          selectedQuesIndex={selectedQuesIndex}
        />
      )}
      <div className={style["student-report-container"]}>
        {!!autoGradingQuestions &&
          !!autoGradingQuestions[selectedStudent.id] &&
          Object.values(autoGradingQuestions[selectedStudent.id]).map(
            (questionElem, index) => {
              {
                let report =
                  studentWiseReport && studentWiseReport[selectedStudent.id]
                    ? studentWiseReport[selectedStudent.id][index]
                    : null;
                let userResponse = report ? report.userResponse : null;
                return (
                  <div key={index} className={style["exam--question--item"]}>
                    <ExamQuestion
                      studentWiseReport={report}
                      userResponse={userResponse}
                      question={questionElem}
                      index={index}
                      selectedStudent={selectedStudent}
                      handleUploadImageModal={handleUploadImageModal}
                      user={user}
                    />
                  </div>
                );
              }
            }
          )}
        {autoGradingQuestionLoader.length > 0 &&
          autoGradingQuestionLoader.map((value, index) => {
            if (!!value && value.status === 0) {
              return (
                <div className={style["loader-container"]}>
                  <div className={style["question-heading"]}>
                    Question {value.quesNo}
                  </div>
                  <div className={style["loader"]}>Loading...</div>
                </div>
              );
            } else if (!!value && value.status === -1) {
              return (
                <div className={style["loader-container"]}>
                  <div className={style["question-heading"]}>
                    Question {value.quesNo}
                  </div>
                  <div
                    onClick={() => {
                      this.handleFailedQuestion(index);
                    }}
                    className={style["loader"]}
                  >
                    Click here to load this question
                  </div>
                </div>
              );
            }
          })}
      </div>
    </div>
  );
};

export default StudentImageUpload;
