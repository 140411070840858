export const GET_CANVAS_CLASS_LIST_REQUEST = "GET_CANVAS_CLASS_LIST_REQUEST";
export const GET_CANVAS_CLASS_LIST_SUCCESS = "GET_CANVAS_CLASS_LIST_SUCCESS";
export const GET_CANVAS_CLASS_LIST_FAILURE = "GET_CANVAS_CLASS_LIST_FAILURE";

export const GET_CANVAS_CHAPTER_LIST_REQUEST = "GET_CANVAS_CHAPTER_LIST_REQUEST";
export const GET_CANVAS_CHAPTER_LIST_SUCCESS = "GET_CANVAS_CHAPTER_LIST_SUCCESS";
export const GET_CANVAS_CHAPTER_LIST_FAILURE = "GET_CANVAS_CHAPTER_LIST_FAILURE";


export const getCanvasClassListRequest = () => ({
    type: GET_CANVAS_CLASS_LIST_REQUEST,
  });

  export const getCanvasChapterListRequest = (classCode) => ({
    type: GET_CANVAS_CHAPTER_LIST_REQUEST,
    classCode
  });
  
