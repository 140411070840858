import { useEffect, useState, useRef } from "preact/hooks";
import { useDispatch, useSelector } from "react-redux";
import style from "./style.scss";
import { DownloadLogo, ErrorLogo } from "../../images";
import { textIdentifier } from "../Common/textIdentifier";

const ConfirmSuccessModal = ({ handleClose, language, user }) => {
  return (
    <div className={style["confirm-main-container"]}>
      <DownloadLogo />
      <div className={style["main-body"]}>
        {`${textIdentifier["the-reports-are-being-generated-now-and-will-be-sent-to"][language]} ${user.email} ${textIdentifier["soon"][language]}.`}
      </div>
      <div className={style["confirm-cta"]} onClick={handleClose}>
        {textIdentifier["okay,-sure"][language]}
      </div>
    </div>
  );
};

const ConfirmFailureModal = ({ handleClose, language }) => {
  return (
    <div className={style["confirm-main-container"]}>
      <ErrorLogo />

      <div
        className={[style["main-body"], style["main-body--error"]].join(" ")}
      >
        {
          textIdentifier[
            "the-report-cannot-be-downloaded-as-theres-no-registered-email-associated-with-your-account"
          ][language]
        }
      </div>
      <div className={style["confirm-cta"]} onClick={handleClose}>
        {textIdentifier["okay,-sure"][language]}
      </div>
    </div>
  );
};

const ConfirmDownloadReportModal = ({
  handleClose,
  downloadSuccess,
  downloadFail,
  isDownloading,
  user,
}) => {
  const { language } = useSelector((state) => state.userSession.user);

  if (!isDownloading && (downloadSuccess || downloadFail)) {
    return (
      <div className={style["confirm-modal-cnt"]}>
        <div className={style["confirm-modal-body"]}>
          {downloadSuccess ? (
            <ConfirmSuccessModal
              handleClose={handleClose}
              language={language}
              user={user}
            />
          ) : (
            <ConfirmFailureModal
              handleClose={handleClose}
              language={language}
            />
          )}
        </div>
      </div>
    );
  } else return null;
};

export default ConfirmDownloadReportModal;
