import { useState } from "preact/hooks";
import style from "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { ContactSupportIcon, SuccessIcon } from "../../../images";
import { resetSubmitErrorReport } from "../../../action";
import ErrorReportingModal from "../ErrorReportingModal";
import SupportSuccessModal from "../SupportSuccessModal";

const SupportErrorReport = () => {
  const [errorReportingModal, setErrorReportingModal] = useState(false);
  const [errorReportingSuccessModal, setErrorReportingSuccessModal] =
    useState(false);
  const [currentReportSelected, setCurrentReportSelected] = useState(null);

  const closeErrorReportingModal = () => {
    setErrorReportingModal(false);
  };
  const handleReportSuccess = () => {
    setErrorReportingSuccessModal(true);
    setErrorReportingModal(false);
  };
  const { selectedClass, screenErrorsMapping} = useSelector( (state) => state);
  const {language}= useSelector((state) => state.userSession.user);
  return (
    <>
      {screenErrorsMapping && (
        <div
          className={style["contact--support"]}
          onClick={() => setErrorReportingModal(true)}
        >
          <ContactSupportIcon />
        </div>
      )}
      {errorReportingModal && (
        <ErrorReportingModal
          language={language}
          closeErrorReportingModal={closeErrorReportingModal}
          classId={selectedClass?.code}
          handleReportSuccess={handleReportSuccess}
          setCurrentSelected={setCurrentReportSelected}
          currentSelected={currentReportSelected}
        />
      )}
      {errorReportingSuccessModal && (
        <SupportSuccessModal
          closeSupportSuccessModal={() => setErrorReportingSuccessModal(false)}
          successMessage={currentReportSelected?.submitMessage}
        />
      )}
    </>
  );
};

export default SupportErrorReport;
