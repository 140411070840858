import React, { useEffect, useState, useRef } from "react";
import style from "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { textIdentifier } from "../../../../components/Common/textIdentifier";
import { CrossIcon } from "../../../../assets/image";
import { resetMockTest, resetMockTestReschedule } from "../../../../action";

const ResetSection = ({
  handleClose,
  lang,
  selectedTest,
  selectedClass,
  student,
  setStudents,
}) => {
  const dispatch = useDispatch();
  const { resetMockTestInfo, resetMockTestLoader, resetMockTestSuccess } =
    useSelector((state) => state);
  const handleResetClick = () => {
    dispatch(
      resetMockTest(selectedTest.id, selectedClass, student.id, null, null)
    );
  };

  useEffect(() => {
    if (!resetMockTestLoader && resetMockTestSuccess) {
      const { reset_count, start_time, end_time } =
        resetMockTestInfo.reschedule;
      setStudents((prev) =>
        prev.map((i) =>
          i.id === student.id
            ? {
                ...i,
                status: resetMockTestInfo.status,
                statusText: resetMockTestInfo.status_text,
                reschedule: {
                  resetCount: reset_count,
                  startTime: start_time,
                  endTime: end_time,
                },
              }
            : { ...i }
        )
      );
      dispatch(resetMockTestReschedule());
      handleClose();
    }
  }, [resetMockTestLoader, resetMockTestSuccess]);

  return (
    <div className={style["footer-btn"]}>
      <button className={style["cancel-btn"]} onClick={handleClose}>
        {textIdentifier["cancel"][lang]}
      </button>
      <button
        className={[
          style["reset-btn"],
          resetMockTestLoader && style["reset-btn--disabled"],
        ].join(" ")}
        onClick={handleResetClick}
      >
        {textIdentifier["reset-now"][lang]}
      </button>
    </div>
  );
};

const RescheduleSection = ({
  handleClose,
  lang,
  selectedTest,
  selectedClass,
  student,
  setStudents,
}) => {
  const dispatch = useDispatch();
  const { resetMockTestInfo, resetMockTestLoader, resetMockTestSuccess } =
    useSelector((state) => state);

  const convertUTCToLocal = (utcDateTime) => {
    if (!utcDateTime) return { localDate: "", localTime: "" };
    const localDateObj = new Date(utcDateTime);
    const localDate = localDateObj.toISOString().split("T")[0];
    const localTime = localDateObj.toTimeString().slice(0, 5);
    return { localDate, localTime };
  };

  const reschedule = student.reschedule || {};

  const { localDate: initialStartDate, localTime: initialStartTime } =
    convertUTCToLocal(reschedule.startTime);
  const { localDate: initialEndDate, localTime: initialEndTime } =
    convertUTCToLocal(reschedule.endTime);

  const [startdate, setStartdate] = useState({ value: initialStartDate });
  const [starttime, setStarttime] = useState({ value: initialStartTime });
  const [enddate, setEnddate] = useState({ value: initialEndDate });
  const [endtime, setEndtime] = useState({ value: initialEndTime });
  const [isEndDateError,setEndDateError] = useState(false);
  const [isResetDisabled, setIsResetDisabled] = useState(false);
  const endDateRef = useRef();
  const endTimeRef = useRef();
  const startDateRef = useRef();
  const startTimeRef = useRef();
  const currentDate = new Date().toISOString().split("T")[0];

  const combineDateTime = (date, time) => {
    return new Date(`${date}T${time}`);
  };

  const handleResetClick = () => {
    if (startdate.value && starttime.value && enddate.value && endtime.value) {
      const startDateTimeUTC = combineDateTime(
        startdate.value,
        starttime.value
      ).toISOString();
      const endDateTimeUTC = combineDateTime(
        enddate.value,
        endtime.value
      ).toISOString();
      dispatch(
        resetMockTest(
          selectedTest.id,
          selectedClass,
          student.id,
          startDateTimeUTC,
          endDateTimeUTC
        )
      );
    } else {
      dispatch(
        resetMockTest(selectedTest.id, selectedClass, student.id, null, null)
      );
    }
  };

  const handleStartDateChange = (e) => {
    setStartdate({ value: e.target.value });
  };

  const handleStartTimeChange = (e) => {
    setStarttime({ value: e.target.value });
  };

  const handleEndDateChange = (e) => {
    setEnddate({ value: e.target.value });
  };

  const handleEndTimeChange = (e) => {
    setEndtime({ value: e.target.value });
  };

  const checkIfResetEnabled = () => {
    if (startdate.value && starttime.value && enddate.value && endtime.value) {
      const startDateTime = combineDateTime(startdate.value, starttime.value);
      const endDateTime = combineDateTime(enddate.value, endtime.value);
      return endDateTime > startDateTime;
    }
    if (
      !startdate.value &&
      !starttime.value &&
      !enddate.value &&
      !endtime.value
    )
      return true;
    return false;
  };

  const checkEndDateError = () => {
    if (startdate.value && starttime.value && enddate.value && endtime.value) {
      const startDateTime = combineDateTime(startdate.value, starttime.value);
      const endDateTime = combineDateTime(enddate.value, endtime.value);
      return endDateTime <= startDateTime;
    }
    return false;
  }

  useEffect(() => {
    setIsResetDisabled(!checkIfResetEnabled());
    setEndDateError(checkEndDateError());
  }, [startdate, starttime, enddate, endtime]);

  useEffect(() => {
    if (!resetMockTestLoader && resetMockTestSuccess) {
      const { reset_count, start_time, end_time } =
        resetMockTestInfo.reschedule;
      setStudents((prev) =>
        prev.map((i) =>
          i.id === student.id
            ? {
                ...i,
                status: resetMockTestInfo.status,
                statusText: resetMockTestInfo.status_text,
                reschedule: {
                  resetCount: reset_count,
                  startTime: start_time,
                  endTime: end_time,
                },
              }
            : { ...i }
        )
      );
      dispatch(resetMockTestReschedule());
      handleClose();
    }
  }, [resetMockTestLoader, resetMockTestSuccess]);

  return (
    <div className={style["reschedule-cnt"]}>
      <div className={style["date-field"]}>
        <div className={style["header-label"]}>
          {textIdentifier["start-date"][lang]}
        </div>
        <div className={style["inp-field-cnt"]}>
          <input
            className={style["inp-field"]}
            ref={startDateRef}
            tabIndex={-1}
            onClick={(e) => {
              e.target.showPicker();
            }}
            value={startdate.value}
            onChange={(e) => {
              handleStartDateChange(e);
            }}
            type="date"
            lang="hi"
            min={currentDate}
          />
          <input
            className={style["inp-field"]}
            ref={startTimeRef}
            tabIndex={-1}
            onClick={(e) => {
              e.target.showPicker();
            }}
            value={starttime.value}
            onChange={(e) => {
              handleStartTimeChange(e);
            }}
            type="time"
            lang="hi"
          />
        </div>
      </div>
      <div className={style["date-field"]}>
        <div className={style["header-label"]}>
          {textIdentifier["end-date"][lang]}
        </div>
        <div className={style["inp-field-cnt"]}>
          <input
            className={[style["inp-field"],isEndDateError && style["inp-field--error"]].join(" ")}
            ref={endDateRef}
            tabIndex={-1}
            onClick={(e) => {
              e.target.showPicker();
            }}
            value={enddate.value}
            onChange={(e) => {
              handleEndDateChange(e);
            }}
            type="date"
            lang="hi"
            min={currentDate}
          />
          <input
            className={[style["inp-field"],isEndDateError && style["inp-field--error"]].join(" ")}
            ref={endTimeRef}
            tabIndex={-1}
            onClick={(e) => {
              e.target.showPicker();
            }}
            value={endtime.value}
            onChange={(e) => {
              handleEndTimeChange(e);
            }}
            type="time"
            lang="hi"
          />
        </div>
      </div>
      <div className={style["note"]}>
        {`${textIdentifier["note"][lang]}: ${textIdentifier["clicking-on-reschedule-will-reset-the-progress-of-this-student"][lang]}.`}
      </div>
      <div className={style["footer-btn"]}>
        <button className={style["cancel-btn"]} onClick={handleClose}>
          {textIdentifier["cancel"][lang]}
        </button>
        <button
          className={[
            style["reset-btn"],
            resetMockTestLoader && style["reset-btn--disabled"],
            isResetDisabled && style["reset-btn--disabled"],
          ].join(" ")}
          onClick={handleResetClick}
        >
          {textIdentifier["reschedule"][lang]}
        </button>
      </div>
    </div>
  );
};

const MockResetModal = ({
  student,
  handleClose,
  setStudents,
  selectedTest,
  selectedClass,
}) => {
  const { userSession } = useSelector((state) => state);
  const lang = userSession && userSession.user && userSession.user.language;
  const [tabs, setTabs] = useState([
    {
      id: 0,
      tabName: "Reset",
      isActive: true,
    },
    {
      id: 1,
      tabName: "Reschedule",
      isActive: false,
    },
  ]);
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabClick = (id) => {
    setTabs((prev) =>
      prev.map((i) =>
        i.id === id ? { ...i, isActive: true } : { ...i, isActive: false }
      )
    );
    setCurrentTab(id);
  };

  return (
    <div className={style["reset-modal-cnt"]}>
      <div className={style["reset-modal-body"]}>
        <div className={style["header"]}>
          <div className={style["header--text"]}>{textIdentifier["reset-reschedule-test"][lang]}</div>
          <div className={style["cross-cnt"]} onClick={handleClose}>
            <CrossIcon />
          </div>
        </div>
        <div className={style["tabnav-cnt"]}>
          {tabs.map((i, index) => (
            <div
              className={[
                style["tab-cnt"],
                i.isActive && style["tab-cnt--active"],
              ].join(" ")}
              key={i.id}
              onClick={() => handleTabClick(i.id)}
            >
              <div
                className={[
                  style["indicator"],
                  i.isActive && style["indicator--active"],
                ].join(" ")}
              >
                {i.isActive && <div className={style["inner"]}></div>}
              </div>
              {i.tabName}
            </div>
          ))}
        </div>
        <div className={style["student-name"]}>
          {`"${student.firstName} ${student.lastName}"`}
        </div>
        <div className={style["reset-info"]}>
          The progress of this student will be reset, allowing the student to
          reattempt the test.
        </div>
        {currentTab == 0 ? (
          <ResetSection
            student={student}
            handleClose={handleClose}
            lang={lang}
            selectedTest={selectedTest}
            selectedClass={selectedClass}
            setStudents={setStudents}
          />
        ) : (
          <RescheduleSection
            student={student}
            handleClose={handleClose}
            lang={lang}
            selectedTest={selectedTest}
            selectedClass={selectedClass}
            setStudents={setStudents}
          />
        )}
      </div>
    </div>
  );
};

export default MockResetModal;
