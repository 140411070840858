const Markup = ({ markup }) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: markup,
      }}
    />
  );
};

export default Markup;