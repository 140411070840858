import { textIdentifier } from "../../../../components/Common/textIdentifier";
import style from "./style.scss";
function ResetTestModal(props) {


  const student = props.selectedStudent;
 
  const language = props.language || "eng";
  return (
    <div>
      <div className={style["wrapper"]}>
        <div className={style["mainCard"]}>
          <div className={style["modal-head"]}>
            <div>{textIdentifier["confirmation"][language]}</div>
            <div className={style["close-icon"]} onClick={()=>props.handleModalClick(false)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.38163 6.38166C6.54569 6.21781 6.76809 6.12577 6.99996 6.12577C7.23184 6.12577 7.45423 6.21781 7.6183 6.38166L14 12.7633L20.3816 6.38166C20.4617 6.2957 20.5583 6.22674 20.6657 6.17892C20.773 6.1311 20.8889 6.10538 21.0064 6.10331C21.1238 6.10124 21.2405 6.12285 21.3495 6.16686C21.4584 6.21086 21.5574 6.27637 21.6405 6.35945C21.7236 6.44254 21.7891 6.54151 21.8331 6.65047C21.8771 6.75942 21.8987 6.87612 21.8967 6.99361C21.8946 7.11109 21.8689 7.22696 21.821 7.33429C21.7732 7.44163 21.7043 7.53823 21.6183 7.61833L15.2366 14L21.6183 20.3817C21.7043 20.4618 21.7732 20.5584 21.821 20.6657C21.8689 20.773 21.8946 20.8889 21.8967 21.0064C21.8987 21.1239 21.8771 21.2406 21.8331 21.3495C21.7891 21.4585 21.7236 21.5575 21.6405 21.6405C21.5574 21.7236 21.4584 21.7891 21.3495 21.8331C21.2405 21.8771 21.1238 21.8988 21.0064 21.8967C20.8889 21.8946 20.773 21.8689 20.6657 21.8211C20.5583 21.7733 20.4617 21.7043 20.3816 21.6183L14 15.2367L7.6183 21.6183C7.45243 21.7729 7.23304 21.857 7.00635 21.853C6.77967 21.849 6.56339 21.7572 6.40307 21.5969C6.24276 21.4366 6.15092 21.2203 6.14693 20.9936C6.14293 20.7669 6.22707 20.5475 6.38163 20.3817L12.7633 14L6.38163 7.61833C6.21777 7.45427 6.12573 7.23187 6.12573 7C6.12573 6.76812 6.21777 6.54573 6.38163 6.38166Z"
                  fill="#9E9E9E"
                />
              </svg>
            </div>
          </div>
          <div className={style["container-info"]}>
            <div className={style["message"]}>
              <p>
                {textIdentifier["are-you-sure-you-want-to-reset-this-test"][language]} “{props.selectedTest?.name}” {textIdentifier["for"][language]} “{student.showLastNameFirst ? (student.lastName ? student.lastName : "") + " " + (student.firstName ? student.firstName : "") : (student.firstName ? student.firstName : "") + " " +  (student.lastName ? student.lastName : "")}”</p>
            </div>
            <div className={style["buttons"]}>
              <button className={style["cancel"]} onClick={()=> props.handleModalClick(false)}>{textIdentifier["cancel"][language]}</button>
              <button className={style["okay"]} onClick={props.handleRefreshTest}>{textIdentifier["okay,reset"][language]}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}



export default ResetTestModal;
