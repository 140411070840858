export const FEAT_CLASS_DROPDOWN = "FEAT_CLASS_DROPDOWN"
export const FEAT_CREATE_CLASS = "FEAT_CREATE_CLASS"
export const FEAT_CHANGE_PASSWORD = "FEAT_CHANGE_PASSWORD"
export const FEAT_LOGOUT = "FEAT_LOGOUT"
export const FEAT_PRACTICE_MODE = "FEAT_PRACTICE_MODE"
export const FEAT_TEST_MODE = "FEAT_TEST_MODE"
export const FEAT_LEADERBOARD = "FEAT_LEADERBOARD"
export const FEAT_CLASS_LIST = "FEAT_CLASS_LIST"
export const FEAT_ANNOUNCEMENT = "FEAT_ANNOUNCEMENT"
export const FEAT_TEACH_NOW = "FEAT_TEACH_NOW"
export const FEAT_LEADERBOARD_VIEW="FEAT_LEADERBOARD_VIEW"
export const FEAT_ALERT_NOTIFICATION="FEAT_ALERT_NOTIFICATION"
export const FEAT_BOOKMARK_BUTTON = "FEAT_BOOKMARK_BUTTON"
export const FEAT_SETTINGS_BUTTON = "FEAT_SETTINGS_BUTTON";
export const FEAT_ACTIVITY_LOG = "FEAT_ACTIVITY_LOG";
export const FEAT_STUDENT_LEVEL = "FEAT_STUDENT_LEVEL";
export const FEAT_GOOGLE_CLASSROOM = "FEAT_GOOGLE_CLASSROOM";
export const FEAT_DESIGN_TEST = "FEAT_DESIGN_TEST";
export const FEAT_DISABLE_GRADES = "FEAT_DISABLE_GRADES";
export const FEAT_STUDY_MATERIAL = "FEAT_STUDY_MATERIAL";
export const FEAT_TOPIC_REPORT_DOWNLOAD = "FEAT_TOPIC_REPORT_DOWNLOAD";
export const FEAT_MOCK_TEST_REPORT_DOWNLOAD = "FEAT_MOCK_TEST_REPORT_DOWNLOAD";

export const customFeaturesList = list => {
  let featList = []
  if(list.includes(0)) featList.push(FEAT_CLASS_DROPDOWN)
  if(list.includes(1)) featList.push(FEAT_CREATE_CLASS)
  if(list.includes(2)) featList.push(FEAT_CHANGE_PASSWORD)
  if(list.includes(3)) featList.push(FEAT_LOGOUT)
  if(list.includes(4)) featList.push(FEAT_PRACTICE_MODE)
  if(list.includes(5)) featList.push(FEAT_TEST_MODE)
  if(list.includes(6)) featList.push(FEAT_LEADERBOARD)
  if(list.includes(7)) featList.push(FEAT_CLASS_LIST)
  if(list.includes(8)) featList.push(FEAT_ANNOUNCEMENT)
  if(list.includes(9)) featList.push(FEAT_TEACH_NOW)
  if(list.includes(10)) featList.push(FEAT_DESIGN_TEST) // design button
  if(list.includes(16)) featList.push(FEAT_LEADERBOARD_VIEW)
  if(list.includes(17)) featList.push(FEAT_ALERT_NOTIFICATION)
  if(list.includes(18)) featList.push(FEAT_BOOKMARK_BUTTON) //bookmark button
  if(list.includes(19)) featList.push(FEAT_SETTINGS_BUTTON)
  if(list.includes(20)) featList.push(FEAT_ACTIVITY_LOG)
  if(list.includes(22)) featList.push(FEAT_STUDENT_LEVEL)
  if(list.includes(21)) featList.push(FEAT_GOOGLE_CLASSROOM)
  if(list.includes(23)) featList.push(FEAT_DISABLE_GRADES)
  if(list.includes(24)) featList.push(FEAT_STUDY_MATERIAL)
  if(list.includes(26)) featList.push(FEAT_MOCK_TEST_REPORT_DOWNLOAD)
  if(list.includes(27)) featList.push(FEAT_TOPIC_REPORT_DOWNLOAD)
  return featList;
}
