import { useState, useEffect, useRef } from "react";
import style from "./style.scss";
import { textIdentifier } from "../../../../../components/Common/textIdentifier";
import {
  CrossIcon,
  UploadImageDeleteIcon,
  UploadingImageIcon,
} from "../../../../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import { resetUploadAutoGradingImage, uploadAutoGradingImage } from "../../../../../action";

const UploadImageModal = ({
  selectedQuestion,
  selectedSubpartNo,
  examId,
  handleUploadImageModal,
  selectedClass,
  user,
  studentId,
  index
}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const [imageUploading, setImageUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [imageUploaded, setImageUploaded] = useState(false);
  const fileInputRef = useRef(null);

  const {
    autoGradingImageUploading,
    autoGradingImageUploadError,
    autoGradingImageUploadSuccess,
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  useEffect(() => {
    const imageUploaded = selectedQuestion.images[selectedSubpartNo || 0];
    if (imageUploaded) {
      setImageUploaded(true);
      setSelectedImageUrl(imageUploaded);
    }
  }, [selectedQuestion, selectedSubpartNo]);

  useEffect(() => {
    if (!autoGradingImageUploading) {
      setImageUploading(false);
      const uploadedImage = selectedQuestion.images[selectedSubpartNo || 0];
      if (uploadedImage) {
        setImageUploaded(true);
        setSelectedImageUrl(uploadedImage);
      }
      else if(autoGradingImageUploadError){
        setErrorMessage(textIdentifier["something-went-wrong"][user.language]);
      }
    }
  }, [autoGradingImageUploading,autoGradingImageUploadError, selectedQuestion, selectedSubpartNo]);

  useEffect(() => {
    if (!autoGradingImageUploading && autoGradingImageUploadSuccess) {
      closeModal();
    }
  }, [autoGradingImageUploadSuccess, autoGradingImageUploading]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    processFile(file);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];
    processFile(file);
  };

  const processFile = (file) => {
    if (!file) return;
    setErrorMessage("");
    if (
      ![
        "image/png",
        "image/jpg",
        "image/jpeg",
        "image/webp",
        "image/heic",
      ].includes(file.type)
    ) {
      setErrorMessage("Please upload a valid image file.");
      return;
    }
    if (file.size > 5 * 1024 * 1024) {
      setErrorMessage("File size should be less than 5MB.");
      return;
    }
    setSelectedImage(file);
    setSelectedImageUrl(URL.createObjectURL(file));
  };

  const removeSelectedImage = (e) => {
    e.stopPropagation();
    setSelectedImage(null);
    setImageUploading(false);
    setErrorMessage("");
    setImageUploaded(false);
    setSelectedImageUrl("");
  };

  const handleImageUpload = (index) => {
    if (!selectedImage) {
      setErrorMessage("Please select an image to upload.");
      return;
    }
    setImageUploading(true);
    // setUploadProgress(0);
    // uploadAutoGradingImage(
    //   selectedImage,
    //   selectedQuestion.id,
    //   selectedQuestion.qid,
    //   selectedQuestion.ques_no,
    //   examId,
    //   selectedSubpartNo
    // );
    dispatch(
      uploadAutoGradingImage(
        selectedImage,
        index,
        studentId,
        selectedClass,
        selectedQuestion.id,
        selectedQuestion.qid,
        selectedQuestion.quesNo,
        examId,
        selectedSubpartNo
      )
    );
  };
  const handleChangeImage = (e) => {
    removeSelectedImage(e);
    fileInputRef.current?.click();
  };

  const closeModal = () => {
    handleUploadImageModal(null, null);
    dispatch(resetUploadAutoGradingImage());
  };

  return (
    <div className={style["upload-image-modal-container"]}>
      <div className={style["upload-image-modal"]}>
        <div className={style["upload-image-modal-header"]}>
          {imageUploaded ? (
            <div className={style["uploaded-image-header"]}>
              {textIdentifier["image-uploaded"][user.language]}
              <div className={style["close-modal"]} onClick={closeModal}>
                <CrossIcon />
              </div>
            </div>
          ) : (
            textIdentifier["upload-image"][user.language]
          )}
          {!imageUploaded && (
            <div className={style["upload-image-sub-header"]}>
              {textIdentifier["upload-image-sub-header-text"][user.language]}
            </div>
          )}
        </div>

        <div className={style["upload-image-modal-body"]}>
          <div className={style["select-file"]}>
            {imageUploading ? (
              <div className={style["uploading-image"]}>
                <div className={style["uploading-icon"]}>
                  <UploadingImageIcon />
                </div>
                <div className={style["upload-progress"]}>
                  {textIdentifier["uploading..."][user.language]}
                </div>
              </div>
            ) : !selectedImageUrl ? (
              <div
                className={style["drag-drop-area"]}
                onClick={handleChangeImage}
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleDrop}
              >
                <input
                  type="file"
                  accept="image/png, image/jpg, image/jpeg, image/webp, image/heic"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <label className={style["file-label"]}>
                  {textIdentifier["browse"][user.language]}
                </label>
                <p>{textIdentifier["or-drag-a-file-here"][user.language]}</p>
              </div>
            ) : (
              <div className={style["selected-image"]}>
                <div className={style["selected-image-header"]}>
                  {selectedImage ? (
                    <span className={style["image-name"]}>
                      {selectedImage.name} (
                      {(selectedImage.size / 1024).toFixed(2)} KB)
                    </span>
                  ) : (
                    <span className={style["image-name"]}></span>
                  )}
                  <div
                    className={style["delete-image"]}
                    onClick={removeSelectedImage}
                  >
                    <UploadImageDeleteIcon />
                  </div>
                </div>
                <img src={selectedImageUrl} alt="selected" />
              </div>
            )}
          </div>
        </div>

        {errorMessage && (
          <div className={style["error-message"]}>{errorMessage}</div>
        )}
        <div className={style["upload-image-modal-buttons"]}>
          <button className={style["cancel-btn"]} onClick={closeModal}>
            {textIdentifier["cancel"][user.language]}
          </button>
          <button
            className={[style["upload-btn"], (imageUploading || !selectedImageUrl) ? style["disabled"] : ""].join(" ")}
            onClick={(e)=> {imageUploaded ? handleChangeImage(e) : handleImageUpload(index)}}
            disabled={imageUploading}
          >
            {imageUploaded
              ? textIdentifier["change-image"][user.language]
              : textIdentifier["upload-image"][user.language]}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadImageModal;
