import { useEffect, useRef, useState } from "preact/hooks";
import style from "./style.scss";
import {
  AIFeedbackBackArrow,
  AIFeedbackBackArrowWhite,
  CheckRubricIcon,
  CheckRubricIconWhite,
  EditIconAICriteria,
  markedRight,
  markedWrong,
} from "../../../../assets/images";
import ArrowDown from "../../../../assets/images/ArrowDown.svg";
import ArrowUp from "../../../../assets/images/ArrowUp.svg";
import RubricModal from "./RubricModal";
import { useDispatch, useSelector } from "react-redux";
import {
  editAIFeedbackCriteria,
  editOpenEndedFeedback,
  exitAIFeedbackReportView,
  getAIFeedbackReport,
  setCurrentOpenEndedQuestionFeedback,
  setEditAIFeedbackCriteriaLoader,
  setEditOpenEndedFeedbackLoader,
  updateStudentReportQuestionMarks,
} from "../../../../action";
import { textIdentifier } from "../../../../components/Common/textIdentifier";

const getSubpartNumber = (subpart_number) => {
  return String.fromCharCode("a".charCodeAt(0) + subpart_number);
};

const CriteriaDropdown = ({
  currentCriteria,
  handleSelectCriteria,
  allCriterias,
}) => {
  const [dropdown, setDropdown] = useState(false);

  const handleSelectItem = (index) => {
    handleSelectCriteria(index);
    setDropdown(false);
  };

  return (
    <div
      className={[style["criteria-dropdown-container"]].join(" ")}
      onMouseEnter={() => {
        setDropdown(true);
      }}
      onMouseLeave={() => {
        setDropdown(false);
      }}
    >
      <div
        className={style["title-container"]}
        onClick={() => {
          setDropdown(!dropdown);
        }}
        role="presentation"
      >
        <div className={[style["title"]]}>{currentCriteria}</div>
        <img
          src={dropdown ? ArrowUp : ArrowDown}
          alt="..."
          className={style["criteria--arrow--img"]}
        />
      </div>
      <div className={style["separator"]}></div>
      {dropdown && (
        <div className={style["criteria--select--dropdown"]}>
          {allCriterias.map((item, index) => {
            return (
              <div
                className={[
                  style["criteria-list-item"],
                  index !== allCriterias.length - 1 &&
                    style["criteria-list-item--border"],
                ].join(" ")}
                onClick={() => handleSelectItem(index)}
              >
                {item}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const DangerousMarkup = (props) => {
  const [markup, changeMarkup] = useState(props.markup);
  const [qid, changeQid] = useState(props.qid);
  useEffect(() => {
    changeMarkup(props.markup);
  }, [qid]);

  if (qid !== props.qid) changeQid(props.qid);

  return (
    <div
      key={qid}
      dangerouslySetInnerHTML={{
        __html: markup,
      }}
    />
  );
};

const AIFeedbackHeader = ({
  isEssayType,
  handleClose,
  marks,
  maxMarks,
  handleToggleRubricModal,
  language,
}) => {
  const dispatch = useDispatch();
  return (
    <div className={style["ai-feedback--header"]}>
      <div
        className={style["back-btn-cnt"]}
        onClick={() => {
          dispatch(exitAIFeedbackReportView());
          handleClose();
        }}
      >
        <AIFeedbackBackArrowWhite />
      </div>

      <div className={style["header-label-left"]}>
        {textIdentifier["back"][language]}
      </div>
      <div className={style["header-label-main"]}>
        {textIdentifier["ai-and-teacher-evaluation"][language]}:
        <span className={style["marks"]}>{`${marks} out of ${maxMarks} ${
          textIdentifier["mark(s)"][language][0].toUpperCase() +
          textIdentifier["mark(s)"][language].slice(1)
        }`}</span>
      </div>
      {isEssayType && (
        <div
          className={style["check-rubric"]}
          onClick={handleToggleRubricModal}
        >
          <CheckRubricIconWhite className={style["check-rubric--icon"]} />
          <span className={style["check-rubric--text"]}>
            {textIdentifier["ai-grader-rubric"][language]}
          </span>
        </div>
      )}
    </div>
  );
};

const QuestionAnswerView = ({
  questionId,
  question,
  currentAnswer,
  language,
  isEssayType,
  selectedQuestionAIFeedback,
}) => {
  const answerRef = useRef(null);
  const answerId = `exam_question_0`;

  const adjustHeight = () => {
    answerRef.current.style.height = "0px";
    const scrollHeight = answerRef.current.scrollHeight;
    answerRef.current.style.height = Number(scrollHeight) + 30 + "px";
  };

  const handleResize = () => {
    adjustHeight();
  };

  useEffect(() => {
    if (answerRef.current) {
      adjustHeight();
    }
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (answerRef.current) {
      adjustHeight();
    }
  }, [questionId]);

  useEffect(() => {
    if (selectedQuestionAIFeedback.userResponse) {
      let arg =
        typeof selectedQuestionAIFeedback.userResponse === "string"
          ? parseQuestionResponse(selectedQuestionAIFeedback.userResponse)
          : selectedQuestionAIFeedback.userResponse;
      if (Boolean(arg)) {
        parse_answer.insertAnswers(arg, answerId);
      }
      parse_answer.disableAnswers(answerId);
    }

    if (
      selectedQuestionAIFeedback &&
      selectedQuestionAIFeedback.solution &&
      selectedQuestionAIFeedback.question &&
      selectedQuestionAIFeedback.id &&
      selectedQuestionAIFeedback.marking_list &&
      Array.isArray(selectedQuestionAIFeedback.marking_list) &&
      selectedQuestionAIFeedback.marking_list.length > 0
    ) {
      const subpartAnsInterval = setInterval(() => {
        if (
          document.getElementById(answerId) &&
          document.getElementById(answerId).getElementsByClassName("sub-part")
            .length > 0
        ) {
          const ansSubparts = Object.values(
            document.getElementById(answerId).getElementsByClassName("sub-part")
          );
          ansSubparts.forEach((partAnsEle, partAnsIndex) => {
            let partAnsPoint = partAnsEle.querySelector("b");
            partAnsPoint.style.color =
              selectedQuestionAIFeedback.marking_list[partAnsIndex] > 0
                ? "#3CD22D"
                : "#FF6666";
            let partAnsMarked = document.createElement("div");
            partAnsMarked.style.marginTop = "2px";
            partAnsMarked.style.marginLeft = "-13px";
            partAnsMarked.style.marginRight = "13px";
            partAnsMarked.className = "sub-part-marked";
            partAnsMarked.innerHTML =
              selectedQuestionAIFeedback.marking_list[partAnsIndex] > 0
                ? markedRight
                : markedWrong;
            if (
              partAnsPoint.nextSibling &&
              partAnsPoint.nextSibling.tagName == "DIV" &&
              partAnsPoint.nextSibling.className == "sub-part-marked"
            )
              partAnsPoint.nextSibling.remove();
            partAnsPoint.insertAdjacentElement("afterend", partAnsMarked);
          });
          clearInterval(subpartAnsInterval);
        }
      }, 25);
    }
  }, []);

  return (
    <div
      className={[
        style["question-view-cnt"],
        !isEssayType && style["question-view-cnt--openended-height"],
      ].join(" ")}
    >
      <div className={style["question-cnt"]}>
        <div className={style["label"]}>
          {textIdentifier["question"][language]}
        </div>
        <div className={style["val"]}>
          <DangerousMarkup qid={questionId} markup={question} />
        </div>
      </div>
      <div className={style["separator"]} />
      <div className={style["answer-cnt"]}>
        <div className={style["label"]}>
          {textIdentifier["answer"][language]}
        </div>
        {isEssayType ? (
          <textarea
            value={currentAnswer}
            className={style["val"]}
            ref={answerRef}
            disabled
          />
        ) : (
          <div id={answerId}>
            <DangerousMarkup qid={questionId} markup={currentAnswer} />
          </div>
        )}
      </div>
    </div>
  );
};

const AIEvaluationFeedbackView = ({
  currentCriteriaId,
  currentCriteria,
  currentFeedback,
  currentMarks,
  currentMaxMarks,
  setEssayGradingAIFeedbackList,
  selectedCriteriaIndex,
  setTotalMarksObtained,
  studentId,
  question,
  language,
}) => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [editSubmitted, setEditSubmitted] = useState(false);
  const [updatedMarks, setUpdatedMarks] = useState(undefined);
  const [feedback, setFeedback] = useState(undefined);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [fieldsSaved, setFieldsSaved] = useState(false);
  const { savingAIFeedbackCriteria, savingAIFeedbackCriteriaSuccess } =
    useSelector((state) => state);

  const handleEditClick = () => {
    setIsEditing(true);
    setUpdatedMarks(currentMarks);
    setFeedback(currentFeedback);
  };

  const handleMarksChange = (e) => {
    setUpdatedMarks("" + Number(e.target.value));
  };

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const handleCancelEdit = () => {
    setError(false);
    setIsEditing(false);
  };

  const handleBack = () => {
    setFieldsSaved(false);
    dispatch(setEditAIFeedbackCriteriaLoader(false));
  };

  const handleSubmit = () => {
    dispatch(setEditAIFeedbackCriteriaLoader(true));
    dispatch(
      editAIFeedbackCriteria(currentCriteriaId, Number(updatedMarks), feedback)
    );
    setEditSubmitted(true);
  };

  const handleResetEdit = () => {
    setFieldsSaved(false);
    setError(false);
    setErrorMsg("");
    setIsEditing(false);
  };

  useEffect(() => {
    const isBlank =
      feedback !== undefined &&
      feedback.split(/\s+/).filter((i) => i !== "").length === 0;
    if (
      updatedMarks !== undefined &&
      Number(updatedMarks) > Number(currentMaxMarks)
    ) {
      setError(true);
      setErrorMsg("Score cannot be greater than total marks");
    } else if (feedback !== undefined && isBlank) {
      setError(true);
      setErrorMsg("Fields cannot be empty");
    } else {
      setError(false);
      setErrorMsg("");
    }
  }, [feedback, updatedMarks]);

  useEffect(() => {
    if (
      !savingAIFeedbackCriteria &&
      savingAIFeedbackCriteriaSuccess &&
      editSubmitted
    ) {
      setFieldsSaved(false);
      setIsEditing(false);
      setEditSubmitted(false);
      setTotalMarksObtained((prev) => {
        const newTotalMarks = (
          Number(prev) -
          Number(currentMarks) +
          Number(updatedMarks)
        ).toFixed(2);
        dispatch(
          updateStudentReportQuestionMarks(
            newTotalMarks,
            studentId,
            question.questionIndex
          )
        );
        return newTotalMarks;
      });
      setEssayGradingAIFeedbackList((prev) =>
        prev.map((i) => {
          if (i.essay_grading_id === currentCriteriaId)
            return {
              ...i,
              marks: updatedMarks,
              feedback: feedback,
            };
          else
            return {
              ...i,
            };
        })
      );
    }
  }, [savingAIFeedbackCriteriaSuccess, savingAIFeedbackCriteria]);

  useEffect(() => {
    if (isEditing) {
      handleResetEdit();
    }
  }, [selectedCriteriaIndex]);

  return (
    <div className={style["feedback-cnt"]}>
      <div className={style["header"]}>
        <div className={style["header-feedback-text"]}>
          {`Feedback - ${currentCriteria}`}{" "}
        </div>
        {!isEditing && (
          <div className={style["edit-cta"]} onClick={handleEditClick}>
            <EditIconAICriteria />
            <span className={style["edit-text"]}>Edit</span>
          </div>
        )}
      </div>
      {isEditing && (
        <div className={style["marks-container"]}>
          <span>Scored-</span>{" "}
          <input
            type="number"
            className={[
              style["marks--inp"],
              fieldsSaved && style["marks--inp--disabled"],
            ].join(" ")}
            max={currentMaxMarks}
            value={Number(updatedMarks)}
            onChange={handleMarksChange}
            disabled={fieldsSaved}
          />{" "}
          <span>{`out of ${currentMaxMarks} mark(s)`}</span>
        </div>
      )}

      {!isEditing && currentMarks !== undefined && (
        <div className={style["marks-container"]}>
          {`Scored- ${Number(currentMarks).toFixed(
            2
          )} out of ${currentMaxMarks} mark(s)`}
        </div>
      )}

      {isEditing ? (
        <textarea
          className={[
            style["feedback--inp"],
            fieldsSaved && style["feedback--inp--disabled"],
          ].join(" ")}
          value={feedback}
          onChange={handleFeedbackChange}
          placeholder="Enter Your Description"
          disabled={fieldsSaved}
        />
      ) : (
        <textarea
          value={currentFeedback}
          className={style["feedback"]}
          disabled
        />
      )}
      {error && <div className={style["error-msg"]}>{errorMsg}</div>}
      {fieldsSaved && (
        <div className={style["confirm-toast"]}>
          <div className={style["main-text"]}>
            Are you sure you want to save this?{" "}
          </div>
          <div className={style["secondary-text"]}>
            These modifications will also reflect on the student dashboard.
          </div>
        </div>
      )}
      {isEditing && (
        <div
          className={[
            style["footer-cta"],
            (fieldsSaved || error) && style["footer-cta--isError"],
          ].join(" ")}
        >
          <div
            className={style["cancel-btn"]}
            onClick={() => (fieldsSaved ? handleBack() : handleCancelEdit())}
          >
            {fieldsSaved
              ? textIdentifier["back"][language]
              : textIdentifier["cancel"][language]}
          </div>
          <div
            className={[
              style["save-btn"],
              error && style["save-btn--disabled"],
            ].join(" ")}
            onClick={() =>
              fieldsSaved ? handleSubmit() : setFieldsSaved(true)
            }
          >
            {savingAIFeedbackCriteria ? (
              <svg
                className={style["spin"]}
                width="22"
                height="22"
                viewBox="0 0 50.098 50.098"
              >
                <g
                  id="Group_611"
                  data-name="Group 611"
                  transform="translate(-154.951 -279.951)"
                >
                  <path
                    id="Path_1026"
                    data-name="Path 1026"
                    d="M16.837,0A16.837,16.837,0,1,1,0,16.837"
                    transform="translate(203 311.163) rotate(150)"
                    fill="#fff"
                    stroke="#00b1b0"
                    stroke-linecap="round"
                    stroke-width="3"
                  />
                  <path
                    id="Path_1027"
                    data-name="Path 1027"
                    d="M9.794,0A9.794,9.794,0,1,1,0,9.794"
                    transform="translate(170.206 295.206)"
                    fill="#fff"
                    stroke="#00b1b0"
                    stroke-linecap="round"
                    stroke-width="3"
                  />
                </g>
              </svg>
            ) : fieldsSaved ? (
              textIdentifier["confirm"][language]
            ) : (
              textIdentifier["save"][language]
            )}
          </div>
        </div>
      )}
      <div />
    </div>
  );
};

const OpenEndedFeedbackCard = ({
  currentFeedbackObj,
  language,
  index,
  handleConfirmEdit,
  setTotalMarksObtained,
  studentId,
  question,
}) => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [editSubmitted, setEditSubmitted] = useState(false);
  const [updatedMarks, setUpdatedMarks] = useState(undefined);
  const [feedback, setFeedback] = useState(undefined);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [fieldsSaved, setFieldsSaved] = useState(false);
  const textareaRef = useRef(null);
  const { savingOpenEndedFeedback, savingOpenEndedFeedbackSuccess } =
    useSelector((state) => state);

  const handleEditClick = () => {
    setIsEditing(true);
    setUpdatedMarks(currentFeedbackObj.marks_obtained);
    setFeedback(currentFeedbackObj.feedback);
  };

  const handleMarksChange = (e) => {
    setUpdatedMarks("" + Number(e.target.value));
  };

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const handleCancelEdit = () => {
    setError(false);
    setIsEditing(false);
  };

  const handleBack = () => {
    setFieldsSaved(false);
    dispatch(setEditOpenEndedFeedbackLoader(false));
  };

  const handleSubmit = () => {
    dispatch(setEditOpenEndedFeedbackLoader(true));
    dispatch(
      editOpenEndedFeedback(
        question.assessmentStudentId,
        Number(updatedMarks),
        feedback,
        currentFeedbackObj.subpart_number
      )
    );
    setEditSubmitted(true);
  };

  useEffect(() => {
    const isBlank =
      feedback !== undefined &&
      feedback.split(/\s+/).filter((i) => i !== "").length === 0;
    if (updatedMarks !== undefined && Number(updatedMarks) < 0) {
      setError(true);
      setErrorMsg("score-cannot-be-negative");
    } else if (
      updatedMarks !== undefined &&
      Number(updatedMarks) > Number(currentFeedbackObj.total_marks)
    ) {
      setError(true);
      setErrorMsg("score-cannot-be-greater-than-total-marks");
    } else if (feedback !== undefined && isBlank) {
      setError(true);
      setErrorMsg("fields-cannot-be-empty");
    } else {
      setError(false);
      setErrorMsg("");
    }
  }, [feedback, updatedMarks]);

  useEffect(() => {
    if (
      !savingOpenEndedFeedback &&
      savingOpenEndedFeedbackSuccess &&
      editSubmitted
    ) {
      setFieldsSaved(false);
      setIsEditing(false);
      setEditSubmitted(false);
      setTotalMarksObtained((prev) => {
        const newTotalMarks = (
          Number(prev) -
          Number(currentFeedbackObj.marks_obtained) +
          Number(updatedMarks)
        ).toFixed(2);
        dispatch(
          updateStudentReportQuestionMarks(
            newTotalMarks,
            studentId,
            question.questionIndex
          )
        );
        return newTotalMarks;
      });
      handleConfirmEdit(
        { feedback: feedback, marks_obtained: Number(updatedMarks) },
        index
      );
    }
  }, [savingOpenEndedFeedback, savingOpenEndedFeedbackSuccess]);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;

    textarea.style.height = "auto";

    const newHeight = textarea.scrollHeight;

    textarea.style.height = Math.min(newHeight, 550) + "px";

    if (newHeight > 550) {
      textarea.style.overflowY = "auto";
    } else {
      textarea.style.overflowY = "hidden";
    }
  };

  useEffect(() => {
    if (isEditing && textareaRef.current) {
      adjustTextareaHeight();
    }
  }, [isEditing, feedback]);

  return (
    <div className={style["open-ended-feedback-card"]}>
      <div className={style["open-ended-feedback-card-header"]}>
        <div className={style["open-ended-feedback-card-header--left"]}>
          {currentFeedbackObj.subpart_number !== null &&
            `(${getSubpartNumber(currentFeedbackObj.subpart_number)})`}
        </div>
        {isEditing ? (
          <div className={style["edit-score-container"]}>
            <span>{textIdentifier["scored"][language]} - </span>{" "}
            <input
              type="number"
              className={[
                style["marks--inp"],
                fieldsSaved && style["marks--inp--disabled"],
              ].join(" ")}
              min={0}
              max={currentFeedbackObj.total_marks}
              value={Number(updatedMarks)}
              onChange={handleMarksChange}
              disabled={fieldsSaved}
            />{" "}
            <span>{`out of ${currentFeedbackObj.total_marks} ${textIdentifier["mark(s)"][language]}`}</span>
          </div>
        ) : (
          <div className={style["score-cnt"]}>
            {`${textIdentifier["scored"][language]} - ${currentFeedbackObj.marks_obtained} ${textIdentifier["out-of"][language]} ${currentFeedbackObj.total_marks} ${textIdentifier["mark(s)"][language]}`}
          </div>
        )}
      </div>
      {isEditing ? (
        <textarea
          ref={textareaRef}
          className={[
            style["feedback--inp"],
            fieldsSaved && style["feedback--inp--disabled"],
          ].join(" ")}
          value={feedback}
          onChange={handleFeedbackChange}
          placeholder="Enter Your Description"
          disabled={fieldsSaved}
        />
      ) : (
        <div className={style["feedback"]}>{currentFeedbackObj.feedback}</div>
      )}
      {error && errorMsg.length > 0 && (
        <div className={style["error-msg"]}>
          {textIdentifier[errorMsg][language]}
        </div>
      )}
      {fieldsSaved && (
        <div className={style["confirm-toast"]}>
          <div className={style["main-text"]}>
            {textIdentifier["are-you-sure-you-want-to-save-this-?"][language]}
          </div>
          <div className={style["secondary-text"]}>
            {
              textIdentifier[
                "these-modifications-will-also-reflect-on-the-student-dashboard"
              ][language]
            }
          </div>
        </div>
      )}
      {isEditing ? (
        <div
          className={[
            style["footer-cta"],
            (fieldsSaved || error) && style["footer-cta--isError"],
          ].join(" ")}
        >
          <div
            className={style["cancel-btn"]}
            onClick={() => (fieldsSaved ? handleBack() : handleCancelEdit())}
          >
            {fieldsSaved
              ? textIdentifier["back"][language]
              : textIdentifier["cancel"][language]}
          </div>
          <div
            className={[
              style["save-btn"],
              error && style["save-btn--disabled"],
            ].join(" ")}
            onClick={() =>
              fieldsSaved ? handleSubmit() : setFieldsSaved(true)
            }
          >
            {savingOpenEndedFeedback ? (
              <svg
                className={style["spin"]}
                width="22"
                height="22"
                viewBox="0 0 50.098 50.098"
              >
                <g
                  id="Group_611"
                  data-name="Group 611"
                  transform="translate(-154.951 -279.951)"
                >
                  <path
                    id="Path_1026"
                    data-name="Path 1026"
                    d="M16.837,0A16.837,16.837,0,1,1,0,16.837"
                    transform="translate(203 311.163) rotate(150)"
                    fill="#fff"
                    stroke="#00b1b0"
                    stroke-linecap="round"
                    stroke-width="3"
                  />
                  <path
                    id="Path_1027"
                    data-name="Path 1027"
                    d="M9.794,0A9.794,9.794,0,1,1,0,9.794"
                    transform="translate(170.206 295.206)"
                    fill="#fff"
                    stroke="#00b1b0"
                    stroke-linecap="round"
                    stroke-width="3"
                  />
                </g>
              </svg>
            ) : fieldsSaved ? (
              textIdentifier["confirm"][language]
            ) : (
              textIdentifier["save"][language]
            )}
          </div>
        </div>
      ) : (
        <div className={style["footer-edit-cnt"]}>
          <div className={style["edit-cta"]} onClick={handleEditClick}>
            {textIdentifier["edit"][language]}
          </div>
        </div>
      )}
    </div>
  );
};

const OpenEndedFeedbackView = ({
  allFeedbacks,
  language,
  setTotalMarksObtained,
  studentId,
  question,
}) => {
  const dispatch = useDispatch();
  const [openEndedFeedbackList, setOpenEndedFeedbackList] = useState([
    ...allFeedbacks,
  ]);

  const handleConfirmEdit = (updatedFeedbackObj, index) => {
    const updatedFeedbackList = [...openEndedFeedbackList];
    updatedFeedbackList[index] = {
      ...updatedFeedbackList[index],
      feedback: updatedFeedbackObj.feedback,
      marks_obtained: updatedFeedbackObj.marks_obtained,
    };
    setOpenEndedFeedbackList(updatedFeedbackList);
    dispatch(
      setCurrentOpenEndedQuestionFeedback(
        updatedFeedbackList,
        studentId,
        question.questionIndex
      )
    );
  };

  return (
    <div className={style["open-ended-feedback-cnt"]}>
      <div className={style["header"]}>
        {textIdentifier["feedback"][language]}
      </div>
      <div className={style["open-ended-feedback-cnt"]}>
        {openEndedFeedbackList.map((feedback, index) => (
          <OpenEndedFeedbackCard
            language={language}
            currentFeedbackObj={feedback}
            index={index}
            handleConfirmEdit={handleConfirmEdit}
            setTotalMarksObtained={setTotalMarksObtained}
            studentId={studentId}
            question={question}
          />
        ))}
      </div>
    </div>
  );
};

const AIFeedbackView = ({ question, handleClose, examConfigId, studentId }) => {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.userSession.user);
  const [selectedCriteriaIndex, setSelectedCriteriaIndex] = useState(undefined);
  const [totalMarksObtained, setTotalMarksObtained] = useState(
    question.isOpenEnded ? question.marks : undefined
  );
  const [showRubricModal, setShowRubricModal] = useState(false);
  const [essayGradingAIFeedbackList, setEssayGradingAIFeedbackList] = useState(
    []
  );
  const { essayGradingAIFeedback } = useSelector((state) => state);

  const handleToggleRubricModal = () => {
    setShowRubricModal((prev) => !prev);
  };

  const handleSelectCriteria = (index) => {
    setSelectedCriteriaIndex(index);
  };

  useEffect(() => {
    if (question.isEssayType) {
      dispatch(
        getAIFeedbackReport(question.id, studentId, question.attemptOrder)
      );
    }

    return () => {
      setSelectedCriteriaIndex(undefined);
      setEssayGradingAIFeedbackList([]);
    };
  }, []);

  useEffect(() => {
    if (
      selectedCriteriaIndex === undefined &&
      essayGradingAIFeedback &&
      essayGradingAIFeedback.criterias.length > 0
    ) {
      setEssayGradingAIFeedbackList([...essayGradingAIFeedback.criterias]);
      setSelectedCriteriaIndex(0);
      setTotalMarksObtained(
        essayGradingAIFeedback.question_marks_obtained || question.marks
      );
    }
  }, [essayGradingAIFeedback]);

  const allCriterias =
    (essayGradingAIFeedbackList.length > 0 &&
      essayGradingAIFeedbackList.map((i) => i.criteria)) ||
    [];

  if (
    question.isEssayType &&
    essayGradingAIFeedbackList.length === 0 &&
    selectedCriteriaIndex === undefined
  ) {
    return null;
  } else
    return (
      <div className={style["ai-feedback"]}>
        <AIFeedbackHeader
          isEssayType={question.isEssayType}
          language={language}
          handleClose={handleClose}
          marks={totalMarksObtained}
          maxMarks={
            question.isEssayType
              ? essayGradingAIFeedback.question_total_marks ||
                question.maximumMarks
              : question.maximumMarks
          }
          handleToggleRubricModal={handleToggleRubricModal}
        />
        <div className={style["feedback-body-cnt"]}>
          {question.isEssayType && (
            <div className={style["criteria-navigation-left"]}>
              {essayGradingAIFeedbackList.map((i, index) => {
                return (
                  <div
                    className={[
                      style["criteria-bubble"],
                      index === selectedCriteriaIndex &&
                        style["criteria-bubble--active"],
                    ].join(" ")}
                    onClick={() => {
                      handleSelectCriteria(index);
                    }}
                  >
                    {i.alias}
                  </div>
                );
              })}
            </div>
          )}
          <div className={style["main-cnt"]}>
            {question.isEssayType && (
              <div className={style["criteria-header"]}>
                <CriteriaDropdown
                  allCriterias={allCriterias}
                  currentCriteria={
                    essayGradingAIFeedbackList[selectedCriteriaIndex].criteria
                  }
                  handleSelectCriteria={handleSelectCriteria}
                />
                <div className={style["marks"]}>
                  {`${Number(
                    essayGradingAIFeedbackList[selectedCriteriaIndex].marks
                  ).toFixed(2)} out of ${
                    essayGradingAIFeedbackList[selectedCriteriaIndex]
                      .maximum_marks
                  } mark(s)`}
                </div>
              </div>
            )}
            <div className={style["evaluation-cnt"]}>
              {question.isEssayType ? (
                <AIEvaluationFeedbackView
                  selectedCriteriaIndex={selectedCriteriaIndex}
                  currentFeedback={
                    essayGradingAIFeedbackList[selectedCriteriaIndex].feedback
                  }
                  currentCriteria={
                    essayGradingAIFeedbackList[selectedCriteriaIndex].criteria
                  }
                  currentMarks={Number(
                    essayGradingAIFeedbackList[selectedCriteriaIndex].marks
                  ).toFixed(2)}
                  currentMaxMarks={
                    essayGradingAIFeedbackList[selectedCriteriaIndex]
                      .maximum_marks
                  }
                  currentCriteriaId={
                    essayGradingAIFeedbackList[selectedCriteriaIndex]
                      .essay_grading_id
                  }
                  setTotalMarksObtained={setTotalMarksObtained}
                  setEssayGradingAIFeedbackList={setEssayGradingAIFeedbackList}
                  studentId={studentId}
                  question={question}
                  language={language}
                />
              ) : (
                <OpenEndedFeedbackView
                  allFeedbacks={question.openEndedFeedback}
                  language={language}
                  setTotalMarksObtained={setTotalMarksObtained}
                  studentId={studentId}
                  question={question}
                />
              )}
              <div className={style["evaluation-cnt-right"]}>
                <QuestionAnswerView
                  questionId={question.id}
                  selectedQuestionAIFeedback={question}
                  isEssayType={question.isEssayType}
                  question={question.question}
                  currentAnswer={
                    question.isEssayType
                      ? essayGradingAIFeedbackList[selectedCriteriaIndex]
                          .evaluated_response
                      : question.answerType
                  }
                  language={language}
                />
                <div className={style["footer-padding"]} />
              </div>
            </div>
          </div>
        </div>
        {showRubricModal && (
          <RubricModal
            handleToggleRubricModal={handleToggleRubricModal}
            language={language}
          />
        )}
      </div>
    );
};

export default AIFeedbackView;
