import Immutable from 'seamless-immutable';
import {
  GET_CHAPTERS_SUCCEEDED,
  GET_CLASS_CREATE_SELECTED,
  GET_CREATE_CLASS_SUCCEEDED,
  GET_ME_SUCCEEDED,
  GET_ME_FAILED,
  GET_SCHOOL_CODE_SUBMISSION_FAILED,
  GET_SCHOOL_CODE_SUBMISSION_SUCCEEDED,
  GET_SCHOOL_GRADES_SUCCEEDED,
  GET_LEVEL_DROPDOWN_ITEMS_SUCCEEDED,
  GET_SCHOOL_GRADE_SELECTED,
  GET_SCHOOL_PIN_SUBMISSION_FAILED,
  GET_SCHOOL_PIN_SUBMISSION_SUCCEEDED,
  GET_USER_LOGIN_FAILED,
  GET_USER_LOGIN_SUCCEEDED,
  POST_NEW_USER_FAILED,
  POST_NEW_USER_SUCCEEDED,
  USER_LOGGED_OUT_SUCCEEDED,
  CLASS_SELECTED,
  GET_STUDENTS_SUCCEEDED,
  GET_STUDENTS_FAILED,
  DELETE_STUDENT_FAILED,
  GET_STUDENT_REPORT_SUCCEEDED,
  GET_STUDENT_REPORT_FAILED,
  GET_CONFIG_TYPE_SUCCEEDED,
  GET_CONFIG_TYPE_FAILED,
  GET_TESTS_SUCCEEDED,
  GET_TESTS_FAILED,
  GET_TESTS_SELECTED,
  CREATE_LIST_FOR_LOADER,
  GET_EXAMS_QUESTIONS_SUCCEEDED,
  GET_EXAMS_QUESTIONS_FAILED,
  RESET_STUDENT_TEST_REPORT,
  RESET_TEST_ATTEMPTS,
  LAUNCH_EXAM_FAILED,
  FETCH_EXAM_REPORT_SUCCEDED,
  FETCH_EXAM_REPORT_FAILED,
  FETCH_STUDENT_WISE_REPORT_SUCCEDED,
  FETCH_STUDENT_WISE_REPORT_FAILED,
  CREATE_ANNOUNCEMENT_SUCCEEDED,
  CREATE_ANNOUNCEMENT_FAILED,
  GET_MEETING_URL_SUCCEEDED,
  GET_MEETING_URL_FAILED,
  GET_START_CONFERENCE_SUCCEEDED,
  GET_START_CONFERENCE_FAILED,
  FETCH_DATE_RANGE_SUCCEDED,
  FETCH_DATE_RANGE_FAILED,
  SELECT_ATTENDENCE_MONTH_SUCCEDED,
  SELECT_ATTENDENCE_MONTH_FAILED,
  GET_STUDENT_SESSION_SUCCEEDED,
  FETCH_PRACTICED_SUCCEDED,
  GET_STUDENT_STUCK_SUCCEDED,
  FETCH_QUESTION_HISTORY_SUCCEDED,
  GET_CHANGE_PASSWORD_SUCCEEDED,
  GET_CHANGE_PASSWORD_FAILED,
  GET_USER_LOGOUT_SUCCEEDED,
  GET_SYLLABUS_SUCCEEDED,
  SYLLABUS_SELECTED,
  CONFIG_DATA_SUCCEEDED,
  RESET_STUCK_AT,
  GET_QUESTION_SUCCEEDED,
  PUBLISH_TEACHER_TOPIC_SUCCEDDED,
  PUBLISH_TEACHER_TOPIC_FAILED,
  GET_USER_PASSWORD_LINK_FAILED,
  GET_USER_PASSWORD_LINK_SUCCEEDED,
  VERIFY_RESET_CODE_SUCCEEDED,
  VERIFY_RESET_CODE_FAILED,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_SUCCEEDED,
  POST_REPORT_SUCCEEDED,
  POST_REPORT_FAILED,
  POST_REPORT_RESET,
	GET_QUESTION_CONTENT_SUCCEEDED,
  GET_BROKEN_DOWN_SUCCEEDED, GET_BROKEN_DOWN_FAILED,
  DELETE_ANNOUNCEMENT_SUCCEEDED,
  EXIT_OVERVIEW,
  MODE_SELECTED,
  REGISTER_CLIENT_USER_SUCCEEDED, REGISTER_CLIENT_USER_FAILED,
  CLIENT_MAIL_RESEND_REQUESTED, CLIENT_MAIL_RESEND_SUCCEEDED, CLIENT_MAIL_RESEND_FAILED,
  REGISTER_NEW_STUDENT_SUCCEEDED,
  REGISTER_NEW_STUDENT_FAILED,
  REGISTER_NEW_STUDENT_REQUESTED,
  RESET_NEW_STUDENT_REGISTRATION,
  SET_NEW_STUDENT_REGISTRATION_DATA,
  BULK_REGISTER_STUDENTS_REQUESTED,
  BULK_REGISTER_STUDENTS_SUCCEEDED,
  BULK_REGISTER_STUDENTS_FAILED,
  BULK_RESET_STUDENTS_REGISTRATION,
  CHECK_STUDENT_EXISTS_DATA,
  CHECK_TEACHER_EXISTS_DATA,
  GET_TOPIC_LAUNCH_SETTINGS_SUCCEEDED,
  GET_ASSESSMENT_LAUNCH_SETTINGS_SUCCEEDED,
  SET_ASSESSMENT_LAUNCH_SETTINGS_SUCCEEDED,
  SET_ASSESSMENT_LAUNCH_SETTINGS_RESET,
  SET_TOPIC_LAUNCH_SETTINGS_SUCCEEDED,
  SET_TOPIC_LAUNCH_SETTINGS_RESET,
  GET_CLASS_BOOKMARKS_DATA,
  SUBMIT_ASSESSMENT_REQUEST_SUCCEEDED,
  SUBMIT_ASSESSMENT_REQUEST_RESET,
  ADD_QUESTION_TO_BOOKMARK_DATA,
  REMOVE_QUESTION_FROM_BOOKMARK_DATA,
  GET_CLASS_BOOKMARKS_DATA_SUCCEEDED,
  MOVE_QUESTION,
  UPDATE_BOOKMARK_DATA_SUCCEEDED,
  FETCH_ASSESSMENT_REQUESTS_SUCCEEDED,
  SET_ACTIVE_VIEW_DRAFT,
  GET_ASSESSMENT_DRAFT_DATA_SUCCEEDED,
  PUBLISH_ASSESSMENT_REQUEST_SUCCEEDED,
  PUBLISH_ASSESSMENT_REQUEST_RESET,
  PUBLISH_ASSESSMENT_REQUEST_FAILED,
  SELECT_LANGUAGE_PRACTICE_MODE,
  SELECT_LANGUAGE_PRACTICE_MODE_RESET,
  CONFIG_DATA_REQUESTED,
  GET_LEADERBOARD_SUCCEEDED,
  GET_LEADERBOARD_FAILED,
  GET_TOGGLE_LEADERBOARD_SUCCEEDED,
  GET_TOGGLE_LEADERBOARD_FAILED,
  GET_LEADERBOARD_STUDENT_SUCCEEDED,
  GET_LEADERBOARD_REQUESTED,
  GET_LEADERBOARD_STUDENT_FAILED,
  EMPTY_NEW_CLASS,
  STUDENT_REGISTERED_DEVICES,
  STUDENT_REGISTERED_DEVICES_SUCCESS,
  

 GET_MYLEADERBOARD_SUCCEEDED,
 GET_MYLEADERBOARD_FAILED,
 FETCH_DATE_FILTERS_SUCCEEDED, FETCH_DATE_FILTERS_FAILED,
 GET_ALERT_SUCCEEDED, GET_ALERT_FAILED,
 GET_NOTIFICATION_SESSION_SUCCEEDED, GET_NOTIFICATION_SESSION_FAILED,
 GET_ATTEMTPS_NEW_SUCCEDED,
 GET_QUESTION_ATTEMPTS_SUCCEEDED, GET_QUESTION_ATTEMPTS_FAILED,
 GET_GOOGLECLASSES_SUCCEEDED, GET_GOOGLECLASSES_FAILED,
 GET_LINK_GOOGLECLASS_SUCCEEDED, GET_LINK_GOOGLECLASS_FAILED,
 GET_UNLINK_GOOGLECLASS_SUCCEEDED, GET_UNLINK_GOOGLECLASS_FAILED, GET_GOOGLECLASSESONBOARDING_SUCCEEDED, GET_GOOGLE_CLASSROOM_STUDENTS_SUCCEEDED, GET_GOOGLE_CLASSROOM_STUDENTS_FAILED, SYNC_GOOGLE_CLASSROOM_STUDENTS_SUCCEEDED, SYNC_GOOGLE_CLASSROOM_STUDENTS_FAILED, GET_LEADERBOARD_DATA_SUCCEEDED, GET_LEADERBOARD_DATA_FAILED, UPDATE_SINGLE_STUDENT_NINJA_TEAM,
 GET_ALL_ATTEMPTS_SUCCEEDED, GET_ALL_ATTEMPTS_FAILED, REMOVE_TEST_INFO, GET_ALL_ASSESSMENT_CLASSES_SUCCEEDED, GET_ASSESSMENT_DRAFT_CLASSES_SUCCEEDED, GET_ASSESSMENT_ADDED_CLASSES_SUCCEEDED, GET_ALL_ASSESSMENT_CLASSES_REQUESTED, GET_ALL_ASSESSMENT_CLASSES_FAILED,FETCH_ALL_ASSESSMENT_CLASSES_LOADING, GET_FILTERED_ASSESSMENT_CLASSES_SUCCEEDED, SET_LOADING_FILTERED_CLASSES, GET_FILTERED_ASSESSMENT_CLASSES_FAILED,
 SET_SYNC_LEADERBOARD_DATA_LOADER,
 SET_NEW_STUDENT_PASSWORD_FAILED,
 SET_NEW_STUDENT_PASSWORD_SUCCEEDED,
 SET_NEW_STUDENT_PASSWORD_REQUESTED,
 RESET_TEST_ATTEMPTS_SUCCEEDED,
 GET_CREATE_MULTI_CLASS_SUCCEEDED,
 GET_STUDENT_EXCEL_DOWNLOAD_SUCCEEDED,
 GET_STUDENT_EXCEL_UPLOAD_SUCCEEDED,
 GET_STUDENT_EXCEL_DOWNLOAD_FAILED,
 GET_STUDENT_EXCEL_UPLOAD_FAILED,
 EMPTY_EXCEL_UPLOAD,
 GET_CLASS_TEACHERS_SUCCEEDED,
 BULK_REGISTER_TEACHERS_REQUESTED,
 BULK_REGISTER_TEACHERS_SUCCEEDED,
 BULK_REGISTER_TEACHERS_FAILED,
 BULK_RESET_TEACHERS_REGISTRATION,
 REMOVE_CLASS_TEACHERS_SUCCEEDED,
 REMOVE_CLASS_TEACHERS_FAILED,
 RESET_EXCEL_NEW_STUDENT_REGISTRATION,
 GET_AI_FEEDBACK_REPORT_SUCCEEDED,
 GET_AI_FEEDBACK_REPORT_FAILED,
 SET_EDIT_AI_FEEDBACK_CRITERIA_LOADER,
 EDIT_AI_FEEDBACK_CRITERIA_SUCCEEDED,
 EDIT_AI_FEEDBACK_CRITERIA_FAILED,
 EXIT_AI_FEEDBACK_REPORT_VIEW,
 UPDATE_STUDENT_REPORT_QUESTION_MARKS,
 SET_UNAUTH_LANGUAGE,
 GET_SCREEN_ERRORS_MAPPING_FAILED,
  GET_SCREEN_ERRORS_MAPPING_SUCCEEDED,
  SUBMIT_LOGIN_ERROR_REPORT_SUCCEEDED,
  SUBMIT_LOGIN_ERROR_REPORT_FAILED,
  RESET_SUBMIT_LOGIN_ERROR_REPORT,
  SUBMIT_ERROR_REPORT_SUCCEEDED,
  SUBMIT_ERROR_REPORT_FAILED,
  RESET_SUBMIT_ERROR_REPORT,
  MODIFY_STUDENT_DETAILS_SUCCEEDED,
  MODIFY_STUDENT_DETAILS_FAILED,
  RESET_MODIFY_STUDENT_DETAILS,
  GET_SEARCH_TEACHERS_SUCCEEDED,
  EDIT_OPEN_ENDED_FEEDBACK_FAILED,
  SET_EDIT_OPEN_ENDED_FEEDBACK_LOADER,
  EDIT_OPEN_ENDED_FEEDBACK_SUCCEEDED,
  SET_CURRENT_OPEN_ENDED_QUESTION_FEEDBACK,
  MOCK_TEST_RESET_REQUESTED,
  MOCK_TEST_RESET_SUCCESS,
  MOCK_TEST_RESET_FAILURE,
  RESET_MOCK_TEST_RESCHEDULE,
  DOWNLOAD_TOPIC_REPORT_REQUESTED,
  DOWNLOAD_TOPIC_REPORT_SUCCESS,
  DOWNLOAD_TOPIC_REPORT_FAILURE,
  DOWNLOAD_MOCK_EXAM_REPORT_REQUESTED,
  DOWNLOAD_MOCK_EXAM_REPORT_SUCCESS,
  DOWNLOAD_MOCK_EXAM_REPORT_FAILURE,
  GET_CHANGE_PASSWORD_REQUESTED,
  GET_AI_GRADING_FEEDBACK_REPORT_SUCCEEDED,
  GET_AI_GRADING_FEEDBACK_REPORT_FAILED,
  SET_EDIT_AI_GRADING_FEEDBACK_LOADER,
  EDIT_AI_GRADING_FEEDBACK_SUCCEEDED,
  EDIT_AI_GRADING_FEEDBACK_FAILED,
  EXIT_AI_GRADING_FEEDBACK_REPORT_VIEW,
  GET_CREATE_MULTI_CLASS_REQUESTED,
  GET_CREATE_MULTI_CLASS_FAILED,
  GET_CREATE_CLASS_REQUESTED,
  GET_CREATE_CLASS_FAILED,
  RESET_CREATE_CLASS,
  FETCH_AUTO_GRADING_CTS_SUCCEEDED,
  GET_AUTO_GRADING_QUESTIONS_SUCCEEDED,
  GET_AUTO_GRADING_QUESTIONS_FAILED,
  UPLOAD_AUTO_GRADING_IMAGE_SUCCEEDED,
  UPLOAD_AUTO_GRADING_IMAGE_REQUESTED,
  UPLOAD_AUTO_GRADING_IMAGE_FAILED,
  RESET_UPLOAD_AUTO_GRADING_IMAGE,
  FETCH_UNATTEMPTED_AUTO_GRADING_SUCCEEDED,
  FETCH_UNATTEMPTED_AUTO_GRADING_FAILED,
  EVALUATE_AUTO_GRADING_IMAGE_SUCCEEDED,
  EVALUATE_AUTO_GRADING_IMAGE_REQUESTED,
  EVALUATE_AUTO_GRADING_IMAGE_FAILED,
  RESET_EVALUATE_AUTO_GRADING_IMAGE,
  // GET_SCREEN_ERRORS_MAPPING_REQUESTED,
} from './action';

import {
  GET_CANVAS_CLASS_LIST_REQUEST,
  GET_CANVAS_CLASS_LIST_SUCCESS,
  GET_CANVAS_CLASS_LIST_FAILURE,
  GET_CANVAS_CHAPTER_LIST_REQUEST,
  GET_CANVAS_CHAPTER_LIST_SUCCESS,
  GET_CANVAS_CHAPTER_LIST_FAILURE
} from './routes/CanvasTool/action'

import { b64DecodeUnicode, convertTeacherLanguageToLanguage, parseQuestionResponse } from './utils';

export const initialState = Immutable({
  userSession: {
    user: undefined,
    tokenRefreshed: false, // true when jwt expires
    loggedOut: false,
    isAuthenticated: false,
  },
  registerError: undefined,
  loginError: undefined,
  joinSchoolError: '',
  showPinScreen: undefined,
  selectedSchoolCode: undefined,
  schoolGrades: [],
  newClass: {
    selectedGrade: undefined,
    className: undefined,
    classCode: undefined,
    classObj: undefined,
  },
  levelDropdownItems: [],
  selectedClass: undefined,
  selectedGrade: undefined,
  selectedClassCategory: undefined,
  chapterView: {}, //mapped by class id
  studentsView: {}, //mapped by class id
  testsView: {}, //mapped by class id
  assessmentsView: {}, //mapped by class id
  selectedTopic: undefined,
  configType: undefined,
  selectedLevel: undefined,
  studentReport: undefined,
  notStarted: undefined,
  questionView: {},
  showQuestion: false,
  forgotError: undefined,
  verifyCodeError: undefined,
  resetCodeError: undefined,
  topicLevelList: undefined,
  topicLevelNameMap: {},
  confUrl: undefined,
  meetingEnabled: false,
  meetOngoing: false,
  datesRange: undefined,
  selectedAttendenceData: undefined,
  attendenceMonthList: undefined,
  selectedSubtopic: undefined,
  selectedSubtopicIndex: undefined,
  studentSession: {},
  studentPracticed: {},
  questionHistory: {},
  questionType: null,
  visualDashboardActive: true,
  selectedTopicIndex: '',
  syllabusView: {},
  selectedSyllabus: undefined,
  selectedTest: undefined,
  examQuestions: undefined,
  examReport: undefined,
  examQuestionLoader: [],
  autoGradingQuestionLoader: [],
  studentWiseReport: {},
  selectedTestStudentId: undefined,
  changePassword: undefined,
  contentSyllabus: {},
  syllabusBrokenDown: {},
  clientSignupUser: undefined,
  clientMailResent: undefined,
  clientRegisterError: undefined,
  studentExists: undefined,
  topicLaunchSettingsData: undefined,
  assessmentLaunchSettingsData: undefined,
  setTopicLaunchSettings: false,
  setAssessmentLaunchSettings: false,
  assessmentRequestData: undefined,
  testPaperData: undefined,
  chapterQuestionCounter: undefined,
  ctIdData: undefined,
  // localAssessmentRequestData: [],
  assessmentRequestSubmit: false,
  filterOrder: [],
  publishAssessmentRequestStatus: undefined,
	selectedLanguagePracticeMode: undefined,
  leaderboard:undefined,
  leaderboardData:undefined,
  isLeaderboardSyncing: false,
  myleaderboard:undefined,
  googleClassesOnboarding: undefined,
  googleClassroomStudent: undefined,
  syncedGoogleClassroomStudent: undefined,
  leaderboardStudent:undefined,
  studentRegisteredDevice:[],
  notificationView: undefined,
  attemptsNew: {},
  notificationSessionFailed:false,
  allAssessmentClasses:[],
  isAllAssessmentClassesLoading:false,
  hasMoreAssessmentClasses:false,
  allAssessmentClassesLimit: 50,
  allAssessmentClassesOffset: 0,
  assessmentDraftClasses:undefined,
  publishedAssessmentClasses:[],
  filteredAssessmentClasses:[],
  loadingFilteredClasses:undefined,
  setStudentPasswordError: false,
  setStudentPasswordSuccess: false,
  canvasClassList: [],
  canvasData: {},
  essayGradingAIFeedback:undefined,
  savingAIFeedbackCriteria:false,
  savingAIFeedbackCriteriaSuccess:false,
  savingAIFeedbackCriteriaError: false,
  unauthLanguage:null,
  currentOpenEndedQuestionFeedback:[],
  savingOpenEndedFeedback:false,
  savingOpenEndedFeedbackSuccess:false,
  savingOpenEndedFeedbackError: false,
  resetMockTestInfo:undefined,
  resetMockTestLoader: false,
  resetMockTestSuccess: false,
  resetMockTestError: false,
  downloadMockExamReportLoading: false,
  downloadMockExamReportSuccess: false,
  downloadMockExamReportError: false,
  downloadTopicReportLoading: false,
  downloadTopicReportSuccess: false,
  downloadTopicReportError: false,
  aiGradingFeedback:undefined,
  savingAIGradingFeedback:false,
  savingAIGradingFeedbackSuccess:false,
  savingAIGradingFeedbackError: false,
});

export default (state = initialState, action) => {
  if (action.resp && action.resp.hasOwnProperty('tokenRefreshed')) {
    const tokenRefreshed = action.resp.tokenRefreshed;
    state = state.merge({
      userSession: state.userSession.merge({
        tokenRefreshed: true,
      }),
    });
  }
  switch (action.type) {
    case USER_LOGGED_OUT_SUCCEEDED:
      state = initialState;
      return state.merge({
        userSession: state.userSession.merge({
          user: undefined,
          tokenRefreshed: false,
          loggedOut: true,
          isAuthenticated: false,
        }),
        googleClassroomStates: undefined,
      });
    case GET_ME_SUCCEEDED:
      return state.merge({
        userSession: state.userSession.merge({
          user: convertTeacherLanguageToLanguage(action.resp.data),
          tokenRefreshed: false,
          loggedOut: false,
          isAuthenticated: true,
        }),
        googleClassroomStates: action.resp.data.googleClassroomStates,
        meetingEnabled: action.resp.data.isMeetingEnabledTeacher || false,
        visualDashboardActive: action.resp.data.showNewUi,
      });
    case GET_ME_FAILED:
      return state.setIn(['userSession', 'user'], 'failed');
    case POST_NEW_USER_SUCCEEDED:
      const { data } = action;
      return state.merge({
        userSession: state.userSession.merge({
          user: convertTeacherLanguageToLanguage(data),
          tokenRefreshed: false,
          loggedOut: false,
          isAuthenticated: true,
        }),
        registerError: undefined,
        loginError: undefined,
      });
    case POST_NEW_USER_FAILED:
      if (action.data)
        return state.merge({
          registerError: Object.values(action.data).join(' '),
        });
      return state;
    case GET_USER_LOGIN_SUCCEEDED:
      if(action.reset){
        return state.merge({
          registerError: undefined,
          loginError: undefined,
        });
      }
      return state.merge({
        userSession: state.userSession.merge({
          user: convertTeacherLanguageToLanguage(action.data),
          tokenRefreshed: false,
          loggedOut: false,
          isAuthenticated: true,
        }),
        googleClassroomStates: action.data.googleClassroomStates,
        registerError: undefined,
        loginError: undefined,
      });
    case GET_USER_PASSWORD_LINK_SUCCEEDED:
      if(action.reset){
        return state.merge({
          forgotPasswordSuccess: false,
          forgotPasswordError: false,
        });
      }
      return state.merge({
        forgotPasswordSuccess: 'link-sent',
      });
    case VERIFY_RESET_CODE_SUCCEEDED:
      return state.merge({
        verifyCodeSuccess: true,
        ...action.data
      });
    case STUDENT_REGISTERED_DEVICES_SUCCESS:
      // return state.merge({
      //   ...state,
      //   loading: false,
      //   studentRegisteredDevice: action.data,
      // });
      return state.merge({
        ...state,
        loading:false,
        studentRegisteredDevice: action.resp.data,
      });
    case RESET_PASSWORD_FAILED:
      return state.merge({
        resetPasswordError: action.data?action.data.nonFieldErrors.join(' '):action.message,
        resetPasswordSuccess: undefined,
      });
    case RESET_PASSWORD_SUCCEEDED:
      if(action.reset){
        return state.merge({
          resetPasswordSuccess: undefined,
          resetPasswordError: undefined
        });
      }
      return state.merge({
        resetPasswordSuccess: action.data,
        resetPasswordError: undefined
      });
    case VERIFY_RESET_CODE_FAILED:
      return state.merge({
        verifyCodeError: action.data?action.data.nonFieldErrors.join(' '):action.message,
      });
    case GET_USER_LOGIN_FAILED:
      if (action.data)
        return state.merge({
          loginError: action.data.nonFieldErrors.join(' '),
        });
      return state;
    case GET_USER_PASSWORD_LINK_FAILED:
      if (action.data){
        return state.merge({
          forgotPasswordError: action.data.nonFieldErrors.join(' '),
        });
      }
      if(action.message){
        return state.merge({
          forgotPasswordError: action.message,
        });
      }
      return state;
    case 'RESET_LOGIN_ERROR':
      return state.merge({
        loginError: false,
      });
    case 'RESET_CREATE_ACCOUNT_ERROR':
      return state.merge({
        registerError: false,
      });
    case GET_SCHOOL_CODE_SUBMISSION_SUCCEEDED:
      return state.merge({
        showPinScreen: action.data.showPinScreen,
        selectedSchoolCode: action.schoolCode,
        joinSchoolError: '',
      });
    case GET_SCHOOL_CODE_SUBMISSION_FAILED:
      if (action.resp) {
        return state.merge({
          joinSchoolError: action.resp.data.join(' '),
        });
      }
      return state;
    case GET_SCHOOL_PIN_SUBMISSION_SUCCEEDED:
      return state.merge({
        joinSchoolError: undefined,
        showPinScreen: undefined,
        selectedSchoolCode: undefined,
      });
    case GET_SCHOOL_PIN_SUBMISSION_FAILED:
      if (action.resp)
        return state.merge({
          joinSchoolError: action.resp.data.join(' '),
        });
      return state;
    case GET_LEVEL_DROPDOWN_ITEMS_SUCCEEDED:
      return state.merge({
        levelDropdownItems: action.data,
    });
    case GET_SCHOOL_GRADES_SUCCEEDED:
      let classObj = undefined;
      let gradeObj = undefined;
      if (!!state.selectedClass && !!state.selectedClass.code) {
        classObj = state.selectedClass;
        gradeObj = state.selectedGrade;
      } else {
        let key;
        for (key in action.data) {
          gradeObj = action.data[key];
          if (gradeObj.classes.length > 0) {
            classObj = { ...gradeObj.classes[0] };
            classObj.announcement =
              classObj.announcement.length > 0 &&
              classObj.announcement[0].length > 0
                ? classObj.announcement[0][0]
                : '';
            break;
          }
        }
      }
      return state.merge({
        schoolGrades: action.data,
        selectedClass: classObj,
        newClass: state.newClass.merge({
          selectedGrade: action.data[0],
        }),
        selectedGrade: gradeObj,
      });
    case GET_SCHOOL_PIN_SUBMISSION_FAILED:
      if (action.resp)
        return state.merge({
          joinSchoolError: action.resp.data.join(' '),
        });
      return state;

    case GET_SCHOOL_GRADE_SELECTED:
      const selectedGrade = { ...action.grade };
      delete selectedGrade.classes;
      return state.merge({
        newClass: state.newClass.merge({
          selectedGrade,
        }),
      });
    case GET_STUDENT_EXCEL_UPLOAD_SUCCEEDED:{
      return state.merge({
        studentExcelUpload: true,
      });
    }
    case GET_STUDENT_EXCEL_UPLOAD_FAILED:{
      return state.merge({
        studentExcelUploadError: action.resp.data,
      });
    }
    case GET_STUDENT_EXCEL_DOWNLOAD_SUCCEEDED: { 
      return state.merge({
        studentExcelDownload: action.data,
        
      });}
    case GET_CREATE_MULTI_CLASS_REQUESTED:
      return state.merge({
        gettingCreateClass: true,
      })
    case GET_CREATE_MULTI_CLASS_SUCCEEDED:
      return state.merge({
        newMultiClasses: action.data,
        gettingCreateClass: false,
        createClassError: undefined,
        createClassFailed: false,
      });
    case GET_CREATE_MULTI_CLASS_FAILED:
      return state.merge({
        gettingCreateClass: false,
        createClassError: action.resp?.data[0],
        createClassFailed: true
      });
    case GET_CREATE_CLASS_REQUESTED:
      return state.merge({
        gettingCreateClass: true,
      });
    case GET_CREATE_CLASS_SUCCEEDED:
      return state.merge({
        newClass: state.newClass.merge({
          className: undefined,
          classCode: action.data.code,
          classObj: action.data,
        }),
        googleClassroomStates: action.data.googleClassroomStates,
        gettingCreateClass: false,
        createClassError: undefined,
        createClassFailed: false,
      });
    case GET_CREATE_CLASS_FAILED:
      return state.merge({
        gettingCreateClass: false,
        createClassError: action.resp?.data[0],
        createClassFailed: true
      });
    case RESET_CREATE_CLASS:
      return state.merge({
        createClassFailed: false,
        createClassError: undefined,
      });

    case GET_CLASS_CREATE_SELECTED:
      return state.merge({
        newClass: state.newClass.merge({
          className: action.className,
        }),
      });
    case GET_CHAPTERS_SUCCEEDED:
      return state.setIn(
        ['chapterView', action.classId],
        action.data,
      )
      .setIn(['leaderboardView', action.classId],
        action.leaderboardData);
    case GET_SEARCH_TEACHERS_SUCCEEDED:
      return state.merge({
        searchTeachersData: action.data,
      });
    case CLASS_SELECTED:
      const newClassObj = {
        ...action.classObj,
      };
      return state.merge({
        selectedClass: newClassObj,
        selectedSubtopic: undefined,
        selectedGrade: action.gradeObj,
      });
    case MODE_SELECTED:
      return state.merge({
        mode: action.mode
      });
    case GET_CONFIG_TYPE_SUCCEEDED:
      return state
        .setIn(['configType'], action.configTypes)
        .setIn(['topicLevelList'], action.topicLevelList)
        .setIn(['topicLevelNameMap'], action.topicLevelNameMap || {});
    case GET_STUDENT_REPORT_SUCCEEDED:
      return state.setIn(['studentReport'], action.studentReport)
        .setIn(['notStarted'], action.notStarted)
    case GET_TESTS_SUCCEEDED:
      return Immutable.setIn(
        state,
        ['testsView', action.classCode],
        action.data,
      );
    case GET_TESTS_SELECTED:
      return state.setIn(['selectedTest'], action.testObj);
    case CREATE_LIST_FOR_LOADER:
      const { questions, index } = action;
      let questionLoader = [];
      questions.forEach((element, indexNo) => {
        if (
          state.examQuestionLoader.length > 0 &&
          !!state.examQuestionLoader[indexNo] &&
          state.examQuestionLoader[indexNo].status === 1
        ) {
          questionLoader.push({ status: 1 });
        } else {
          questionLoader.push({ status: 0 });
        }
      });
      return state.setIn(['examQuestionLoader'], questionLoader);
    case GET_EXAMS_QUESTIONS_SUCCEEDED:
      let examQuestion;
      if (Array.isArray(action.data)) {
        examQuestion = [];
        action.data.map((questionResponse, index) => {
          let questionObj = {};
          questionObj.id = questionResponse.data.id;
          questionObj.qid = questionResponse.data.question.id;
          questionObj.marks = questionResponse.data.marks;
          if (questionResponse.data.question.question) questionObj.question = b64DecodeUnicode(
              questionResponse.data.question.question
              );
          if (questionResponse.data.question.solution) examQuestion.solution = b64DecodeUnicode(
							questionResponse.data.question.solution
						);
          if(questionResponse.data.question.answer_type && !questionResponse.data.question.is_essay_type) questionObj.answerType = b64DecodeUnicode(
							questionResponse.data.question.answer_type
						);
          questionObj.isEssayType = questionResponse.data.question.is_essay_type;
          questionObj.isOpenEnded = questionResponse.data.question.is_openended;
          questionObj.isAiGrading = questionResponse.data.question.is_ai_grading;
          questionObj.openEndedFeedback = questionResponse.data.question.openended_feedback;
          questionObj.assessmentStudentId = questionResponse.data.question.assessment_student_id

          questionObj.userResponse =
            null ||
            (questionResponse.hasOwnProperty('user_response') &&
            questionResponse.data.user_response !== null &&
            questionResponse.data.user_response !== '[]'
              ? typeof questionResponse.data.user_response === 'string'
                ? parseQuestionResponse(questionResponse.data.userResponseState)
                : questionResponse.data.user_response
              : null);
          questionObj.userResponseState = questionResponse.data.userResponse
            ? true
            : false;
          questionObj.choiceIndex = index;
          examQuestion.push(questionObj);
        });
      } else {
        examQuestion = {};
        examQuestion.id = action.data.id;
        examQuestion.qid = action.data.question.id;
        examQuestion.marks = action.data.marks;
        if (action.data.question.question) examQuestion.question = b64DecodeUnicode(action.data.question.question);
        if (action.data.question.solution) examQuestion.solution = b64DecodeUnicode(action.data.question.solution);
        if (action.data.question.answer_type && !action.data.question.is_essay_type) examQuestion.answerType = b64DecodeUnicode(
          action.data.question.answer_type,
        );
        examQuestion.isEssayType = action.data.question.is_essay_type;
        examQuestion.isOpenEnded = action.data.question.is_openended;
        examQuestion.isAiGrading = action.data.question.is_ai_grading;
        examQuestion.openEndedFeedback = action.data.question.openended_feedback;
        examQuestion.assessmentStudentId = action.data.question.assessment_student_id
        examQuestion.userResponse =
          null ||
          (action.data.hasOwnProperty('user_response') &&
          action.data.user_response !== null &&
          action.data.user_response !== '[]'
            ? typeof action.data.user_response === 'string'
              ? parseQuestionResponse(action.data.user_response)
              : action.data.user_response
            : null);
        examQuestion.userResponseState = examQuestion.userResponse
          ? true
          : false;
      }
      return state
        .setIn(['examQuestions', action.userId, action.index], examQuestion)
        .setIn(['examQuestionLoader', action.index, 'status'], 1);
    case GET_AUTO_GRADING_QUESTIONS_SUCCEEDED:
      let autoGradingQuestion = {};
      autoGradingQuestion.id = action.data.id;
      autoGradingQuestion.qid = action.data.question.id;
      autoGradingQuestion.marks = action.data.marks;
      autoGradingQuestion.autoGradingSubparts =
          action.data.question.auto_grading_subparts;

      if (action.data.question.question) autoGradingQuestion.question = b64DecodeUnicode(action.data.question.question);
      if (action.data.question.solution) autoGradingQuestion.solution = b64DecodeUnicode(action.data.question.solution);
      if (action.data.question.answer_type && !action.data.question.is_essay_type) autoGradingQuestion.answerType = b64DecodeUnicode(
        action.data.question.answer_type,
      );
      autoGradingQuestion.isEssayType = action.data.question.is_essay_type;
      autoGradingQuestion.isOpenEnded = action.data.question.is_openended;
      autoGradingQuestion.isAiGrading = action.data.question.is_ai_grading;
      autoGradingQuestion.openEndedFeedback = action.data.question.openended_feedback;
      autoGradingQuestion.images = action.data.images;
      autoGradingQuestion.assessmentStudentId = action.data.question.assessment_student_id
      autoGradingQuestion.userResponse =
        null || (action.data.hasOwnProperty('user_response') && action.data.user_response !== null && action.data.user_response !== '[]' ? typeof action.data.user_response === 'string' ? parseQuestionResponse(action.data.user_response) : action.data.user_response : null);
      autoGradingQuestion.userResponseState = autoGradingQuestion.userResponse ? true : false;
      autoGradingQuestion.quesNo = action.quesNo;
      return state  
        .setIn(['autoGradingQuestions', action.userId, action.index], autoGradingQuestion)
        .setIn(['autoGradingQuestionLoader', action.index, 'status'], 1)
        .setIn(['autoGradingImageData', action.userId, action.index], action.data.images);
    case GET_AUTO_GRADING_QUESTIONS_FAILED  :
      let newAutoGradingLoaderLength = state.autoGradingQuestionLoader.length;
      let newAutoGradingLoaderArray = [];
      for (let indexLoader = 0; indexLoader < newAutoGradingLoaderLength; indexLoader++) {
        if (state.autoGradingQuestionLoader[indexLoader].status !== 1) {
          newAutoGradingLoaderArray.push({ status: -1 });
        } else {
          newAutoGradingLoaderArray.push({
            status: state.autoGradingQuestionLoader[indexLoader].status,
          });
        }
      }
      return state.setIn(['autoGradingQuestionLoader'], newAutoGradingLoaderArray);    
    case UPLOAD_AUTO_GRADING_IMAGE_REQUESTED:
      return state.setIn(['autoGradingImageUploading'], true);  
    case UPLOAD_AUTO_GRADING_IMAGE_SUCCEEDED:
      return state
                .setIn(['autoGradingImageData', action.userId, action.index, action.subpartInd], action.resp.data.image_url)
      .setIn(['autoGradingQuestions', action.userId, action.index, 'images',action.subpartInd], action.resp.data.image_url)
      .setIn(['autoGradingImageUploadSuccess'], true)
      .setIn(['autoGradingImageUploading'], false);
    case UPLOAD_AUTO_GRADING_IMAGE_FAILED:
      return state.setIn(['autoGradingImageUploading'], false)
                  .setIn(['autoGradingImageUploadError'], action.resp.data);
    case RESET_UPLOAD_AUTO_GRADING_IMAGE:
      return state.setIn(['autoGradingImageUploading'], false)
                  .setIn(['autoGradingImageUploadError'], undefined)
                  .setIn(['autoGradingImageUploadSuccess'], false);
   case FETCH_UNATTEMPTED_AUTO_GRADING_SUCCEEDED:
      return state.setIn(['unattemptedAutoGrading'], action.data.unattempted_questions);
    case FETCH_UNATTEMPTED_AUTO_GRADING_FAILED:
      return state.setIn(['unattemptedAutoGrading'], []);
    case EVALUATE_AUTO_GRADING_IMAGE_REQUESTED:
      return state.setIn(['autoGradingImageEvaluating'], true);
    case EVALUATE_AUTO_GRADING_IMAGE_SUCCEEDED:
      return state.setIn(['autoGradingImageEvaluating'], false)
                  .setIn(['autoGradingImageEvaluationSuccess'], true);
    case EVALUATE_AUTO_GRADING_IMAGE_FAILED:
      return state.setIn(['autoGradingImageEvaluating'], false)
                  .setIn(['autoGradingImageEvaluationError'], true);
    case RESET_EVALUATE_AUTO_GRADING_IMAGE:
      return state
        .setIn(["autoGradingImageEvaluating"], false)
        .setIn(["autoGradingImageEvaluationSuccess"], false)
        .setIn(["autoGradingImageEvaluationError"], false)
        .setIn(["unattemptedAutoGrading"], []);
    case GET_EXAMS_QUESTIONS_FAILED:
      let newLoaderLength = state.examQuestionLoader.length;
      let newLoaderArray = [];
      for (let indexLoader = 0; indexLoader < newLoaderLength; indexLoader++) {
        if (state.examQuestionLoader[indexLoader].status !== 1) {
          newLoaderArray.push({ status: -1 });
        } else {
          newLoaderArray.push({
            status: state.examQuestionLoader[indexLoader].status,
          });
        }
      }
      return state.setIn(['examQuestionLoader'], newLoaderArray);
    case RESET_STUDENT_TEST_REPORT:
      return state
        .setIn(['selectedTest'], undefined)
        .setIn(['examQuestions'], [])
        .setIn(['examQuestionLoader'], []);
    case FETCH_EXAM_REPORT_SUCCEDED:
      return state.setIn(['examReport', action.examId], action.data);
    case UPDATE_SINGLE_STUDENT_NINJA_TEAM:
      const { studentId, classCode, groupId, groupName } = action;
      const classStudents = state.getIn(['studentsView', classCode]);
      const studentIndex = classStudents.findIndex(student => student.id === studentId);
      const updatedStudent = {
        ...classStudents[studentIndex],
        group: {
          id: groupId,
          groupName: groupName
        }
      };

      const updatedStudentsArray = [
        ...classStudents.slice(0, studentIndex),
        updatedStudent,
        ...classStudents.slice(studentIndex + 1)
      ];

      return state.setIn(['studentsView', classCode], updatedStudentsArray);
    case GET_STUDENTS_SUCCEEDED:
      return Immutable.setIn(
        state,
        ['studentsView', action.classCode],
        action.data,
      );
    case FETCH_STUDENT_WISE_REPORT_SUCCEDED:
      return state
        .setIn(['studentWiseReport', action.userId], action.data)
        .setIn(['selectedTestStudentId'], action.userId);
    case DELETE_ANNOUNCEMENT_SUCCEEDED:
    case CREATE_ANNOUNCEMENT_SUCCEEDED:
      return state.merge({
        selectedClass: state.selectedClass.merge({
          announcement: action.text,
        }),
      })
      .setIn(['selectedGrade','classes',action.classIndex,'announcement'],action.text)
      .setIn(['schoolGrades',action.schoolGradeIndex,'classes',action.classIndex,'announcement'],[[action.text]])
    case GET_MEETING_URL_SUCCEEDED:
      return state.merge({
        selectedClass: state.selectedClass.merge({
          confUrl: action.confUrl,
          meetOngoing: !!action.confUrl ? true : false,
          announcement: action.announcement,
        }),
      });
    case GET_START_CONFERENCE_SUCCEEDED:
      return state.merge({
        selectedClass: state.selectedClass.merge({
          confUrl: action.confUrl,
          conferenceStarted: true,
          meetOngoing: !!action.confUrl ? true : false,
        }),
      });
    case FETCH_DATE_RANGE_SUCCEDED:
      return state
        .setIn(['datesRange'], action.data)
        .setIn(['selectedAttendenceData', action.classCode], action.data[0]);
    case SELECT_ATTENDENCE_MONTH_SUCCEDED:
      return state
        .setIn(['attendenceMonthList', action.classCode], action.resp.data)
        .setIn(
          ['selectedAttendenceData', action.classCode],
          action.dateDetails,
        );
    case GET_ATTEMTPS_NEW_SUCCEDED:
  
      return state.merge({
        'attemptsNew': action.attemptsNew,
      });
      
    case GET_STUDENT_SESSION_SUCCEEDED:
      return state.setIn(
        ['studentSession', action.studentId],
        action.studentSession,
      );
    case FETCH_PRACTICED_SUCCEDED:
      return state.setIn(['studentPracticed'], action.studentPracticed);
    case GET_STUDENT_STUCK_SUCCEDED:
      return state.merge({
        studentStuck: action.studentStuck,
      });
    case GET_QUESTION_ATTEMPTS_SUCCEEDED:
      return state.merge({
        studentStuck: action.studentAttempt,
      });
    case FETCH_QUESTION_HISTORY_SUCCEDED:
      return state.setIn(['questionHistory', action.questionId], action.data);
    case GET_CHANGE_PASSWORD_REQUESTED:
      return state.setIn(
        ['changePassword'],
        undefined,
      );
      case GET_CHANGE_PASSWORD_SUCCEEDED:
      return state.setIn(['changePassword'], 'Password changed');
    case GET_CHANGE_PASSWORD_FAILED:
      return state.setIn(
        ['changePassword'],
        action.resp.data[0],
      );
    case GET_USER_LOGOUT_SUCCEEDED:
      return initialState;
    case GET_SYLLABUS_SUCCEEDED:
      let responseData =
        action.data && action.data.length > 0 ? action.data : {};
      let selectedSyllabus = responseData.length > 0 ? action.data[0] : {};
      selectedSyllabus.configData = {};
      selectedSyllabus.currentLevel = 0;
      return state
        .setIn(['syllabusView', action.chapterId], responseData)
        .setIn(['selectedSyllabus'], selectedSyllabus);
    case SYLLABUS_SELECTED:
      let changedSyllabus = state.syllabusView[action.chapterId][action.index];
      return state.merge({
        selectedSyllabus: state.selectedSyllabus.merge({
          ...changedSyllabus,
          configData: {},
        }),
      })
      .setIn(['selectedLanguagePracticeMode'], undefined)
    case CONFIG_DATA_SUCCEEDED:
      let topicObj = state.selectedSyllabus.configData[action.topicId] || {
        [action.topicLevel]: action.data,
      };
      let currentLevel = state.selectedSyllabus.topicLevelList.indexOf(action.topicLevel)
      // fallback to first level
      if(currentLevel<0){
        currentLevel=0
      }
      let newTopicObj = { ...action.data };
      let results = newTopicObj.results;
      results = results.map((resultConfig) => {
        let newObj = { ...resultConfig };
        if(newObj.question.question) newObj.question.question = b64DecodeUnicode(newObj.question.question);
        if(newObj.question.answerType) newObj.question.answerType = b64DecodeUnicode(newObj.question.answerType);
        if(newObj.question.solution) newObj.question.solution = b64DecodeUnicode(newObj.question.solution);
        if(newObj.question.hint) newObj.question.hint = b64DecodeUnicode(newObj.question.hint);
        return resultConfig;
      });
      newTopicObj.results = results;
      if (topicObj.merge) {
        topicObj = topicObj.merge({ [action.topicLevel]: newTopicObj });
      }
      return state.merge({
        selectedSyllabus: state.selectedSyllabus.merge({
          configData: state.selectedSyllabus.configData.merge({
            [action.topicId]: {
              ...topicObj,
            },
          }),
          currentLevel
        }),
      })
    case RESET_STUCK_AT:
      return state
        .setIn(['studentStuck'], undefined)
        .setIn(['questionHistory'], {});
    case GET_QUESTION_SUCCEEDED:
      return state.setIn(['questionView', action.configId], action.data);
    case PUBLISH_TEACHER_TOPIC_SUCCEDDED:
      return Immutable.setIn(state, ["chapterView", action.classCodeId,action.chapterIndex,"topics",action.topicIndex,'isPublished'], action.publishStatus);
    case POST_REPORT_RESET:
      return state.merge({ reportSuccess:undefined,reportError:undefined})
    case POST_REPORT_SUCCEEDED:
      const { question } = action.data;
      let level = state.selectedSyllabus.topicLevelList[state.selectedSyllabus.currentLevel];
      let questionObjIndex =  state.selectedSyllabus.configData[state.selectedSyllabus.id][level].results.findIndex(x=>x.question.id===question)

      return state
        .setIn(['selectedSyllabus','configData',state.selectedSyllabus.id,level,'results',questionObjIndex,'isReported'],true)
        .merge({ reportSuccess:true,reportError:undefined})
    case  POST_REPORT_FAILED:
      if (action.resp)
        return state.merge({
          reportError: action.resp.detail|| action.resp.problem || action.resp,
        });
      if (action.message)
        return state.merge({
          reportError: action.message,
        });
      return state;
    case GET_QUESTION_CONTENT_SUCCEEDED:
  		return state.setIn(["contentSyllabus", action.contentId], action.data)
    case GET_BROKEN_DOWN_SUCCEEDED:
  		return state.setIn(["syllabusBrokenDown", action.quesId], action.data)
    case EXIT_OVERVIEW:
      return state.setIn(["configType"], undefined)
        .setIn(["studentReport"], undefined)
        .setIn(["notStarted"], undefined)
    case CLIENT_MAIL_RESEND_REQUESTED:
      return state.setIn(["clientMailResent"], undefined)
    case REGISTER_CLIENT_USER_SUCCEEDED:
      return state.setIn(["clientSignupUser"], action.userObj)
        .setIn(["clientMailResent"], undefined)
        .setIn(["clientRegisterError"], undefined)
    case REGISTER_CLIENT_USER_FAILED:
      return state.setIn(["clientRegisterError"], action.resp.data)
    case CLIENT_MAIL_RESEND_SUCCEEDED:
      return state.setIn(["clientMailResent"], true)
    case CLIENT_MAIL_RESEND_FAILED:
      return state.setIn(["clientMailResent"], false)
    case REGISTER_NEW_STUDENT_REQUESTED:
      return state.merge({
				studentRegistrationError: undefined,
			});
    case REGISTER_NEW_STUDENT_SUCCEEDED:
      return state.merge({
				studentRegistrationError: undefined,
				newStudentRegistrationData: undefined,
        studentRegistrationSuccessful: true
			});
    case REGISTER_NEW_STUDENT_FAILED:
      return state.merge({
        studentRegistrationError: Object.values(action.data).join(' ')
      });
    case SET_NEW_STUDENT_REGISTRATION_DATA:
      return state.merge({
				newStudentRegistrationData: action.studentData,
			});
    case RESET_NEW_STUDENT_REGISTRATION:
      return state.merge({
				studentRegistrationError: undefined,
				newStudentRegistrationData: undefined,
				studentRegistrationSuccessful: undefined,
			});
    
    case BULK_REGISTER_STUDENTS_REQUESTED:
      return state.merge({
				bulkStudentRegistrationError: undefined,
			});
    case BULK_REGISTER_TEACHERS_REQUESTED:
      return state.merge({
				bulkTeacherRegistrationError: undefined,
			});
    case BULK_REGISTER_STUDENTS_SUCCEEDED:
      return state.merge({
				bulkStudentRegistrationError: undefined,
				bulkStudentRegistrationData: action.resp.data,
        bulkStudentRegistrationSuccess: true
			});
    case BULK_REGISTER_TEACHERS_SUCCEEDED:
      return state.merge({
				bulkTeacherRegistrationError: undefined,
				bulkTeacherRegistrationData: action.resp.data,
        bulkTeacherRegistrationSuccess: true
			});
    case BULK_REGISTER_STUDENTS_FAILED:
      return state.merge({
        bulkStudentRegistrationError: action.resp.data[0]
      });
    case BULK_REGISTER_TEACHERS_FAILED:
      return state.merge({
        bulkTeacherRegistrationError: Object.values(action.data).join(' ')
      });
    case RESET_EXCEL_NEW_STUDENT_REGISTRATION:
      return state.merge({
        studentExcelUpload: undefined,
        studentExcelUploadError: undefined,
        studentExcelDownload: undefined,
      });
    
    case BULK_RESET_STUDENTS_REGISTRATION:
      return state.merge({
				bulkStudentRegistrationError: undefined,
				bulkStudentRegistrationData: undefined,
				bulkStudentRegistrationSuccess: undefined,
			});
    case BULK_RESET_TEACHERS_REGISTRATION:
      return state.merge({
				bulkTeacherRegistrationError: undefined,
				bulkTeacherRegistrationData: undefined,
				bulkTeacherRegistrationSuccess: undefined,
			});
    case GET_CLASS_TEACHERS_SUCCEEDED:{
      return state.merge({
        classTeachers: action.data,
      });
      }
    case CHECK_STUDENT_EXISTS_DATA:
      return state.merge({
				studentExists: action.resp.data,
			});
    case CHECK_TEACHER_EXISTS_DATA:
      return state.merge({
        teacherExists: action.resp.data,
      });
    case REMOVE_CLASS_TEACHERS_SUCCEEDED:
      return state.merge({
        classTeacherRemoved: action.teacherId,
      });
    case REMOVE_CLASS_TEACHERS_FAILED:
      return state.merge({
        classTeacherRemoved: {id: action.teacherId,
          error: action.resp.data
        },
      });
      
    case GET_TOPIC_LAUNCH_SETTINGS_SUCCEEDED:
      return state.merge({
        topicLaunchSettingsData: action.resp.data
      });
    case SET_TOPIC_LAUNCH_SETTINGS_SUCCEEDED:
      return state.merge({
        setTopicLaunchSettings: true
      });
    case SET_TOPIC_LAUNCH_SETTINGS_RESET:
      return state.merge({
        setTopicLaunchSettings: false
      });
    case GET_ASSESSMENT_LAUNCH_SETTINGS_SUCCEEDED:
      return state.merge({
        assessmentLaunchSettingsData: action.resp.data
      });
    case SET_ASSESSMENT_LAUNCH_SETTINGS_SUCCEEDED:
      return state.merge({
        setAssessmentLaunchSettings: true
      });
    case SET_ASSESSMENT_LAUNCH_SETTINGS_RESET:
      return state.merge({
        setAssessmentLaunchSettings: false
      });
    case PUBLISH_ASSESSMENT_REQUEST_SUCCEEDED:
      return state.merge({
        publishAssessmentRequestStatus: "success"
      });
    case PUBLISH_ASSESSMENT_REQUEST_FAILED:
      return state.merge({
        publishAssessmentRequestStatus: "failed"
      });
    case PUBLISH_ASSESSMENT_REQUEST_RESET:
      return state.merge({
        publishAssessmentRequestStatus: undefined
      });
    case GET_CLASS_BOOKMARKS_DATA:
      return state.merge({
        assessmentRequestSubmit: false
      });
    case GET_CLASS_BOOKMARKS_DATA_SUCCEEDED:
      // console.log(action.resp.data)
      let tempArr = []
      let tempObj = {}
      let tempCtArr = []
      if(!!action.resp.data){
        action.resp.data.map((item,index)=>{
          if(!tempCtArr.includes(item.ct_id)){
            tempCtArr.push(item.ct_id)
          }
          if(item.added_in_assessment_request){
            tempArr.push(item)
          }
          if(item.chapter_id in tempObj){
            tempObj[item.chapter_id] = tempObj[item.chapter_id]+1
          }
          else{
            tempObj[item.chapter_id] = 1
          }
        })
      }
      return state.merge({
        assessmentRequestData: action.resp.data,
        testPaperData: tempArr,
        chapterQuestionCounter: tempObj,
        ctIdData: tempCtArr
      });
    case GET_ASSESSMENT_DRAFT_DATA_SUCCEEDED:
      return state.merge({
        draftAssessmentData: action.resp.data,
      });
    case SUBMIT_ASSESSMENT_REQUEST_SUCCEEDED:
      return state.merge({
        assessmentRequestSubmit: true
      });
    case SUBMIT_ASSESSMENT_REQUEST_RESET:
      return state.merge({
        assessmentRequestSubmit: false
      });
    case MOVE_QUESTION:
      // console.log(action.payload)
      let tempArr2 = JSON.parse(JSON.stringify(state.testPaperData));
      var element = tempArr2[action.payload.position];
      // console.log(JSON.parse(JSON.stringify(state.testPaperData)),element)
      // console.log(action.payload.direction=="U" && action.payload.position>0)
      if(action.payload.direction=="U" && action.payload.position>0){
        tempArr2.splice(action.payload.position, 1);
        tempArr2.splice(action.payload.position-1, 0, element);
      }
      else if(action.payload.direction=="D" && action.payload.position<JSON.parse(JSON.stringify(state.testPaperData)).length-1){
        tempArr2.splice(action.payload.position, 1);
        // console.log(action.payload.position)
        tempArr2.splice(action.payload.position+1, 0, element);
      }
      // console.log(tempArr2)
      return state.merge({
        testPaperData: tempArr2
      });
    case UPDATE_BOOKMARK_DATA_SUCCEEDED:
      let tempArr3 = JSON.parse(JSON.stringify(state.testPaperData));
      if("marks" in action.payload.payload){
        tempArr3.map((item,index)=>{
          // console.log(item.id==action.payload.payload.bookmark_id)
          if(item.id==action.payload.payload.bookmark_id){
            item.marks = Number(action.payload.payload.marks)
          }
        })
      }
      return state.merge({
        testPaperData: tempArr3
      });
    // case ADD_QUESTION_TO_BOOKMARK_DATA:
    //   return state.merge({
    //     localAssessmentRequestData: [...state.localAssessmentRequestData,...action.payload]
    //   });
    case SET_ACTIVE_VIEW_DRAFT:
      return state.merge({
        activeDraft: action.payload
      });
    // case REMOVE_QUESTION_FROM_BOOKMARK_DATA:
    //   let temp = JSON.parse(JSON.stringify(state.localAssessmentRequestData))
    //   let removeIndex = null;
    //   temp.map((item,index)=>{
    //     if(item.ct_id==action.payload.ct_id){
    //       removeIndex = index;
    //     }
    //   })
    //   if(removeIndex!=null){
    //     temp.splice(removeIndex,1)
    //   }
    //   return state.merge({
    //     localAssessmentRequestData: temp
    //   });
    case FETCH_ASSESSMENT_REQUESTS_SUCCEEDED:
      return Immutable.setIn(
        state,
        ['assessmentsView', action.classCode],
        action.data,
      );		
      
    case SELECT_LANGUAGE_PRACTICE_MODE:
			return state.setIn(['selectedLanguagePracticeMode'], action.language)
    case SELECT_LANGUAGE_PRACTICE_MODE_RESET:
			return state.setIn(['selectedLanguagePracticeMode'], undefined)
  
    case GET_MYLEADERBOARD_SUCCEEDED:
      return state.setIn(['myleaderboard'], action.data)
    case GET_MYLEADERBOARD_FAILED:
    console.log("called");
    return state.setIn(['myleaderboard'], undefined)
    case GET_LEADERBOARD_SUCCEEDED:
        console.log(">>>>>>>><<<<<<<<<<<<<<<<<<")
        console.log(action.data)
        return state.setIn(['leaderboard'], action.data)
    case GET_LEADERBOARD_FAILED:
      console.log("called")
			return state.setIn(['leaderboard'], undefined)
    case SET_SYNC_LEADERBOARD_DATA_LOADER:
      return state.setIn(['isLeaderboardSyncing'],action.isSyncing)
    case GET_LEADERBOARD_DATA_SUCCEEDED:
      return state.setIn(['leaderboardData'],action.data)
    case GET_LEADERBOARD_DATA_FAILED:
      return state.setIn(['leaderboardData'], undefined)
    case GET_LEADERBOARD_STUDENT_SUCCEEDED:
      return state.setIn(['leaderboardStudent'], action.data)
    case GET_LEADERBOARD_STUDENT_FAILED:
			return state.setIn(['leaderboardStudent'], undefined)
    case EMPTY_NEW_CLASS:
      return state.setIn(["newClass", "classCode"], undefined)
        .setIn(["newClass", "classObj"], undefined)
        .setIn(["newMultiClasses"], undefined)
    case EMPTY_EXCEL_UPLOAD:
      return state.setIn(["studentExcelUpload"], undefined)
        .setIn(["studentExcelUploadError"], undefined)
    case FETCH_DATE_FILTERS_SUCCEEDED:
      return state.setIn(["notificationView", "dateFilter"], action.data)
    case GET_ALERT_SUCCEEDED:
      return state.setIn(["notificationView", action.classCode], action.data)
    case GET_NOTIFICATION_SESSION_SUCCEEDED:
      return state.setIn(["notificationView", action.classCode, "reviewUser", action.userId], action.data)
    case GET_NOTIFICATION_SESSION_FAILED:
      return state.setIn(["notificationSessionFailed"],true)
    case GET_GOOGLECLASSES_SUCCEEDED:
      return state.setIn(["googleClasses", action.classCode], action.data.googleClassroomClasses)
    case GET_GOOGLECLASSESONBOARDING_SUCCEEDED:
        return state.setIn(["googleClassesOnboarding"],action.data.googleClassroomClasses);
      case GET_GOOGLE_CLASSROOM_STUDENTS_SUCCEEDED:
          return state.setIn(["googleClassroomStudents"],action.data);
      case GET_GOOGLE_CLASSROOM_STUDENTS_FAILED:
        return state.setIn(["googleClassroomStudents"],[]);
      case SYNC_GOOGLE_CLASSROOM_STUDENTS_SUCCEEDED:
        return state.setIn(["syncedGoogleClassroomStudent"],action.data.students);
      case SYNC_GOOGLE_CLASSROOM_STUDENTS_FAILED:
        return state.setIn(["syncedGoogleClassroomStudent"],[]);
      case GET_LINK_GOOGLECLASS_SUCCEEDED:
      return state.setIn(["googleClasses"], undefined)
        .setIn(["googleClassroomStates"], action.data)
    case GET_UNLINK_GOOGLECLASS_SUCCEEDED:
      return state.setIn(["googleClasses"], undefined)
        .setIn(["googleClassroomStates"], action.data)
    case GET_ALL_ATTEMPTS_SUCCEEDED:
      return state.setIn(["testAttempts", action.testId, action.userId], action.data)
    case FETCH_AUTO_GRADING_CTS_SUCCEEDED:
      let configTypes = action.data;
      return state.setIn(["examAutoGradingCts"], action.data)
                  .setIn(["autoGradingQuestionLoader"], configTypes.map((configType) => {
                    return {status: 0 , quesNo: configType.questionNo}
                  }));
    case REMOVE_TEST_INFO:
      return state.setIn(["studentWiseReport"],{})
        .setIn(["selectedTestStudentId"],undefined)
        .setIn(["examQuestionLoader"],[])
        .setIn(["examQuestions"],undefined)
    case FETCH_ALL_ASSESSMENT_CLASSES_LOADING:
      return state.setIn(["isAllAssessmentClassesLoading"],true)
    case GET_ALL_ASSESSMENT_CLASSES_SUCCEEDED:
      const currectClasses = state.allAssessmentClasses;
      return state.setIn(["allAssessmentClasses"],[...currectClasses, ...action.data])
      .setIn(["isAllAssessmentClassesLoading"],false)
      .setIn(["hasMoreAssessmentClasses"],action.data.length>0)
      .setIn(["allAssessmentClassesOffset"],state.allAssessmentClassesOffset+state.allAssessmentClassesLimit)
    case SET_LOADING_FILTERED_CLASSES:
      return state.setIn(["loadingFilteredClasses"],true)
    case GET_FILTERED_ASSESSMENT_CLASSES_SUCCEEDED:
      return state.setIn(["filteredAssessmentClasses"],[...action.data])
      .setIn(["loadingFilteredClasses"],false)
    case GET_FILTERED_ASSESSMENT_CLASSES_FAILED:
      return state.setIn(["loadingFilteredClasses"],false)
    case GET_ALL_ASSESSMENT_CLASSES_FAILED:
      return  state.setIn(["isAllAssessmentClassesLoading"],false)
    case GET_ASSESSMENT_DRAFT_CLASSES_SUCCEEDED:
      return state.setIn(["assessmentDraftClasses"],action.data)
    case GET_ASSESSMENT_ADDED_CLASSES_SUCCEEDED:
      return state.setIn(["publishedAssessmentClasses"],action.data)
    case GET_SCREEN_ERRORS_MAPPING_SUCCEEDED:
      return state.setIn(["screenErrorsMapping"],action.data)
    case SUBMIT_LOGIN_ERROR_REPORT_SUCCEEDED:
      return state.setIn(["loginErrorReport"],{success:true})
    case SUBMIT_LOGIN_ERROR_REPORT_FAILED:
      return state.setIn(["loginErrorReport"],{error:action.error})
    case RESET_SUBMIT_LOGIN_ERROR_REPORT:
      return state.setIn(["loginErrorReport"],undefined)
    case SUBMIT_ERROR_REPORT_SUCCEEDED:
      return state.setIn(["errorReport"],{success:true})
    case SUBMIT_ERROR_REPORT_FAILED:
      return state.setIn(["errorReport"],{error:action.resp.data})
    case RESET_SUBMIT_ERROR_REPORT:
      return state.setIn(["errorReport"],undefined)
    case SET_NEW_STUDENT_PASSWORD_REQUESTED:
      return state.setIn(["setStudentPasswordError"],false)
      .setIn(["setStudentPasswordSuccess"],false)
    case SET_NEW_STUDENT_PASSWORD_FAILED:
      return state.setIn(["setStudentPasswordError"],true)
            .setIn(["setStudentPasswordSuccess"],false)
    case SET_NEW_STUDENT_PASSWORD_SUCCEEDED:
      return state.setIn(["setStudentPasswordSuccess"],true)
            .setIn(["setStudentPasswordError"],false)
    case MODIFY_STUDENT_DETAILS_SUCCEEDED:
      return state.setIn(["modifyStudentDetailsSuccess"],true)
            .setIn(["modifyStudentDetailsError"],false)
    case MODIFY_STUDENT_DETAILS_FAILED:
      return state.setIn(["modifyStudentDetailsSuccess"],false)
            .setIn(["modifyStudentDetailsError"],true)
    case RESET_MODIFY_STUDENT_DETAILS:
      return state.setIn(["modifyStudentDetailsSuccess"],false)
            .setIn(["modifyStudentDetailsError"],false)
            case GET_CANVAS_CLASS_LIST_REQUEST:
              return state.set('canvasClassList', []);
            case GET_CANVAS_CLASS_LIST_SUCCESS:
              return state.set('canvasClassList', action.data);
            case GET_CANVAS_CLASS_LIST_FAILURE:
              return state.set('canvasClassList', action.message);

              case GET_CANVAS_CHAPTER_LIST_REQUEST:
                return state.set('canvasData', {});
              case GET_CANVAS_CHAPTER_LIST_SUCCESS:
                return state.set('canvasData', {[action.classCode]: action.data});
              case GET_CANVAS_CHAPTER_LIST_FAILURE:
                return state.set('canvasData', action.message);                
    case GET_AI_FEEDBACK_REPORT_SUCCEEDED:
      return state.setIn(['essayGradingAIFeedback'],action.resp.data);
    case GET_AI_FEEDBACK_REPORT_FAILED:
      return state.setIn(['essayGradingAIFeedback'],undefined);
    case SET_EDIT_AI_FEEDBACK_CRITERIA_LOADER:
        return state.setIn(['savingAIFeedbackCriteria'],action.isSaving)
    case EDIT_AI_FEEDBACK_CRITERIA_SUCCEEDED:
      return state.setIn(["savingAIFeedbackCriteriaSuccess"],true)
      .setIn(["savingAIFeedbackCriteriaError"],false);
    case EDIT_AI_FEEDBACK_CRITERIA_FAILED:
      return state.setIn(["savingAIFeedbackCriteriaSuccess"],false)
      .setIn(["savingAIFeedbackCriteriaError"],true);
    case EXIT_AI_FEEDBACK_REPORT_VIEW:
      return state.setIn(['essayGradingAIFeedback'],undefined);
    case UPDATE_STUDENT_REPORT_QUESTION_MARKS:
      const { marksObtained, student_id, index: questionIndex} = action;
      const reportArr =[...state.studentWiseReport[student_id]];
      reportArr[questionIndex] = {
        ... reportArr[questionIndex],
        marks: Number(marksObtained)
      };
      return state.setIn(['studentWiseReport', student_id], reportArr)
    case SET_UNAUTH_LANGUAGE:
      return state.setIn(["unauthLanguage"],action.language)
    case SET_CURRENT_OPEN_ENDED_QUESTION_FEEDBACK:
      {const { feedback, student_id, index: questionIndex} = action;
      const examQuestion = state.examQuestions[student_id][questionIndex];
      const updatedExamQuestion = {...examQuestion};
      updatedExamQuestion.openEndedFeedback = [...feedback];
      return state.setIn(['examQuestions', student_id,questionIndex], updatedExamQuestion)
    }
    case SET_EDIT_OPEN_ENDED_FEEDBACK_LOADER:
      return state.setIn(['savingOpenEndedFeedback'],action.isSaving)
    case EDIT_OPEN_ENDED_FEEDBACK_SUCCEEDED:
      return state.setIn(["savingOpenEndedFeedbackSuccess"],true)
      .setIn(["savingOpenEndedFeedbackError"],false);
    case EDIT_OPEN_ENDED_FEEDBACK_FAILED:
      return state.setIn(["savingOpenEndedFeedbackSuccess"],false)
      .setIn(["savingOpenEndedFeedbackError"],true);
    case MOCK_TEST_RESET_REQUESTED:
      return state.setIn(["resetMockTestLoader"],true)
        .setIn(["resetMockTestSuccess"],false)
        .setIn(["resetMockTestError"],false)
    case MOCK_TEST_RESET_SUCCESS:
      return state.setIn(["resetMockTestInfo"],action.resp.data)
      .setIn(["resetMockTestLoader"],false)
      .setIn(["resetMockTestSuccess"],true)
      .setIn(["resetMockTestError"],false)
    case MOCK_TEST_RESET_FAILURE:
      return state.setIn(["resetMockTestInfo"],undefined)
      .setIn(["resetMockTestLoader"],false)
      .setIn(["resetMockTestSuccess"],false)
      .setIn(["resetMockTestError"],true)
    case RESET_MOCK_TEST_RESCHEDULE:
      return state.setIn(["resetMockTestInfo"],undefined)
      .setIn(["resetMockTestLoader"],false)
      .setIn(["resetMockTestSuccess"],false)
      .setIn(["resetMockTestError"],false);
    case DOWNLOAD_TOPIC_REPORT_REQUESTED:
      return state.setIn(["downloadTopicReportSuccess"],false)
      .setIn(["downloadTopicReportError"],false)
      .setIn(["downloadTopicReportLoading"],true)
    case DOWNLOAD_TOPIC_REPORT_SUCCESS:
      return state.setIn(["downloadTopicReportSuccess"],true)
      .setIn(["downloadTopicReportError"],false)
      .setIn(["downloadTopicReportLoading"],false)
    case DOWNLOAD_TOPIC_REPORT_FAILURE:
      return state.setIn(["downloadTopicReportSuccess"],false)
      .setIn(["downloadTopicReportError"],true)
      .setIn(["downloadTopicReportLoading"],false)
    case DOWNLOAD_MOCK_EXAM_REPORT_REQUESTED:
      return state.setIn(["downloadMockExamReportSuccess"],false)
      .setIn(["downloadMockExamReportError"],false)
      .setIn(["downloadMockExamReportLoading"],true)
    case DOWNLOAD_MOCK_EXAM_REPORT_SUCCESS:
      return state.setIn(["downloadMockExamReportSuccess"],true)
      .setIn(["downloadMockExamReportError"],false)
      .setIn(["downloadMockExamReportLoading"],false)
    case DOWNLOAD_MOCK_EXAM_REPORT_FAILURE:
      return state.setIn(["downloadMockExamReportSuccess"],false)
      .setIn(["downloadMockExamReportError"],true)
      .setIn(["downloadMockExamReportLoading"],false)
    case GET_AI_GRADING_FEEDBACK_REPORT_SUCCEEDED:
      const aiGradingFeedbackDecoded = {
        ...action.resp.data,
        evaluations : [
          ...action.resp.data.evaluations.map(i=>({
            ...i,
            feedback: b64DecodeUnicode(i.feedback)
          }))
        ]   
      }
      return state.setIn(['aiGradingFeedback'],aiGradingFeedbackDecoded);
    case GET_AI_GRADING_FEEDBACK_REPORT_FAILED:
      return state.setIn(['aiGradingFeedback'],undefined);
    case SET_EDIT_AI_GRADING_FEEDBACK_LOADER:
        return state.setIn(['savingAIGradingFeedback'],action.isSaving)
    case EDIT_AI_GRADING_FEEDBACK_SUCCEEDED:
    {
      const aiGradingFeedbackDecoded = {
        ...action.resp.data,
        evaluations : [
          ...action.resp.data.evaluations.map(i=>({
            ...i,
            feedback: b64DecodeUnicode(i.feedback)
          }))
        ]   
      }
      return state.setIn(["savingAIGradingFeedbackSuccess"],true)
      .setIn(["savingAIGradingFeedbackError"],false)
      .setIn(['savingAIGradingFeedback'],false)
      .setIn(['aiGradingFeedback'],aiGradingFeedbackDecoded);}
    case EDIT_AI_GRADING_FEEDBACK_FAILED:
      return state.setIn(["savingAIGradingFeedbackSuccess"],false)
      .setIn(["savingAIGradingFeedbackError"],true);
    case EXIT_AI_GRADING_FEEDBACK_REPORT_VIEW:
      return state.setIn(['aiGradingFeedback'],undefined);
    default:
      return state;
  }
};
