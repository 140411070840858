import { useEffect, useLayoutEffect, useRef, useState } from "preact/hooks";
import style from "./style.scss";
import {
  AIFeedbackBackArrowWhite,
  CheckRubricIconWhite,
  markedRight,
  markedWrong,
} from "../../../../assets/images";
import RubricModal from "./RubricModal";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import {
  editAIGradingFeedback,
  exitAIGradingFeedbackReportView,
  getAIGradingFeedbackReport,
  setEditAIGradingFeedbackLoader,
  updateStudentReportQuestionMarks,
} from "../../../../action";
import { textIdentifier } from "../../../../components/Common/textIdentifier";
import { LeftImageNavArrow, RightImageNavArrow } from "../../../../images";
import { b64EncodeUnicode } from "../../../../utils";

const getSubpartNumber = (subpart_number) => {
  return String.fromCharCode("a".charCodeAt(0) + subpart_number);
};

const DangerousMarkup = (props) => {
  const [markup, changeMarkup] = useState(props.markup);
  const [qid, changeQid] = useState(props.qid);
  useEffect(() => {
    changeMarkup(props.markup);
  }, [qid]);

  if (qid !== props.qid) changeQid(props.qid);

  return (
    <div
      key={qid}
      dangerouslySetInnerHTML={{
        __html: markup,
      }}
    />
  );
};

const AIFeedbackHeader = ({
  isEssayType,
  handleClose,
  marks,
  maxMarks,
  handleToggleRubricModal,
  language,
  rubric,
}) => {
  const dispatch = useDispatch();
  return (
    <div className={style["ai-feedback--header"]}>
      <div
        className={style["back-btn-cnt"]}
        onClick={() => {
          dispatch(exitAIGradingFeedbackReportView());
          handleClose();
        }}
      >
        <AIFeedbackBackArrowWhite />
      </div>

      <div className={style["header-label-left"]}>
        {textIdentifier["back"][language]}
      </div>
      <div className={style["header-label-main"]}>
        {textIdentifier["ai-and-teacher-evaluation"][language]}:
        <span className={style["marks"]}>{`${marks} out of ${maxMarks} ${
          textIdentifier["mark(s)"][language][0].toUpperCase() +
          textIdentifier["mark(s)"][language].slice(1)
        }`}</span>
      </div>
      {(isEssayType || rubric.length > 0 ) && (
        <div
          className={style["check-rubric"]}
          onClick={handleToggleRubricModal}
        >
          <CheckRubricIconWhite className={style["check-rubric--icon"]} />
          <span className={style["check-rubric--text"]}>
            {textIdentifier["ai-grader-rubric"][language]}
          </span>
        </div>
      )}
    </div>
  );
};

const QuestionAnswerView = ({
  questionId,
  question,
  currentAnswer,
  language,
  selectedQuestionAIFeedback,
  solutionImages,
}) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [uploads, setUploads] = useState([...solutionImages]);
  const [dots, setDots] = useState(Math.min(5, uploads.length));
  const answerRef = useRef(null);
  const answerId = `exam_question_0`;

  const adjustHeight = () => {
    answerRef.current.style.height = "0px";
    const scrollHeight = answerRef.current.scrollHeight;
    answerRef.current.style.height = Number(scrollHeight) + 30 + "px";
  };

  const handleResize = () => {
    adjustHeight();
  };

  const handleNextImage = () => {
    setCurrentImage((prev) => {
      if (prev % 5 === 4) {
        setDots((prev2) => Math.min(5, uploads.length - (prev + 1)));
      }
      return prev + 1;
    });
  };

  const handlePrevImage = () => {
    setCurrentImage((prev) => {
      if (prev % 5 === 0) {
        setDots((prev2) => 5);
      }
      return prev - 1;
    });
  };

  useEffect(() => {
    if (answerRef.current) {
      adjustHeight();
    }
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (answerRef.current) {
      adjustHeight();
    }
  }, [questionId]);

  useEffect(() => {
    if (selectedQuestionAIFeedback.userResponse) {
      let arg =
        typeof selectedQuestionAIFeedback.userResponse === "string"
          ? parseQuestionResponse(selectedQuestionAIFeedback.userResponse)
          : selectedQuestionAIFeedback.userResponse;
      if (Boolean(arg)) {
        parse_answer.insertAnswers(arg, answerId);
      }
      parse_answer.disableAnswers(answerId);
    }

    if (
      selectedQuestionAIFeedback &&
      selectedQuestionAIFeedback.solution &&
      selectedQuestionAIFeedback.question &&
      selectedQuestionAIFeedback.id &&
      selectedQuestionAIFeedback.marking_list &&
      Array.isArray(selectedQuestionAIFeedback.marking_list) &&
      selectedQuestionAIFeedback.marking_list.length > 0
    ) {
      const subpartAnsInterval = setInterval(() => {
        if (
          document.getElementById(answerId) &&
          document.getElementById(answerId).getElementsByClassName("sub-part")
            .length > 0
        ) {
          const ansSubparts = Object.values(
            document.getElementById(answerId).getElementsByClassName("sub-part")
          );
          ansSubparts.forEach((partAnsEle, partAnsIndex) => {
            let partAnsPoint = partAnsEle.querySelector("b");
            partAnsPoint.style.color =
              selectedQuestionAIFeedback.marking_list[partAnsIndex] > 0
                ? "#3CD22D"
                : "#FF6666";
            let partAnsMarked = document.createElement("div");
            partAnsMarked.style.marginTop = "2px";
            partAnsMarked.style.marginLeft = "-13px";
            partAnsMarked.style.marginRight = "13px";
            partAnsMarked.className = "sub-part-marked";
            partAnsMarked.innerHTML =
              selectedQuestionAIFeedback.marking_list[partAnsIndex] > 0
                ? markedRight
                : markedWrong;
            if (
              partAnsPoint.nextSibling &&
              partAnsPoint.nextSibling.tagName == "DIV" &&
              partAnsPoint.nextSibling.className == "sub-part-marked"
            )
              partAnsPoint.nextSibling.remove();
            partAnsPoint.insertAdjacentElement("afterend", partAnsMarked);
          });
          clearInterval(subpartAnsInterval);
        }
      }, 25);
    }
  }, []);

  return (
    <div
      className={[
        style["question-view-cnt"],
        style["question-view-cnt--openended-height"],
      ].join(" ")}
    >
      <div className={style["question-cnt"]}>
        <div className={style["label"]}>
          {textIdentifier["question"][language]}
        </div>
        <div className={style["val"]}>
          <DangerousMarkup qid={questionId} markup={question} />
        </div>
      </div>
      <div className={style["separator"]} />
      <div className={style["answer-cnt"]}>
        <div className={style["label"]}>
          {textIdentifier["answer"][language]}
        </div>
        <div id={answerId}>
          <DangerousMarkup qid={questionId} markup={currentAnswer} />
        </div>
      </div>
      <div className={style["separator"]} />
      {uploads.length > 0 && (
        <div className={style["solution-uploads-cnt"]}>
          <div className={style["label"]}>
            {textIdentifier["answer-uploaded"][language]}
          </div>
          <div className={style["image-cnt"]}>
            <div
              className={[
                style["left-arrow"],
                currentImage === 0 && style["left-arrow--disabled"],
              ].join(" ")}
              onClick={handlePrevImage}
            >
              <LeftImageNavArrow />
            </div>
            <img
              src={uploads[currentImage]}
              className={style["uploaded-img"]}
            />
            <div
              className={[
                style["right-arrow"],
                currentImage === uploads.length - 1 &&
                  style["right-arrow--disabled"],
              ].join(" ")}
              onClick={handleNextImage}
            >
              <RightImageNavArrow />
            </div>
            <div className={style["count-tracker"]}>
              {currentImage + 1}/{uploads.length}
            </div>
          </div>
          <div className={style["dot-navigation"]}>
            {Array(dots)
              .fill(0)
              .map((i, index) => (
                <div
                  className={[
                    style["bubble"],
                    currentImage % 5 === index && style["bubble--active"],
                  ].join(" ")}
                ></div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

const OpenEndedFeedbackCard = ({
  currentFeedbackObj,
  language,
  index,
  handleConfirmEdit,
  setTotalMarksObtained,
  studentId,
  question,
}) => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [editSubmitted, setEditSubmitted] = useState(false);
  const [updatedMarks, setUpdatedMarks] = useState(undefined);
  const [feedback, setFeedback] = useState(undefined);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [fieldsSaved, setFieldsSaved] = useState(false);
  const textareaRef = useRef(null);
  const { savingAIGradingFeedback, savingAIGradingFeedbackSuccess } =
    useSelector((state) => state);

  const handleEditClick = () => {
    setIsEditing(true);
    setUpdatedMarks(currentFeedbackObj.marks_obtained);
    setFeedback((prev) => currentFeedbackObj.raw_feedback);
  };

  const handleMarksChange = (e) => {
    setUpdatedMarks("" + Number(e.target.value));
  };

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const handleCancelEdit = () => {
    setError(false);
    setIsEditing(false);
  };

  const handleBack = () => {
    setFieldsSaved(false);
    dispatch(setEditAIGradingFeedbackLoader(false));
  };

  const handleSubmit = () => {
    dispatch(setEditAIGradingFeedbackLoader(true));
    dispatch(
      editAIGradingFeedback(
        currentFeedbackObj.id,
        Number(updatedMarks),
        b64EncodeUnicode(feedback),
        currentFeedbackObj.subpart_number
      )
    );
    setEditSubmitted(true);
  };

  useEffect(() => {
    const isBlank =
      feedback !== undefined &&
      feedback.split(/\s+/).filter((i) => i !== "").length === 0;
    if (updatedMarks !== undefined && Number(updatedMarks) < 0) {
      setError(true);
      setErrorMsg("score-cannot-be-negative");
    } else if (
      updatedMarks !== undefined &&
      Number(updatedMarks) > Number(currentFeedbackObj.maximum_marks)
    ) {
      setError(true);
      setErrorMsg("score-cannot-be-greater-than-total-marks");
    } else if (feedback !== undefined && isBlank) {
      setError(true);
      setErrorMsg("fields-cannot-be-empty");
    } else {
      setError(false);
      setErrorMsg("");
    }
  }, [feedback, updatedMarks]);

  useEffect(() => {
    if (
      !savingAIGradingFeedback &&
      savingAIGradingFeedbackSuccess &&
      editSubmitted
    ) {
      setFieldsSaved(false);
      setIsEditing(false);
      setEditSubmitted(false);
      setTotalMarksObtained((prev) => {
        const newTotalMarks = (
          Number(prev) -
          Number(currentFeedbackObj.marks_obtained) +
          Number(updatedMarks)
        ).toFixed(2);
        dispatch(
          updateStudentReportQuestionMarks(
            newTotalMarks,
            studentId,
            question.questionIndex
          )
        );
        return newTotalMarks;
      });
    }
  }, [savingAIGradingFeedback, savingAIGradingFeedbackSuccess]);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;

    textarea.style.height = "auto";

    const newHeight = textarea.scrollHeight;

    textarea.style.height = Math.min(newHeight, 550) + "px";

    if (newHeight > 550) {
      textarea.style.overflowY = "auto";
    } else {
      textarea.style.overflowY = "hidden";
    }
  };

  useEffect(() => {
    if (isEditing && textareaRef.current) {
      adjustTextareaHeight();
    }
  }, [isEditing, feedback]);

  useLayoutEffect(() => {
    window.MathJax.typeset();
  }, [feedback, isEditing]);
   
  return (
    <div className={style["open-ended-feedback-card"]}>
      <div className={style["open-ended-feedback-card-header"]}>
        <div className={style["open-ended-feedback-card-header--left"]}>
          {currentFeedbackObj.subpart_number !== null &&
            `(${getSubpartNumber(currentFeedbackObj.subpart_number)})`}
        </div>
        {isEditing ? (
          <div className={style["edit-score-container"]}>
            <span>{textIdentifier["scored"][language]} - </span>{" "}
            <input
              type="number"
              className={[
                style["marks--inp"],
                fieldsSaved && style["marks--inp--disabled"],
              ].join(" ")}
              min={0}
              max={currentFeedbackObj.maximum_marks}
              value={Number(updatedMarks)}
              onChange={handleMarksChange}
              disabled={fieldsSaved}
            />{" "}
            <span>{`out of ${currentFeedbackObj.maximum_marks} ${textIdentifier["mark(s)"][language]}`}</span>
          </div>
        ) : (
          <div className={style["score-cnt"]}>
            {`${textIdentifier["scored"][language]} - ${currentFeedbackObj.marks_obtained} ${textIdentifier["out-of"][language]} ${currentFeedbackObj.maximum_marks} ${textIdentifier["mark(s)"][language]}`}
          </div>
        )}
      </div>
      {isEditing ? (
        <textarea
          ref={textareaRef}
          className={[
            style["feedback--inp"],
            fieldsSaved && style["feedback--inp--disabled"],
          ].join(" ")}
          value={feedback}
          onChange={handleFeedbackChange}
          placeholder="Enter Your Description"
          disabled={fieldsSaved}
        />
      ) : (
        <div className={style["feedback"]}>
          {currentFeedbackObj.is_base64 ? (
            <DangerousMarkup markup={currentFeedbackObj.feedback} />
          ) : (
            currentFeedbackObj.feedback
          )}
        </div>
      )}
      {error && errorMsg.length > 0 && (
        <div className={style["error-msg"]}>
          {textIdentifier[errorMsg][language]}
        </div>
      )}
      {fieldsSaved && (
        <div className={style["confirm-toast"]}>
          <div className={style["main-text"]}>
            {textIdentifier["are-you-sure-you-want-to-save-this-?"][language]}
          </div>
          <div className={style["secondary-text"]}>
            {
              textIdentifier[
                "these-modifications-will-also-reflect-on-the-student-dashboard"
              ][language]
            }
          </div>
        </div>
      )}
      {isEditing ? (
        <div
          className={[
            style["footer-cta"],
            (fieldsSaved || error) && style["footer-cta--isError"],
          ].join(" ")}
        >
          <div
            className={style["cancel-btn"]}
            onClick={() => (fieldsSaved ? handleBack() : handleCancelEdit())}
          >
            {fieldsSaved
              ? textIdentifier["back"][language]
              : textIdentifier["cancel"][language]}
          </div>
          <div
            className={[
              style["save-btn"],
              error && style["save-btn--disabled"],
            ].join(" ")}
            onClick={() =>
              fieldsSaved ? handleSubmit() : setFieldsSaved(true)
            }
          >
            {savingAIGradingFeedback ? (
              <svg
                className={style["spin"]}
                width="22"
                height="22"
                viewBox="0 0 50.098 50.098"
              >
                <g
                  id="Group_611"
                  data-name="Group 611"
                  transform="translate(-154.951 -279.951)"
                >
                  <path
                    id="Path_1026"
                    data-name="Path 1026"
                    d="M16.837,0A16.837,16.837,0,1,1,0,16.837"
                    transform="translate(203 311.163) rotate(150)"
                    fill="#fff"
                    stroke="#00b1b0"
                    stroke-linecap="round"
                    stroke-width="3"
                  />
                  <path
                    id="Path_1027"
                    data-name="Path 1027"
                    d="M9.794,0A9.794,9.794,0,1,1,0,9.794"
                    transform="translate(170.206 295.206)"
                    fill="#fff"
                    stroke="#00b1b0"
                    stroke-linecap="round"
                    stroke-width="3"
                  />
                </g>
              </svg>
            ) : fieldsSaved ? (
              textIdentifier["confirm"][language]
            ) : (
              textIdentifier["save"][language]
            )}
          </div>
        </div>
      ) : (
        <div className={style["footer-edit-cnt"]}>
          <div className={style["edit-cta"]} onClick={handleEditClick}>
            {textIdentifier["edit"][language]}
          </div>
        </div>
      )}
    </div>
  );
};

const OpenEndedFeedbackView = ({
  allFeedbacks,
  language,
  setTotalMarksObtained,
  studentId,
  question,
}) => {
  const dispatch = useDispatch();
  const [openEndedFeedbackList, setOpenEndedFeedbackList] = useState([
    ...allFeedbacks,
  ]);

  const handleConfirmEdit = (updatedFeedbackObj, index) => {
    const updatedFeedbackList = [...openEndedFeedbackList];
    updatedFeedbackList[index] = {
      ...updatedFeedbackList[index],
      feedback: updatedFeedbackObj.feedback,
      marks_obtained: updatedFeedbackObj.marks_obtained,
    };
    setOpenEndedFeedbackList(updatedFeedbackList);
  };

  useEffect(() => {
    setOpenEndedFeedbackList(allFeedbacks);
  }, [allFeedbacks]);

  return (
    <div className={style["open-ended-feedback-cnt"]}>
      <div className={style["header"]}>
        {textIdentifier["feedback"][language]}
      </div>
      <div className={style["open-ended-feedback-cnt"]}>
        {openEndedFeedbackList.map((feedback, index) => (
          <OpenEndedFeedbackCard
            language={language}
            currentFeedbackObj={feedback}
            index={index}
            handleConfirmEdit={handleConfirmEdit}
            setTotalMarksObtained={setTotalMarksObtained}
            studentId={studentId}
            question={question}
          />
        ))}
      </div>
    </div>
  );
};

const AIGradingFeedbackView = ({
  question,
  handleClose,
  examConfigId,
  studentId,
}) => {
  const dispatch = useDispatch();
  const { language } = useSelector((state) => state.userSession.user);
  const [totalMarksObtained, setTotalMarksObtained] = useState(undefined);
  const [rubric, setRubric] = useState([]);
  const [showRubricModal, setShowRubricModal] = useState(false);
  const [essayGradingAIFeedbackList, setEssayGradingAIFeedbackList] = useState(
    []
  );
  const { aiGradingFeedback } = useSelector((state) => state);

  const handleToggleRubricModal = () => {
    setShowRubricModal((prev) => !prev);
  };

  useEffect(() => {
    dispatch(
      getAIGradingFeedbackReport(question.id, studentId, question.attemptOrder)
    );
    window.MathJax = {
      tex: {
        inlineMath: [
          ["$", "$"],
          ["\\(", "\\)"],
          ["\\[", "\\]"],
        ],
      },
    };

    const mathJaxScript = document.createElement("script");
    mathJaxScript.id = "MathJax-script";
    mathJaxScript.async = true;
    mathJaxScript.src =
      "https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-mml-chtml.js";

    document.body.appendChild(mathJaxScript);

    return () => {
      setEssayGradingAIFeedbackList([]);
      document.body.removeChild(mathJaxScript);
    };
  }, []);

  useEffect(() => {
    if (aiGradingFeedback && aiGradingFeedback.evaluations.length > 0) {
      setEssayGradingAIFeedbackList([...aiGradingFeedback.evaluations]);
      setTotalMarksObtained(
        aiGradingFeedback.question_marks_obtained || question.marks
      );
    }

    if (aiGradingFeedback && aiGradingFeedback.rubric) { 
      setRubric([...aiGradingFeedback.rubric]);
    }
  }, [aiGradingFeedback]);

  if (question.isAiGrading && essayGradingAIFeedbackList.length === 0) {
    return null;
  } else
    return (
      <div className={style["ai-feedback"]}>
        <AIFeedbackHeader
          isEssayType={question.isEssayType}
          language={language}
          handleClose={handleClose}
          marks={totalMarksObtained}
          maxMarks={
            question.isEssayType
              ? aiGradingFeedback.question_total_marks || question.maximumMarks
              : question.maximumMarks
          }
          handleToggleRubricModal={handleToggleRubricModal}
          rubric={rubric}
        />
        <div className={style["feedback-body-cnt"]}>
          <div className={style["main-cnt"]}>
            <div className={style["evaluation-cnt"]}>
              <OpenEndedFeedbackView
                allFeedbacks={
                  aiGradingFeedback && [...aiGradingFeedback.evaluations]
                }
                language={language}
                setTotalMarksObtained={setTotalMarksObtained}
                studentId={studentId}
                question={question}
              />
              <div className={style["evaluation-cnt-right"]}>
                <QuestionAnswerView
                  solutionImages={
                    aiGradingFeedback &&
                    aiGradingFeedback.evaluations
                      .map((i) => i.image_url)
                      .filter((i) => i)
                  }
                  questionId={question.id}
                  selectedQuestionAIFeedback={question}
                  question={question.question}
                  currentAnswer={question.answerType}
                  language={language}
                />
                <div className={style["footer-padding"]} />
              </div>
            </div>
          </div>
        </div>
        {showRubricModal && (
          <RubricModal
            handleToggleRubricModal={handleToggleRubricModal}
            language={language}
            rubric={rubric}
          />
        )}
      </div>
    );
};

export default AIGradingFeedbackView;
