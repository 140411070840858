import style from "./style.scss";
import CrossIcon from "../../../../../assets/images/CrossIcon.svg";
import { textIdentifier } from "../../../../../components/Common/textIdentifier";

const RubricModal = ({ handleToggleRubricModal,language }) => {
  return (
    <div className={style["dialog-bg"]}>
      <div className={style["dialog-cnt"]}>
        <div className={style["header"]}>
          <div className={style["left-cnt"]}>{textIdentifier["ai-grader-rubric"][language]}</div>
          <div className={style["cross-icon"]}>
            <img
              src={CrossIcon}
              alt="cross-icon"
              onClick={handleToggleRubricModal}
            />
          </div>
        </div>
        <div className={style["main-cnt"]}>
          <div className={style["table"]}>
            <div
              className={[style["table-row"], style["table-row--header"]].join(
                " "
              )}
            >
              <div className={style["table-cell"]}></div>
              <div className={style["table-cell"]}>0-69</div>
              <div className={style["table-cell"]}>70-80</div>
              <div className={style["table-cell"]}>81-90</div>
              <div className={style["table-cell"]}>91-100</div>
            </div>
            <div className={style["table-row"]}>
              <div
                className={[
                  style["table-cell"],
                  style["table-cell--first"],
                ].join(" ")}
              >
                Problem and Solution 25%
              </div>
              <div className={style["table-cell"]}>
                The statement of the problem cannot be found. The statement of
                the solution cannot be found or recognized or misaligned with
                the problem being discussed.
              </div>
              <div className={style["table-cell"]}>
                The problem is stated but is difficult to find and difficult to
                understand. The solutions are stated but difficult to find and
                understand.
              </div>
              <div className={style["table-cell"]}>
                The problem is stated, but it is a little difficult to
                understand. The solutions are stated, but further discussion is
                needed. It is a little difficult to understand.
              </div>
              <div className={style["table-cell"]}>
                The problem is stated in and explained in clear language. It is
                easy to recognize and understand. The solutions are discussed
                comprehensively and in clear language. It is easy to recognize
                and understand.
              </div>
            </div>
            <div className={style["table-row"]}>
              <div
                className={[
                  style["table-cell"],
                  style["table-cell--first"],
                ].join(" ")}
              >
                Evidence 15%
              </div>
              <div className={style["table-cell"]}>
                No evidence was given to support the main idea.
              </div>
              <div className={style["table-cell"]}>
                Only a little evidence was provided. It does not support the
                main idea very well.
              </div>
              <div className={style["table-cell"]}>
                Some evidence was given to support the main idea but not enough.
              </div>
              <div className={style["table-cell"]}>
                Good support was given to the main idea. Facts and statistics
                were supplied from credible resources.
              </div>
            </div>
            <div className={style["table-row"]}>
              <div
                className={[
                  style["table-cell"],
                  style["table-cell--first"],
                ].join(" ")}
              >
                Call to action/Interdisciplinary approach 10%
              </div>
              <div className={style["table-cell"]}>
                No call to action was given to tell readers what they can do.
              </div>
              <div className={style["table-cell"]}>
                The call to action is very weak. Readers probably won't
                understand what they can do to help the problem.
              </div>
              <div className={style["table-cell"]}>
                The call to action is not very recognizable. The readers might
                not understand what they can do to help the problem.
              </div>
              <div className={style["table-cell"]}>
                The audience (readers) will easily recognize what they can do to
                help solve the problem using the interdisciplinary approach.
              </div>
            </div>
            <div className={style["table-row"]}>
              <div
                className={[
                  style["table-cell"],
                  style["table-cell--first"],
                ].join(" ")}
              >
                Style/Voice/Word Choice 15%
              </div>
              <div className={style["table-cell"]}>
                No distinguishable voice or forced/unnatural voice.
              </div>
              <div className={style["table-cell"]}>
                Wordiness, clichés, and/or vagueness sometimes muffle the voice.
                The tone is inconsistent.
              </div>
              <div className={style["table-cell"]}>
                The paper reads well with developing style and voice.
              </div>
              <div className={style["table-cell"]}>
                A consistent voice that is appropriate for the paper's meaning
                and engages the reader. The essay has personality.
              </div>
            </div>
            <div className={style["table-row"]}>
              <div
                className={[
                  style["table-cell"],
                  style["table-cell--first"],
                ].join(" ")}
              >
                Organization 15%
              </div>
              <div className={style["table-cell"]}>
                The essay is very messy. There is no clear order to the writing.
              </div>
              <div className={style["table-cell"]}>
                The essay is only somewhat organized. It is a little difficult
                to read from one part to the next.
              </div>
              <div className={style["table-cell"]}>
                The essay is somewhat organized. Most parts are in a logical
                order.
              </div>
              <div className={style["table-cell"]}>
                The essay is written in a logical order that the reader can
                easily follow and understand.
              </div>
            </div>
            <div className={style["table-row"]}>
              <div
                className={[
                  style["table-cell"],
                  style["table-cell--first"],
                ].join(" ")}
              >
                Grammar and Mechanics 10%
              </div>
              <div className={style["table-cell"]}>
                Surface problems are so frequent that they obscure meaning. Many
                sentences are poorly structured.
              </div>
              <div className={style["table-cell"]}>
                Frequent grammar, spelling and/or punctuation errors clutter the
                paper's surface. Some sentences may be awkward.
              </div>
              <div className={style["table-cell"]}>
                Surface errors are minimal and do not detract from meaning and
                readability.
              </div>
              <div className={style["table-cell"]}>
                Advanced traditional grammar and mechanics, except when
                irregularities (like fragments) are used for special effects.
              </div>
            </div>
            <div className={style["table-row"]}>
              <div
                className={[
                  style["table-cell"],
                  style["table-cell--first"],
                ].join(" ")}
              >
                Citation 10%
              </div>
              <div className={style["table-cell"]}>
                An attempt at citation was made.
              </div>
              <div className={style["table-cell"]}>
                An attempt at citation was made but is missing either in-text
                citations or a reference list.
              </div>
              <div className={style["table-cell"]}>
                References and in-text citations are nearly flawless.
              </div>
              <div className={style["table-cell"]}>
                References and in-text citations are flawless.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RubricModal;
