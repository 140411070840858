import { useEffect, useState } from "preact/hooks";
import style from "./style.scss";
import { textIdentifier } from "../../../../../components/Common/textIdentifier";
import { useSelector } from "react-redux";

export const DangerousMarkup = (props) => {
  const [markup, changeMarkup] = useState(props.markup);
  const [qid, changeQid] = useState(props.qid);
  useEffect(() => {
    changeMarkup(props.markup);
  }, [qid]);

  if (qid !== props.qid) changeQid(props.qid);

  return (
    <div
      key={qid}
      dangerouslySetInnerHTML={{
        __html: markup,
      }}
    />
  );
};

const ExamQuestion = (props) => {
  const { question, index, hideQuestionNumber} =
    props;
  const { language } = useSelector((state) => state.userSession.user);
  return (
    Boolean(question) && (
      <div class={style["exam--question--section"]}>
        {!hideQuestionNumber && (
          <div class={style["question--heading"]}>
            <div class={style["question--heading--left"]}>
              <div class={style["ques--num"]}>
                {textIdentifier["question"][language]} {question.quesNo}
              </div>
            </div>
          </div>
        )}
        <div class={style["exam--question"]}>
          <DangerousMarkup
            qid={"exam--question" + question.qid}
            markup={question.question}
          />
        </div>
        <div className={style["upload-button-cont"]}>
          {question && !question.autoGradingSubparts ? (
            <div
              className={style["upload-button"]}
              onClick={() => props.handleUploadImageModal(question, index)}
            >
              {question.images && question.images[0]
                ? textIdentifier["upload-edit-answers"][language]
                : textIdentifier["upload-answers"][language]}
            </div>
          ) : (
            question.autoGradingSubparts.map((subpart, subpartIndex) => {
              return (
                <div className={style["subpart-cont"]}>
                  <div class={style["subpart-no"]}>
                    {"(" + String.fromCharCode(97 + subpartIndex) + ")"}
                  </div>
                  <div
                    className={style["upload-button"]}
                    onClick={() =>
                      props.handleUploadImageModal(
                        question,
                        index,
                        subpart.subpart_no
                      )
                    }
                  >
                    {question.images && question.images[subpart.subpart_no]
                      ? textIdentifier["upload-edit-answers"][language]
                      : textIdentifier["upload-answers"][language]}
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    )
  );
};

export default ExamQuestion;
